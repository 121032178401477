import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useWizard, ActionButton, useForm, FormFieldSelect, Notifications} from "@netapp/bxp-design-system-react";
import _ from "lodash";
import classNames from "classnames";
import {getAzureProps} from "../../utils/azure";
import {FooterLayout, ContentLayout} from "./Page";
import InlineNotification from "../InlineNotification";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import commonStyles from "../../styles/common.module.scss";
import styles from "./SnapshotCopy.module.scss";


export const Footer = () => {
    const dispatch = useDispatch();
    const {state} = useWizard();
    const createVolumePending = useSelector(state => _.get(state, `cvs.createVolumePending`));

    const submitForm = () => {
        dispatch({
            type: `CVS:AZURE-CREATE-VOLUME`,
            payload: { createVolumeForm: state }
        });
    };

    return (
        <FooterLayout><ActionButton isSubmitting={createVolumePending} onClick={submitForm}>Continue</ActionButton></FooterLayout>
    );
};

const initialState =  {
    snapshot: null,
    volume: null
};

export const Content = () => {
    const dispatch = useDispatch();
    const {setState, state} = useWizard();
    const subnetId = state?.detailsAndTagsForm?.formState?.subnet?.subnetId || null;
    const { subscriptionId, netappAccountName, resourceGroupName, capacityPoolName, protocolTypes } = getAzureProps(state);
    const volumes = useSelector(state => _.get(state, `cvs.volumes`));
    const filteredVolumes = useMemo(() => {
        return _.filter(volumes, { subnetId, subscriptionId, netAppAccountName: netappAccountName, resourceGroupName, poolName: capacityPoolName, protocolTypes, lifecycleStatus: "Success" });
    }, [volumes, subnetId, subscriptionId, netappAccountName, resourceGroupName, capacityPoolName, protocolTypes]);
    const getVolumesPending = useSelector(state => _.get(state, `cvs.getVolumesPending`));
    const getVolumesError = useSelector(state => _.get(state, `cvs.getVolumesError`));
    const getVolumesShowError = useSelector(state => _.get(state, `cvs.getVolumesShowError`));
    const getSnapshotsResolved = useSelector(state => _.get(state, `cvs.getVolumeSnapshotsResolved`));
    const getSnapshotsPending = useSelector(state => _.get(state, `cvs.getVolumeSnapshotsPending`));
    const getSnapshotsError = useSelector(state => _.get(state, `cvs.getVolumeSnapshotsError`));
    const getSnapshotsShowError = useSelector(state => _.get(state, `cvs.getVolumeSnapshotsShowError`));
    const createVolumeError = useSelector(state => _.get(state, `cvs.createVolumeError`));
    const createVolumeShowError = useSelector(state => _.get(state, `cvs.createVolumeShowError`));
    const snapshots = useSelector(state => _.get(state, `cvs.volumeSnapshots`));
    const snapshotCopyForm = useForm(initialState);

    useEffect(() => {
        dispatch({
            type: `CVS:AZURE-GET-VOLUMES`
        });
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: {
                volumeSnapshots: [],
                getVolumeSnapshotsResolved: false,
                getVolumeSnapshotsError: null,
                getVolumeSnapshotsShowError: false,
                getVolumesResolved: false,
                getVolumesError: null,
                getVolumesShowError: false,
                createVolumeResolved: false,
                createVolumeError: null,
                createVolumeShowError: false,
            }
        });
    }, [dispatch]);

    useEffect(() => {
        setState({snapshotCopyForm})
    }, [snapshotCopyForm, setState]);

    const notifications = [{
        type: "error",
        description: getVolumesError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { getVolumesShowError: false }}),
        showError: getVolumesShowError
    }, {
        type: "error",
        description: getSnapshotsError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { getVolumeSnapshotsShowError: false }}),
        showError: getSnapshotsShowError
    }, {
        type: "error",
        description: createVolumeError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { createVolumeShowError: false }}),
        showError: createVolumeShowError
    }];

    const title = () => <span>Volume from Snapshot <i>(Optional)</i></span>;

    const onVolumeSelection = volume => {
        if (!volume) {
            snapshotCopyForm.resetForm({ ...snapshotCopyForm.formState, volume: null, snapshot: null });
            return;
        }
        if (!_.isEqual(volume, snapshotCopyForm.formState.volume)) {
            dispatch({
                type: "CVS:CLEAR-STATE",
                payload: { getVolumeSnapshotsResolved: false }
            });
            dispatch({
                type: "CVS:AZURE-GET-VOLUME-SNAPSHOTS",
                payload: { volume }
            });
            snapshotCopyForm.resetForm({ ...snapshotCopyForm.formState, volume, snapshot: null })
        }
    };

    const onSnapshotSelection = snapshot => {
        if (_.isEmpty(snapshotCopyForm.formState.volume)) {
            snapshotCopyForm.resetForm({ ...snapshotCopyForm.formState, snapshot: null });
        }
        if (!_.isEqual(snapshot, snapshotCopyForm.formState.snapshot)) {
            snapshotCopyForm.resetForm({ ...snapshotCopyForm.formState, snapshot })
        }
    };

    return (
        <ContentLayout className={styles["container"]} title={title()}>
            <div className={classNames(typography.body14, styles["info"])}>You can create the volume from an existing Snapshot copy. This step is optional.</div>
            <FormFieldSelect
                value={snapshotCopyForm.formState.volume}
                onChange={onVolumeSelection}
                isClearable={true}
                isSearchable={true}
                name="volume"
                label="Volume Name"
                placeholder="- Select a volume -"
                options={filteredVolumes}
                isLoading={getVolumesPending}
                className={classNames(commonStyles["form-field"], styles["volumes"])}
            />
            <FormFieldSelect
                value={snapshotCopyForm.formState.snapshot}
                onChange={onSnapshotSelection}
                isClearable={true}
                isSearchable={true}
                name="snapshot"
                label="Snapshot Name"
                placeholder="- Select a snapshot -"
                options={(!getSnapshotsError && snapshotCopyForm.formState.volume && snapshots) || []}
                isLoading={getSnapshotsPending}
                className={commonStyles["form-field"]}
            />
            {getSnapshotsResolved && _.isEmpty(snapshots) && <InlineNotification className={styles["snapshot-warn"]} type={"warning"} text={"No snapshots exist for the selected volume."}>No snapshots found for the selected volume</InlineNotification>}
            <Notifications className={commonStyles["error-area"]} notifications={_.filter(notifications, { showError: true })}/>
        </ContentLayout>
    );
};
