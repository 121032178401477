import React, {useEffect} from "react";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {Button, Modal, ModalHeader, ModalFooter} from "@netapp/bxp-design-system-react";
import {ModalContent} from "../../../Modal";
import styles from "./DeleteWeModal.module.scss";

const textMapper = {
    aws:{
        title: "Remove Cloud Volumes Service",
        bodyTitle: `Are you sure you want to remove Cloud Volumes Service`,
        bodyText: `This action removes Cloud Volumes Service from BlueXP. It doesn’t delete your Cloud Volumes Service account or volumes. You can add Cloud Volumes Service back to BlueXP at any time.`
    },
    azure:{
        title: "Remove Azure NetApp Files",
        bodyTitle: `Are you sure you want to remove Azure NetApp Files`,
        bodyText: `This action removes Azure NetApp Files from BlueXP. It doesn’t delete your Azure NetApp Files account or volumes. You can add Azure NetApp Files back to BlueXP at any time.`
    },
    gcp: {
        title: "Remove Cloud Volumes Service",
        bodyTitle: `Are you sure you want to remove Cloud Volumes Service`,
        bodyText: `This action removes Cloud Volumes Service from BlueXP. It doesn’t delete your Cloud Volumes Service account or volumes. You can add Cloud Volumes Service back to BlueXP at any time.`
    }
};

export default ({ flavour, we, target, closeTrigger}) => {
    const dispatch = useDispatch();
    const error = useSelector(state => _.get(state, "cvs.deleteWeError"));
    const showError = useSelector(state => _.get(state, "cvs.deleteWeShowError"));
    const pending = useSelector(state => _.get(state, "cvs.deleteWePending"));

    useEffect(() => {
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: { deleteWeError: null, deleteWeResolved: false, deleteWeShowError: false }
        });
    }, [dispatch]);

    const onActionClicked = () => dispatch({
        type: "CVS:DELETE-WE"
    });

    return (
        <Modal>
            <ModalHeader>{textMapper[flavour].title}</ModalHeader>
            <ModalContent>
                <div className={styles["question"]}>{textMapper[flavour].bodyTitle} <span className={styles["note"]}>{`${we.name}`}</span>?</div>
                <div><span className={styles["note"]}>Note:</span> {textMapper[flavour].bodyText}</div>
            </ModalContent>
            <ModalFooter error={showError && error}>
                <Button disabled={pending} isSubmitting={pending} onClick={onActionClicked}>{"Remove"}</Button>
                <Button color="secondary" disabled={pending} onClick={closeTrigger}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};
