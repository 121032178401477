import features from "./../consts/features";
import _ from "lodash";

/*eslint default-case: 0*/

let userId = null;
const alwaysEnabledFeatures = (process.env.REACT_APP_FEATURES || "").split(";");

export default (state = {active: {}, list: []}, {payload, type}) => {
    switch (type){
        case "AUTH:REFRESH-SSO-SUCCESS": {
            userId = _.get(payload, "idTokenPayload.sub");

            const getStatus = (key) => alwaysEnabledFeatures.includes(key) || localStorage.getItem(`portal.feature.${key}.${userId}`) === "true";

            const active = {
                demoMode: getStatus('demoMode')
            };
            const list = _.cloneDeep(features);

            list.forEach(feature => {
                const status = getStatus(feature.key);
                feature.active = status;
                feature.status = status ? "ON" : "OFF";
                feature.toggleAble = !alwaysEnabledFeatures.includes(feature.key);
                if(status) {
                    active[feature.key] = true
                }
            });

            return {active, list};
        }
        case "FEATURES:TOGGLE-FEATURE": {
            const isActive = state.active[payload];
            const storageKey = `portal.feature.${payload}.${userId}`;

            if(isActive){
                localStorage.removeItem(storageKey);
            } else {
                localStorage.setItem(storageKey, "true");
            }

            window.location.reload();
        }
    }

    return state;
};
