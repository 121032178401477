import React, {useRef, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {ContentLayout} from "../../../Page";
import {filterVolumes, getVolumesStatistics} from "../../../../utils/common";
import numeral from "numeral";
import SearchWidget from "../../../../../../components/widgets/SearchWidget";
import {Link} from "react-router-dom";
import classNames from "classnames";
import Card from "../volume-card/Card";
import DeleteVolumeModal from "./DeleteVolumeModal";
import {VolumeIcon} from "@netapp/bxp-design-system-react/icons/monochrome";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import button from "@netapp/bxp-design-system-react/styles/buttons.module.scss";
import styles from "./Gallery.module.scss";

const providerIdMap = {
    'aws': 'volumeId',
    'azure': 'id',
    'gcp': 'volumeId'
};

export default ({volumes}) => {
    const dispatch = useDispatch();
    const accountId = useSelector(state => _.get(state, "cvs.accountId"));
    const credentialsId = useSelector(state => _.get(state, "cvs.credentialsId"));
    const flavour = useSelector(state => _.get(state, "cvs.cvsFlavour"));
    const newlyCreatedAnfVolume = useSelector(state => _.get(state, "cvs.newlyCreatedAnfVolume"));
    const volumeToDelete = useSelector(state => _.get(state, "cvs.volumeToDelete"));
    const isDeleteVolumeModalOpen = useSelector(state => _.get(state, "cvs.isDeleteVolumeModalOpen"));
    const [filterValue, setFilterValue] = useState("");
    const totalVolumeCount = volumes.length;
    const filteredVolumes = useMemo(() => {
        return filterVolumes(volumes, filterValue.toLowerCase(), newlyCreatedAnfVolume);
    }, [filterValue, volumes, newlyCreatedAnfVolume]);
    const { totalAllocatedBytes, totalUsedBytes } = getVolumesStatistics(filteredVolumes);
    const filteredVolumesRegionsCount = _.keys(_.groupBy(filteredVolumes,'region')).length;
    const buttonRef = useRef(null);

    const openDeleteModal = volume => {
        dispatch({
            type: "CVS:OPEN-DELETE-VOLUME-MODAL",
            payload: { volumeToDelete: volume }
        });
    };

    const closeDeleteModal = () => {
        dispatch({
            type: "CVS:CLOSE-DELETE-VOLUME-MODAL"
        });
    };


    return (
        <ContentLayout className={styles["container"]}>
            <div className={styles["content"]}>
                <div className={styles["actions-and-info"]}>
                    {filteredVolumes.length > 0 && <div className={classNames(typography.body14, styles["info"])}>
                        {filteredVolumes.length < totalVolumeCount && `${filteredVolumes.length} out of `} {totalVolumeCount} Volumes
                        | {`${filteredVolumesRegionsCount} ${filteredVolumesRegionsCount > 1 ? 'Regions' : 'Region'}`}
                        | {numeral(totalAllocatedBytes).format('0.0 ib')} Provisioned
                        | {numeral(totalUsedBytes).format('0.0 ib')} Total Used</div>}
                    <div className={styles["search-and-create"]}>
                        {totalVolumeCount > 1 && <SearchWidget className={styles["search-widget"]} setFilter={setFilterValue}/>}
                        <Link id={"working-environment-tab-add-volume"} className={classNames(button.primary, styles["create-btn"])}
                              to={`/components/cvs/${flavour}/${accountId}/${credentialsId}/create-volume`}>
                            Add New Volume
                        </Link>
                    </div>
                </div>
                {filteredVolumes.length > 0
                    ? <div className={styles["gallery"]}>
                        {_.map(filteredVolumes, volume => <Card key={volume[providerIdMap[flavour]]} volume={volume} openConfirmDeleteDialog={openDeleteModal}/>)}
                    </div>
                    : <div className={styles["no-volumes-watermark"]}>
                        <VolumeIcon/>
                        No Volumes Found
                    </div>
                }
                {isDeleteVolumeModalOpen && <DeleteVolumeModal target={buttonRef} closeTrigger={closeDeleteModal} volumeToDelete={volumeToDelete}/>}
            </div>
        </ContentLayout>
    );
};
