const defaultState = () => ({
    communicationsMenuOpen: false,
    userMenuOpen: false,
    notificationMenuOpen: false
});

/*eslint default-case: 0*/

export default (state = defaultState(), {payload, type}) => {
    switch(type){
        case "UI:OPEN-RIGHT-MENU": {
            switch (payload.menuType){
                case "users":
                    return { communicationsMenuOpen: false, userMenuOpen: true, notificationMenuOpen: false};
                case "notifications":
                    return { communicationsMenuOpen: false, userMenuOpen: false, notificationMenuOpen: true};
                case "community":
                    return { communicationsMenuOpen: true, userMenuOpen: false, notificationMenuOpen: false};
                default:
                    return state
            }
        }
        case "UI:CLOSE-RIGHT-MENU": {
            switch (payload.menuType){
                case "users":
                    return { ...state, userMenuOpen: false};
                case "notifications":
                    return { ...state, notificationMenuOpen: false};
                case "community":
                    return { ...state, communicationsMenuOpen: false};
                default:
                    return state;
            }
        }
    }
    return state;
};