import _ from "lodash";
import * as actions from "../actions";

/* eslint default-case: 0 */

const defaultState = () => ({ status: actions.STATUS_PENDING, testStatus: actions.STATUS_PENDING, userAccountStatus: actions.STATUS_PENDING });

const extractFirstName = fullName => (fullName || "").trim().split(" ")[0];


const userAccountStorageKey = (userId) => `portal.userAccount.${userId}`;

export default (state = defaultState(), { payload, type }) => {
    switch (type) {
        case "AUTH:REFRESH-SSO-SUCCESS": {
            const isNetAppInternal = payload.idTokenPayload["http://cloud.netapp.com/internal"] === "NetApp";
            const name = payload.idTokenPayload["http://cloud.netapp.com/user_metadata"].name || "";
            const firstName = extractFirstName(name);
            const email = _.get(payload, 'idTokenPayload["http://cloud.netapp.com/user_metadata"].email');
            const scopeArr = (payload.scope || "").split(" ");
            const userId = _.get(payload, "idTokenPayload.sub");
            const isFederatedUser = _.get(payload, 'idTokenPayload["http://cloud.netapp.com/is_federated"]', false);
            const redirecting = payload.paramsForUI.continueToCvoTrial || payload.paramsForUI.continueToCvoTrial2;

            return {
                status: actions.STATUS_SUCCESS,
                userId,
                accessToken: payload.accessToken,
                authenticated: true,
                data: payload.idTokenPayload,
                isEmailVerified: payload.idTokenPayload['email_verified'],
                firstName,
                name,
                isFederatedUser,
                scopes: _(scopeArr).keyBy().mapValues(_.constant(true)).value(),
                isNetAppInternal,
                email,
                redirecting,
                userAccounts: state.userAccounts || [],
                userAccount: state.userAccount,
                userAccountStatus: state.userAccountStatus,
                userAccountOptions: state.userAccountOptions,
                renewTimeout: false
            };
        }
        case "AUTH:RENEW-SSO-TIMEOUT": {
            return {...state, renewTimeout: true}
        }
        case "AUTH:TEST-FEDERATION-CONNECTION-SUCCESS": {
            const email = _.get(payload, 'idTokenPayload["http://cloud.netapp.com/user_metadata"].email');

            return {
                testStatus: actions.STATUS_SUCCESS,
                email
            };
        }
        case "AUTH:TEST-FEDERATION-CONNECTION-ERROR": {
            return { testStatus: actions.STATUS_ERROR, error: payload };
        }
        case "AUTH:REFRESH-SSO-FAILED": {
            const err = payload;
            console.log(err);
            return { status: actions.STATUS_ERROR, authenticated: false };
        }
        case "AUTH:NO-AUTH": {
            return { status: "NO-AUTH", authenticated: false };
        }
        case "USER:UPDATE-INFO": {
            const firstName = extractFirstName(payload.newName);
            return {
                ...state,
                firstName,
                data: {
                    ...state.data,
                    "http://cloud.netapp.com/user_metadata": {
                        ...state.data["http://cloud.netapp.com/user_metadata"],
                        name: payload.newName
                    }
                }
            };
        }
        case "AUTH:GET-ACCOUNTS-SUCCESS": {
            const { accounts } = payload;
            const userAccountOptions = accounts.map(account => ({label:account.accountName, value:account.accountPublicId}));
            const localStorageKey = `portal.userAccount.${state.userId}`;
            const storedAccountId = window.localStorage.getItem(localStorageKey);
            let userAccount = null;

            if(storedAccountId){
                userAccount = _.find(userAccountOptions, {value: storedAccountId});
                if(!userAccount && storedAccountId){
                    window.localStorage.removeItem(localStorageKey)
                }
            } else if(accounts.length > 0) {
                userAccount = userAccountOptions[0];
                window.localStorage.setItem(userAccountStorageKey(state.userId), userAccount.value);
            }

            return {...state, userAccounts: accounts, userAccount, userAccountOptions, userAccountStatus: actions.STATUS_SUCCESS}
        }
        case "AUTH:COMPONENT-MODE-SET-ACCOUNT": {
            const { accountId } = payload;
            return {...state, userAccount: {value: accountId}, userAccountStatus: actions.STATUS_SUCCESS};
        }
        case "AUTH:GET-ACCOUNTS-FAILED": {
            return {...state, userAccounts: [], userAccountOptions: [], userAccountsError: payload, userAccountStatus: actions.STATUS_ERROR}
        }
        case "AUTH:USER-ACCOUNT-CHANGE": {
            window.localStorage.setItem(userAccountStorageKey(state.userId), payload.accountId);
            window.location.reload();
        }
    }

    return state;
};
