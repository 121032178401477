import React from 'react'
import styles from './SmartBack.module.scss'
import {Link, withRouter} from 'react-router-dom';
import { BackArrowIconIcon as BackArrowIcon } from '@netapp/bxp-design-system-react/icons/monochrome';

const resolvePath = pathname => {
    const index = pathname.lastIndexOf("/");
    return pathname.substring(0, index) || "/";
};

const SmartBack = ({location}) => {
    const resolvedPath = resolvePath(location.pathname);
    if(resolvedPath === "/"){
        return ""
    } else {
        return  <span className={styles.base}><Link to={resolvedPath}><BackArrowIcon/>Back</Link></span>
    }
};

export default withRouter(SmartBack);

