import * as actions from "../actions";

const defaultState = () => ({
    status: actions.STATUS_PENDING
});

/*eslint default-case: 0*/

export default (state = defaultState(), {payload, type}) => {
    switch(type){
        case "TENANCY:GET-USERS-SUCCESS": {
            const { users } = payload;
            return {status: actions.STATUS_SUCCESS, users}
        }
        case "TENANCY:GET-USERS-ERROR": {
            return {status: actions.STATUS_ERROR, users: []}
        }
    }
    return state;
};