import React from "react";
import * as yup from "yup";
import {FormFieldSelect, FormFieldInputNew} from "@netapp/bxp-design-system-react";
import {awsRegions} from "../../utils/aws";
import classNames from "classnames";
import commonStyles from "../../styles/common.module.scss";
import styles from "./Form.module.scss";

export const initialState = {
    name: "",
    apiKey: "",
    secretKey: "",
    region: null
};

export const schema = yup.object().shape({
    name: yup.string().required("Service name is required."),
    apiKey: yup.string().required("Service API key is required."),
    secretKey: yup.string().required("Service secrete key is required."),
    region: yup.object().nullable().required("Region selection is required.")
});

export default ({ form }) => {
    return (
        <div className={styles["form"]}>
            <FormFieldInputNew
                form={form}
                name="name"
                label="Working Environment Name"
                inputStyle={{ width: "360px" }}
                maxChars={256}
                autoFocus={true}
                className={classNames(commonStyles["form-field"], styles["field"])}
            />
            <FormFieldInputNew
                form={form}
                name="apiKey"
                label="Cloud Volumes Service API Key"
                className={classNames(commonStyles["form-field"], styles["field"])}
                autoComplete="off"
            />
            <FormFieldInputNew
                form={form}
                type="password"
                name="secretKey"
                label="Cloud Volumes Service Secret Key"
                className={classNames(commonStyles["form-field"], styles["field"])}
            />
            <FormFieldSelect
                form={form}
                name="region"
                label="AWS Region"
                placeholder="- Select a project -"
                isSearchable={true}
                options={awsRegions}
                className={classNames(commonStyles["form-field"], styles["last-form-element"])}
            />
        </div>
    );
};
