import React from 'react'
import { trackButtonClick } from "./../../utils/mrTracker"
import _ from 'lodash'

export default (props) => {
    const {category, label, onClick, action} = props;
    return <button {...props} onClick={(e) => {
        if(_.isFunction(onClick))
            onClick(e);
        trackButtonClick({category, label, action});
    }}/>
}

