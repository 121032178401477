import axios from "axios"

export default ({next, action}) => {
    const { payload, type } = action;
    if (type === "NOTIFICATIONS:CLEAR") {
        if(payload){
            axios.post("/portal/set-notification-visibility", {notificationsList: payload, visibility: 'deleted'});
        }
    } else if (type === "NOTIFICATIONS:DELETE") {
        axios.post("/portal/set-notification-visibility", {notificationsList: [payload], visibility: 'deleted'});
    } else if (type === "NOTIFICATIONS:SET-VIEWED") {
        axios.post("/portal/set-notification-visibility", {notificationsList: payload, visibility: 'viewed'});
    }

    return next(action);
};