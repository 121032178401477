import React from 'react';
import { connect } from 'react-redux';
import ExternalLink from "./../widgets/ExternalLink";

import { servicesMap } from "../../consts/sevicesMap";
import styles from "./DemoView.module.scss";
import buttons from "../../styles/button/button.module.scss";
import { InlineLoaderIcon as InlineLoader } from "@netapp/bxp-design-system-react/icons/loaders";
import CloudCentralSecondaryHeader from "./../headers/CloudCentralSecondaryHeader";

import {registerFeature} from './../../middlewares/apiMiddleware';
import demoMiddleware from "./../../middlewares/featureApis/demoMiddleware";
registerFeature("DEMO", demoMiddleware);

const DemoParent = ({tieringRequestState, tieringResetError, resetDemo}) => {
    const CloudTieringLogo = servicesMap["cloud-tiering"].logo;

    return (
            <div>
                <CloudCentralSecondaryHeader title="Demo"/>
                <div className={styles["main-container"]}>
                    <h4 className={styles["service-title"]}><CloudTieringLogo className={styles["service-icon"]}/>Cloud Tiering</h4>
                    <div className={styles["buttons-container"]}>
                        <ExternalLink className={buttons.primary} linkKey="demos.cloudTiering" newTab>Goto Demo</ExternalLink>
                        {tieringRequestState === "PENDING" ? <InlineLoader className={styles.loader}/> : <button className={buttons.primary} onClick={resetDemo}>Reset Demo</button>}
                    </div>
                    {tieringRequestState === "ERROR" && <div className={styles["reset-error"]}>{tieringResetError}</div>}
                    {tieringRequestState === "SUCCESS" && <div className={styles["reset-success"]}>Reset Succeeded</div>}
                </div>
            </div>
        );
};

const mapStateToProps = state => {
    return {
        tieringRequestState: state.demo.tieringRequestState,
        tieringResetError: state.demo.tieringResetError
    };
};

const mapDispatchToProps = dispatch => {
    return {
        resetDemo: () => {
            dispatch({
                type: "DEMO:RESET_CLOUD_TIERING",
            })
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DemoParent);