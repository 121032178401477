import React from "react";
import _ from 'lodash'
import SVG from "react-inlinesvg";
import classNames from "classnames";

let servicesArray = [];
let servicesMap = {};

const setServicesArray = (servicesJson) => {
    servicesArray = _.reject(servicesJson, {isFeature: true}).map(service => ({
        ...service,
        logo: (props) => <SVG {...props} src={service.logo} className={classNames(props.className, `cc-service-icon-${service.category || "services"}`)}/>,
        banner: (props) => <SVG {...props} src={service.banner}/>
    }));
    servicesMap = _.keyBy(servicesArray, o => o.key);
};

export  { setServicesArray, servicesArray, servicesMap };
