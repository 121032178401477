import axios from "axios";
import * as actions from "../../actions";

export default ({ dispatch, getState, next, action }) => {
    const { payload, type } = action;
    if (type === "USER:UPDATE-INFO") {
        axios.post("portal/update-user-info", { userName: payload.newName })
            .catch(err => {
                console.log(err);
            });
    } else if (type === "USER:CHANGE-PASSWORD") {
        window.auth.auth.changePassword({
            connection: 'Username-Password-Authentication',
            email: getState().auth.email
        }, err => {
            if (err) {
                console.log("Failed requesting to send change password email.");
                console.log(err.message);
            }
        });
    } else if (type === "USER:GENERATE-REFRESH-TOKEN") {
        const { accessToken, audience, scope } = payload;
        window.auth.auth.authorize({
            scope: scope || "offline_access",
            redirectUri: `${window.__react_app_portal_backend_base_url}/misc/token-callback`,
            audience,
            clientId: window.__auth__.refreshTokenClientId,
            responseMode: "query",
            responseType: "code",
            state: window.btoa(accessToken),
            prompt: "none"
        });
    } else if (type === "USER:FETCH-REFRESH-TOKENS") {
        const promise = axios.get("/portal/refresh-token");

        promise.then(
            r => dispatch({ type: "GET-REFRESH-TOKEN", status: actions.STATUS_SUCCESS, payload: r }),
            e => dispatch({ type: "GET-REFRESH-TOKEN", status: actions.STATUS_ERROR, payload: e })
        );

        return next({
            type: "GET-REFRESH-TOKEN",
            status: actions.STATUS_PENDING
        });
    } else if (type === "USER:REVOKE-REFRESH-TOKENS") {
        return axios.post("/portal/revoke-refresh-token").then(
            () => dispatch({ type: "USER:FETCH-REFRESH-TOKENS" })
        );
    }

    return next(action);
};
