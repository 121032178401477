import React from "react";
import {useSelector} from "react-redux";
import _ from "lodash";
import {Wizard, WizardStepTransitionRenderer, useWizard} from "@netapp/bxp-design-system-react";
import {Header, HeaderWrapper} from "./Page";
import {PageLayout, PageLayoutNoFooter} from "../Page";
import Loader from "../../../../Loader";
import * as NetappAccount from "./NetappAccountAzure";
import * as CapacityPool from "./CapacityPoolAzure";
import * as DetailsAndTags from "./DetailsAndTagsAzure";
import * as Protocol from "./ProtocolAzure";
import * as SnapshotCopy from "./SnapshotCopyAzure";

const stepsMap = {
    "netapp-account": NetappAccount,
    "capacity-pool": CapacityPool,
    "details-and-tags": DetailsAndTags,
    "protocol": Protocol,
    "snapshot-copy": SnapshotCopy
};

const stepPaths = {
    "create": [
        {label: "Account", key: "netapp-account"},
        {label: "Capacity Pool", key: "capacity-pool"},
        {label: "Details & tags", key: "details-and-tags"},
        {label: "Protocol", key: "protocol"},
        {label: "Snapshot Copy", key: "snapshot-copy"}
    ],
};

export const CreateVolume = () => {
    const {currentStep} = useWizard();
    const {Footer: StepFooter} = stepsMap[currentStep];

    return (
        <PageLayout>
            <Header flavour={"azure"} title={"Add Volume Wizard"}/>
            <div style={{position: "relative", overflowX: "hidden"}}>
                <WizardStepTransitionRenderer>
                    {({Content: StepContent}) => <StepContent/>}
                </WizardStepTransitionRenderer>
            </div>
            <StepFooter/>
        </PageLayout>
    );
};

export const AzureCreateVolumePageWrapper = () => {
    const urlParamsAreSet = useSelector(state => _.get(state, "cvs.urlParamsAreSet"));

    const render = () => {
        if (!urlParamsAreSet) {
            return (
                <PageLayoutNoFooter>
                    <HeaderWrapper flavour={"azure"}/>
                    <Loader/>
                </PageLayoutNoFooter>
            );
        }

        return (
            <Wizard stepsMap={stepsMap} stepPaths={stepPaths} initialStep={"netapp-account"} initialPath={"create"}>
                <CreateVolume/>
            </Wizard>
        );
    };

    return render();
};
