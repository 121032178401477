import React, {useState} from "react";
import classNames from "classnames";
import ContentLayout, {Title} from "../ContentLayout";
import {RadioButtonCard} from "../../../RadioButtonWidget";
import { Popover } from "@netapp/bxp-design-system-react";
import {protocolMapper} from "../../../../utils/common";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {CopyIcon as Copy} from "@netapp/bxp-design-system-react/icons/monochrome";
import styles from "./Content.module.scss";

const widgetButtons = [
    { label: "NFS", name: "protocol", value: "nfs" },
    { label: "SMB", name: "protocol", value: "smb" }
];

export const Smb = ({ volume }) => {
    return (
        <>
            <div className={classNames(styles["text-title"])}>Mapping your network drive</div>
            <ol>
                <li>
                    <div>
                        Click the <span className={styles["inline-bold"]}>Start</span> button and then click on <span className={styles["inline-bold"]}>Computer</span>.
                    </div>
                </li>
                <li>
                    <div>
                        Click <span className={styles["inline-bold"]}>Map Network Drive</span>.
                    </div>
                </li>
                <li>
                    <div>
                        In the <span className={styles["inline-bold"]}>Drive</span> list, click any available drive letter.
                    </div>
                </li>
                <li>
                    <div>
                        In the <span className={styles["inline-bold"]}>Folder</span> box, type this:
                        <CopyToClipboard text={`\\\\${volume.smbServerFQDN}\\${volume.creationToken}`}>
                            <div className={styles["copy-container"]}>
                                <span>{`\\\\${volume.smbServerFQDN}\\${volume.creationToken}`}</span>
                                <button><Copy className={styles["copy-icon"]}/></button>
                                <Popover triggerClassName={styles["popover"]}>Copy to clipboard</Popover>
                            </div>
                        </CopyToClipboard>
                        To connect every time you log on to your computer, check the <span className={styles["inline-bold"]}>Reconnect at logon</span> option.
                    </div>
                </li>
                <li>
                    <div>
                        Click <span className={styles["inline-bold"]}>Finish</span>.
                    </div>
                </li>
            </ol>
        </>
    );
};


export const Nfs = ({ volume }) => {
    const ipAddressV3 = volume.ipAddresses["NFSv3"];
    const ipAddressV4 = volume.ipAddresses["NFSv4"];

    return (
        <>
            <div className={classNames(styles["text-title"])}>Setting up your instance</div>
            <ol>
                <li>
                    <div>
                        Open an SSH client and connect to your instance.
                    </div>
                </li>
                <li>
                    <div>
                        Install the nfs client on your instance.<br/>
                        On Red Hat Enterprise Linux or SuSE Linux instance:
                        <CopyToClipboard text="$ sudo yum install -y nfs-utils">
                            <div className={styles["copy-container"]}>
                                <span>$ sudo yum install -y nfs-utils</span>
                                <button><Copy className={styles["copy-icon"]}/></button>
                                <Popover triggerClassName={styles["popover"]}>Copy to clipboard</Popover>
                            </div>
                        </CopyToClipboard>
                        On an Ubuntu or Debian instance:
                        <CopyToClipboard text="$ sudo apt-get install nfs-common">
                            <div className={styles["copy-container"]}>
                                <span>$ sudo apt-get install nfs-common</span>
                                <button><Copy className={styles["copy-icon"]}/></button>
                                <Popover triggerClassName={styles["popover"]}>Copy to clipboard</Popover>
                            </div>
                        </CopyToClipboard>
                    </div>
                </li>
            </ol>
            <div className={classNames(styles["text-title"])}>Mounting your volume</div>
            <ol>
                <li>
                    <div>
                        Create a new directory on your instance:
                        <CopyToClipboard text="$ sudo mkdir /dir">
                            <div className={styles["copy-container"]}>
                                <span>$ sudo mkdir /dir</span>
                                <button><Copy className={styles["copy-icon"]}/></button>
                                <Popover triggerClassName={styles["popover"]}>Copy to clipboard</Popover>
                            </div>
                        </CopyToClipboard>
                    </div>
                </li>
                {ipAddressV3 && <li>
                    <div>
                        Mount your NFSv3 volume using the command below:
                        <CopyToClipboard text={`sudo mount -t nfs -o rw,hard,rsize=65536,wsize=65536,vers=3,tcp ${ipAddressV3}:/${volume.creationToken}<dest_dir>`}>
                            <div className={styles["copy-container"]}>
                                <span title={`sudo mount -t nfs -o rw,hard,rsize=65536,wsize=65536,vers=3,tcp ${ipAddressV3}:/${volume.creationToken}<dest_dir>`}>{`sudo mount -t nfs -o rw,hard,rsize=65536,wsize=65536,vers=3,tcp ${ipAddressV3}:/${volume.creationToken} <dest_dir>`}</span>
                                <button><Copy className={styles["copy-icon"]}/></button>
                                <Popover triggerClassName={styles["popover"]}>Copy to clipboard</Popover>
                            </div>
                        </CopyToClipboard>
                    </div>
                </li>}
                {ipAddressV4 && <li>
                    <div>
                        Mount your NFSv4 volume using the command below:
                        <CopyToClipboard text={`sudo mount -t nfs -o rw,hard,rsize=65536,wsize=65536,vers=4,tcp ${ipAddressV4}:/${volume.creationToken} <dest_dir>`}>
                            <div className={styles["copy-container"]}>
                                <span title={`sudo mount -t nfs -o rw,hard,rsize=65536,wsize=65536,vers=4,tcp ${ipAddressV4}:/${volume.creationToken} <dest_dir>`}>{`sudo mount -t nfs -o rw,hard,rsize=65536,wsize=65536,vers=4,tcp ${ipAddressV4}:/${volume.creationToken} <dest_dir>`}</span>
                                <button><Copy className={styles["copy-icon"]}/></button>
                                <Popover triggerClassName={styles["popover"]}>Copy to clipboard</Popover>
                            </div>
                        </CopyToClipboard>
                    </div>
                </li>}
                <li>
                    <div>
                        Repeat the above two steps for future mount targets.
                    </div>
                </li>
            </ol>
        </>
    );
};

export default ({volume}) => {
    const isDual = protocolMapper[volume.protocol] === "dual";
    const [protocolOption, setProtocolOption] = useState(isDual ? "nfs" : protocolMapper[volume.protocol]);
    const handleRadioButtonSelection = e => {
        setProtocolOption(e.target.value);
    };

    return (
        <ContentLayout className={styles["content"]}>
            <Title className={classNames("not-spaced", styles["title"])}>Mount the volume - <span className={"vol-name"} title={volume.name}>{volume.name}</span></Title>
            {isDual && <RadioButtonCard
                buttons={widgetButtons}
                onChange={handleRadioButtonSelection}
                widgetLabel="View mount instructions for:"
                checkedValue={protocolOption}
                className={styles["widget"]}
            />}
            {protocolOption === "nfs" ? <Nfs volume={volume}/> : <Smb volume={volume}/>}
        </ContentLayout>
    );
};
