import React, {useEffect} from "react";
import _ from "lodash";
import classNames from "classnames";
import {ActionButton, FormFieldSelect, useForm, Notifications} from "@netapp/bxp-design-system-react";
import {useDispatch, useSelector} from "react-redux";
import ContentLayout, {Title} from "../../common/ContentLayout";
import ActionButtonContainer from "../../common/ActionButtonContainer";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import commonStyles from "../../../../styles/common.module.scss";
import styles from "./Content.module.scss";

const initialState = {
    capacityPool: null
};

const validator = state => {
    const errors = {};
    if (!state.capacityPool) {
        errors.capacityPool = "Capacity pool selection is required."
    }
    return errors;
};

export default ({volume}) => {
    const dispatch = useDispatch();
    const form = useForm(initialState, validator);
    const capacityPools = useSelector(state => _.get(state, "cvs.capacityPools"));
    const getCapacityPoolsPending = useSelector(state => _.get(state, "cvs.getCapacityPoolsPending"));
    const getCapacityPoolsError = useSelector(state => _.get(state, "cvs.getCapacityPoolsError"));
    const getCapacityPoolsShowError = useSelector(state => _.get(state, "cvs.getCapacityPoolsShowError"));
    const updateVolumePoolPending = useSelector(state => _.get(state, "cvs.updateVolumePoolPending"));
    const updateVolumePoolError = useSelector(state => _.get(state, "cvs.updateVolumePoolError"));
    const updateVolumePoolShowError = useSelector(state => _.get(state, "cvs.updateVolumePoolShowError"));

    useEffect(() => {
        dispatch({
            type: `CVS:AZURE-GET-CAPACITY-POOLS`,
            payload: { caller: "volume-view", subscriptionId: volume.subscriptionId, resourceGroupName: volume.resourceGroupName, netappAccountName: volume.netAppAccountName }
        });
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: {
                capacityPools: [],
                getCapacityPoolsPending: false,
                getCapacityPoolsError: null,
                getCapacityPoolsShowError: false,
                updateVolumePoolPending: false,
                updateVolumePoolError: null,
                updateVolumePoolShowError: false
            }
        });
    }, [dispatch]);

    const notifications = [{
        type: "error",
        description: getCapacityPoolsError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { getCapacityPoolsShowError: false }}),
        showError: getCapacityPoolsShowError
    }, {
        type: "error",
        description: updateVolumePoolError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { updateVolumePoolShowError: false }}),
        showError: updateVolumePoolShowError
    }];

    const updateCapacityPool = () => {
        dispatch({
            type: "CVS:AZURE-UPDATE-VOLUME-CAPACITY-POOL",
            payload: {
                poolId: form.formState.capacityPool.id,
                subscriptionId: volume.subscriptionId,
                resourceGroupName: volume.resourceGroupName,
                netappAccountName: volume.netAppAccountName,
                poolName: volume.poolName,
                volumeName: volume.name
            }
        });
    };

    return (
        <ContentLayout className={styles["content"]}>
            <Title className={"not-spaced"}>Change service level - <span className={"vol-name"} title={volume.name}>{volume.name}</span></Title>
            <div className={classNames(typography.body14, styles["info"])}>Select a capacity pool with the service level you want to use:</div>
            <FormFieldSelect
                isSearchable={true}
                form={form}
                name="capacityPool"
                label="Capacity Pool and Service Level"
                placeholder="- Select a capacity pool -"
                options={capacityPools || []}
                disabled={_.isNil(capacityPools) || getCapacityPoolsPending}
                isLoading={getCapacityPoolsPending}
                className={styles["form-field"]}
            />
            <ActionButtonContainer className={styles["button-container"]}>
                <ActionButton isSubmitting={updateVolumePoolPending} onClick={e => form.handleFormSubmit(updateCapacityPool)(e)}>Change</ActionButton>
            </ActionButtonContainer>
            <Notifications className={commonStyles["error-area"]} notifications={_.filter(notifications, { showError: true })}/>
        </ContentLayout>
    );
};
