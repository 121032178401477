import React from "react";
import {useDispatch} from "react-redux";
import {BackButton} from "../../BackButton";

export const BackToGallery = () => {
    const dispatch = useDispatch();
    const onBackClicked = () => {
        dispatch({
            type: "CVS:RETURN-TO-GALLERY"
        });
    };
    return <BackButton onBackClicked={onBackClicked}/>
};
