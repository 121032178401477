import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { createBrowserHistory } from 'history'
import createStaticReducers from './reducers'
import persistState from 'redux-localstorage'
import authMiddleware from "./middlewares/authMiddleware"
import trackingMiddleware from "./middlewares/trackingMiddleware"
import apiMiddleware from "./middlewares/apiMiddleware"

export const history = createBrowserHistory();

const _composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialState = {};
const enhancers = [persistState('persisted', {key: 'NetAppCloudCentral'})];

const middleware = [
    authMiddleware,
    apiMiddleware,
    trackingMiddleware
];

const composedEnhancers = _composeEnhancers(
    applyMiddleware(...middleware),
    ...enhancers
);

const staticReducers = createStaticReducers();
const asyncReducers = {};

const createReducer  = () => {
    return combineReducers({
        ...staticReducers,
        ...asyncReducers
    })
};

const store = createStore(
    createReducer(),
    initialState,
    composedEnhancers
);

export const registerReducer = (key, asyncReducer) => {
    asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer())
};

export default store
