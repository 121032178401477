import React, {useEffect} from "react";
import {Route, Switch} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import Loader from "../../../../../Loader";
import {PageLayoutNoFooter, SimpleHeader} from "../../Page";
import VolumesView from "../common/volumes-gallery/Page";
import VolumeActionRoutes from "./VolumeActionRoutes";
import {VolumeIcon} from "@netapp/bxp-design-system-react/icons/monochrome";
import NotFoundPageNew from "../../NotFoundPage";

export default () => {
    const dispatch = useDispatch();
    const urlParamAreSet = useSelector(state => _.get(state, "cvs.urlParamsAreSet"));
    const we = useSelector(state => _.get(state, "cvs.we"));
    const getWeError = useSelector(state => _.get(state, "cvs.getWeError"));
    const getWeShowError = useSelector(state => _.get(state, "cvs.getWeShowError"));

    useEffect(() => {
        if (urlParamAreSet && !we) {
            dispatch({
                type: "CVS:GET-WE"
            });
        }
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: { getWeError: null, getWeResolved: false, getWeShowError: false }
        });
    }, [dispatch, urlParamAreSet, we]);

    const hideNotification = () => {
        dispatch({
            type: "CVS:CLEAR-STATE",
            payload: { getWeShowError: false }
        });
    };

    if (getWeError) {
        return <NotFoundPageNew
            deleteNotification={hideNotification}
            showError={getWeShowError}
            errorMessage={getWeError}
            icon={VolumeIcon}
            notFoundText={"Working Environment Not Found"}
            flavour={"gcp"}
            headerText={"Cloud Volumes Services"}/>
    }

    if (!we) {
        return <PageLayoutNoFooter>
            <SimpleHeader flavour={"gcp"} title={"Cloud Volumes Services"}/>
            <Loader/>
        </PageLayoutNoFooter>;
    }

    return (
        <Switch>
            <Route path="/components/cvs/gcp/:accountId/:credentialsId/volumes" exact render={() => <VolumesView we={we}/>}/>
            <Route path="/components/cvs/gcp/:accountId/:credentialsId/volumes/:volumeId" render={() => <VolumeActionRoutes we={we}/>}/>
        </Switch>
    )
};
