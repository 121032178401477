import _ from "lodash";
import numeral from "numeral";

export const downloadFile = ({ fileName, data }) => {
    if (window.navigator.msSaveOrOpenBlob) { // EDGE workaround
        const blob = new Blob([decodeURIComponent(encodeURI(data))], { type: 'text/csv;charset=utf-8;' });
        navigator.msSaveBlob(blob, fileName);
    } else {
        const element = document.createElement('a');
        element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(data)}`);
        element.setAttribute('download', fileName);
        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }
};

export const parentIs = (node, comparator) => node === comparator || (node.parentNode && parentIs(node.parentNode, comparator));

export const parentHasClass = (node, className) => (node.classList && node.classList.contains(className)) || (node.parentNode && parentHasClass(node.parentNode, className));

export const scrollToTop = () => {
    window.scroll(0, 0);
};

export const reflect = promise => promise.then(
    value => ({ value, status: "fulfilled" }),
    error => ({ error, status: "rejected" })
);

// This behaves like Promise.all, but will always execute all promises, even if one of them fails.
export const executeAllPromises = promises =>
    Promise.all(_.map(promises, reflect))
        .then(results => {
            if (_.every(results, { status: 'fulfilled' })) {
                return _.map(results, 'value');
            }
            const firstErrorMessage = _.find(results, { status: 'rejected' }).error;
            return Promise.reject(firstErrorMessage);
        });

export const normalizeCapacity = bytes => {
    const [capacity, unit] = numeral(bytes).format(`0.00 ib`).split(' ');
    return { value: capacity, capacity, unit };
};
