import React, {useEffect} from "react";
import _ from "lodash";
import classNames from "classnames";
import {ActionButton, FormFieldInputNew, FormFieldSelect, useForm, Popover, Notifications} from "@netapp/bxp-design-system-react";
import {bytesToGiB} from "../../../../utils/common";
import {useDispatch, useSelector} from "react-redux";
import {serviceLevelOptions} from "../../../../utils/aws";
import Content, {Title} from "../../common/ContentLayout";
import uuidv4 from "uuid/v4";
import {Tags, validator} from "../../../create-volume/DetailsAndTagsAws";
import ActionButtonContainer from "../../common/ActionButtonContainer";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import commonStyles from "../../../../styles/common.module.scss";
import styles from "../../../create-volume/DetailsAndTags.module.scss";

const tagItemInitialState = { name: "", id: uuidv4() };

const convertTagsFromObjectToTagsFormState = tagsObject => {
    let tags;
    if (!tagsObject || _.isEmpty(tagsObject))
        tags = [tagItemInitialState];
    else {
        tags = _.map(tagsObject, tag => ({ name: tag, id: uuidv4() }));
    }
    return tags
};

export default ({volume}) => {
    const dispatch = useDispatch();
    const editVolumePending = useSelector(state => _.get(state, "cvs.editVolumePending"));
    const editVolumeError = useSelector(state => _.get(state, "cvs.editVolumeError"));
    const editVolumeShowError = useSelector(state => _.get(state, "cvs.editVolumeShowError"));

    useEffect(() => {
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: { editVolumeResolved: false, editVolumeError: null, editVolumeShowError: false }
        });
    }, [dispatch]);

    const state = {
        sizeInGiB: bytesToGiB(volume.quotaInBytes).toString(),
        serviceLevel: _.find(serviceLevelOptions, option => {
            return option.label.toLowerCase() === volume.serviceLevel;
        }),
        volumeName: volume.name,
        tags: convertTagsFromObjectToTagsFormState(volume.labels)
    };
    const form = useForm(state, validator);

    const editVolume = () => {
        dispatch({
            type: "CVS:AWS-EDIT-VOLUME",
            payload: { newState: form.formState, volume }
        });
    };

    const notifications = [{
        type: "error",
        description: editVolumeError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { editVolumeShowError: false }}),
        showError: editVolumeShowError
    }];

    return (
        <Content>
            <Title className={"not-spaced"}>Edit volume - <span className={"vol-name"} title={volume.name}>{volume.name}</span></Title>
            <div style={{ marginTop: "35px" }} className={styles["content-grid"]}>
                <div className={styles["details"]}>
                    <div className={typography.body16}>Details</div>
                    <div>
                        <div className={styles["name-and-size"]}>
                            <FormFieldInputNew
                                form={form}
                                disabled={true}
                                name="volumeName"
                                label="Volume Name"
                                className={classNames(commonStyles["form-field"], styles["volume-name"])}
                            />
                            <FormFieldInputNew
                                autoFocus={true}
                                name="sizeInGiB"
                                label="Size (GiB)"
                                form={form}
                                placeholder=">=100"
                                maxChars={256}
                                className={classNames(commonStyles["form-field"], styles["volume-size"])}
                                labelChildren={<Popover>Volume size should be greater then or equal to 100 GiB and smaller then or equal to 100000 GiB</Popover>}
                            />
                        </div>
                        <FormFieldSelect
                            name="serviceLevel"
                            label="Service Level"
                            form={form}
                            isSearchable={true}
                            placeholder="- Select a service level -"
                            options={serviceLevelOptions}
                            labelChildren={<Popover>This is the target performance for the volume resource that is being created</Popover>}
                        />
                    </div>
                </div>
                <div className={styles["tags"]}>
                    <div className={typography.body16}>Tags <i>(Optional)</i></div>
                    <Tags form={form}/>
                </div>
            </div>
            <ActionButtonContainer>
                <ActionButton isSubmitting={editVolumePending} onClick={e => form.handleFormSubmit(editVolume)(e)}>Edit</ActionButton>
            </ActionButtonContainer>
            <Notifications className={commonStyles["error-area"]} notifications={_.filter(notifications, { showError: true })}/>
        </Content>
    );
};
