import axios from "axios";
import links from './../../consts/links'

export default ({getState, dispatch, next, action}) => {
    const {type} = action;

    const tieringAxios = axios.create({
        baseURL: links.demos.cloudTiering
    });

    if (type === "DEMO:RESET_CLOUD_TIERING") {
        dispatch({type: "DEMO:RESET_CLOUD_TIERING_PENDING"});
        const selectedAccount = getState().auth.userAccount;
        tieringAxios.put('/api/reset-simulator', null, {
            headers: {
                "x-account-id": selectedAccount.value
            }
        })
            .then(() => dispatch({type: "DEMO:RESET_CLOUD_TIERING_SUCCESS"}))
            .catch(err => dispatch({type: "DEMO:RESET_CLOUD_TIERING_ERROR", payload: {err}}));
    }
    return next(action);
};