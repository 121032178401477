import React, {Component} from "react"
import _ from 'lodash';
import styles from "./SearchWidget.module.scss"
import classNames from "classnames";
import {SearchIcon as SearchIcon} from "@netapp/bxp-design-system-react/icons/monochrome";
import { CloseMonochromeIconsIcon as CloseIcon } from "@netapp/bxp-design-system-react/icons/monochrome";

export default class extends Component {
    constructor(props){
        super(props);
        this.input = React.createRef();
        this.state = {open: false, value: ""};

        this.throttledSetFilter = _.throttle((value) => {
            this.props.setFilter(value);
        }, 500)
    }

    open(){
        this.setState({open: true});
        setTimeout(() => {
           this.input.current.focus();
        });
    }

    close() {
        this.setState({open: false, value: ""});
        this.props.setFilter("");
    }

    handleBlur() {
        if(!this.state.value){
            this.close();
        }
    }

    handleChange(event) {
        const value = event.target.value;
        this.setState({value});
        this.throttledSetFilter(value);
    }

    render(){
        return (
            <div className={classNames(styles.base, {'is-open': this.state.open}, this.props.className)} title="Search">
                <button className="open" onClick={this.open.bind(this)}><SearchIcon/></button>
                <input onBlur={this.handleBlur.bind(this)} ref={this.input} value={this.state.value} onChange={this.handleChange.bind(this)}/>
                <button className="close" onClick={this.close.bind(this)}><CloseIcon/></button>
            </div>
        )
    }
}
