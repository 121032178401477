import _ from 'lodash';
import moment from "moment";

/*eslint default-case: 0*/

const last30daysRange = _.map(_.range(30, 0, -1), d => moment().subtract(d, 'days').format('YYYY-MM-DD'));

const defaultState = () => ({
    cloudSyncData: {
        totalBytes: {},
        dataTransferredByDay: last30daysRange.map(date => ({
            capacityCopied: 0,
            unit: 'GiB',
            date
        })),
        highestNormalizedCapacityUnitInLast30days: 'GiB',
        protocolsCount: {}
    }
});

export default (state = defaultState(), { payload, type }) => {
    switch (type) {
        case "DASHBOARDS:FETCH-CLOUD-SYNC-DATA-PENDING": {
            return { ...state, cloudSyncData: defaultState().cloudSyncData }
        }
        case "DASHBOARDS:FETCH-CLOUD-SYNC-DATA-SUCCESS": {
            return { ...state, cloudSyncData: payload.cloudSyncData}
        }
        case "DASHBOARDS:FETCH-CLOUD-SYNC-DATA-ERROR": {
            return { ...state, cloudSyncError: payload.error }
        }
    }

    return state;
};
