import React from "react";
import _ from "lodash";
import classNames from "classnames";
import {RadioButton} from "@netapp/bxp-design-system-react";
import styles from "./RadioButtonWidget.module.scss";

const RadioButtonContents = ({form, name, buttons, widgetLabel, onChange: _onChange, checkedValue, widgetTitleClassName}) => {
    let onChange = _onChange;
    if (form && name) {
        onChange = onChange || form.handleFormChange;
    }
    return <>
        <div className={classNames(styles["radio-button-widget-title"], widgetTitleClassName)}>{widgetLabel}</div>
        <div className={styles["radio-buttons"]}>
            {_.map(buttons, button =>
                <RadioButton
                    key={button.value}
                    form={form}
                    name={name}
                    onChange={onChange}
                    value={button.value}
                    checked={checkedValue ? (checkedValue === button.value) : null}
                    disabled={button.disabled}
                >{button.label}</RadioButton>)}
        </div>
    </>
};

export const RadioButtonWidget = (props) => {
    return (
        <div className={classNames(styles["radio-button-widget"], props.className)}>
            <RadioButtonContents {...props}/>
        </div>
    );
};

export const RadioButtonCard = (props) => {
    return (
        <div className={classNames(styles["radio-button-card"], props.className)}>
            <RadioButtonContents {...props} widgetTitleClassName={styles["radio-button-widget-title-card"]}/>
        </div>
    );
};
