import React from "react";
import _ from "lodash";
import {useSelector} from "react-redux";
import classNames from "classnames";
import {Checkbox, FormFieldInputNew, RadioButton, Popover} from "@netapp/bxp-design-system-react";
import {ExportPolicies} from "./ProtocolExportPolicies";
import commonStyles from "../../styles/common.module.scss";
import styles from "./Protocol.module.scss";

export const Nfs = ({ form, disabled }) => {
    const flavour = useSelector(state => _.get(state, `cvs.cvsFlavour`));
    const {handleFormChange, formState, submissionAttempted} = form;

    const handleVersionSelection = e => {
        let updatedPolicies = formState.exportPolicies;
        if (!e.target.checked) {
            updatedPolicies = _.map(formState.exportPolicies, policy => {
                return { ...policy, [e.target.name]: e.target.checked }
            });
            handleFormChange(updatedPolicies, { name: "exportPolicies" });
        }
        handleFormChange(e.target.checked, { name: e.target.name });
    };

    return (
        <div className={styles["two-col-form"]}>
            <div className={styles["left-col"]}>
                <div className={styles["title"]}>Protocol</div>
                <FormFieldInputNew
                    form={form}
                    disabled={disabled}
                    name="volumePath"
                    label={"Volume Path"}
                    maxChars={256}
                    autoFocus={true}
                    className={commonStyles["form-field"]}
                    labelChildren={<Popover>This is the name of the volume export. A volume is mounted using the export path. File path must start with an alphabetical character and be unique within the subscription.</Popover>}
                />
                <div className={styles["nfs-version"]}>
                    <div className={classNames(styles["version-title"], {[styles["disabled"]]: disabled})}>Select NFS Version:</div>
                    <div className={styles["checkboxes"]}>
                        {flavour === "azure"
                            ?  <>
                                <RadioButton form={form} disabled={disabled} value="NFSv3" name={"nfsVersion"}>NFSv3</RadioButton>
                                <RadioButton form={form} disabled={disabled} value="NFSv4" name={"nfsVersion"}>NFSv4.1</RadioButton>
                            </> : <>
                                <Checkbox disabled={disabled} onChange={handleVersionSelection} checked={formState.NFSv3} name={"NFSv3"}>NFSv3</Checkbox>
                                <Checkbox disabled={disabled} onChange={handleVersionSelection} checked={formState.NFSv4} name={"NFSv4"}>NFSv4.1</Checkbox>
                            </>
                        }
                    </div>
                    {(submissionAttempted && ((!formState.NFSv3 && !formState.NFSv4) || !formState.nfsVersion)) && <div className={styles["error"]}>Select NFS version.</div>}
                </div>
            </div>
            <div className={styles["right-col"]}>
                <div className={styles["title"]}>Export Policy</div>
                <div className={styles["policy-title"]}>
                    <span>Allowed Client & Access</span>
                    <Popover>Allowed clients will accept a comma separated list of IPs (v4) and/or cidrs. In most cases this is the private IP of your instance/VM. If using public IPs please be aware that they have to be reachable from the volume's network for the export policy to work correctly</Popover>
                </div>
                <ExportPolicies form={form}/>
            </div>
        </div>
    );
};
