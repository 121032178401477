import React from "react";
import {ChevronIcon as Chevron} from "@netapp/bxp-design-system-react/icons/monochrome";
import ExternalLink from "../../../../components/widgets/ExternalLink";
import commonStyles from "../../styles/common.module.scss";
import styles from "./Info.module.scss";

export default () => {
    return (
        <>
            <div className={styles["info-item-container"]}>
                <Chevron/>
                <span>Subscribe in the</span>
                <ExternalLink className={commonStyles["in-text-button"]} href={"https://aws.amazon.com/marketplace/pp/NetApp-Inc-NetApp-Cloud-Volumes-Service-Contract-v/B07MF4GHYW"} newTab>AWS Marketplace</ExternalLink>
            </div>
            <div className={styles["info-item-container"]}>
                <Chevron/>
                <span>Log into</span>
                <ExternalLink className={commonStyles["in-text-button"]} href={"https://cds-aws-bundles.netapp.com/storage/volumes"} newTab>Cloud Volumes Service</ExternalLink>
            </div>
            <div className={styles["info-item-container"]}>
                <Chevron/>
                <span>Click API access, copy the credentials, and enter them in the fields on the left</span>
            </div>
            <div className={styles["info-item-container"]}>
                <span>To learn more about Cloud Volumes Service,</span>
                <ExternalLink className={commonStyles["in-text-button"]} href={"https://cloud.netapp.com/cloud-volumes-service-for-aws"} newTab>click here</ExternalLink>
            </div>
        </>
    );
};
