import React from "react";
import * as yup from "yup";
import {FormFieldInputNew} from "@netapp/bxp-design-system-react";
import classNames from "classnames";
import commonStyles from "../../styles/common.module.scss";
import styles from "./Form.module.scss";

export const initialState = {
    name: "",
    clientId: "",
    clientSecret: "",
    tenantId: ""
};

export const schema = yup.object().shape({
    name: yup.string().required("Service name is required."),
    clientId: yup.string().required("Client ID is required."),
    clientSecret: yup.string().required("Client secrete is required."),
    tenantId: yup.string().required("Tenant id is required.")
});

export default ({ form }) => {
    return (
        <div className={styles["form"]}>
            <FormFieldInputNew
                form={form}
                name="name"
                label="Working Environment Name"
                maxChars={256}
                autoFocus={true}
                className={classNames(commonStyles["form-field"], styles["field"])}
            />
            <FormFieldInputNew
                form={form}
                name="clientId"
                label="Application (client) ID"
                className={classNames(commonStyles["form-field"], styles["field"])}
                autoComplete="off"
            />
            <FormFieldInputNew
                form={form}
                type="password"
                name="clientSecret"
                label="Client Secret"
                className={classNames(commonStyles["form-field"], styles["field"])}
            />
            <FormFieldInputNew
                form={form}
                name="tenantId"
                label="Directory (tenant) ID"
                className={classNames(commonStyles["form-field"], styles["last-form-element"])}
                autoComplete="off"
            />
        </div>
    );
};
