import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import _ from "lodash";
import * as yup from "yup";
import classNames from "classnames";
import {FormFieldSelect, FormFieldInputNew, ActionButton, FormFieldTextArea} from "@netapp/bxp-design-system-react";
import commonStyles from "../../styles/common.module.scss";
import styles from "./Form.module.scss";

export const initialState = {
    name: "",
    serviceAccountCredentials: "",
    project: null
};

export const schema = yup.object().shape({
    project: yup.object().nullable().required("Project selection is required."),
    name: yup.string().required("Name is required."),
    serviceAccountCredentials: yup.string().test('credentials', null, function () {
        let error;
        try {
            JSON.parse(this.parent.serviceAccountCredentials);
        } catch (e) {
            error = "Invalid JSON format."
        }
        if (_.isEmpty(this.parent.serviceAccountCredentials)) {
            error = "Service account credentials are required."
        }
        if (_.isEmpty(error)) {
            return true;
        } else {
            return new yup.ValidationError(error, null, 'serviceAccountCredentials');
        }
    })
});

export default ({ form }) => {
    const dispatch = useDispatch();
    const projects = useSelector(state => _.get(state, "cvs.gcpProjects"));
    const getProjectsPending = useSelector(state => _.get(state, "cvs.getGcpProjectsPending"));
    const [invalidJsonError, setInvalidJsonError] = useState(null);

    const handleCredentialsSubmission = e => {
        e.preventDefault();
        try {
            const credentials = JSON.parse(form.formState.serviceAccountCredentials);
            dispatch({
                type: "CVS:GET-GCP-PROJECTS",
                payload: { serviceAccountCredentials: credentials }
            });
            setInvalidJsonError(null);
            if (form.formState.project) {
                form.handleFormChange(null, { name: "project" });
            }
        } catch (e) {
            setInvalidJsonError("Invalid JSON format.");
        }
    };

    return (
        <div className={styles["form"]}>
            <FormFieldInputNew
                form={form}
                name="name"
                label="Working Environment Name"
                maxChars={256}
                className={classNames(commonStyles["form-field"], styles["field"])}
                autoFocus={true}
            />
            <div className={styles["gcp-json"]}>
                <FormFieldTextArea
                    form={form}
                    name="serviceAccountCredentials"
                    label="Service Account Credentials"
                    placeholder="Paste the contents of the JSON file here"
                    showError={(form.submissionAttempted && form.formErrors.serviceAccountCredentials) || invalidJsonError}
                    className={classNames(commonStyles["form-field"], styles["field"])}
                />
                <ActionButton disabled={!!getProjectsPending} isSubmitting={getProjectsPending} onClick={handleCredentialsSubmission}>Apply</ActionButton>
            </div>
            <FormFieldSelect
                form={form}
                name="project"
                label="Project"
                placeholder="- Please Select -"
                isSearchable={true}
                options={projects || []}
                isLoading={getProjectsPending}
                className={classNames(commonStyles["form-field"], styles["last-form-element"])}
            />
        </div>
    );
};
