import React from 'react';

const RoutingContext = React.createContext({});

export const withRoutingContext = (Component) => (props) => {
    return <RoutingContext.Consumer>
        {({location}) => <Component {...props} location={location} />}
    </RoutingContext.Consumer>;
};

export default RoutingContext;