import React, {useEffect, useRef} from "react";
import * as yup from "yup";
import ContentLayout, {Title} from "../ContentLayout";
import {Notifications, FormFieldSelect, ActionButton, useForm} from "@netapp/bxp-design-system-react";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import ActionButtonContainer from "../ActionButtonContainer";
import DeleteSnapshotModal from "./DeleteSnapshotModal";
import classNames from "classnames";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import commonStyles from "../../../../styles/common.module.scss";
import styles from "./Content.module.scss";

const initialState = {
    snapshot: null
};

const schema = yup.object().shape({
    snapshot: yup.object().nullable().required("Snapshot selection is required.")
});

export default ({volume}) => {
    const dispatch = useDispatch();
    const buttonRef = useRef(null);
    const flavour = useSelector(state => _.get(state, "cvs.cvsFlavour"));
    const snapshots = useSelector(state => _.get(state, "cvs.volumeSnapshots"));
    const getSnapshotsPending = useSelector(state => _.get(state, "cvs.getVolumeSnapshotsPending"));
    const getSnapshotError = useSelector(state => _.get(state, "cvs.getVolumeSnapshotsError"));
    const getSnapshotShowError = useSelector(state => _.get(state, "cvs.getVolumeSnapshotsShowError"));
    const deleteSnapshotPending = useSelector(state => _.get(state, "cvs.deleteSnapshotPending"));
    const isModalOpen = useSelector(state => _.get(state, "cvs.isDeleteSnapshotModalOpen"));
    const form = useForm(initialState, schema);

    useEffect(() => {
        dispatch({
            type: `CVS:${flavour.toUpperCase()}-GET-VOLUME-SNAPSHOTS`,
            payload: { volume }
        });
        return () => dispatch({
            type: "CSV:CLEAR-STATE",
            payload: {
                volumeSnapshots: [],
                getVolumeSnapshotsError: null,
                getVolumeSnapshotsResolved: false,
                getVolumeSnapshotsShowError: false,
                deleteSnapshotError: null,
                deleteSnapshotResolved: false,
                deleteSnapshotShowError: false
            }
        });
    }, [dispatch, flavour, volume]);

    const openDeleteModal = () => {
        dispatch({
            type: "CVS:OPEN-DELETE-SNAPSHOT-MODAL"
        });
    };

    const closeDeleteModal = () => {
        dispatch({
            type: "CVS:CLOSE-DELETE-SNAPSHOT-MODAL"
        });
    };

    const hideNotification = () => dispatch({
        type: "CVS:CLEAR-STATE",
        payload: { getVolumeSnapshotsShowError: false, deleteSnapshotShowError: false }
    });

    const notifications = [{
        type: "error",
        description: getSnapshotError,
        onClose: () => hideNotification(),
        showError: getSnapshotShowError
    }];

    return (
        <ContentLayout className={styles["content"]}>
            <Title className={"not-spaced"}>Delete a Snapshot Copy - <span className={"vol-name"} title={volume.name}>{volume.name}</span></Title>
            <div className={classNames(typography.body14, styles["info"])}>This action deletes the selected Snapshot copy.</div>
            <FormFieldSelect
                form={form}
                isClearable={true}
                isSearchable={true}
                name="snapshot"
                label="Snapshot Name"
                placeholder="- Select a snapshot copy -"
                options={snapshots}
                isLoading={getSnapshotsPending}
                className={styles["form-field"]}
            />
            {isModalOpen && <DeleteSnapshotModal target={buttonRef} closeTrigger={closeDeleteModal} volume={volume} snapshotToDelete={form.formState.snapshot} />}
            <ActionButtonContainer className={styles["button-container"]}>
                <ActionButton isSubmitting={deleteSnapshotPending} onClick={e => form.handleFormSubmit(openDeleteModal)(e)}>Delete</ActionButton>
            </ActionButtonContainer>
            <Notifications className={commonStyles["error-area"]} notifications={_.filter(notifications, { showError: true })}/>
        </ContentLayout>
    );
};

