import React from 'react';
import CloudCentralSecondaryHeader from "./../../../components/headers/CloudCentralSecondaryHeader";
import { CheckCircleTwoColorIconsIcon as OkTwoColor } from '@netapp/bxp-design-system-react/icons/2-colors';
import buttons from "../../../styles/button/button.module.scss";
import styles from "./EmailVerification.module.scss";
import { ErrorNoFillIcon as Error } from '@netapp/bxp-design-system-react/icons/monochrome';
import {connect} from "react-redux";

const PasswordUpdatePage = ({message, success, authenticated}) => {
    return (
        <React.Fragment>
            <CloudCentralSecondaryHeader title="Password Update">
            </CloudCentralSecondaryHeader>
            <div className={styles["outer-container"]}>
                {success === "true" ?
                    <UpdateSucceeded/> :
                    <UpdateFailed message={message} authenticated={authenticated}/>
                }
            </div>
        </React.Fragment>
    );
};

const UpdateSucceeded = () => {
    return (
        <React.Fragment>
            <OkTwoColor/>
            <p style={{marginBottom: "50px"}}>Your password was updated successfully</p>
            <div className={styles["button-container"]}>
                <button className={buttons.primary} onClick={() => window.auth.loginRedirect({}, {service: "portal", initialScreen: "login"})}>
                    Login to Cloud Central
                </button>
            </div>
        </React.Fragment>
    );
};

const UpdateFailed = ({message, authenticated}) => {
    return (
        <React.Fragment>
            <Error/>
            <p>Oops! Something went wrong…</p>
            <p>{message}</p>
            <div className={styles["button-container"]}>
                <button className={buttons.primary} onClick={() => window.auth.loginRedirect({}, {service: "portal", initialScreen: "login"})}>
                    { authenticated ? "Back to Cloud Central" : "Login to Cloud Central" }
                </button>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        auth: state.auth
    };
};

export default connect(mapStateToProps)(PasswordUpdatePage);