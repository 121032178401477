import _ from "lodash";
import {gibToByte} from "./common";
import uuidv4 from "uuid/v4";

export const serviceLevelOptions = [
    { label: "Standard", value: "Standard" },
    { label: "Premium", value: "Premium" },
    { label: "Ultra", value: "Ultra" },
];

export const azureLocations = [
    { value: 'australiasoutheast', label: 'Australia Southeast' },
    { value: 'australiaeast', label: 'Australia East' },
    { value: 'canadacentral', label: 'Canada Central' },
    { value: 'canadaeast', label: 'Canada East' },
    { value: 'centralindia', label: 'Central India' },
    { value: 'centralus', label: 'Central US' },
    { value: 'eastus', label: 'East US' },
    { value: 'eastus2', label: 'East US 2' },
    { value: 'germanywestcentral', label: 'Germany West Central' },
    { value: 'japaneast', label: 'Japan East' },
    { value: 'japanwest', label: 'Japan West' },
    { value: 'northeurope', label: 'North Europe' },
    { value: 'southcentralus', label: 'South Central US' },
    { value: 'southindia', label: 'South India' },
    { value: 'southeastasia', label: 'Southeast Asia' },
    { value: 'uaecentral', label: 'UAE Central' },
    { value: 'uksouth', label: 'UK South' },
    { value: 'ukwest', label: 'UK West' },
    { value: 'westeurope', label: 'West Europe' },
    { value: 'westus2', label: 'West US 2' },
    { value: 'westus', label: 'West US' }
];

export const netappAccountRegex = /^[a-zA-Z0-9_-]*$/img;
// export const resourceGroupNameRegex = /^[-\w\._\(\)]+$/img;
export const capacityPoolNameRegex = /^[a-zA-Z0-9][a-zA-Z0-9\-_]{0,63}$/img;

export const isNetappAccountValid = netappAccount => {
    const netappAccountRegex = /^[a-zA-Z0-9_-]*$/img;
    return netappAccount.match(netappAccountRegex);
};

export const isResourceGroupNameValid = resourceGroupName => {
    const resourceGroupNameRegex = /^[-\w\._\(\)]+$/img;
    return resourceGroupName.match(resourceGroupNameRegex) && !_.endsWith(resourceGroupName,'.');
};

export const isCapacityPoolNameValid = capacityPoolName => {
    const capacityPoolNameRegex = /^[a-zA-Z0-9][a-zA-Z0-9\-_]{0,63}$/img;
    return capacityPoolName.match(capacityPoolNameRegex);
};

export const getAzureProps = wizardState => {
    const netappAccountFormState = wizardState?.netappAccountForm?.formState;
    const capacityPoolFormState = wizardState?.capacityPoolForm?.formState;
    const protocolFormState = wizardState?.protocolForm?.formState;
    const subscriptionId = netappAccountFormState?.accountOption === "existing" ? netappAccountFormState?.netappAccount?.subscriptionId : netappAccountFormState?.subscription?.value;
    const location = netappAccountFormState?.accountOption === "existing" ? netappAccountFormState?.netappAccount?.location : netappAccountFormState?.location?.value;
    const netappAccountName = netappAccountFormState?.accountOption === "existing" ? netappAccountFormState?.netappAccount?.name : netappAccountFormState?.accountName;
    let resourceGroupName;
    if (netappAccountFormState?.accountOption === "existing") {
        resourceGroupName = netappAccountFormState?.netappAccount?.resourceGroupName;
    } else {
        resourceGroupName = netappAccountFormState?.resourceGroupOption === "existing" ? netappAccountFormState?.resourceGroup?.value : netappAccountFormState?.resourceGroupName;
    }
    let protocolTypes = [];
    if (protocolFormState?.protocolOption === "nfs") {
        if (protocolFormState?.nfsVersion === "NFSv3") {
            protocolTypes.push("NFSv3");
        } else {
            protocolTypes.push("NFSv4.1");
        }
    }
    if (protocolFormState?.protocolOption === "smb") {
        protocolTypes.push("CIFS");
    }
    const capacityPoolName = capacityPoolFormState?.poolOption === "existing" ? capacityPoolFormState?.capacityPool?.name : capacityPoolFormState?.poolName;
    return { subscriptionId, location, netappAccountName, resourceGroupName, capacityPoolName, protocolTypes }
};

const mapRules = (exportPolicies, selectedNfsVersion) => {
    return _.map(exportPolicies, (policy, index) => {
        const rule = {};
        rule.allowedClients = policy.ip;
        rule.nfsv3 = selectedNfsVersion === "NFSv3";
        rule.nfsv41 = selectedNfsVersion === "NFSv4";
        rule.ruleIndex = index + 1;
        rule.unixReadOnly = policy.policy === "r";
        rule.unixReadWrite = policy.policy === "r/w";
        return rule;
    });
};

export const createVolumeData = (createVolumeForm, location) => {
    const {detailsAndTagsForm, protocolForm, snapshotCopyForm} = createVolumeForm;
    let volumeData = {};
    const { volumeName, sizeInGiB, subnet, tags } = detailsAndTagsForm.formState;
    let protocolTypes = [];
    let volumePath;
    let labels = {};

    volumeData.name = volumeName;
    volumeData.quotaInBytes = gibToByte(sizeInGiB);
    volumeData.location = location;
    volumeData.subnetId = subnet.subnetId;

    if (!_.isEmpty(tags[0].key) && !_.isEmpty(tags[0].value)) {
        labels = _.reduce(tags || [], (tagsObject, tag) => {
            tagsObject[tag.key] = tag.value;
            return tagsObject;
        }, {});
    }
    const { snapshot } = snapshotCopyForm.formState;
    if (snapshot) {
        volumeData.snapshotId = snapshot.value;
    }

    if (protocolForm.formState.protocolOption === "nfs") {
        volumePath = protocolForm.formState.volumePath;
        volumeData.rules = mapRules(protocolForm.formState.exportPolicies, protocolForm.formState.nfsVersion);
        if (protocolForm.formState.nfsVersion === "NFSv3") {
            protocolTypes.push("NFSv3");
        } else {
            protocolTypes.push("NFSv4.1");
        }
    }
    if (protocolForm.formState.protocolOption === "smb") {
        volumePath = protocolForm.formState.volumePath;
        protocolTypes.push("CIFS");
    }
    return { ...volumeData, protocolTypes, volumePath, labels };
};

export const azureStateMap = {
    "Succeeded": "available",
    "Failed": "disabled",
    "Creating": "creating",
    "Deleting": "deleting",
    "Error": "error",
    "ChangePool": "deleting"
};

export const parseAnfVolume = (volume) => {
    const locationDisplayName = _.find(azureLocations,{value: volume.region});
    return({
        ...volume,
        lifeCycleState: azureStateMap[volume.lifeCycleState],
        regionDisplayName: locationDisplayName ? locationDisplayName.label : volume.region,
        fileSystemId: volume.fileSystemId || uuidv4(),
        usedBytes: volume.usedBytes || 0,
        value: volume.name,
        label: volume.name
    })
};

export const deconstructId = id => {
    const properties = _.compact(id.split("/"));
    const ans = {};
    for (let i = 0; i < properties.length; i += 2) {
        ans[properties[i]] = properties[i + 1];
    }
    return ans;
};

