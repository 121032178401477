import React, {useState} from "react";
import _ from "lodash";
import {useSelector} from "react-redux";
import uuidv4 from "uuid/v4";
import classNames from "classnames";
import {isExportAccessIpValid} from "../../utils/common";
import {Checkbox, FormFieldInputNew, RadioButton} from "@netapp/bxp-design-system-react";
import { CloseMonochromeIconsIcon as Close } from "@netapp/bxp-design-system-react/icons/monochrome";
import {AddItemIcon as AddIcon} from "@netapp/bxp-design-system-react/icons/monochrome";
import commonStyles from "../../styles/common.module.scss";
import styles from "./Protocol.module.scss";

export const exportPolicyInitialState = {
    ip: "",
    policy: "r",
    NFSv3: false,
    NFSv4: false,
    id: uuidv4()
};

export const exportPolicyFormValidator = (flavour, itemState) => {
    const errors = {};
    if (itemState.ip && !isExportAccessIpValid(itemState.ip)) {
        errors[`${itemState.id}-ip`] = "Invalid address.";
    }
    if (!itemState.ip) {
        errors[`${itemState.id}-ip`] = "Access ip is required."
    }
    if (flavour !== "azure") {
        if (!itemState.NFSv3 && !itemState.NFSv4) {
            errors[`${itemState.id}-nfsVersions`] = "Select NFS version.";
        }
    }
    return errors;
};

const policiesErrors = (formErrors, exportPolicies) => _.reduce(exportPolicies, (existingErrors, policy) => {
    const ipError = !_.isEmpty(formErrors[`${policy.id}-ip`]);
    const versionError = !_.isEmpty(formErrors[`${policy.id}-nfsVersions`]);
    return ipError || versionError || existingErrors;
}, false);

export const ExportPolicies = ({ form }) => {
    const flavour = useSelector(state => _.get(state, `cvs.cvsFlavour`));
    const [showAddButton, setShowAddButton] = useState(true);
    const [showListItemsErrors, setShowListItemsErrors] = useState(false);
    const {formState, handleFormChange, formErrors, submissionAttempted} = form;
    const showRemovalButton = formState.exportPolicies.length > 1;

    const handleItemChange = index => e => {
        const isCheckbox = e.target.type === "checkbox";
        const updatedPolicies = _.map(formState.exportPolicies, (policy, i) => {
            return i === index ? ({ ...policy, [e.target.name]: isCheckbox ? e.target.checked : e.target.value }) : policy;
        });
        handleFormChange(updatedPolicies, { name: "exportPolicies" });
    };

    const handleItemRemoval = index => {
        const filteredItems = [
            ...formState.exportPolicies.slice(0, index),
            ...formState.exportPolicies.slice(index + 1, formState.exportPolicies.length)
        ];
        handleFormChange(filteredItems, { name: "exportPolicies" });
        setShowAddButton(true);
    };

    const handleItemAddition = () => {
        if (formState.exportPolicies.length === 5) {
            // do not allow more than 5 export policies
            return
        }
        if (!policiesErrors(formErrors, formState.exportPolicies)) {
            if (formState.exportPolicies === 4) {
                showAddButton(false);
            }
            const withNewItem = [...formState.exportPolicies, { ...exportPolicyInitialState, id: uuidv4() }];
            handleFormChange(withNewItem, { name: "exportPolicies" });
            setShowListItemsErrors(false);
        } else {
            setShowListItemsErrors(true);
        }
    };

    return (
        <div className={classNames(commonStyles["dynamic-list-form"], styles["dynamic-policies-form"])}>
            {_.map(formState.exportPolicies, (policy, i) => <div key={formState.exportPolicies[i].id} className={classNames(flavour === "azure" ? styles["policy-grid-no-versions"] : styles["policy-grid"])}>
                <FormFieldInputNew
                    name="ip"
                    value={formState.exportPolicies[i].ip}
                    onChange={handleItemChange(i)}
                    className={styles["ip"]}
                    showError={showListItemsErrors || submissionAttempted}
                    errorMessage={formErrors[`${formState.exportPolicies[i].id}-ip`]}
                    maxChars={256}/>
                <div className={styles["radio-buttons"]}>
                    <RadioButton className={commonStyles["radio-button"]} value="r/w" name="policy" onChange={handleItemChange(i)} checked={formState.exportPolicies[i].policy === "r/w"}>Read & Write</RadioButton>
                    <RadioButton className={commonStyles["radio-button"]} value="r" name="policy" onChange={handleItemChange(i)} checked={formState.exportPolicies[i].policy === "r"}>Read Only</RadioButton>
                </div>
                { flavour !== "azure" && <>
                    <div className={styles["version-error"]}>
                        Select NFS Version:
                        {((showListItemsErrors || submissionAttempted) && formErrors[`${formState.exportPolicies[i].id}-nfsVersions`]) && <div className={styles["error"]}>{formErrors[`${formState.exportPolicies[i].id}-nfsVersions`]}</div>}
                    </div>
                    <div className={styles["checkbox-buttons"]}>
                        <Checkbox onChange={handleItemChange(i)} name="NFSv3" checked={formState["NFSv3"] && formState.exportPolicies[i].NFSv3} disabled={!formState["NFSv3"]}>NFSv3</Checkbox>
                        <Checkbox onChange={handleItemChange(i)} name="NFSv4" checked={formState["NFSv4"] && formState.exportPolicies[i].NFSv4} disabled={!formState["NFSv4"]}>NFSv4.1</Checkbox>
                    </div>
                </> }
                { showRemovalButton &&
                <button onClick={() => handleItemRemoval(i)}>
                    <Close/>
                </button>}
            </div>)}
            {showAddButton &&
            <div className={commonStyles["add-button"]}>
                <button onClick={handleItemAddition}><AddIcon/><span>Add Export Policy Rule (Up to 5)</span></button>
            </div>}
        </div>
    );
};
