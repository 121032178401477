import React from 'react';
import connect from "react-redux/es/connect/connect";
import DashboardContainer from "./DashboardContainer";
import { STATUS_ERROR, STATUS_SUCCESS } from "../../actions";
import styles from "./Dashboard.module.scss"
import GettingStartedContent from "./GettingStartedContent"

import { CalculatorIcon } from "@netapp/bxp-design-system-react/icons/2-colors";
import { PricingIcon } from "@netapp/bxp-design-system-react/icons/2-colors";
import { ApiTwoColorIconsIcon as ApiIcon } from "@netapp/bxp-design-system-react/icons/2-colors";
import {DemoIcon} from "@netapp/bxp-design-system-react/icons/2-colors";
import { AutomationIcon } from "@netapp/bxp-design-system-react/icons/2-colors";
import { GetStartedIcon as RocketshipIcon } from "@netapp/bxp-design-system-react/icons/2-colors";
import { FeaturesPricingIcon as FeaturesAndPricingIcon } from "@netapp/bxp-design-system-react/icons/2-colors";
import { FeatureFlagsIcon as PerformanceIcon } from "@netapp/bxp-design-system-react/icons/2-colors";
import { FaqIcon } from '@netapp/bxp-design-system-react/icons/2-colors';
import { DocumentationIcon } from '@netapp/bxp-design-system-react/icons/2-colors';

import { ReactComponent as AzureLogo } from 'images/logo-azure.svg';
import { ReactComponent as GoogleCloudLogo } from 'images/logo-google-cloud.svg';
import { ReactComponent as AWSLogo } from 'images/logo-aws.svg';

import { registerFeature } from "../../middlewares/apiMiddleware";

import dashboardsMiddleware from "../../middlewares/featureApis/dashboardsMiddleware";
import {Redirect, Route, Switch, NavLink} from "react-router-dom";
import ExternalLink from "../widgets/ExternalLink";

registerFeature("DASHBOARDS", dashboardsMiddleware);

const CloudSyncDashboardDumb = ({ cloudSync }) => {
    return (cloudSync.status === STATUS_SUCCESS || cloudSync.status === STATUS_ERROR) && <DashboardContainer
        category="services"
        serviceKey="cloudSync"
        title="Cloud Sync"
        iconKey="cloud-sync"
        iconStyle={{width: 94, height: 94}}
        productText="Cloud Sync is NetApp’s service for rapid and secure data synchronization. Whether you need to transfer files between on-premises NFS or CIFS file shares, Amazon S3 object format, Azure Blob, IBM Cloud Object Storage or NetApp StorageGRID® Webscale appliance, Cloud Sync moves the files where you need them quickly and securely."
        gettingStartedLink="dashboards.cloudSync.gettingStarted"
        gettingStartedContent={[
            {
                Icon: DemoIcon,
                title: "FAQ",
                text: "Frequently asked questions",
                linkKey: "dashboards.cloudSync.faq"
            },
            {
                Icon: PricingIcon,
                title: "Supported Sync Relationships",
                text: "Learn about the supported source and target protocols for data transfer",
                linkKey: "dashboards.cloudSync.supportedSyncRelationships"
            },
            {
                Icon: ApiIcon,
                title: "API",
                text: "Cloud Sync Rest API",
                linkKey: "dashboards.cloudSync.api"
            }
        ]}
        footerContent={{
            title: "Cloud Sync Documentation & Support",
            links: [
                {
                    text: "Getting Started Guide",
                    linkKey: "dashboards.cloudSync.gettingStarted"
                },
                {
                    text: "Documentation",
                    linkKey: "dashboards.cloudSync.documentation"
                },
                {
                    text: "FAQ",
                    linkKey: "dashboards.cloudSync.faq"
                },
                {
                    text: "Blog",
                    linkKey: "dashboards.cloudSync.blog"
                }
            ]
        }}
    />
};

const mapStateToPropsCloudSync = state => {
    return {
        cloudSync: state.services.cloudSync
    };
};

export const CloudSyncDashboard = connect(mapStateToPropsCloudSync)(CloudSyncDashboardDumb);

export const CloudVolumesOntapDashboard = () => <DashboardContainer
    category="storage"
    title="Cloud Volumes ONTAP"
    serviceKey="cloudVolumesOntap"
    iconKey="cloud-volumes-ontap"
    iconStyle={{width: 148, height: 94}}
    productText="Cloud Volumes ONTAP is a powerful, cost-effective, and easy-to-use data management solution for your cloud workloads. The familiar and reliable Cloud Volumes ONTAP data management software with its data protection and storage efficiency features, now available in the AWS, Azure cloud and Google Cloud."
    gettingStartedLink="dashboards.cloudVolumesOntap.gettingStarted"
    gettingStartedContent={[
        {   Icon: CalculatorIcon,
            title: "TCO Calculator",
            text: "Calculate your Cloud Volumes ONTAP TCO on AWS and Azure",
            linkKey: "dashboards.cloudVolumesOntap.tcoCalculators"
        },
        {   Icon: PricingIcon,
            title: "Pricing",
            text: "Cloud Volumes ONTAP pricing for AWS and Azure",
            linkKey: "dashboards.cloudVolumesOntap.pricing"
        },
        {   Icon: ApiIcon,
            title: "API",
            text: "Cloud Volumes ONTAP Rest API",
            linkKey: "dashboards.cloudVolumesOntap.api"
        },
        {   Icon: AutomationIcon,
            title: "Automation Samples",
            text: "BlueXP and Cloud Volumes ONTAP automation samples",
            linkKey: "dashboards.cloudVolumesOntap.automationSamples"
        }
    ]}
    footerContent={{
        title: "Cloud Volumes ONTAP Documentation & Support",
        links: [
            {
                text: "Getting Started Guide",
                linkKey: "dashboards.cloudVolumesOntap.gettingStarted"
            },
            {
                text: "Documentation",
                linkKey: "dashboards.cloudVolumesOntap.documentation"
            },
            {
                text: "API",
                linkKey: "dashboards.cloudVolumesOntap.api"
            },
            {
                text: "Blog",
                linkKey: "dashboards.cloudVolumesOntap.blog"
            }
        ]
    }}
/>;

const CVSAzure = () => <React.Fragment>
    <p>A Microsoft Azure native service built on NetApp technology, giving you the file capabilities in Azure even your core business applications require.</p>
    <ExternalLink linkKey="launchPad.services.cloudVolumesServiceAzurePreview" trackCategory="dashboards" className={styles['cta-no-center']} newTab>Subscribe to the Service</ExternalLink>
    <GettingStartedContent gettingStartedContent={[
        {
            Icon: RocketshipIcon,
            title: "Get Started with Azure NetApp Files",
            linkKey: "dashboards.cloudVolumesServiceAzure.getStarted"
        },
        {
            Icon: PerformanceIcon,
            title: "Get to know the performance of Azure NetApp Files",
            linkKey: "dashboards.cloudVolumesServiceAzure.performance"
        },
        {
            Icon: FeaturesAndPricingIcon,
            title: "Features and Pricing",
            linkKey: "dashboards.cloudVolumesServiceAzure.featuresAndPricing"
        }
    ]}/>
</React.Fragment>;

const CVSGCP = () => <React.Fragment>
    <p>A Google Cloud integrated file service with the performance, availability, and security required to efficiently run your business-critical applications.</p>
    <ExternalLink linkKey="launchPad.services.cloudVolumesServiceGooglePreview" trackCategory="dashboards" className={styles['cta-no-center']} newTab>Subscribe to the Service</ExternalLink>
    <GettingStartedContent gettingStartedContent={[
        {
            Icon: DemoIcon,
            title: "Learn More About Cloud Volumes Service for Google Cloud",
            linkKey: "dashboards.cloudVolumesServiceGcp.learnMore"
        },
        {
            Icon: PerformanceIcon,
            title: "Get to know the performance of Cloud Volumes Service for Google Cloud",
            linkKey: "dashboards.cloudVolumesServiceGcp.performance"
        },
        {
            Icon: FeaturesAndPricingIcon,
            title: "Features and Pricing",
            linkKey: "dashboards.cloudVolumesServiceGcp.featuresAndPricing"
        }
    ]}/>
</React.Fragment>;

const CVSAWS = () => <React.Fragment>
    <p>Extreme Performance for File Services</p>
    <ExternalLink linkKey="launchPad.services.cloudVolumesServiceAwsSubscription" trackCategory="dashboards" className={styles['cta-no-center']} newTab>Subscribe to the Service</ExternalLink>
    <GettingStartedContent gettingStartedContent={[
        {
            Icon: RocketshipIcon,
            title: "Get Started with Cloud Volumes Service for AWS",
            linkKey: "dashboards.cloudVolumesServiceAws.getStarted"
        },
        {
            Icon: PerformanceIcon,
            title: "Get to know the performance of Cloud Volumes Service for AWS",
            linkKey: "dashboards.cloudVolumesServiceAws.performance"
        },
        {
            Icon: FeaturesAndPricingIcon,
            title: "Features and Pricing",
            linkKey: "dashboards.cloudVolumesServiceAws.featuresAndPricing"
        }
    ]}/>
</React.Fragment>;

const CloudVolumesServiceCustom = () => <section className={styles["cloud-volumes-service-custom"]}>
    <h5>Fully managed, cloud service that enables you to move your mission-critical workloads and applications to the cloud, and manage them with ease. Available in Azure, AWS, and Google Clouds.</h5>
    <div className={styles["cloud-volumes-service-switcher-container"]}>
        <NavLink to="/dashboard/cloud-volumes-service/azure" className={styles['cloud-volumes-service-switcher']} activeClassName={styles['cvs-active']}><AzureLogo/></NavLink>
        <NavLink to="/dashboard/cloud-volumes-service/gcp" className={styles['cloud-volumes-service-switcher']} activeClassName={styles['cvs-active']}><GoogleCloudLogo/></NavLink>
        <NavLink to="/dashboard/cloud-volumes-service/aws" className={styles['cloud-volumes-service-switcher']} activeClassName={styles['cvs-active']}><AWSLogo/></NavLink>
    </div>
    <div className={styles["cloud-volumes-service-container"]}>
        <Switch>
            <Route path="/dashboard/cloud-volumes-service/azure" render={() => <CVSAzure/>}/>
            <Route path="/dashboard/cloud-volumes-service/gcp" render={() => <CVSGCP/>}/>
            <Route path="/dashboard/cloud-volumes-service/aws" render={() => <CVSAWS/>}/>
            <Redirect to="/dashboard/cloud-volumes-service"/>
        </Switch>
    </div>
</section>;

export const CloudVolumesServiceDashboard = () => <DashboardContainer
    category="storage"
    title="CVS-AWS/GCP & ANF"
    serviceKey="cloudVolumesService"
    iconKey="cloud-volumes-service"
    CustomPane={CloudVolumesServiceCustom}
    iconStyle={{width: 120, height: 125}}
/>;

export const CloudInsightsDashboard = () => <DashboardContainer
    category="analytics"
    title="Cloud Insights"
    serviceKey="cloudInsights"
    iconKey="observability"
    iconStyle={{width: 118, height: 80}}
    productText="NetApp Cloud Insights is an infrastructure monitoring tool that gives you visibility into your complete infrastructure. With Cloud Insights, you can monitor, troubleshoot and optimize all your resources including your public clouds and your private data centers."
    gettingStartedLink="dashboards.cloudInsights.gettingStarted"
/>;

export const CloudTieringDashboard = () => <DashboardContainer
    category="services"
    title="Cloud Tiering"
    serviceKey="cloudTiering"
    iconKey="cloud-tiering"
    iconStyle={{width: 101, height: 86}}
    productText={["Automatically tier cold data to the cloud to Free up high-performance storage space.", "NetApp’s Cloud Tiering service allows you to retain your on-prem, high-performance AFF (and FAS SSD) storage and combine it with the benefits of cloud economics, leveraging the low costs of Azure Blob, Amazon S3, Google Cloud and NetApp StorageGrid (private cloud)."]}
/>;

export const CloudBackupServiceDashboard = () => <DashboardContainer
    category="services"
    title="Cloud Backup Service"
    serviceKey="cloudBackup"
    iconKey="cloud-backup"
    iconStyle={{width: 103, height: 93}}
    productText="Cloud Backup Service is an integrated feature for Cloud Volumes Service. It delivers fully managed backup and restore capabilities for protection, and long-term archive of your cloud data."
/>;

export const VirtualDesktopServiceDashboard = () => {
    return <DashboardContainer
        category="controls"
        serviceKey="virtualDesktopService"
        title="Virtual Desktop Service"
        iconKey="virtual-desktop-service"
        iconStyle={{width: 105, height: 91}}
        productText="NetApp Virtual Desktop Service (VDS) is NetApp’s SaaS solution to automatically provision, deploy, orchestrate and manage virtual desktops in any cloud environment, or even on-premise. NetApp VDS extends cloud capabilities by delivering a global control plane to manage virtual desktops through all phases of the desktop lifecycle. VDS is a flexible solution, with open REST APIs, that is interoperable with your cloud of choice, your desktop devices. NetApp VDS currently supports both Microsoft’s Windows Virtual Desktop Service, as well as Remote Desktop Service when deployed in AWS and Google Cloud, or on-premise HCI environments with VMware."
        contactUsEmail="ng-VDS-sales@netapp.com"
        gettingStartedContent={[
            {
                Icon: FaqIcon,
                title: "Request Info",
                linkKey: "dashboards.virtualDesktopService.requestInfo"
            },
            {
                Icon: RocketshipIcon,
                title: "Free Trial",
                linkKey: "dashboards.virtualDesktopService.freeTrial"
            },
            {
                Icon: DocumentationIcon,
                title: "How to Documentation",
                linkKey: "dashboards.virtualDesktopService.documentation"
            }
        ]}
    />
};
