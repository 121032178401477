import _ from "lodash"
import { connect } from 'react-redux'
import store from './../../store'
import * as actions from "./../../actions";
import axios from 'axios'

const LaunchPadLoaderWithAccounts = ({ loadAll, userAccountStatus }) => {
    if(userAccountStatus === "SUCCESS" || userAccountStatus === "ERROR") {
        loadAll();
    }
    return "";
};

const mapStateToProps = state => ({
    userAccountStatus: state.auth.userAccountStatus
});

const mapDispatchToProps = dispatch => {
    const fetchOccms = () => {
        const account = store.getState().auth.userAccount;
        const payload = account ? axios.get(`/occm/list-occms/${account.value}`) : axios.get("/occm/list-occms");
        payload.then(
            r => dispatch({type: "OCCMS", payload: r, status: actions.STATUS_SUCCESS}),
            e => dispatch({type: "OCCMS", payload: e, status: actions.STATUS_ERROR}));

        dispatch({
            type: "OCCMS",
            status: actions.STATUS_PENDING
        });
        return payload;
    };

    const fetchCloudSyncUserInfo = () => {
        dispatch({
            type: "LAUNCHPAD:FETCH-CLOUD-SYNC-USER-INFO"
        })
    };

    const loadAll = _.once(() => {
        const fetchOccmsRecur = () =>
            fetchOccms().then(() => {
                const cloudVolumesOntap = store.getState().services.cloudVolumesOntap;
                if(cloudVolumesOntap.status === actions.STATUS_SUCCESS && cloudVolumesOntap.data.occms.length === 1 && cloudVolumesOntap.data.occms[0].status === "initializing"){
                    setTimeout(fetchOccmsRecur, 10000);
                }
            }, () => {
                const cloudVolumesOntap = store.getState().services.cloudVolumesOntap;
                if(cloudVolumesOntap.succeededOnce){
                    setTimeout(fetchOccmsRecur, 10000);
                }
            });
        fetchOccmsRecur();
        fetchCloudSyncUserInfo();
    });

    return {
        loadAll
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LaunchPadLoaderWithAccounts)