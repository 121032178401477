import _ from "lodash";
import querystring from 'query-string';
import store from "./../store"

/*
 Hi, I'm Mr. Tracker! How may I help?
 */

const isDemoMode =  () => _.get(store.getState(), "features.active.demoMode", false);

const sendBeacon = ({category, action, label, extra, email}) => {
    const state = store.getState();
    navigator.sendBeacon(`${window.__react_app_portal_backend_base_url}/analytics/event`, JSON.stringify({
        userEmail: (state.auth && state.auth.email) || email,
        userId: state.auth && state.auth.userId,
        category,
        action,
        label,
        extra
    }));
};

const EXTERNAL_LINK_ACTION = "ExternalLinkClick";
const INTERNAL_LINK_ACTION = "InternalLinkClick";
const FLOW_ACTION = "FlowAction";
const LOCATION = "StartPage";
const CUSTOM_ACTION = "CustomTracking";
const BUTTON_ACTION = "ClickButton";

/*
    Custom Message Tracking
    =======================
 */

export const trackCustomAction = ({category, label, email, extra}) => {
    if(isDemoMode()) return;

    sendBeacon({category, action: CUSTOM_ACTION, label, email, extra})
};

/*
    Internal Link Tracking
    ======================
 */

export const trackInternalLink = ({path, link, category}) => {
    if(isDemoMode()) return;

    window.ga('send', {
        hitType: 'event',
        eventCategory: category,
        eventAction: INTERNAL_LINK_ACTION,
        eventLabel: path
    })
};

/*
    External Link Tracking
    ======================
 */

const keySplitter = (linkKey, trackCategory) => {
    let firstDotIndex = linkKey.indexOf(".");
    if(firstDotIndex === -1){
        firstDotIndex = linkKey.length;
    }

    return {
        category: _.upperFirst(trackCategory || linkKey.substring(0, firstDotIndex)),
        label: linkKey.substring(firstDotIndex+1)
    }
};

const shouldCustomTrackExternalLink = ({ category, label }) => category === "CloudAssessment" && _.startsWith(label, "summary");

export const trackExternalLink = ({linkKey, extra, trackCategory}) => {
    if(isDemoMode()) return;

    const {category, label} = keySplitter(linkKey, trackCategory);
    window.ga('send', {
        hitType: 'event',
        eventCategory: category,
        eventAction: EXTERNAL_LINK_ACTION,
        eventLabel: label
    });

    if(shouldCustomTrackExternalLink({category, label})){
        sendBeacon({category, action: EXTERNAL_LINK_ACTION, label, extra})
    }
};


/*
    ACTION TRACKING
    ===============
*/

const trackActionLabelBlacklist = ["clearError", "gcpErrorUi", "clearGcpAccessToken", "awsSuccessUi", "awsErrorUi", "azureErrorUi", "storeAzureLoginToken", "azureSelectSubscription"];
const trackActionCategory = {
    "Audit": true
};

const shouldCustomTrackAction = ({category, label}) => category === "CloudAssessment" && !trackActionLabelBlacklist.includes(label);
const shouldTrackAction = ({category, ignoreTrack}) => trackActionCategory[category] && !ignoreTrack;

const oldActionSplitter = type => {
    const colonIndex = type.indexOf(":");
    return {
        category: _.upperFirst(_.camelCase(type.substring(0, colonIndex))),
        label: _.camelCase(type.substring(colonIndex+1))
    }
};

const actionSplitter = type => {
    const colonIndex = type.indexOf(":");
    return {
        category: _.upperFirst(_.camelCase(type.substring(0, colonIndex))),
        actionSuffix: _.upperFirst(type.substring(colonIndex+1))
    }
};

const trackActionOld = ({payload, type, trackPayload, omitInPayload}) => {
    const {category, label} = oldActionSplitter(type);
    const action = FLOW_ACTION;

    const dispatchGa = () => {
        window.ga('send', {
            hitType: 'event',
            eventCategory: category,
            eventAction: action,
            eventLabel: label
        });
    };

    if(shouldCustomTrackAction({category, label})){
        const extra = trackPayload ? _.omit(_.cloneDeep(payload), omitInPayload) : undefined;
        sendBeacon({category, action, label, extra});
        dispatchGa();
    } else if (shouldTrackAction({category})){
        dispatchGa();
    }
};

export const trackAction = ({payload, type, trackPayload, omitInPayload, ignoreTrack}) => {
    if(isDemoMode()) return;


    if(type.startsWith("CLOUD-ASSESSMENT")){
        trackActionOld({payload, type, trackPayload, omitInPayload});
    } else {
        const {category, actionSuffix} = actionSplitter(type);
        if(shouldTrackAction({category, ignoreTrack})){
            const action = `${FLOW_ACTION}:${actionSuffix}`;
            const label = payload ? payload.trackingLabel || payload.label || payload.id || "-" : "-";

            const dispatchGa = () => {
                window.ga('send', {
                    hitType: 'event',
                    eventCategory: category,
                    eventAction: action,
                    eventLabel: label
                });
            };

            dispatchGa();
        }
    }
};


/*
        LOCATION TRACKING
        =================
 */

const shouldCustomTrackLocation = ({category}) => category === "CloudAssessment";

const pathSplitter = (pathname) => {
    if (!pathname || pathname === "/"){
        return {
            category: null,
            label: null
        }
    }

    const split = pathname.substring(1).split(/\/(.+)/);
    return {
        category: _.upperFirst(_.camelCase(split[0])),
        label: split[1] || "/"
    }
};

export const trackPageView = ({location}) => {
    if(isDemoMode()) return;

    let path = location.pathname;
    if(location.search){const search = querystring.parse(location.search);
        delete search.paramsForLoginPage;
        delete search.__hstc;
        delete search.__hssc;
        delete search.__hsfp;
        delete search.hsCtaTracking;
        delete search.code;

        const searchString = querystring.stringify(search);
        path = path + (searchString ? "?" + searchString : '');
    }
    window.ga('set', 'page', path);
    window.ga('send', 'pageview', path);

    const {category, label} = pathSplitter(location.pathname);

    if(shouldCustomTrackLocation({category, label})) {
        sendBeacon({category, action: LOCATION, label, extra: location.state})
    }
};


/*
    Button Click Tracking
    ===================

 */

export const trackButtonClick = ({category, action, label}) => {
    if(isDemoMode()) return;

    window.ga('send', {
        hitType: 'event',
        eventCategory: category,
        eventAction: action || BUTTON_ACTION,
        eventLabel: label
    });
};
