import React, {useState, useEffect, useMemo} from "react";
import _ from "lodash";
import uuidv4 from "uuid/v4";
import classNames from "classnames";
import {useWizard, ActionButton, useForm, FormFieldInputNew, FormFieldSelect, Popover} from "@netapp/bxp-design-system-react";
import {serviceLevelOptions} from "../../utils/aws";
import {FooterLayout, ContentLayout} from "./Page";
import {AddItemIcon as AddIcon} from "@netapp/bxp-design-system-react/icons/monochrome";
import { CloseMonochromeIconsIcon as Close } from "@netapp/bxp-design-system-react/icons/monochrome";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import commonStyles from "../../styles/common.module.scss";
import styles from "./DetailsAndTags.module.scss";

export const Footer = () => {
    const {setStep, state} = useWizard();
    const detailsAndTagsForm = state.detailsAndTagsForm || {};
    const { handleFormSubmit } = detailsAndTagsForm;

    const submitForm = () => {
        setStep("protocol");
    };

    return (
        <FooterLayout><ActionButton disabled={!handleFormSubmit} onClick={e => handleFormSubmit(submitForm)(e)}>Continue</ActionButton></FooterLayout>
    );
};

const tagItemInitialState = { name: "", id: uuidv4() };

const detailsAndTagsInitialState =  {
    volumeName: "",
    sizeInGiB: "",
    serviceLevel: serviceLevelOptions[0],
    tags: [{...tagItemInitialState}]
};

const tagFormValidator = tag => {
    const errors = {};
    if (!tag.name) {
        errors[`${tag.id}-name`] = "Tag Name is required."
    }
    return errors;
};

const tagsFormValidator = tags => {
    let errors = {};
    _.forEach(tags, tag => {
        const itemErrors = tagFormValidator(tag);
        if (!_.isEmpty(itemErrors)) {
            errors = { ...errors, ...itemErrors };
        }
    });
    return errors;
};

export const validator = state => {
    const errors = {};
    const tagsErrors = tagsFormValidator(state.tags);
    if (!state.volumeName) {
        errors.volumeName = "Volume name is required."
    }
    if (state.sizeInGiB) {
        const sizeInt = Number(state.sizeInGiB);
        if (isNaN(sizeInt)) {
            errors.sizeInGiB = "Size must be a number."
        }
        if (sizeInt < 100) {
            errors.sizeInGiB = "Size must be >= 100 GiB."
        }
        if (sizeInt > 100000) {
            errors.sizeInGiB = "Volume size must <= 100000 GiB."
        }
    }
    if (!state.sizeInGiB) {
        errors.sizeInGiB = "Volume size is required."
    }
    if (!state.serviceLevel) {
        errors.serviceLevel = "Service level selection is required."
    }
    if (state.tags.length === 1 && _.isEmpty(state.tags[0].name)) {
        // tags are optional - allow user to continue if first tag form is empty (by removing tag form errors from form errors)
        return errors;
    } else {
        return { ...tagsErrors, ...errors };
    }
};

export const Tags = ({ form }) => {
    const [showListItemsErrors, setShowListItemsErrors] = useState(false);
    const { formState, handleFormChange } = form;
    const showRemovalButton = formState?.tags.length > 1;
    const tagsErrors = useMemo(() => {
        return tagsFormValidator(formState.tags)
    }, [formState]);

    const handleItemChange = index => e => {
        const updatedTags = _.map(formState.tags, (tag, i) => {
            return i === index ? ({ ...tag, "name": e.target.value }) : tag;
        });
        handleFormChange(updatedTags, { name: "tags" });
        if (updatedTags.length === 1 && _.isEmpty(updatedTags[0].name)) {
            setShowListItemsErrors(false);
        }
    };

    const handleItemRemoval = index => {
        const filteredItems = [
            ...formState.tags.slice(0, index),
            ...formState.tags.slice(index + 1, formState.tags.length)
        ];
        handleFormChange(filteredItems, { name: "tags" });
    };

    const handleItemAddition = () => {
        if (_.isEmpty(tagsErrors)) {
            const withNewItem = [...formState.tags, { ...tagItemInitialState, id: uuidv4() }];
            handleFormChange(withNewItem, { name: "tags" });
            setShowListItemsErrors(false);
        } else {
            setShowListItemsErrors(true);
        }
    };

    if (_.isEmpty(form)) {
        return null;
    }

    return (
        <div className={commonStyles["dynamic-list-form"]}>
                {_.map(formState.tags, (tag, i) => <div key={tag.id} className={styles["tag-form"]}>
                    <FormFieldInputNew
                        form={form}
                        value={formState.tags[i].name}
                        label="Tag Name"
                        onChange={handleItemChange(i)}
                        className={commonStyles["form-field"]}
                        showError={showListItemsErrors}
                        errorMessage={tagsErrors[`${formState.tags[i].id}-name`]}
                        maxChars={256}/>
                    { showRemovalButton &&
                    <button onClick={() => handleItemRemoval(i)}>
                        <Close/>
                    </button>}
                </div>)}
            <div className={commonStyles["add-button"]}>
                <button onClick={handleItemAddition}><AddIcon/><span>Add More Tags</span></button>
            </div>
        </div>
    );
};

export const Content = () => {
    const {setState, state} = useWizard();
    const currentState = state?.detailsAndTagsForm?.formState || {};
    const detailsAndTagsForm = useForm({...detailsAndTagsInitialState, ...currentState}, validator);

    useEffect(() => {
        setState({detailsAndTagsForm})
    }, [detailsAndTagsForm, setState]);

    return (
        <ContentLayout className={styles["container"]} title={"Details & Tags"}>
            <div className={styles["content-grid"]}>
                <div className={styles["details"]}>
                    <div className={typography.body16}>Details</div>
                    <div>
                        <div className={styles["name-and-size"]}>
                            <FormFieldInputNew
                                form={detailsAndTagsForm}
                                name="volumeName"
                                label="Volume Name"
                                className={classNames(commonStyles["form-field"], styles["volume-name"])}
                                autoFocus={true}
                                maxChars={255}/>
                            <FormFieldInputNew
                                form={detailsAndTagsForm}
                                name="sizeInGiB"
                                label="Size (GiB)"
                                placeholder=">=100"
                                maxChars={256}
                                className={classNames(commonStyles["form-field"], styles["volume-size"])}
                                labelChildren={<Popover>Volume size should be greater then or equal to 100 GiB and smaller then or equal to 100000 GiB</Popover>}
                            />
                        </div>
                        <FormFieldSelect
                            form={detailsAndTagsForm}
                            name="serviceLevel"
                            label="Service Level"
                            isSearchable={true}
                            placeholder="- Select a service level -"
                            options={serviceLevelOptions}
                            labelChildren={<Popover>This is the target performance for the volume resource that is being created</Popover>}
                        />
                    </div>
                </div>
                <div className={styles["tags"]}>
                    <div className={typography.body16}>Tags <i>(Optional)</i></div>
                    <Tags form={detailsAndTagsForm}/>
                </div>
            </div>
        </ContentLayout>
    );
};
