import React from "react";
import classNames from "classnames";
import {FormFieldInputNew} from "@netapp/bxp-design-system-react";
import commonStyles from "../styles/common.module.scss";
import styles from "./ActiveDirectoryForm.module.scss";

export default ({ form, disabled }) => {
    return (
        <div className={classNames(styles["connectivity-form"])}>
            <FormFieldInputNew
                form={form}
                name="DNS"
                label="DNS Primary IP Address"
                maxChars={256}
                autoFocus={true}
                disabled={disabled}
                placeholder="127.0.0.1"
                className={commonStyles["form-field"]}/>
            <FormFieldInputNew
                form={form}
                name="username"
                label="User Name"
                maxChars={256}
                disabled={disabled}
                placeholder="administrator"
                className={classNames(commonStyles["form-field"], styles["alignment"])} autoComplete="off"/>
            <FormFieldInputNew
                form={form}
                name="domain"
                label="Active Directory Domain to Join"
                maxChars={107}
                disabled={disabled}
                placeholder="yourdomain.com up to 107 characters"
                className={commonStyles["form-field"]}/>
            <FormFieldInputNew
                form={form}
                type="password"
                name="password"
                label="Password"
                maxChars={256}
                disabled={disabled}
                className={classNames(commonStyles["form-field"], styles["alignment"])}/>
            <FormFieldInputNew
                form={form}
                name="netBIOS"
                label="SMB Server NetBIOS Name"
                maxChars={10}
                disabled={disabled}
                className={commonStyles["form-field"]}/>
            <FormFieldInputNew
                form={form}
                name="organizationalUnit"
                label="Organizational Unit"
                maxChars={256}
                disabled={disabled}
                placeholder="CN=Computers"
                className={classNames(commonStyles["form-field"], styles["alignment"])}/>
        </div>
    );
};
