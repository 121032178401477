import React from "react";
import {SimpleHeader, PageLayoutNoFooter, ContentLayout} from "./Page";
import {Notification} from "@netapp/bxp-design-system-react";
import commonStyles from "../styles/common.module.scss";
import styles from "./NotFoundPage.module.scss";

export const NotFoundBody = ({ notFoundText, showError, errorMessage, deleteNotification, icon }) => {
    const Icon = icon;
    return (
        <ContentLayout className={styles["container"]}>
            <div className={styles["content"]}>
                <div className={styles["no-volumes-watermark"]}>
                    <Icon/>
                    {notFoundText}
                </div>
                {showError && <div className={commonStyles["error-area"]}>
                    <Notification type={'error'} onClose={deleteNotification}>{errorMessage}</Notification>
                </div>}
            </div>
        </ContentLayout>
    );
};

export default ({ children, icon, notFoundText, headerText, flavour, showError, errorMessage, deleteNotification, onPageClose }) => {
    return (
        <PageLayoutNoFooter>
            {children || <SimpleHeader onClose={onPageClose} flavour={flavour} title={headerText}/>}
            <NotFoundBody icon={icon} notFoundText={notFoundText} deleteNotification={deleteNotification} errorMessage={errorMessage} showError={showError}/>
        </PageLayoutNoFooter>
    );
};
