import React from 'react';
import _ from "lodash";

import links from '../../consts/links';
import { trackExternalLink } from "../../utils/mrTracker";

export default ({ linkKey, href, newTab, children, className, style, title, trackCategory, queryString }) => (
    <a href={(href || _.get(links, linkKey)) + (queryString || "")}
       onClick={() => linkKey && trackExternalLink({ linkKey, trackCategory })}
       className={className}
       style={style}
       title={title}
       target={newTab ? "_blank" : undefined}
       rel={newTab ? "noopener noreferrer" : undefined}
       children={children}/>
);
