import React from "react";
import {BackButton} from "../BackButton";
import styles from "./ToPreviousStepButton.module.scss";

export const BackToPreviousStep = ({flavour}) => {
    const onBackClicked = () => {
        window.parent.postMessage({ action: "cvs-add-we:back", provider: flavour }, "*");
    };
    return <BackButton className={styles["to-previous-step"]} onBackClicked={onBackClicked}/>
};
