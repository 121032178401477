import _ from "lodash"
import { connect } from 'react-redux'

const LaunchPadLoader = ({loadAll}) => {
    loadAll();
    return "";
};

const mapDispatchToProps = dispatch => {

    const fetchCloudInsightsUserInfo = () => {
        dispatch({
            type: "LAUNCHPAD:FETCH-CLOUD-INSIGHT-USER-INFO"
        })
    };

    const fetchCloudVolumesServiceAwsUserInfo = () => {
        dispatch({
            type: "LAUNCHPAD:FETCH-CLOUD-VOLUMES-SERVICE-AWS-USER-INFO"
        })
    };

    const fetchDefaultStates = () => {
        dispatch({
            type: "LAUNCHPAD:LOAD-SERVICE-DEFAULT-STATES"
        })
    };

    const loadAll = _.once(() => {
        fetchDefaultStates();
        fetchCloudInsightsUserInfo();
        fetchCloudVolumesServiceAwsUserInfo();
    });

    return {
        loadAll
    }
};

export default connect(null, mapDispatchToProps)(LaunchPadLoader)