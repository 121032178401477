import _ from "lodash";

export default (state = {}, {type, payload}) => {
    switch (type) {
        case "DEMO:RESET_CLOUD_TIERING_SUCCESS": {
            console.log("reducer dispatching reset success");
            return {tieringRequestState: "SUCCESS"}
        }
        case "DEMO:RESET_CLOUD_TIERING_PENDING": {
            console.log("reducer dispatching reset pending");
            return {tieringRequestState: "PENDING"}
        }
        case "DEMO:RESET_CLOUD_TIERING_ERROR": {
            console.log("reducer dispatching reset error");
            return {tieringRequestState: "ERROR", tieringResetError: _.get(payload, "err.message")}
        }
        default:
            return state;
    }
};