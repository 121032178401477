import {awsVolumePathRegex} from "../../utils/aws";
import {dnsRegx, domainRegx} from "../../utils/common";
import _ from "lodash";
import * as yup from "yup";
import {exportPolicyInitialState, exportPolicyFormValidator} from "./ProtocolExportPolicies";
import {yupValidator} from "@netapp/bxp-design-system-react";

export const initialState = {
    protocolOption: "nfs",
    internalStep: 0,
    editActiveDirectoryMode: false,
    volumePath: "",
    NFSv3: true,
    NFSv4: true,
    nfsVersion: "NFSv3",
    encryption: false,
    security: "Unix",
    exportPolicies: [{...exportPolicyInitialState}],
    DNS: "",
    netBIOS: "",
    domain: "",
    username: "",
    password: "",
    organizationalUnit: ""
};

export const schema = yup.object().shape({
    protocolOption: yup.string().oneOf(["nfs", "smb", "dual"]),
    nfsVersion: yup.string().oneOf(["NFSv3", "NFSv4"]),
    editActiveDirectoryMode: yup.boolean(),
    internalStep: yup.number().max(1).min(0),
    volumePath: yup.string().when("internalStep", { is: 0, then: yup.string().matches(awsVolumePathRegex, "Invalid volume path.").required("Volume path is required.") }),
    encryption: yup.boolean(),
    NFSv3: yup.boolean(),
    NFSv4: yup.boolean(),
    security: yup.string(),
    DNS: yup.string().when("internalStep", { is: 1, then: yup.string().matches(dnsRegx, "Invalid DNS.").required("Primary IP is required.") }),
    netBIOS: yup.string().when("internalStep", { is: 1, then: yup.string().required("Primary IP is required.") }),
    domain: yup.string().when("internalStep", { is: 1, then: yup.string().matches(domainRegx, "Invalid domain.").required("Domain is required.") }),
    username: yup.string().when("internalStep", { is: 1, then: yup.string().required("User Name is required.") }),
    password: yup.string().when("internalStep", { is: 1, then: yup.string().required("Password is required.") }),
    organizationalUnit: yup.string().when("internalStep", { is: 1, then: yup.string().required("Organisational unit is required.") }),
}).test('version-selection', null, obj => {
    if (obj.protocolOption === "smb" || obj.internalStep === 1) {
        return true;
    }
    if (obj.NFSv3 || obj.NFSv4) {
        return true;
    }
    return new yup.ValidationError(
        'Select NFS version.',
        null,
        'nfsVersions'
    );
});

export const validator = flavour => state => {
    const { exportPolicies, ...rest }  = state;
    let exportPoliciesErrors = {};
    if (state.protocolOption === "nfs" || (state.protocolOption === "dual" && state.internalStep === 0)) {
        _.forEach(exportPolicies, itemState => {
            const itemErrors = exportPolicyFormValidator(flavour, itemState);
            if (!_.isEmpty(itemErrors)) {
                exportPoliciesErrors = { ...exportPoliciesErrors, ...itemErrors }
            }
        });
    }
    const errors = yupValidator(schema)(rest);
    return { ...errors, ...exportPoliciesErrors };
};
