import React from 'react'
import { Modal, ModalFooter, ModalHeader, ModalContent, Button, ExternalLink, Text } from "@netapp/bxp-design-system-react";

export default ({ closeTrigger, noChat }) => {
    return <Modal closeTrigger={closeTrigger}>
        <ModalHeader>
            Contact Us
        </ModalHeader>
        <ModalContent>
            <Text>For any questions, please reach out to <ExternalLink variant="text" href="https://mysupport.netapp.com/site/help" newTab>support.</ExternalLink></Text>
        </ModalContent>
        <ModalFooter>
            <Button onClick={closeTrigger}>OK</Button>
        </ModalFooter>
    </Modal>
};