import React from "react";
import _ from "lodash";
import classNames from "classnames";
import {Checkbox, FormFieldInputNew, RadioButton, Popover} from "@netapp/bxp-design-system-react";
import {ExportPolicies} from "./ProtocolExportPolicies";
import commonStyles from "../../styles/common.module.scss";
import styles from "./Protocol.module.scss";

export const Dual = ({ form }) => {
    const {handleFormChange, formState, submissionAttempted} = form;

    const handleVersionSelection = e => {
        let updatedPolicies = formState.exportPolicies;
        if (!e.target.checked) {
            updatedPolicies = _.map(formState.exportPolicies, policy => {
                return { ...policy, [e.target.name]: e.target.checked }
            });
            handleFormChange(updatedPolicies, { name: "exportPolicies" });
        }
        handleFormChange(e.target.checked, { name: e.target.name });
    };

    return (
        <div className={styles["two-col-form"]}>
            <div className={styles["left-col"]}>
                <div className={styles["title"]}>Protocol</div>
                <FormFieldInputNew
                    form={form}
                    name="volumePath"
                    label="Volume Path"
                    maxChars={256}
                    autoFocus={true}
                    className={commonStyles["form-field"]}/>
                <div className={styles["nfs-version"]}>
                    <div className={styles["version-title"]}>Select NFS Version:</div>
                    <div className={styles["checkboxes"]}>
                        <Checkbox onChange={handleVersionSelection} checked={formState.NFSv3} name={"NFSv3"}>NFSv3</Checkbox>
                        <Checkbox onChange={handleVersionSelection} checked={formState.NFSv4} name={"NFSv4"}>NFSv4.1</Checkbox>
                        {(submissionAttempted && ((!formState.NFSv3 && !formState.NFSv4) || !formState.nfsVersion)) && <div className={styles["error"]}>Select NFS version.</div>}
                    </div>
                </div>
                <div className={styles["dual-version"]}>
                    <div className={styles["version-title"]}>Select Security Style:</div>
                    <div className={styles["checkboxes"]}>
                        <RadioButton form={form} name="security" value="Unix">Unix</RadioButton>
                        <RadioButton form={form} name="security" value="NTFS">NTFS</RadioButton>
                    </div>
                </div>
                <div className={styles["dual-version"]}>
                    <div className={styles["version-title"]}>SMB Protocol Encryption</div>
                    <div className={styles["checkboxes"]}>
                        <Checkbox form={form} name="encryption">SMB3</Checkbox>
                    </div>
                </div>
            </div>
            <div className={styles["right-col"]}>
                <div className={styles["title"]}>Export Policy</div>
                <div className={styles["policy-title"]}>
                    Allowed Client & Access
                    <Popover>Allowed clients will accept a comma separated list of IPs (v4) and/or cidrs. In most cases this is the private IP of your instance/VM. If using public IPs please be aware that they have to be reachable from the volume's network for the export policy to work correctly</Popover>
                </div>
                <div className={classNames(commonStyles["list-form"], styles["list-form"])}>
                    <ExportPolicies form={form}/>
                </div>
            </div>
        </div>
    );
};
