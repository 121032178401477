import React, {useEffect} from "react";
import * as yup from "yup";
import ContentLayout, {Title} from "../ContentLayout";
import {Notification, Notifications, FormFieldSelect, ActionButton, useForm} from "@netapp/bxp-design-system-react";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import commonStyles from "../../../../styles/common.module.scss";
import styles from "./Content.module.scss";
import ActionButtonContainer from "../ActionButtonContainer";

const initialState = {
    snapshot: null
};

const schema = yup.object().shape({
    snapshot: yup.object().nullable().required("Snapshot selection is required.")
});

export default ({volume}) => {
    const dispatch = useDispatch();
    const flavour = useSelector(state => _.get(state, "cvs.cvsFlavour"));
    const form = useForm(initialState, schema);
    const snapshots = useSelector(state => _.get(state, "cvs.volumeSnapshots"));
    const getSnapshotsPending = useSelector(state => _.get(state, "cvs.getVolumeSnapshotsPending"));
    const getSnapshotError = useSelector(state => _.get(state, "cvs.getVolumeSnapshotsError"));
    const getSnapshotShowError = useSelector(state => _.get(state, "cvs.getVolumeSnapshotsShowError"));
    const revertVolumePending = useSelector(state => _.get(state, "cvs.revertVolumePending"));
    const revertVolumeError = useSelector(state => _.get(state, "cvs.revertVolumeError"));
    const revertVolumeShowError = useSelector(state => _.get(state, "cvs.revertVolumeShowError"));

    useEffect(() => {
        dispatch({
            type: `CVS:${flavour.toUpperCase()}-GET-VOLUME-SNAPSHOTS`,
            payload: { volume }
        });
        return () => dispatch({
            type: "CSV:CLEAR-STATE",
            payload: {
                volumeSnapshots: [],
                getVolumeSnapshotsError: null,
                getVolumeSnapshotsResolved: false,
                getVolumeSnapshotsShowError: false,
                revertVolumeError: null,
                revertVolumeResolved: false,
                revertVolumeShowError: false
            }
        });
    }, [dispatch, flavour, volume]);

    const restoreVolumeFromSnapshot = () => {
        dispatch({
            type: `CVS:${flavour.toUpperCase()}-REVERT-VOLUME`,
            payload: { volume, snapshotId: form.formState.snapshot.value }
        });
    };

    const hideNotification = () => dispatch({
        type: "CVS:CLEAR-STATE",
        payload: { getSnapshotShowError: false, revertVolumeShowError: false }
    });

    const notifications = [{
        type: "error",
        description: getSnapshotError,
        onClose: () => hideNotification(),
        showError: getSnapshotShowError
    }, {
        type: "error",
        description: revertVolumeError,
        onClose: () => hideNotification(),
        showError: revertVolumeShowError
    }];

    return (
        <ContentLayout className={styles["content"]}>
            <Title className={"not-spaced"}>Revert volume to Snapshot - <span className={"vol-name"} title={volume.name}>{volume.name}</span></Title>
            <Notification className={styles["notification"]} type={"warning"}>This action reverts the volume to a previous state. Any data saved after the Snapshot copy was created will be lost. This action can’t be reversed.</Notification>
            <FormFieldSelect
                form={form}
                isClearable={true}
                isSearchable={true}
                name="snapshot"
                label="Snapshot Name"
                placeholder="- Select a snapshot copy -"
                options={snapshots}
                isLoading={getSnapshotsPending}
                className={styles["form-field"]}
            />
            <ActionButtonContainer>
                <ActionButton isSubmitting={revertVolumePending} onClick={e => form.handleFormSubmit(restoreVolumeFromSnapshot)(e)}>Revert</ActionButton>
            </ActionButtonContainer>
            <Notifications className={commonStyles["error-area"]} notifications={_.filter(notifications, { showError: true })}/>
        </ContentLayout>
    );
};
