import { Component } from 'react'



let loadCount = 1;

const decrement = () => {
    loadCount = loadCount - 1;
    if(loadCount === 0) {
        document.body.classList.add("loaded");
    }
};

const increment = () => {
    loadCount = loadCount + 1;
    if(loadCount === 1) {
        document.body.classList.remove("loaded");
    }
};

export const removeLoader = (ignoreTimer) => {
    const now = (new Date()).valueOf();
    const then = window.__loadStart;

    const diff = now - then;
    if(diff < 1400 && !ignoreTimer){
        setTimeout(() => {
            decrement();
        }, 1400 - diff)
    } else {
        decrement();
    }

};

export default class Loader extends Component {
    componentDidMount(){
        increment();
    }

    componentWillUnmount(){
        decrement();
    }

    render() {
        return ""
    }
}