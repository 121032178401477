const links = {
    marketingPageRedirect: process.env.REACT_APP_HOMEPAGE_REDIRECT,
    mainNav: {
        statusPage: "https://status.services.cloud.netapp.com",
        awsTcoCalculator: process.env.REACT_APP_CVO_TCO_CALCULATOR_AWS,
        azureTcoCalculator: process.env.REACT_APP_CVO_TCO_CALCULATOR_AZURE,
        gcpTcoCalculator: process.env.REACT_APP_CVO_TCO_CALCULATOR_GCP,
        gfcRoiCalculator: "https://cloud.netapp.com/global-file-cache/roi",
        cvoSizer: "https://cloud.netapp.com/cvo-sizer"
    },
    dashboards: {
        cloudVolumesServiceAws: {
            gettingStarted: "https://docs.netapp.com/us-en/cloud_volumes/aws/concept_overview.html",
            getStarted: "https://tv.netapp.com/detail/video/5985394922001",
            performance: "https://cloud.netapp.com/cloud-volumes-service/aws-benchmarks",
            featuresAndPricing: "https://cloud.netapp.com/cloud-volumes-service-for-aws"
        },
        cloudVolumesServiceGcp: {
            learnMore: "https://www.youtube.com/watch?v=mZQzPAUH2Lc",
            performance: "https://cloud.netapp.com/cloud-volumes-service/gcp-benchmarks",
            featuresAndPricing: "https://cloud.netapp.com/cloud-volumes-service-for-gcp"
        },
        cloudVolumesServiceAzure: {
            getStarted: "https://docs.microsoft.com/en-us/azure/azure-netapp-files/azure-netapp-files-quickstart-set-up-account-create-volumes",
            performance: "https://cloud.netapp.com/cloud-volumes-service/azure-benchmarks",
            featuresAndPricing: "https://cloud.netapp.com/azure-netapp-files"
        },
        cloudInsights: {
            gettingStarted: "https://docs.netapp.com/us-en/cloudinsights/task_cloud_insights_onboarding_1.html#what-if-i-already-have-a-netapp-cloud-login"
        },
        cloudVolumesOntap: {
            gettingStarted: "https://docs.netapp.com/us-en/occm/reference_deployment_overview.html",
            documentation: "https://docs.netapp.com/us-en/occm/",
            tcoCalculators: "https://cloud.netapp.com/tco-calculator",
            pricing: "https://cloud.netapp.com/pricing#price-aws-tab",
            api: "https://docs.netapp.com/us-en/occm/api.html",
            blog: "https://cloud.netapp.com/blog/topic/cloud-volumes-ontap",
            automationSamples: "https://github.com/edarzi/cloud-manager-automation-samples"
        },
        cloudSync: {
            gettingStarted: "https://docs.netapp.com/us-en/cloudsync/task_quick_start.html",
            api: "https://docs.netapp.com/us-en/cloudsync/reference_apis.html",
            faq: "https://docs.netapp.com/us-en/cloudsync/faq.html",
            supportedSyncRelationships: "https://docs.netapp.com/us-en/cloudsync/reference_sync_requirements.html",
            documentation: "https://docs.netapp.com/us-en/cloudsync/index.html",
            blog: "https://cloud.netapp.com/blog/topic/cloud-sync"
        },
        virtualDesktopService: {
            requestInfo: "https://cloud.netapp.com/vds-lp-get-started",
            faq: "",
            freeTrial: "https://cloud.netapp.com/vds-lp-30-day-sandbox-trial",
            documentation: "https://docs.netapp.com/us-en/virtual-desktop-service",
            gettingStarted: "",
            api: "",
            blog: ""
        }
    },
    launchPad: {
        services: {
            cloudInsights: process.env.REACT_APP_OCI_LINK_URL,
            cloudInsightsBackend: process.env.REACT_APP_OCI_API_URL,
            cloudSync: process.env.REACT_APP_CLOUD_SYNC_FRONTEND_URL,
            cloudSyncBackend: process.env.REACT_APP_CLOUD_SYNC_API_URL,
            occmSaas: process.env.REACT_APP_CLOUD_MANAGER_SAAS_URL,
            cloudManagerCredential:process.env.REACT_APP_CLOUD_MANAGER_SAAS_URL + "/credentials",
            cloudBackup: process.env.REACT_APP_CLOUD_MANAGER_SAAS_URL,
            cloudCompliance: process.env.REACT_APP_CLOUD_MANAGER_SAAS_URL + "/governance",
            occmSetupService: process.env.REACT_APP_OCCM_SERVICE_BASE_URL,
            cloudVolumesServiceAzurePreview: process.env.REACT_APP_CLOUD_VOLUMES_SERVICE_AZURE_PREVIEW_URL,
            cloudVolumesServiceGooglePreview: process.env.REACT_APP_CLOUD_VOLUMES_SERVICE_GOOGLE_PREVIEW_URL,
            cloudVolumesServiceAwsPreview: process.env.REACT_APP_CLOUD_VOLUMES_SERVICE_AWS_PREVIEW_URL,
            cloudVolumesServiceAwsSubscription: process.env.REACT_APP_CLOUD_VOLUMES_SERVICE_AWS_SUBSCRIPTION_URL,
            cloudVolumesServiceAws: process.env.REACT_APP_CLOUD_VOLUMES_SERVICE_AWS_LINK_URL,
            cloudVolumesServiceAwsBackend: process.env.REACT_APP_CLOUD_VOLUMES_SERVICE_AWS_API_URL,
            cloudBackupPreview: process.env.REACT_APP_CLOUD_BACKUP_PREVIEW_URL,
            cloudSecure: process.env.REACT_APP_CLOUD_SECURE_BASE_URL,
            cloudSecurePreview: process.env.REACT_APP_CLOUD_SECURE_PREVIEW_URL,
            cloudTiering: process.env.REACT_APP_CLOUD_TIERING_BASE_URL,
            agentsManagementBackend: process.env.REACT_APP_AGENTS_API_URL,
            tenancyBackend: process.env.REACT_APP_TENANCY_API_URL,
            licenseBackend:process.env.REACT_APP_LICENSE_API_URL,
            saasMp:process.env.REACT_APP_SAAS_MP_API_URL,
            cloudVolumesOntapBackend: process.env.REACT_APP_CVO_API_URL,
            astra: process.env.REACT_APP_ASTRA_BASE_URL,
            astraSubscription: process.env.REACT_APP_ASTRA_SUBSCRIPTION,
            subscriptionStgBackend:process.env.REACT_APP_STG_SUBSCRIPTION_API_URL,
            subscriptionDevBackend:process.env.REACT_APP_DEV_SUBSCRIPTION_API_URL,
            spotAnalyzer: "https://spot.io/products/cloud-analyzer/",
            spotEco: "https://spot.io/products/eco/",
            spotElasticgroup: "https://spot.io/products/elastigroup/",
            spotOcean: "https://spot.io/products/ocean/",
            spotPC: "https://spot.io/products/spotpc/",
            virtualDesktopService: "https://cloud.netapp.com/vds-lp-30-day-sandbox-trial"
        },
        marketing: {
            cloudInsights: process.env.REACT_APP_OCI_MARKETING_URL,
            cloudSync: process.env.REACT_APP_CLOUD_SYNC_MARKETING_URL,
            ontapCloud: process.env.REACT_APP_ONTAP_CLOUD_MARKETING_URL,
            cloudVolumes: process.env.REACT_APP_CLOUD_VOLUMES_MARKETING_URL,
            cloudVolumesServiceAws: process.env.REACT_APP_CLOUD_VOLUMES_SERVICE_AWS_MARKETING_URL,
            cloudVolumesServiceGcp: process.env.REACT_APP_CLOUD_VOLUMES_SERVICE_GCP_MARKETING_URL,
            cloudVolumesServiceAzure: process.env.REACT_APP_CLOUD_VOLUMES_SERVICE_AZURE_MARKETING_URL,
            cloudBackup: process.env.REACT_APP_CLOUD_BACKUP_MARKETING_URL,
            cloudSecure: process.env.REACT_APP_CLOUD_SECURE_MARKETING_URL,
            cloudTiering: process.env.REACT_APP_CLOUD_TIERING_MARKETING_URL
        }
    },
    cloudAssessment: {
        credentials: {
            azureRolesDocumentation: "https://docs.microsoft.com/en-us/azure/role-based-access-control/role-assignments-portal",
            googleRolesDocumentation: "https://cloud.google.com/iam/docs/understanding-roles#compute-engine-roles",
            awsIamDocumentation: "http://docs.aws.amazon.com/IAM/latest/UserGuide/PoliciesOverview.html",
            awsManagingIamDocumentation: "http://docs.aws.amazon.com/IAM/latest/UserGuide/ManagingPolicies.html"
        },
        summary: {
            startCloudVolumesServiceAzure: process.env.REACT_APP_CLOUD_VOLUMES_SERVICE_AZURE_PREVIEW_URL,
            startCloudVolumesServiceAws: process.env.REACT_APP_CLOUD_VOLUMES_SERVICE_AWS_PREVIEW_URL,
            startCloudVolumesServiceGcp: process.env.REACT_APP_CLOUD_VOLUMES_SERVICE_GOOGLE_PREVIEW_URL,
            startCloudVolumesService: process.env.REACT_APP_CLOUD_VOLUMES_SERVICE_AWS_PREVIEW_URL,
            readMoreCloudVolumesServiceAws: process.env.REACT_APP_CLOUD_VOLUMES_SERVICE_AWS_MARKETING_URL,
            readMoreCloudVolumesServiceAzure: process.env.REACT_APP_CLOUD_VOLUMES_SERVICE_AZURE_MARKETING_URL,
            readMoreCloudVolumesServiceGcp: process.env.REACT_APP_CLOUD_VOLUMES_SERVICE_GCP_MARKETING_URL,
            startCloudVolumesOntap: process.env.REACT_APP_OCCM_SERVICE_BASE_URL,
            readMoreCloudVolumesOntap: process.env.REACT_APP_ONTAP_CLOUD_MARKETING_URL,
            readMoreCloudVolumes: process.env.REACT_APP_CLOUD_VOLUMES_MARKETING_URL,
            awsTcoCalculator: "https://cloud.netapp.com/tco-calculator",
            azureTcoCalculator: "https://cloud.netapp.com/azure-calculator",
            gcpTcoCalculator: "https://cloud.netapp.com/google-cloud-calculator"
        }
    },
    onPremAssessment: {
        serviceURL: process.env.REACT_APP_ON_PREM_ASSESSMENT_BASE_URL
    },
    internal: {
        sales: {
            cloudFirstPartnersLink: "https://fieldportal.netapp.com/explore/623558",
            CDSProductCommissionsFAQLink: "https://fieldportal.netapp.com/content/904443",
            cloudCentralTutorialLink: "https://netapp.hosted.panopto.com/Panopto/Pages/Viewer.aspx?id=89ae370b-20f8-468c-aa56-a8e9015b8a7e",
            hyperscalerCommissions: "https://netapp.hosted.panopto.com/Panopto/Pages/Viewer.aspx?id=2c96974b-0ef7-4b3c-9c4b-a97d01766edc",
            cloudVolumesService: {
                aws: {
                    demo: "https://labondemand.netapp.com/products/cloud-volumes-service",
                    technicalDocumentation: "http://docs.netapp.com/us-en/cloud_volumes/aws/"
                },
                azure: {
                    demo: "https://labondemand.netapp.com/products/azure-netapp-files-anf",
                    demoVideo: "https://fieldportal.netapp.com/content/812896?assetComponentId=814527",
                    technicalDocumentation: "https://docs.microsoft.com/en-us/azure/azure-netapp-files/"
                },
                gcp: {
                    demo: "https://labondemand.netapp.com/products/cloud-volumes-service",
                    technicalDocumentation: "https://cloud.google.com/solutions/partners/netapp-cloud-volumes/"
                },
                salesTools: {
                    cloudFirstPartnersProgramGuide: "https://fieldportal.netapp.com/content/568934?assetComponentId=579207",
                    cloudFirstPartnersPortalOverview: "https://fieldportal.netapp.com/content/593531?assetComponentId=594614",
                    cloudFirstPartnersPortal: "https://fieldportal.netapp.com/explore/623558",
                    industryEssentialsCloudComputing: "https://netapp.sabacloud.com/Saba/Web_spf/NA1PRD0047/common/leclassview/dowbt-00372407",
                    dataServicesFundamentals: "https://netapp.sabacloud.com/Saba/Web_spf/NA1PRD0047/common/leclassview/dowbt-00371904",
                    integratingHybridCloudsWithDataFabric: "https://netapp.sabacloud.com/Saba/Web_spf/NA1PRD0047/common/ledetail/STRSW-ILT-INTHCDF",
                    sellingCloudVolumesService: "https://fieldportal.netapp.com/content/855219?assetComponentId=856979",
                    internalTrainingWebcasts: "https://teams.microsoft.com/l/team/19%3aa4c394868ac8443089dfef3935c69172%40thread.skype/conversations?groupId=f3de42c7-6379-48ef-80bb-29fba039e9b9&tenantId=4b0911a0-929b-4715-944b-c03745165b3a",
                    sellingCloudDataServices: "https://learningcenter.netapp.com/LC?ObjectType=WBT&ObjectID=00374556",
                    technicalPositioningOfCloudVolumesOntapAndCloudVolumesService: "https://learningcenter.netapp.com/LC?ObjectType=WBT&ObjectID=00379587"
                }
            },
            cloudVolumesONTAP: {
                cloudVolumesONTAPFAQLink: "https://fieldportal.netapp.com/content/203111",
                cloudVolumesONTAPFundamentalsLink: "http://learningcenter.netapp.com/LC?ObjectType=WBT&ObjectID=00368390",
                salesTools: {
                    cloudVolumesOntapPresentationForCustomersJune2018: "https://fieldportal.netapp.com/content/745322",
                    cloudVolumesOntapSePresentationNovember2017: "https://fieldportal.netapp.com/content/597449",
                    cloudVolumesOntapQuickReferenceGuide: "https://fieldportal.netapp.com/content/462071",
                    cloudVolumesOntapOverview: "https://www.youtube.com/watch?v=Ny_7T04SP8k&t=6s",
                    awsUseCasesWithCloudVolumesOntap: "https://cloud.netapp.com/aws-use-cases-with-ontap-cloud-lp",
                    netAppCloudVolumesOntapForMicrosoftAzure: "https://cloud.netapp.com/ontap-microsoft-azure-enterprise-data-management-ebook/",
                    cloudVolumesOntapWebinarsAndVideos: "http://tv.netapp.com/search?q=ontap%20cloud&page=1",
                    competitiveComparisonOfCloudVolumesOntapAndPureStorageAws: "https://fieldportal.netapp.com/content/828526?assetComponentId=830171",
                    cloudVolumesOntapVsAwsFsx: "https://fieldportal.netapp.com/content/846628"
                },
                training: {
                    deploymentAndManagementCloudVolumesOntapGoogleCloud: "https://learningcenter.netapp.com/LC?ObjectType=WBT&ObjectID=0000380633",
                    integratingHybridCloudWithDataFabric: "https://netapp.sabacloud.com/Saba/Web_spf/NA1PRD0047/common/ledetail/STRSW-ILT-INTHCDF",
                    cloudDataServicesFundamentals: "http://learningcenter.netapp.com/LC?ObjectType=WBT&ObjectID=00371904",
                    deploymentAndManagementForAws: "https://learningcenter.netapp.com/LC?ObjectType=WBT&ObjectID=00376094",
                    sellingCloudDataServices: "https://learningcenter.netapp.com/LC?ObjectType=WBT&ObjectID=00374556",
                    cloudVolumesOntapSeTraining: "https://netapp.zoom.us/recording/share/xAgKHqqKRuXmWUb6x_vWxTEKRsUghfMFsE9YEKXTFVKwIumekTziMw ",
                    cloudVolumesOntapFundamentals: "https://netapp.sabacloud.com/Saba/Web_spf/NA1PRD0047/common/leclassview/dowbt-00368390",
                    cloudVolumesOntapDeploymentAndManagementForAzure: "https://netapp.sabacloud.com/Saba/Web_spf/NA1PRD0047/common/ledetail/cours000000000029307",
                    sellingCloudVolumesOntap: "https://learningcenter.netapp.com/LC?ObjectType=WBT&ObjectID=00380064",
                    technicalPositioningOfCloudVolumesOntapAndCloudVolumesService: "https://learningcenter.netapp.com/LC?ObjectType=WBT&ObjectID=00379587"
                }
            },
            cloudSync: {
                cloudSyncFAQLink: "https://confluence.ngage.netapp.com/display/CDSS/CS+11-2019+Launch+TOI",
                cloudSyncDemoLink: "https://labondemand.netapp.com/products/cloud-sync",
                salesTools: {
                    cloudSyncCustomerDeck: "https://fieldportal.netapp.com/content/597691",
                    cloudSyncSePresentation: "https://fieldportal.netapp.com/content/582892",
                    cloudSyncServiceSolutionBrief: "https://fieldportal.netapp.com/content/451947",
                    cloudSyncServiceQuickReferenceGuide: "https://fieldportal.netapp.com/content/451951?assetComponentId=452613",
                    cloudSyncCustomerStories: "https://fieldportal.netapp.com/content/598295?assetComponentId=599407",
                    eBookNetAppCloudSyncService: "https://cloud.netapp.com/hubfs/Cloud%20Sync/NetApp%20Cloud%20Sync%20eBook.pdf?t=1513765793386",
                    useCaseDataMigration: "https://cloud.netapp.com/aws-oct12-webinar-us-ty"
                },
                training: {
                    integratingHybridCloudWithDataFabric: "https://netapp.sabacloud.com/Saba/Web_spf/NA1PRD0047/common/ledetail/STRSW-ILT-INTHCDF",
                    cloudDataServicesFundamentals: "http://learningcenter.netapp.com/LC?ObjectType=WBT&ObjectID=00371904",
                    sellingCloudDataServices: "https://learningcenter.netapp.com/LC?ObjectType=WBT&ObjectID=00374556",
                    UnderstandingBusinessValueAndUseCases: "https://netapp.sabacloud.com/Saba/Web_spf/NA1PRD0047/common/leclassview/dowbt-00368416"
                }
            },
            cloudInsights: {
                cloudInsightsDemoEnv: "https://labondemand.netapp.com/products/cloud-insights",
                cloudInsightsFAQLink: "https://fieldportal.netapp.com/content/940628",
                salesTools: {
                    salesTools: "https://netapp.sharepoint.com/sites/CloudDataServices/CloudInsightsProduct/Cloud%20Insights%20Marketing%20Documents/Forms/AllItems.aspx?viewpath=%2Fsites%2FCloudDataServices%2FCloudInsightsProduct%2FCloud%20Insights%20Marketing%20Documents%2FForms%2FAllItems%2Easpx"
                },
                training: {
                    technicalPositioningOfOnCommandInsight: "https://learningcenter.netapp.com/LC?ObjectType=WBT&ObjectID=00375229",
                    UsingCloudInsights: "http://learningcenter.netapp.com/LC?ObjectType=WBT&ObjectID=00374557",
                    sellingCloudInsights: "https://netapp.sabacloud.com/Saba/Web_spf/NA1PRD0047/common/leclassview/dowbt-00375637",
                    cloudDataServicesFundamentals: "http://learningcenter.netapp.com/LC?ObjectType=WBT&ObjectID=00371904",
                    sellingCloudDataServices: "https://learningcenter.netapp.com/LC?ObjectType=WBT&ObjectID=00374556"
                }
            },
            cloudBackupService: {
                cloudBackupFAQLink: "https://fieldportal.netapp.com/content/1020579",
                cloudBackupDemoLink: "https://labondemand.netapp.com/products/cloud-backup-service",
                salesTools: {
                    fullyManagedBackupForCloudVolumesONTAP: "http://jira.tlveng.netapp.com/secure/attachment/39291/CBS%20for%20CVO%20Overview%20w%20Audio.mp4",
                    cloudBackupService101: "https://fieldportal.netapp.com/content/843305",
                    cloudBackupServiceCustomerPresentation: "https://fieldportal.netapp.com/content/843436",
                    netAppCloudBackupServiceComparisonWithAwsBackup: "https://fieldportal.netapp.com/content/843311",
                    cloudBackupServiceDemo: "https://fieldportal.netapp.com/content/843304"
                },
                training: {
                    cloudDataServicesFundamentals: "http://learningcenter.netapp.com/LC?ObjectType=WBT&ObjectID=00371904",
                    sellingCloudDataServices: "https://learningcenter.netapp.com/LC?ObjectType=WBT&ObjectID=00374556"
                }
            },
            cloudTiering: {
                cloudTieringFAQLink: "https://fieldportal.netapp.com/content/854938",
                salesTools: {
                    OptimizeStorage: "https://fieldportal.netapp.com/content/904725",
                    TechnicalOverview: "https://fieldportal.netapp.com/content/904730",
                    OffloadInfrequently: "https://fieldportal.netapp.com/content/904745",
                    servicePresentation: "https://fieldportal.netapp.com/content/854932",
                    salesGuide: "https://fieldportal.netapp.com/content/855206",
                    introductionForEmployees: "https://netapp.zoom.us/recording/share/3845vET7IS7UknHIIXclKVArF5fUFETvWltcy0z7dgawIumekTziMw"
                },
                training: {
                    cloudDataServicesFundamentals: "http://learningcenter.netapp.com/LC?ObjectType=WBT&ObjectID=00371904",
                    sellingCloudDataServices: "https://learningcenter.netapp.com/LC?ObjectType=WBT&ObjectID=00374556"
                }
            },
            cloudCompliance: {
                cloudComplianceFAQLink: "https://fieldportal.netapp.com/content/964707",
                cloudComplianceDemoLink: "https://labondemand.netapp.com/products/cloud-compliance",
                salesTools: {
                    salesDeck: "https://fieldportal.netapp.com/content/962821",
                    demoKit: "https://fieldportal.netapp.com/content/965806"
                },
                introductionVideo: "https://fieldportal.netapp.com/content/964731"
            },
            globalFileCache: {
                globalFileCacheFAQLink: "https://fieldportal.netapp.com/content/1073093",
                globalFileCacheDemoLink: "https://labondemand.netapp.com/products/global-file-cache",
                globalFileCacheRequestForm: "https://netapp.tap.thinksmart.com/prod/Portal/ShowWorkFlow/ShowWorkflowDetail/fa8b499a-58ec-46ff-b3c0-e2a1efac7f4d",
                salesTools: {
                    competitiveAnalysis: "https://fieldportal.netapp.com/content/1032207",
                    vsFlexCache: "https://fieldportal.netapp.com/content/1031794"
                }
            },
            virtualDesktopService: {
                faq: "https://fieldportal.netapp.com/content/1112309",
                demo: "https://cloud.netapp.com/vds-lp-30-day-sandbox-trial?hs_preview=QZVbKzIu-32604815705"
            },
            spot: {
                introVideo: "https://fieldportal.netapp.com/content/1117586",
                faq: "https://fieldportal.netapp.com/content/1117585"
            }
        }
    },
    developerHub: {
        cloudCentralApiDoc: "/api-doc",
        cloudSyncApiDoc: process.env.REACT_APP_CLOUD_SYNC_API_DOC_URL,
        ontapCloudApiDoc: "https://docs.netapp.com/us-en/occm/api.html",
        cloudVolumesServiceAwsApiDoc: "https://app.swaggerhub.com/apis/NetApp-Cloud/c-vaa_s/",
        virtualDesktopServiceApiDoc: "https://api.cloudworkspace.com/5.4/swagger/ui/index#/"
    },
    community: {
        facebook: "https://www.facebook.com/netappcloud/",
        instagram: "https://www.instagram.com/netappcloud/",
        youtube: "https://www.youtube.com/playlist?list=PLdXI3bZJEw7lnoRo8FBKsX1zHbK8AQOoT",
        community: "http://community.netapp.com/t5/Hybrid-Cloud-Discussions/bd-p/hybrid-cloud-discussions",
        events: "https://cloud.netapp.com/events",
        blog: "https://cloud.netapp.com/blog"
    },
    developers: {
        registerToDistributionList: "mailto:neat@netapp.com?subject=Add Self&body=ng-cloud-central-updates",
        clientManagement: {
        crossOriginAuthentication: "https://auth0.com/docs/cross-origin-authentication",
            webMessageResponseMode: "https://auth0.com/docs/protocols/oauth2#how-response-mode-works",
            logout: "https://auth0.REACT_APP_STAGEcom/docs/logout"
        },
        styleguide: `https://styleguide.services.cloud.netapp.com`
    },
    demos: {
        cloudTiering: "https://demo.tiering.cloud.netapp.com"
    },
    auth0Docs: {
        azureAdConnectionDocs: "https://auth0.com/docs/connections/enterprise/azure-active-directory/v2"
    },
    cvsDocs: {
        serviceLevel: "https://docs.netapp.com/us-en/cloud_volumes/aws/reference_selecting_service_level_and_quota.html"
    }
};

if (process.env.REACT_APP_STAGE !== "prod") {
    let overrideCloudInsights = false;
    try {
        overrideCloudInsights = sessionStorage.getItem("cloudCentral.overrideLink.launchPad.services.cloudInsights");
    } catch (e) {

    }
    if (overrideCloudInsights) {
        links.launchPad.services.cloudInsights = overrideCloudInsights;
        links.launchPad.services.cloudInsightsBackend = overrideCloudInsights;
    }
}

export default links;
