import _ from "lodash";
import { AnfIcon as NetAppFilesLogo } from "@netapp/bxp-design-system-react/icons/netapp-services";
import { CvsIcon as CvsLogo } from "@netapp/bxp-design-system-react/icons/netapp-services";

export const getErrorMessage = error => {
    return _.get(error, "response.data.message", _.get(error, "message", "Server Error"));
};

export const cidrRegex = /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/img;
export const dnsRegx = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)((, ?)(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))*$/img;
export const domainRegx = /^(?:[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?\.)+[A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9]$/img;

export const isCidrValid = cidr => {
    const cidrRegex = /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/img;
    return cidr.match(cidrRegex);
};

export const isDomainValid = domain => {
    const domainRegex = /^(?:[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?\.)+[A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9]$/img;
    return domain.match(domainRegex);
};

export const isDnsValid = dns => {
    const dnsRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)((, ?)(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))*$/img;
    return dns.match(dnsRegex);
};

export const isExportAccessIpValid = ip => {
    const ipRegex = /^(?=\d+\.\d+\.\d+\.\d+($|,|\/))(([1-9]?\d|1\d\d|2[0-4]\d|25[0-5])\.?){4}(\/([0-9]|[1-2][0-9]|3[0-2]))?(,(?=\d+\.\d+\.\d+\.\d+($|,|\/))(([1-9]?\d|1\d\d|2[0-4]\d|25[0-5])\.?){4}(\/([0-9]|[1-2][0-9]|3[0-2]))?)*$/img;
    return ip.match(ipRegex);
};

export const enableCreateVolumeStep = (steps, stepIndexToEnable) => {
    return _.map(steps, (step, index) => {
        return index === stepIndexToEnable ? { ...step, allowed: true } : step;
    });
};

export const gibToByte = gib => (1024 * 1024 * 1024) * gib;

export const tibToByte = tib => {
    const fourTiBinBytes = 4398046511104; // Azure's 4 TiB multiplier: https://docs.microsoft.com/en-us/rest/api/netapp/pools/createorupdate
    return (tib / 4) * fourTiBinBytes;
};

export const bytesToGiB = bytes => bytes / (1024 * 1024 * 1024);

export const promiseDelay = (ms) => new Promise((resolve) => setTimeout(() => resolve(), ms));

export const retryWithDelay = async (numTrials, delay, asyncFunc) => {
    const retry = async (counter) => asyncFunc()
        .catch(error => {
            return promiseDelay(delay)
                .then(() => {
                    return counter < numTrials ? retry(counter + 1) : Promise.reject(error);
                });
        });
    return retry(1);
};

export const pollingDelayInMillisecond = 30 * 1000;

export const getVolumesStatistics = volumes => ({
    volumesCount: volumes && volumes.length,
    regionsCount: volumes && _(volumes).countBy('region').keys().value().length,
    volumesCountByRegion: volumes && _.countBy(volumes, 'region'),
    totalAllocatedBytes: volumes && _.sumBy(volumes, 'quotaInBytes'),
    totalUsedBytes: volumes && _.sumBy(volumes, 'usedBytes')
});

export const isInProgress = (state) => {
    return state === "creating" || state === "updating"|| state === "deleting"
};
export const isDisabled = (state) => {
    return state === "disabled" || state === "error"
};

export const providerIdMap = {
    'aws': 'volumeId',
    'azure': 'fileSystemId',
    'gcp': 'volumeId'
};

export const titleMapper = {
    aws: "Cloud Volumes Services",
    azure: "Azure NetApp Files",
    gcp: "Cloud Volumes Services"
};

export const iconMapper = {
    aws: CvsLogo,
    azure: NetAppFilesLogo,
    gcp: CvsLogo
};

export const getVolumeById = flavour => (volumes, volumeId) => {
    return _.find(volumes, { [providerIdMap[flavour]]: volumeId });
};

export const filterVolumes = (volumes, filterString, newlyCreatedAnfVolume) => {
    // This newlyCreatedVolume parameter is optional and needed only in Azure
    // because the get volume response does not include
    // the volume name and region for volume in status "creating" :((
    return _.filter(volumes, ({ name, region }) =>  {
        const volumeName = name || newlyCreatedAnfVolume?.name;
        const regionName = region || newlyCreatedAnfVolume?.region;
        return volumeName?.toLowerCase().includes(filterString) || regionName?.toLowerCase().includes(filterString);
    });
};

export const updateVolumeStatus = flavour => (volumes, deletedVolume, statusToUpdate) => {
    return _.map(volumes, volume => {
        return volume[providerIdMap[flavour]] === deletedVolume[providerIdMap[flavour]] ? { ...volume, lifeCycleState: statusToUpdate } : volume;
    })
};

export const createVolumeRoute = (postfix, volume) => {
    if (volume.provider === "azure") {
        return `/volumes/${volume[providerIdMap[volume.provider]]}/${postfix}?subscriptionId=${volume.subscriptionId}&resourceGroupName=${volume.resourceGroupName}&netAppAccountName=${volume.netAppAccountName}&poolName=${volume.poolName}&name=${volume.name}`;
    } else if (volume.provider === "gcp" ) {
        return `/volumes/${volume[providerIdMap[volume.provider]]}/${postfix}?location=${volume.region}`;
    } else {
        return `/volumes/${volume[providerIdMap[volume.provider]]}/${postfix}`;
    }
};

export const protocolMapper = {
    "cifs": "smb",
    "nfs": "nfs",
    "dual": "dual",
    "smb": "smb"
};

export const mapRules = exportPolicies => {
    return _.map(exportPolicies, (policy, index) => {
        const rule = {};
        rule.allowedClients = policy.ip;
        rule.nfsv3 = policy.NFSv3;
        rule.nfsv4 = policy.NFSv4;
        rule.ruleIndex = index + 1;
        rule.unixReadOnly = policy.policy === "r";
        rule.unixReadWrite = policy.policy === "r/w";
        return rule;
    });
};

export const mapSnapshotPolicyForm = snapshotPolicyState => {
    const { enabled, hourly, daily, weekly, monthly } = snapshotPolicyState;
    const snapshotPolicy = {};
    snapshotPolicy.enabled = enabled;

    snapshotPolicy.hourlySchedule = {
        minute: (enabled && hourly) ? Number(snapshotPolicyState.hourlyPolicyMinute) : 0,
        snapshotsToKeep: (enabled && hourly) ? Number(snapshotPolicyState.hourlyPolicyNumberOfSnapshots) : 0
    };

    snapshotPolicy.dailySchedule = {
        hour: (enabled && daily) ? Number(snapshotPolicyState.dailyPolicyHour) : 0 ,
        minute: (enabled && daily) ? Number(snapshotPolicyState.dailyPolicyMinute) : 0,
        snapshotsToKeep: (enabled && daily) ? Number(snapshotPolicyState.dailyPolicyNumberOfSnapshots) : 0
    };

    snapshotPolicy.weeklySchedule = {
        day: (enabled && weekly) ? _(snapshotPolicyState.weeklyPolicyDaysOfWeek).map('label').join(",") : "",
        hour: (enabled && weekly) ? Number(snapshotPolicyState.weeklyPolicyHour) : 0,
        minute: (enabled && weekly) ? Number(snapshotPolicyState.weeklyPolicyMinute) : 0,
        snapshotsToKeep: (enabled && weekly) ? Number(snapshotPolicyState.weeklyPolicyNumberOfSnapshots) : 0
    };

    //_(multiValues).map("value").sortBy().value()
    snapshotPolicy.monthlySchedule = {
        daysOfMonth: (enabled && monthly) ? _(snapshotPolicyState.monthlyPolicyDaysOfMonth).map('label').join(",") : "",
        hour: (enabled && monthly) ? Number(snapshotPolicyState.monthlyPolicyHour) : 0,
        minute: (enabled && monthly) ? Number(snapshotPolicyState.monthlyPolicyMinute) : 0,
        snapshotsToKeep: (enabled && monthly) ? Number(snapshotPolicyState.monthlyPolicyNumberOfSnapshots) : 0
    };

    return snapshotPolicy;
};

export const displayProtocolTypes = protocolTypes => {
  return _.map(protocolTypes, protocol => (protocol.toLowerCase() === "cifs" ? "SMB" : protocol));
};


