import React from "react";
import {useSelector} from "react-redux";
import _ from "lodash";
import Gallery from "../../common/volumes-gallery/Page";
import {Link} from "react-router-dom";

const ManageActiveDirectory = props => {
    const accountId = useSelector(state => _.get(state, "cvs.accountId"));
    const credentialsId = useSelector(state => _.get(state, "cvs.credentialsId"));
    return (
        <Link to={`/components/cvs/aws/${accountId}/${credentialsId}/manage-active-directory`} {...props}>
            Manage Active Directory
        </Link>
    );
};

export default ({we}) => {
    const additionalHeaderActions = [
        { key: "manage-ad", label: "Manage Active Directory", Component: ManageActiveDirectory }
    ];

    return <Gallery we={we} additionalHeaderActions={additionalHeaderActions}/>
};
