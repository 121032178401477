import React, { Component, useState } from 'react'
import { registerFeature } from "../../middlewares/apiMiddleware";
import subscriptionsMiddleware from "../../middlewares/featureApis/subscriptionsMiddleware";
import _ from "lodash";
import store, { history } from "../../store";
import connect from "react-redux/es/connect/connect";
import Loader from "../../Loader";
import { Dialog } from "../dialog/Dialog";
import styles from "./SubscriptionMapping.module.scss";
import dialogStyles from "../../styles/dialog/dialog.module.scss"
import { XIconIcon as CloseXIcon } from "@netapp/bxp-design-system-react/icons/monochrome";
import {SuccessCircleIcon as SuccessIcon} from "@netapp/bxp-design-system-react/icons/monochrome";
import { ErrorMonochromeIconsIcon as ErrorIcon } from '@netapp/bxp-design-system-react/icons/monochrome';
import buttons from "../../styles/button/button.module.scss";
import ExternalLink from "../widgets/ExternalLink";
import { Link } from "react-router-dom";
import serviceMapping from "./subscriptionServices";
import Popover from "../widgets/Popover";
import ActionButton from "../widgets/ActionButton";
import { Text, Notification, BulletList, Toggle, FormFieldInputNew } from "@netapp/bxp-design-system-react";

registerFeature("SUBSCRIPTIONS", subscriptionsMiddleware);

const cloudProviderMapping = {
    "aws": "AWS Marketplace",
    "gcp": "GCP Marketplace",
    "azure": "Azure Marketplace"
};

const cloudProviderMappingNameDisplay = {
    "aws": "AWS",
    "gcp": "GCP",
    "azure": "Azure"
};

const cloudProviderLearnMoreLinks = {
    "aws": "https://docs.netapp.com/us-en/cloud-manager-setup-admin/task-adding-aws-accounts.html#subscribe",
    "gcp": "https://docs.netapp.com/us-en/cloud-manager-setup-admin/task-adding-gcp-accounts.html",
    "azure": "https://docs.netapp.com/us-en/cloud-manager-setup-admin/task-adding-azure-accounts.html#subscribe"
}

const fetch = _.once(({cloudProvider, token, serviceName}) => {
    if(cloudProvider === "aws") {
        store.dispatch({
            type: "SUBSCRIPTIONS:RESOLVE-AWS-TOKEN",
            payload: {
                registrationToken: token
            }
        })
    } else if (cloudProvider === "gcp") {
        store.dispatch({
            type: "SUBSCRIPTIONS:RESOLVE-GCP-TOKEN",
            payload: {
                registrationToken: token,
                serviceName
            }
        })
    } else if (cloudProvider === "azure") {
        store.dispatch({
            type: "SUBSCRIPTIONS:RESOLVE-AZURE-TOKEN",
            payload: {
                registrationToken: token,
                serviceName
            }
        })
    } else {
        console.log(`Invalid cloudProvider ${cloudProvider}`);
    }
    history.replace("/subscription-mapping");
});

const mapStateToProps = state => {
    return {
        subscription: state.subscriptions.subscription,
        subscriptionError: state.subscriptions.subscriptionError,
        subscriptionStatus: state.subscriptions.subscriptionStatus,
        subscriptionSavePending: state.subscriptions.subscriptionSavePending,
        subscriptionSaveFinished: state.subscriptions.subscriptionSaveFinished,
        subscriptionSaveError: state.subscriptions.subscriptionSaveError,
        subscriptionConfigFinished: state.subscriptions.subscriptionConfigFinished,
        subscriptionConfigError: state.subscriptions.subscriptionConfigError,
        autoConfigStatus: state.subscriptions.autoConfigStatus
    };
};

const mapDispatchToProps = dispatch => ({
    saveSubscription: ({newName, subscriptionId, accountId, removeAccounts,automaticCredsAccount}) => {
        dispatch({
            type: "SUBSCRIPTIONS:SAVE",
            payload: {
                newName,
                subscriptionId,
                accountId,
                removeAccounts,
                automaticCredsAccount
            }
        });
    }
});

class SubscriptionMappingDialogDumb extends Component {
    constructor(props) {
        super(props);
        this.state = { subscriptionName: "", accounts: null, replaceConfigAccount: null };
    }

    componentDidUpdate(prevProps){
        const subscriptionName = _.get(this.props, "subscription.subscriptionName");
        const oldSubscriptionName = _.get(prevProps, "subscription.subscriptionName");
        if (subscriptionName && !oldSubscriptionName) {
            const accounts = _.get(this.props, "subscription.accounts", []);
            const accountsMap = _.reduce(accounts, (result, e) => {
                result[e.accountPublicId] = { accountName: e.accountName, accountId: e.accountPublicId, isChecked: true };
                return result;
            }, {});
            this.setState({ subscriptionName, accounts: accountsMap, replaceConfigAccount: accounts?.[0]?.accountPublicId });
        }
    }

    handleChangeSubscriptionName(event) {
        this.setState({ subscriptionSaveNameError: null, subscriptionName: event.target.value });
    }

    toggleAccountName(accountPublicId) {
        const el = this.state.accounts[accountPublicId] || { accountPublicId: accountPublicId };
        const toggledReplaceConfigAccount = (this.state.replaceConfigAccount === accountPublicId && el.isChecked) ? null : this.state.replaceConfigAccount;
        this.setState({ subscriptionSaveAccountsError: null, accounts: { ...this.state.accounts, [accountPublicId]: { ...el, isChecked: !el.isChecked } }, replaceConfigAccount: toggledReplaceConfigAccount })
    }

    addReplaceConfigAccount(accountId, event) {
        if (event) {
            this.setState({ replaceConfigAccount: accountId });
        } else {
            this.setState({ replaceConfigAccount: null });
        }
    }


    handleSave(isCloudVolumesOntapListing) {
        if (this.state.subscriptionName.length < 2 || this.state.subscriptionName.length > 50) {
            this.setState({ subscriptionSaveError: "Subscription name must be between 2 and 50 characters." });
        } else {
            const removeAccounts = _.filter(_.values(this.state.accounts), { isChecked: false });

            if (removeAccounts.length === _.values(this.state.accounts).length) {
                this.setState({ subscriptionSaveAccountsError: "Subscription must remain on at least one NetApp Account" })
            } else {
                this.props.saveSubscription({
                    newName: this.state.subscriptionName,
                    accountId: this.props.subscription.accounts[0].accountPublicId,
                    subscriptionId: this.props.subscription.subscriptionId,
                    removeAccounts,
                    automaticCredsAccount: isCloudVolumesOntapListing ? this.state.replaceConfigAccount : null
                });
            }
        }
    }

    render () {
        const {cloudProvider, serviceName, redirectError, subscription, subscriptionError, subscriptionStatus, closeDialog, subscriptionSavePending, subscriptionSaveFinished, subscriptionSaveError,subscriptionConfigFinished,subscriptionConfigError,autoConfigStatus } = this.props;
        const service = serviceMapping[serviceName];
        const cloudProviderTitle = cloudProviderMapping[cloudProvider];
        const isCloudVolumesOntapListing = serviceName === "cloud-volumes-ontap-contract" || serviceName === "cloud-volumes-ontap";

        if(!service || !cloudProviderTitle) {
            return <div>
                Invalid service or cloud provider specified specified.
            </div>
        } else {
            const isError = redirectError || subscriptionStatus === "ERROR";
            if(subscriptionStatus === "PENDING" && !isError) {
                return <div><Loader/></div>
            } else {
                const account = this.state?.accounts?.[this.state.replaceConfigAccount];
                return <div>
                    <header>
                        Subscription Assignment
                        { !service.hideCancel &&  <button onClick={closeDialog}><CloseXIcon/></button> }
                    </header>
                    {subscriptionSaveFinished && <div className={styles['content-rename-finished']}>
                        <Notification type="success">
                            <div>Your subscription {this.state.subscriptionName} was saved successfully.</div>
                        </Notification>
                        {subscriptionConfigFinished && autoConfigStatus === 'success' && <Notification type="success">
                            <div>
                                Automatic configuration for {account?.accountName} was successful.
                            </div>
                        </Notification>}
                        {subscriptionConfigFinished && autoConfigStatus === 'partial' && <Notification type="warning">
                            <div>
                                Automatic configuration for {account?.accountName} was partially successful.
                            </div>
                        </Notification>}
                        {((subscriptionConfigFinished && autoConfigStatus === 'failure') || subscriptionConfigError) && <Notification type="error">
                            Automatic configuration for {account?.accountName} was not successful.
                        </Notification>}
                        {isCloudVolumesOntapListing && <BulletList>
                            <div>Continue configuration in the <ExternalLink style={{color:'#006dc9'}} linkKey="launchPad.services.cloudManagerCredential" trackCategory="subscriptionMapping" newTab>BlueXP console credentials page.</ExternalLink> </div>
                            <div><ExternalLink style={{color:'#006dc9'}} href={cloudProviderLearnMoreLinks[cloudProvider]} newTab>Learn</ExternalLink> how to associate and validate your new marketplace subscription.</div>
                        </BulletList>}
                    </div>}

                    {!subscriptionSaveFinished && <div className={isError ? styles['content-error'] : styles['content-success']}>
                        {isError && <div className={styles["banner-error"]}>
                            <ErrorIcon/>
                            <div>We're unable to create your subscription to</div>
                            <div><span>{service.title}</span> from the <span>{cloudProviderTitle}</span></div>
                        </div>}
                        {!isError && <div className={styles["banner-success"]}>
                            <SuccessIcon/>
                            <span>{serviceName === "cloud-volumes-ontap-contract" ? "Your contract subscription to " : "Your subscription to "}</span>
                            <span>{service.title}</span> from the <span>{cloudProviderTitle}</span>
                            <span> was created successfully.</span>
                        </div>}
                        {!isError && cloudProvider !== "gcp" && <div className={styles.content}>
                            <FormFieldInputNew
                                type="text"
                                name="subscriptionName"
                                label="Subscription name"
                                value={this.state.subscriptionName}
                                errorMessage={this.state.subscriptionSaveError || subscriptionSaveError}
                                onChange={(event) => this.handleChangeSubscriptionName(event)}
                            />
                            <Popover className={styles['subscription-popup']} containerClassName={styles['subscription-popup-container']} component="info">
                                <div>A user-friendly name that identifies the {cloudProviderMappingNameDisplay[cloudProvider]} Marketplace subscription for your {cloudProviderMappingNameDisplay[cloudProvider]} account. For example, you
                                    might enter your {cloudProviderMappingNameDisplay[cloudProvider]} account name.
                                </div>
                            </Popover>
                            <div>
                                <Text style={{ marginBottom: 0 }}>Select the NetApp accounts that you'd like to associate this subscription with.</Text>
                                {isCloudVolumesOntapListing && <Text> You can automatically replace the existing subscription for one account with this new subscription.</Text>}
                                <Popover className={styles['account-popup']} containerClassName={styles['account-popup-container']} component="info" containerStyle={isCloudVolumesOntapListing ? { top: '-87px' } : { top: '-24px' }}>
                                    <div>A Cloud Central account provides multi-tenancy and enables you to organize users and resources in isolated workspaces.</div>
                                </Popover>
                            </div>
                            {subscription.newAccountCreated && <div>We've assigned your subscription to your NetApp Cloud Central account: <span>{subscription.newAccountName}</span></div>}
                            {!subscription.newAccountCreated && this.state.accounts && <React.Fragment>
                                <div style={{ backgroundColor: '#DDF1FF', height: 56, display: 'flex' }}>
                                    <Text bold style={{ padding: 20 }}>Netapp account</Text>
                                    {isCloudVolumesOntapListing && <Text bold style={{ padding: 20, marginLeft: 100 }}>Replace existing subscription</Text>}
                                </div>
                                <div style ={{maxHeight:300,overflow: 'auto'}}><span>{subscription.accounts.map(account =>
                                    <label key={account.accountPublicId} className={styles["account-checkbox"]}>
                                        <input
                                            name={account.accountName}
                                            type="checkbox"
                                            checked={this.state.accounts[account.accountPublicId].isChecked}
                                            onChange={() => this.toggleAccountName(account.accountPublicId)}/>
                                        <div style={{ marginTop: 3 }}>{account.accountName}</div>
                                        {isCloudVolumesOntapListing &&
                                            <div style={{ marginLeft: 'auto', marginRight: 200 }}>
                                                <Toggle value={this.state.replaceConfigAccount === account.accountPublicId} toggle={(event) => this.addReplaceConfigAccount(account.accountPublicId, event)}/>
                                            </div>}
                                    </label>
                                )}</span></div>
                            </React.Fragment>}
                        </div>}
                        {isError && <div className={styles.content}>
                            <div style={{fontWeight: "bolder"}}>{redirectError || subscriptionError}</div>
                            <div>In order to complete the subscription assignment process you will need to unsubscribe and resubscribe in the marketplace.</div>
                        </div>}
                    </div>}

                    <div className={styles.footer}>
                        <div className={dialogStyles["button-pair-wrapper"]}>
                            {(isError || subscriptionSaveFinished || cloudProvider === "gcp") && <React.Fragment>
                                {!service.hideDone && <Link to="/" className={buttons.primary} onClick={closeDialog}>Done</Link>}
                                {service.link && <ExternalLink className={buttons.primary} linkKey={service.link} queryString={`?cloudProvider=${cloudProvider}`} trackCategory="subscriptionMapping">Go to {service.title}</ExternalLink>}
                            </React.Fragment>}
                            {!isError && !subscriptionSaveFinished && cloudProvider !== "gcp" &&
                                <ActionButton
                                    category="subscriptionMapping"
                                    label="rename"
                                    className={buttons.primary}
                                    disabled={subscriptionSavePending}
                                    style={{ width: 140 }}
                                    onClick={() => this.handleSave(isCloudVolumesOntapListing)}>{subscriptionSavePending ? "Saving..." : "Save"}</ActionButton>}
                        </div>
                        {this.state.subscriptionSaveAccountsError && <div className={styles.error}>{this.state.subscriptionSaveAccountsError}</div>}
                    </div>
                </div>
            }
        }
    }
}

const SubscriptionMappingDialog = connect(mapStateToProps, mapDispatchToProps)(SubscriptionMappingDialogDumb);

const SubscriptionMapping = ({cloudProvider, serviceName, token, error}) => {
    fetch({cloudProvider, token, serviceName});
    return <Dialog open={true} closeTrigger={() => history.replace("/")}>
            <SubscriptionMappingDialog serviceName={serviceName} cloudProvider={cloudProvider} redirectError={error} className={styles.dialog}/>
        </Dialog>
};

export default SubscriptionMapping;
