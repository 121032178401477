import axios from "axios";
import _ from 'lodash';
import numeral from "numeral";
import moment from "moment";
import links from "../../consts/links";
import { normalizeCapacity } from "../../utils/utils";


const getProtocol = sourceOrTarget => {
    if (sourceOrTarget.protocol === 'sgws') return sourceOrTarget.sgws.objectStorageType;
    if (sourceOrTarget.protocol === 'nfs') return sourceOrTarget.nfs.nfsType;
    return sourceOrTarget.protocol;
};

const bytesToUnit = (bytes, unit) => {
    switch (unit) {
        case 'bytes':
            return bytes;
        case 'KiB':
            return bytes / 1024;
        case 'MiB':
            return bytes / (1024 * 1024);
        case 'GiB':
            return bytes / (1024 * 1024 * 1024);
        case 'TiB':
            return bytes / (1024 * 1024 * 1024 * 1024);
        default:
            return bytes;
    }
};

export default ({ getState, dispatch, next, action }) => {
    const { type } = action;
    const demoMode = _.get(getState(), "features.active.demoMode");

    if (demoMode) {
        if (type === "DASHBOARDS:FETCH-CLOUD-SYNC-DATA") {
            const last30daysRange = _.map(_.range(30, 0, -1), d => moment().subtract(d, 'days').format('YYYY-MM-DD'));
            const dataTransferredByDay = last30daysRange.map(date => ({
                capacityCopied: (Math.random() * 100).toFixed(2),
                unit: "GiB",
                date
            }));

            const cloudSyncData = {
                totalBytes: { capacity: "6.03", unit: "TiB" },
                totalFiles: "14,494",
                cvoRelationships: "2",
                cvsRelationships: "3",
                dataTransferredByDay,
                highestNormalizedCapacityUnitInLast30days: "GiB",
                protocolsCount: { nfs: 6, ibm: 1, s3: 7, cifs: 6 }
            };

            setTimeout(() => {
                dispatch({
                    type: "DASHBOARDS:FETCH-CLOUD-SYNC-DATA-SUCCESS",
                    payload: { cloudSyncData }
                });
            }, 2000);

            return next({
                type: "DASHBOARDS:FETCH-CLOUD-SYNC-DATA-PENDING"
            });
        }
    }
    if (type === "DASHBOARDS:FETCH-CLOUD-SYNC-DATA") {
        Promise.all([
            axios.get(`${links.launchPad.services.cloudSyncBackend}/api/users/relationships-stats`),
            axios.get(`${links.launchPad.services.cloudSyncBackend}/api/relationships-v2`)
        ])

            .then(([{ data: userInfo }, { data: relationships }]) => {
                const cvoRelationships = numeral(_.countBy(relationships, 'isCvo').true || 0).format('0,0');
                const cvsRelationships = numeral(_.countBy(relationships, 'isQstack').true || 0).format('0,0');
                const totalBytes = normalizeCapacity(userInfo.totalBytes);
                const totalFiles = numeral(userInfo.totalFiles).format('0,0');

                const last30daysRange = _.map(_.range(30, 0, -1), d => moment().subtract(d, 'days').format('YYYY-MM-DD'));
                const highestCapacityTransferredInLast30Days = _(userInfo.bytesCopiedInTheLast30Days).map('bytesCopied').max();
                const highestNormalizedCapacityUnitInLast30days = normalizeCapacity(highestCapacityTransferredInLast30Days).unit;
                const bytesCopiedInTheLast30DaysNormalized = userInfo.bytesCopiedInTheLast30Days.map(c => ({
                    capacityCopied: +bytesToUnit(c.bytesCopied, highestNormalizedCapacityUnitInLast30days).toFixed(2),
                    unit: highestNormalizedCapacityUnitInLast30days,
                    date: c.date
                }));

                const protocolsCount = _.reduce(relationships, (accumulator, relationship) => {
                    const sourceProtocol = getProtocol(relationship.source);
                    const targetProtocol = getProtocol(relationship.target);
                    accumulator[sourceProtocol] = (accumulator[sourceProtocol] || 0) + 1;
                    accumulator[targetProtocol] = (accumulator[targetProtocol] || 0) + 1;

                    return accumulator;
                }, {});

                const bytesCopiedInTheLast30DaysNormalizedByDate = _.keyBy(bytesCopiedInTheLast30DaysNormalized, 'date');
                const dataTransferredByDay = last30daysRange.map(date => bytesCopiedInTheLast30DaysNormalizedByDate[date] || {
                    capacityCopied: 0,
                    unit: highestNormalizedCapacityUnitInLast30days,
                    date
                });

                const cloudSyncData = {
                    totalBytes,
                    totalFiles,
                    cvoRelationships,
                    cvsRelationships,
                    dataTransferredByDay,
                    highestNormalizedCapacityUnitInLast30days,
                    protocolsCount
                };

                dispatch({
                    type: "DASHBOARDS:FETCH-CLOUD-SYNC-DATA-SUCCESS",
                    payload: { cloudSyncData }
                });
            }, error => {
                dispatch({
                    type: "DASHBOARDS:FETCH-CLOUD-SYNC-DATA-ERROR",
                    payload: { error }
                });
            });

        return next({
            type: "DASHBOARDS:FETCH-CLOUD-SYNC-DATA-PENDING"
        });
    }

    return next(action);
};
