import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useWizard, ActionButton, useForm, FormFieldSelect, Notification} from "@netapp/bxp-design-system-react";
import _ from "lodash";
import classNames from "classnames";
import {FooterLayout, ContentLayout} from "./Page";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import commonStyles from "../../styles/common.module.scss";
import styles from "./SnapshotCopy.module.scss";

export const Footer = () => {
    const {setStep} = useWizard();

    const submitForm = () => {
        setStep("snapshot-policy");
    };

    return (
        <FooterLayout><ActionButton onClick={submitForm}>Continue</ActionButton></FooterLayout>
    );
};

const initialState =  {
    snapshot: null
};

export const Content = () => {
    const dispatch = useDispatch();
    const {setState, state} = useWizard();
    const flavour = useSelector(state => _.get(state, `cvs.cvsFlavour`));
    const snapshotOptions = useSelector(state => _.get(state, `cvs.snapshots`));
    const region = state?.detailsAndTagsForm?.formState?.region?.value;
    const filteredSnapshots = useMemo(() => {
        return flavour === "aws" ? snapshotOptions : _.filter(snapshotOptions, { region });
    }, [snapshotOptions, region, flavour]);
    const getSnapshotsPending = useSelector(state => _.get(state, `cvs.getSnapshotsPending`));
    const getSnapshotsError = useSelector(state => _.get(state, `cvs.getSnapshotsError`));
    const getSnapshotsShowError = useSelector(state => _.get(state, `cvs.getSnapshotsShowError`));
    const snapshotCopyForm = useForm(initialState);

    useEffect(() => {
        dispatch({
            type: `CVS:${flavour.toUpperCase()}-GET-SNAPSHOTS`,
            payload: { region }
        });
    }, [flavour, region, dispatch]);

    useEffect(() => {
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: { snapshots: [], getSnapshotsResolved: false, getSnapshotsError: null, getSnapshotsShowError: false }
        });
    }, [dispatch]);

    useEffect(() => {
        setState({snapshotCopyForm})
    }, [snapshotCopyForm, setState]);

    const hideNotification = () => {
        dispatch({
            type: "CVS:CLEAR-STATE",
            payload: { getSnapshotsShowError: false }
        });
    };

    const title = () => <span>Volume from Snapshot <i>(Optional)</i></span>;

    return (
        <ContentLayout className={styles["container"]} title={title()}>
            <div className={classNames(typography.body14, styles["info"])}>You can create the volume from an existing Snapshot copy. This step is optional.</div>
            <FormFieldSelect
                form={snapshotCopyForm}
                isClearable={true}
                isSearchable={true}
                name="snapshot"
                label="Snapshot Name"
                placeholder="- Select a snapshot -"
                options={filteredSnapshots}
                isLoading={getSnapshotsPending}
                className={commonStyles["form-field"]}
            />
            {getSnapshotsShowError && <div className={commonStyles["error-area"]}>
                <Notification type={'error'} onClose={hideNotification}>{getSnapshotsError}</Notification>
            </div>}
        </ContentLayout>
    );
};
