import React, { Component } from 'react';
import './HeaderWidgets.scss';
import classNames from "classnames";
import UserMenu from './user-menu/UserMenu';
import querystring from 'query-string';
import { history } from './../../../store';
import { connect } from 'react-redux';
import BackToMyServicesLink from "./../../../components/launch-pad/BackToMyServicesLink";
import button from "../../../styles/button/button.module.scss";

const menuTracker = (menu, marketingMode) =>
     window.ga('send', {
        hitType: 'event',
        eventCategory: 'CloudCentral',
        eventAction: `OpenMenu${marketingMode ? 'Marketing' : ''}`,
        eventLabel: menu
    });

class HeaderWidgets extends Component {
    constructor(props){
        super(props);
        this.state = {communicationsMenuOpen: false, userMenuOpen: false, notificationMenuOpen: false};
        this.menuClicked = this.menuClicked.bind(this);
        this.menuClosed = this.menuClosed.bind(this);
    }

    componentWillMount(){
        const queryParams = querystring.parse(window.location.search);
        if(queryParams.userMenuOpen !== undefined) {
            if(queryParams.userMenuOpen !== 'false') {
                this.menuClicked("users");
            }

            delete queryParams.userMenuOpen;
            const searchString = querystring.stringify(queryParams);
            history.replace(window.location.pathname + (searchString ? "?" + searchString : ''));
        }
    }

    menuClicked(menuType) {
        let newVal;
        switch (menuType){
            case "users":
                newVal = !this.props.userMenuOpen;
                break;
            case "notifications":
                newVal = !this.props.notificationMenuOpen;
                break;
            default:
                return;
        }
        if(newVal){
            menuTracker(menuType, this.props.marketingMode);
        }
        if(this.props.marketingMode){
            window.parent.postMessage({message: newVal ? "open-menu" : "close-menu"}, "*");
        }
        this.props.menuClicked(menuType, newVal);
    }

    menuClosed(menuType) {
        window.parent.postMessage({message: "close-menu"}, "*");
        this.props.menuClosed(menuType);
    }

    render() {
        return (
            <div className="header-widgets">
                {this.props.auth.authenticated &&
                <button className={classNames("edit-user-button", {"active": this.props.userMenuOpen})} onClick={() => this.menuClicked("users")} style={{whiteSpace: "nowrap"}}>
                    <span className="text-ellipsis" style={{display: "inline-block", maxWidth: "80px"}} title={this.props.auth.firstName}>{this.props.auth.firstName}</span>
                    <span className="arrow">
                        <span className="lefty"/>
                        <span className="righty"/>
                    </span>
                </button>}

                <BackToMyServicesLink marketingMode={this.props.marketingMode}/>

                {!this.props.auth.authenticated && <div className="login-signup-container">
                    {!this.props.marketingMode && <span>
                        <button className="login-signup" onClick={() => window.auth.loginRedirect({}, {service: "portal", initialScreen: "login"}, { pathname: window.location.pathname + window.location.search })}>LOGIN</button>
                        <div className="divider"/>
                        <button className="login-signup" onClick={() => window.auth.loginRedirect({}, {service: "portal", initialScreen: "signUp"}, { pathname: window.location.pathname + window.location.search })}>SIGN UP</button>
                    </span>}

                    {this.props.marketingMode && <span>
                        <a className="button login-signup" onClick={() => window.top.postMessage('Login button clicked', '*')} href="/redirect-to-login?startOnSignup=false" target="_parent">LOGIN</a>
                        <div className="divider"/>
                        <a className="button login-signup" onClick={() => window.top.postMessage('SignUp button clicked', '*')} href="/redirect-to-login?startOnSignup=true" target="_parent">SIGN UP</a>
                    </span>}
                </div>}


                <UserMenu
                    marketingMode={this.props.marketingMode}
                    isActive={this.props.userMenuOpen && this.props.auth.authenticated}
                    triggerClass="edit-user-button"
                    closeTrigger={() => {this.menuClosed("users")}}
                />
            </div>
        )}
}

const mapDispatchToProps = dispatch => {
    return {
        menuClicked: (menuType, newMenuState) => {
            if(newMenuState){
                dispatch({
                    type: "UI:OPEN-RIGHT-MENU",
                    payload: { menuType }
                });
            } else {
                dispatch({
                    type: "UI:CLOSE-RIGHT-MENU",
                    payload: { menuType }
                });
            }
        },
        menuClosed: (menuType) => {
            dispatch({
                type: "UI:CLOSE-RIGHT-MENU",
                payload: { menuType }
            });
        }
    }
};

const mapStateToProps = state => {
    return {
        auth: state.auth,
        notifications: state.notifications,
        userMenuOpen: state.ui.userMenuOpen,
        communicationsMenuOpen: state.ui.communicationsMenuOpen,
        notificationMenuOpen: state.ui.notificationMenuOpen
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderWidgets);
