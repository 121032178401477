import React from "react";
import {useDispatch} from "react-redux";
import _ from "lodash";
import TabItem from "../TabItem";
import {useWizard} from "@netapp/bxp-design-system-react";
import {BaseFooter, BaseHeader, HeaderServiceInfo} from "../Page";
import classNames from "classnames";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import styles from "./Page.module.scss";

export const HeaderWrapper = ({children, flavour}) => {
    const dispatch = useDispatch();

    const closeWizard = () => {
        dispatch({
            type: "CVS:CLOSE-VOLUME-WIZARD"
        });
    };

    return (
        <BaseHeader onClose={closeWizard}>
            <HeaderServiceInfo className={styles["header-title"]} flavour={flavour} title={"Add Volume Wizard"}/>
            {children}
        </BaseHeader>
    );
};

export const Header = ({flavour}) => {
    const {stepPaths, currentStepIndex, setStep, currentStepPath} = useWizard();
    return (
        <HeaderWrapper flavour={flavour}>
            <div className={styles["tabs"]}>
                {_.map(stepPaths[currentStepPath], ({label, key}, index) => {
                    const isDone = index < currentStepIndex;
                    return <TabItem key={key} done={isDone} onTabSelected={() => setStep(key)} isActive={index === currentStepIndex} title={label} index={index + 1}/>
                })}
            </div>
        </HeaderWrapper>
    );
};

export const FooterLayout = ({children}) => {
    return (
        <BaseFooter className={styles["footer"]}>
            {children}
        </BaseFooter>
    );
};

export const ContentLayout = ({children, title, className}) => {
    return (
        <div className={classNames(styles["content-layout"], className)}>
            <div className={classNames(typography.h01, styles["title"])}>{title}</div>
            {children}
        </div>
    );
};
