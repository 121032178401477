//Code from here: https://stackoverflow.com/questions/18338890/are-there-any-sha-256-javascript-implementations-that-are-generally-considered-t
async function sha256(message) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder('utf-8').encode(message);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string
    return hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
}

export async function getUserIdForGoogleAnalytics(userId) {
    //For users with federated identity, encrypt their username to not have plain user data in Google analytics.
    //crypto.subtle API is not available on unsecured locations, such as developing on local machines, so in
    //these cases we just use 'developer-local' as user id.
    if (!crypto.subtle) {
        return 'developer-local';
    } else if (userId.startsWith('auth0')) {
        return userId;
    } else {
        return sha256(userId);
    }
}