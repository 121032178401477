import React from 'react';
import styles from '../styles/header/AppHeader.module.scss';
import { NetAppLogoIcon as NetAppLogo } from '@netapp/bxp-design-system-react/icons/netapp-services';
import { HeaderWidgets } from './../modules'
import ExternalLink from './widgets/ExternalLink'

export default ({hideWidgets}) =>
    (
        <header className={styles.base}>
            <ExternalLink title="NetApp logo" className={styles.logo} linkKey="marketingPageRedirect"><NetAppLogo/></ExternalLink>

            {!hideWidgets && <HeaderWidgets/>}
        </header>
    );