import _ from "lodash";

const defaultState = () => ({
    whitelistedSubscriptions: null,
    whitelistedAccounts: null,
    whitelistSubscriptionPending: false,
    whitelistAccountPending: false,
    fetchError: null,
    subscriptionUpdateError: null,
    accountUpdateError: null,
    loading: true
});

export default (state = defaultState(), { type, payload }) => {
    switch (type) {
        case "CHARGING:GET-WHITELISTED-ITEMS-SUCCESS": {
            const { whitelistedAccountsMap, whitelistedSubscriptionsMap } = payload;
            return { ...state,
                whitelistedSubscriptions: _.map(whitelistedSubscriptionsMap, (value, key) => ({ id: key, pendingSubmission: false, pendingDeletion: false, description: value || "" })),
                whitelistedAccounts: _.map(whitelistedAccountsMap, (value, key) => ({ id: key, pendingSubmission: false, pendingDeletion: false, description: value || "" })),
                fetchError: null,
                loading: false
            };
        }
        case "CHARGING:GET-WHITELISTED-ITEMS-ERROR": {
            const { error } = payload;
            return { ...state,
                loading: false,
                fetchError: error
            };
        }
        case "CHARGING:WHITELIST-SUBSCRIPTION-PENDING": {
            return { ...state,
                subscriptionUpdateError: null,
                whitelistSubscriptionPending: true,
                fetchError: null
            };
        }
        case "CHARGING:WHITELIST-SUBSCRIPTION-SUCCESS": {
            const { subscriptionId, description } = payload;
            const newSubscription = { id: subscriptionId, description, pendingSubmission: false, pendingDeletion: false };
            return { ...state,
                whitelistSubscriptionPending: false,
                whitelistedSubscriptions: [...state.whitelistedSubscriptions, newSubscription],
            };
        }
        case "CHARGING:WHITELIST-SUBSCRIPTION-ERROR": {
            const { error } = payload;
            return { ...state,
                subscriptionUpdateError: error,
                whitelistSubscriptionPending: false,
            };
        }
        case "CHARGING:WHITELIST-ACCOUNT-PENDING": {
            return { ...state,
                accountUpdateError: null,
                whitelistAccountPending: true,
                fetchError: null
            };
        }
        case "CHARGING:WHITELIST-ACCOUNT-SUCCESS": {
            const { accountId, description } = payload;
            const newAccount = { id: accountId, description, pendingSubmission: false, pendingDeletion: false };
            return { ...state,
                whitelistAccountPending: false,
                whitelistedAccounts: [...state.whitelistedAccounts, newAccount],
            };
        }
        case "CHARGING:WHITELIST-ACCOUNT-ERROR": {
            const { error } = payload;
            return { ...state,
                accountUpdateError: error,
                whitelistAccountPending: false
            };
        }
        case "CHARGING:SUBSCRIPTION-DELETE-ICON-CLICKED": {
            const { subscriptionId } = payload;
            return { ...state,
                subscriptionUpdateError: null,
                whitelistedSubscriptions: _.map(state.whitelistedSubscriptions, s => s.id === subscriptionId ? { ...s, pendingSubmission: true } : s)
            };
        }
        case "CHARGING:ACCOUNT-DELETE-ICON-CLICKED": {
            const { accountId } = payload;
            return { ...state,
                subscriptionUpdateError: null,
                whitelistedAccounts: _.map(state.whitelistedAccounts, a => a.id === accountId ? { ...a, pendingSubmission: true } : a)
            };
        }
        case "CHARGING:SUBSCRIPTION-CANCEL-BUTTON-CLICKED": {
            const { subscriptionId } = payload;
            return { ...state,
                subscriptionUpdateError: null,
                whitelistedSubscriptions: _.map(state.whitelistedSubscriptions, s => s.id === subscriptionId ? { ...s, pendingSubmission: false } : s)
            };
        }
        case "CHARGING:ACCOUNT-CANCEL-BUTTON-CLICKED": {
            const { accountId } = payload;
            return { ...state,
                subscriptionUpdateError: null,
                whitelistedAccounts: _.map(state.whitelistedAccounts, a => a.id === accountId ? { ...a, pendingSubmission: false } : a)
            };
        }
        case "CHARGING:UNWHITELIST-SUBSCRIPTION-PENDING": {
            const { subscriptionId } = payload;
            return { ...state,
                subscriptionUpdateError: null,
                whitelistedSubscriptions: _.map(state.whitelistedSubscriptions, s => s.id === subscriptionId ? { ...s, pendingDeletion: true } : s)
            };
        }
        case "CHARGING:UNWHITELIST-SUBSCRIPTION-SUCCESS": {
            const { subscriptionId } = payload;
            return { ...state,
                whitelistedSubscriptions:  _.reject(state.whitelistedSubscriptions, s => s.id === subscriptionId)
            };
        }
        case "CHARGING:UNWHITELIST-SUBSCRIPTION-ERROR": {
            const { error, subscriptionId } = payload;
            return { ...state,
                subscriptionUpdateError: error,
                whitelistedSubscriptions: _.map(state.whitelistedSubscriptions, s => s.id === subscriptionId ? { ...s, pendingDeletion: false, pendingSubmission: false } : s)
            };
        }
        case "CHARGING:UNWHITELIST-ACCOUNT-PENDING": {
            const { accountId } = payload;
            return { ...state,
                accountUpdateError: null,
                whitelistedAccounts: _.map(state.whitelistedAccounts, a => a.id === accountId ? { ...a, pendingDeletion: true } : a)
            };
        }
        case "CHARGING:UNWHITELIST-ACCOUNT-SUCCESS": {
            const { accountId } = payload;
            return { ...state,
                whitelistedAccounts:  _.reject(state.whitelistedAccounts, s => s.id === accountId)
            };
        }
        case "CHARGING:UNWHITELIST-ACCOUNT-ERROR": {
            const { error, accountId } = payload;
            return { ...state,
                accountUpdateError: error,
                whitelistedAccounts: _.map(state.whitelistedAccounts, a => a.id === accountId ? { ...a, pendingDeletion: false, pendingSubmission: false } : a)
            };
        }
        default:
            return state;
    }
};
