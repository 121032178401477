import React from "react";
import { InfoIIcon as ErrorIcon } from "@netapp/bxp-design-system-react/icons/monochrome";
import styles from "./ErrorTooltip.module.scss";

export const ErrorTooltip = () => {
    return (
        <div className={styles["popover"]}>
            <span>Volume Error</span>
            <ErrorIcon/>
        </div>
    );
};
