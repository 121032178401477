import _ from "lodash";
import {initialState as snapshotPolicyInitialState, daysOfWeek} from "../../../create-volume/SnapshotPolicy";
import {bytesToGiB, protocolMapper} from "../../../../utils/common";
import uuidv4 from "uuid/v4";
import {regionMapper as gcpRegionMapper, serviceLevelMapper} from "../../../../utils/gcp";
import {exportPolicyInitialState} from "../../../create-volume/ProtocolExportPolicies";

const daysOfWeekMapper = _.groupBy(daysOfWeek, "label");

const daysOfMonth = _.range(1,32).map(i => ({label: i.toString(), value: i}));

const daysOfMonthMapper = _.groupBy(daysOfMonth, "value");

export const snapshotPolicyMapper = (snapshotPolicy, snapshotDirectory) => {
    if (snapshotPolicy) {
        const hourly = snapshotPolicy.hourlySchedule.snapshotsToKeep !== 0;
        const daily = snapshotPolicy.dailySchedule.snapshotsToKeep !== 0;
        const weekly = snapshotPolicy.weeklySchedule.snapshotsToKeep !== 0;
        const monthly = snapshotPolicy.monthlySchedule.snapshotsToKeep !== 0;
        return {
            enabled: true,
            snapshotDirectory: snapshotDirectory,
            hourly,
            hourlyPolicyNumberOfSnapshots: hourly ? snapshotPolicy.hourlySchedule.snapshotsToKeep : "",
            hourlyPolicyMinute: hourly ? snapshotPolicy.hourlySchedule.minute : "",
            daily,
            dailyPolicyNumberOfSnapshots: daily ? snapshotPolicy.dailySchedule.snapshotsToKeep : "",
            dailyPolicyHour: daily ? snapshotPolicy.dailySchedule.hour : "",
            dailyPolicyMinute: daily ? snapshotPolicy.dailySchedule.minute : "",
            weekly,
            weeklyPolicyNumberOfSnapshots: weekly ? snapshotPolicy.weeklySchedule.snapshotsToKeep : "",
            weeklyPolicyDaysOfWeek: weekly ? _.reduce(snapshotPolicy.weeklySchedule.day.split(","), (accum, day) => {
                return _.flatten(_.concat(accum, daysOfWeekMapper[day]));
            }, []) : null,
            weeklyPolicyHour: weekly ? snapshotPolicy.weeklySchedule.hour : "",
            weeklyPolicyMinute: weekly ? snapshotPolicy.weeklySchedule.minute : "",
            monthly,
            monthlyPolicyNumberOfSnapshots: monthly ? snapshotPolicy.monthlySchedule.snapshotsToKeep : "",
            monthlyPolicyDaysOfMonth: monthly ? _.reduce(snapshotPolicy.monthlySchedule.daysOfMonth.split(","), (accum, day) => {
                return _.flatten(_.concat(accum, daysOfMonthMapper[day]));
            }, []) : null,
            monthlyPolicyHour: monthly ? snapshotPolicy.monthlySchedule.hour : "",
            monthlyPolicyMinute: monthly ? snapshotPolicy.monthlySchedule.minute : "",
        };
    } else {
        return snapshotPolicyInitialState;
    }
};

const readWritePolicyMapper = {
    "ReadOnly": "r",
    "ReadWrite": "r/w"
};

export const volumeProtocolMapper = (volume) => {
    const { protocol, rules } = volume;
    const protocolOption = protocolMapper[protocol];
    let exportPolicies = [{...exportPolicyInitialState}];
    if (protocolOption === "nfs") {
        exportPolicies = _.map(rules, rule => {
            return {
                ip: rule.allowedClients,
                policy: readWritePolicyMapper[rule.access],
                NFSv3: rule.nfsv3.checked,
                NFSv4: rule.nfsv4.checked,
                id: uuidv4()
            }
        });
    }
    return {
        protocolOption,
        internalStep: 0,
        editActiveDirectoryMode: false,
        volumePath: volume.creationToken,
        NFSv3: _.includes(volume.protocolTypes, "NFSv3"),
        NFSv4: _.includes(volume.protocolTypes, "NFSv4"),
        encryption: false,
        security: "Unix",
        exportPolicies,
        DNS: "",
        netBIOS: "",
        domain: "",
        username: "",
        password: "",
        organizationalUnit: ""
    };
};

export const gcpDetailsAndTagsState = volume => {
    const { name, quotaInBytes } = volume;
    const networkParams = volume.network.split("/");

    return {
        volumeName: name,
        sizeInGiB: bytesToGiB(Number(quotaInBytes)).toString(),
        serviceLevel: serviceLevelMapper[volume.serviceLevel],
        region: gcpRegionMapper[volume.region][0],
        vpc: { label: networkParams[networkParams.length - 1], value: volume.network }
    };
};

