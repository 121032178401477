import { registerReducer } from "../../store";
import { registerFeature } from "../../middlewares/apiMiddleware";
import cvsReducer from "./reducer";
import middleware from "./middleware";
import CVS from "./routes";
import "./styles/CVSGlobal.scss";

registerReducer("cvs", cvsReducer);
registerFeature("CVS", middleware);

export default CVS;
