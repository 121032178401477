import React from "react";
import { Modal, ModalHeader, ModalFooter, Button } from "@netapp/bxp-design-system-react";
import {ModalContent} from "./Modal";
import ExternalLink from "../../../components/widgets/ExternalLink";
import {SupportMonochromeIconsIcon as Support} from "@netapp/bxp-design-system-react/icons/monochrome";
import commonStyles from "../styles/common.module.scss";
import styles from "./SupportModal.module.scss";

export default ({target, closeTrigger}) => {
    return (
        <Modal>
            <ModalHeader>
                <div className={styles["modal-header"]}>
                    <Support className={commonStyles["support-icon"]}/>
                    <span>Cloud Volumes Service Support Help</span>
                </div>
            </ModalHeader>
            <ModalContent>
                Use the BlueXP chat for general service questions. For technical support issues associated with your Cloud Volumes Service (CVS) volume, please use your 20 digit CVS “930” serial number located in the "Support" tab of the CVS User Interface. This support id should be used when opening a web ticket or calling for support.
                Please be sure to activate your CVS serial number for support from the CVS User Interface. Those steps are explained
                <ExternalLink className={styles["redirect"]} href={"https://register.netapp.com"} newTab>HERE</ExternalLink>.
            </ModalContent>
            <ModalFooter>
                <Button onClick={closeTrigger}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};
