import React from 'react';
import {Link} from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import {withRoutingContext} from "./../RoutingContext"

const BackToMyServicesLink = ({authenticated, marketingMode, isRoot}) => {
    if(!authenticated || isRoot) return "";

    if(marketingMode){
        return <a className="my-services-button" href="/" target="_parent"> Cloud Volumes </a>
    } else {
        return <Link className="my-services-button" to="/" title="Cloud Volumes">
            Cloud Volumes
        </Link>
    }
};

const mapStateToProps = (state, ownProps) => {
    return {
        isRoot: ownProps.location.pathname && ownProps.location.pathname === "/fabric-view",
        authenticated: state.auth.authenticated,
    };
};

export default withRoutingContext(connect(mapStateToProps)(BackToMyServicesLink));