import React from "react";
import {ModalContent as Content} from "@netapp/bxp-design-system-react";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";

export const ModalContent = ({children}) => {
    return (
        <Content className={typography.body14}>
            {children}
        </Content>
    );
};
