import React, { Component } from 'react';
import classNames from "classnames";
import ReactDOM from 'react-dom';
import "./Popover.scss";
import { ReactComponent as InfoIcon } from './info-icon.svg';
import { parentHasClass } from "../../utils/utils";

const closePopover = event => {
    if (!parentHasClass(event.target, "popover-trigger-container") && !parentHasClass(event.target, "popover-container")) popoverContainer.setActivePopover(null);
};

const updatePosition = () => {
    if (!popoverContainer || !popoverContainer.state.activePopover) return;
    const trigger = ReactDOM.findDOMNode(popoverContainer.state.activePopover);
    const rect = trigger.getBoundingClientRect();
    const newState = {
        left: rect.left + rect.width / 2 + popoverContainer.state.activePopover.state.leftAdjust,
        top: rect.top + rect.height + popoverContainer.state.activePopover.state.topAdjust
    };
    if (popoverContainer.state.left !== newState.left || popoverContainer.state.top !== newState.top) {
      if(Math.abs(newState.left - popoverContainer.state.originalLeft) > 100 || Math.abs(newState.top - popoverContainer.state.originalTop) > 100){
        popoverContainer.setActivePopover(null);
      } else {
        popoverContainer.setState(Object.assign({}, popoverContainer.state, newState));
      }
    }
};

document.body.addEventListener('click', closePopover);
window.addEventListener("resize", updatePosition);
window.addEventListener("scroll", updatePosition, true);
const observer = new MutationObserver(updatePosition);
observer.observe(document, { attributes: true, childList: true, characterData: true, subtree: true });


export default class Popover extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            left: 0,
            top: 0,
            topAdjust: props.topAdjust || 0,
            leftAdjust: props.leftAdjust || 0,
            active: props.startOpen
        };

        if (props.startOpen) {
            setTimeout(() => {
                popoverContainer.setActivePopover(this);
            }, 100);
        }
    }

    componentWillUnmount() {
        if (popoverContainer && this === popoverContainer.state.activePopover) popoverContainer.setActivePopover(null);
    }

    render() {
        const { component } = this.props;

        let triggerProps;

        if (this.props.trigger === "mouseover") {
            triggerProps = {
                onMouseOver: () => popoverContainer.setActivePopover(this),
                onMouseOut: () => popoverContainer.setActivePopover(null)
            };
        } else {
            triggerProps = {
                onClick: event => {
                    event.stopPropagation();
                    popoverContainer.setActivePopover(this);
                }
            };
        }
        const trigger = component === "info" ?
            <button type="button" className="transparent" {...triggerProps}><InfoIcon/></button> :
            React.cloneElement(component, triggerProps);
        return (
            <span style={this.props.containerStyle}
                  className={classNames("popover-trigger-container", { active: this.state.active }, this.props.containerClassName)}
                  onClick={event => event.stopPropagation()}>
                {trigger}
            </span>
        );
    }
}

const PopoverContent = ({ className, enter, left, top, children, style, arrowStyle, contentStyle, useTransform, transform }) => {
    const position = {
        top: 0,
        left: 0
    };
    if(useTransform) {
        const originalTransform = transform ? `${transform} ` : 'translate(calc(-50%), 8px) ';
        position.transform = `${originalTransform}translate(${left}px, ${top}px)`;
    } else {
        position.top = top;
        position.left = left;
    }
    console.log(position.top);

    return <div>
        <div className={classNames("popover", className, { enter })}
             style={{...style, ...position}}>
            <div className="arrow" style={arrowStyle}>

            </div>
            <div className="arrow-block">
            </div>
            <div className="content" style={contentStyle}>
                {children}
            </div>
        </div>
    </div>
};

class PopoverContainer extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    componentWillMount() {
        popoverContainer = this;
    }

    setActivePopover(popover) {
        if (this.state.activePopover) {
            this.state.activePopover.setState(Object.assign({}, this.state.activePopover.state, { active: false }));
        }

        this.setState({ activePopover: null });

        if (popover && popover !== this.state.activePopover) {
            popover.setState(Object.assign({}, popover.state, { active: true }));

            setTimeout(() => {
                const trigger = ReactDOM.findDOMNode(popover);
                const rect = trigger.getBoundingClientRect();
                const left = rect.left + rect.width / 2 + popover.state.leftAdjust;
                const top = rect.top + rect.height + popover.state.topAdjust;
                const newState = {
                  left,
                  top,
                  originalLeft: left,
                  originalTop: top
                };
                this.setState({ enter: true, ...newState, activePopover: popover });

                setTimeout(() => {
                    this.setState({ enter: false });
                });
            });
        }
    }

    render() {
        return (<div>
            {this.state.activePopover ?
                <PopoverContent children={this.state.activePopover.props.children} enter={this.state.enter}
                                style={this.state.activePopover.props.style}
                                arrowStyle={this.state.activePopover.props.arrowStyle}
                                contentStyle={this.state.activePopover.props.contentStyle}
                                contentUp={this.state.activePopover.props.contentUp}
                                className={this.state.activePopover.props.className}
                                left={this.state.left}
                                top={this.state.top}
                                useTransform={this.state.activePopover.props.useTransform}
                                transform={this.state.activePopover.props.transform}
                /> :
                ""}
        </div>);
    }
}

let holder = document.getElementsByClassName("popover-container")[0];
let popoverContainer = null;
if (!holder) {
    holder = document.createElement("div");
    holder.classList.add("popover-container");
    document.body.appendChild(holder);
}

if (!popoverContainer) {
    ReactDOM.render(
        <PopoverContainer/>,
        holder
    );
}
