import _ from "lodash";
import {history} from "../../store";
import {updateVolumeStatus} from "./utils/common";
import {parseAnfVolume} from "./utils/azure";
import {activeDirectoryProps} from "./utils/aws";
import numeral from "numeral";

const defaultState = () => ({
    cvsFlavour: null,
    accountId: null,
    credentialsId: null,
    isSimulated: false,
    urlParamsAreSet: false,
    gcpProjects: [],
    getGcpProjectsResolved: false,
    getGcpProjectsPending: false,
    getGcpProjectsError: null,
    getGcpProjectsShowError: false,
    we: null,
    getWeResolved: false,
    getWePending: false,
    getWeError: null,
    getWeShowError: false,
    deleteWeResolved: false,
    deleteWePending: false,
    deleteWeError: null,
    deleteWeShowError: false,
    addWeResolved: false,
    addWePending: false,
    addWeError: null,
    addWeShowError: false,
    volumeViewSuccessMessage: null,
    showVolumeViewSuccessMessage: false,
    volumes: null,
    getVolumesResolved: false,
    getVolumesError: null,
    getVolumesShowError: null,
    getVolumesPending: false,
    volume: null,
    getVolumeResolved: false,
    getVolumePending: false,
    getVolumeError: null,
    getVolumeShowError: false,
    isDeleteVolumeModalOpen: false,
    volumeToDelete: null,
    deleteVolumeResolved: false,
    deleteVolumePending: false,
    deleteVolumeError: null,
    deleteVolumeShowError: false,
    volumeSnapshots: [],
    getVolumeSnapshotsResolved: false,
    getVolumeSnapshotsPending: false,
    getVolumeSnapshotsError: null,
    getVolumeSnapshotsShowError: null,
    createSnapshotResolved: false,
    createSnapshotPending: false,
    createSnapshotError: null,
    createSnapshotShowError: false,
    deleteSnapshotResolved: false,
    deleteSnapshotPending: false,
    deleteSnapshotError: null,
    deleteSnapshotShowError: false,
    isDeleteSnapshotModalOpen: false,
    isOnBoarded: null,
    isOnBoardedResolved: false,
    isOnBoardedError: null,
    isOnBoardedShowError: false,
    isOnBoardedPending: false,
    activeDirectory: {},
    getActiveDirectoryResolved: false,
    getActiveDirectoryPending: false,
    getActiveDirectoryError: null,
    getActiveDirectoryShowError: false,
    createActiveDirectoryResolved: false,
    createActiveDirectoryPending: false,
    createActiveDirectoryError: null,
    createActiveDirectoryShowError: false,
    deleteActiveDirectoryResolved: false,
    deleteActiveDirectoryPending: false,
    deleteActiveDirectoryError: null,
    deleteActiveDirectoryShowError: false,
    editActiveDirectoryResolved: false,
    editActiveDirectoryPending: false,
    editActiveDirectoryError: null,
    editActiveDirectoryShowError: false,
    networkSetupResolved: false,
    networkSetupPending: false,
    networkSetupError: null,
    networkSetupShowError: null,
    snapshots: [],
    getSnapshotsResolved: false,
    getSnapshotsPending: false,
    getSnapshotsError: null,
    getSnapshotsShowError: null,
    netappAccounts: [],
    getNetappAccountsResolved: false,
    getNetappAccountsPending: false,
    getNetappAccountsError: null,
    getNetappAccountsShowError: false,
    subscriptions: [],
    getSubscriptionsResolved: false,
    getSubscriptionsPending: false,
    getSubscriptionsError: null,
    getSubscriptionsShowError: null,
    resourceGroups: [],
    getResourceGroupResolved: false,
    getResourceGroupPending: false,
    getResourceGroupError: null,
    getResourceGroupShowError: false,
    createResourceGroupResolved: false,
    createResourceGroupPending: false,
    createResourceGroupError: null,
    createResourceGroupShowError: false,
    netappAccount: {},
    createNetappAccountResolved: false,
    createNetappAccountPending: false,
    createNetappAccountError: null,
    createNetappAccountShowError: false,
    capacityPools: [],
    getCapacityPoolsResolved: false,
    getCapacityPoolsPending: false,
    getCapacityPoolsError: null,
    getCapacityPoolsShowError: false,
    capacityPool: {},
    createCapacityPoolResolved: false,
    createCapacityPoolPending: false,
    createCapacityPoolError: null,
    createCapacityPoolShowError: false,
    updateVolumePoolResolved: false,
    updateVolumePoolPending: false,
    updateVolumePoolError: null,
    updateVolumePoolShowError: false,
    virtualNetworks: [],
    getVirtualNetworksResolved: false,
    getVirtualNetworksPending: false,
    getVirtualNetworksError: null,
    getVirtualNetworksShowError: false
});

export default (state = defaultState(), {payload, type}) => {
    switch (type) {
        case "CVS:URL-PARAMS": {
            const { cvsFlavour, accountId, credentialsId, isSimulated } = payload;
            return {...state, cvsFlavour, accountId, credentialsId, isSimulated, urlParamsAreSet: true}
        }
        case "CVS:CLEAR-STATE": {
            return { ...state, ...payload }
        }
        case "CVS:GET-WE": {
            return { ...state, getWePending: true, getWeShowError: false };
        }
        case "CVS:GET-WE-SUCCESS": {
            const { we } = payload;
            return { ...state, we, getWeResolved: true, getWePending: false, getWeError: null, getWeShowError: false };
        }
        case "CVS:GET-WE-FAILURE": {
            const { getWeError } = payload;
            return { ...state, getWeResolved: true, getWePending: false, getWeError, getWeShowError: true };
        }
        case "CVS:DELETE-WE": {
            return { ...state, deleteWePending: true, deleteWeShowError: false }
        }
        case "CVS:DELETE-WE-SUCCESS": {
            setTimeout(() => {
                window.parent.postMessage({ action: "cvs-volume-view:delete-we-success" }, "*");
            });
            return { ...state, deleteWeResolved: true, deleteWePending: false, deleteWeError: null, deleteWeShowError: false }
        }
        case "CVS:DELETE-WE-FAILURE": {
            const { deleteWeError } = payload;
            return { ...state, deleteWeResolved: true, deleteWePending: false, deleteWeError, deleteWeShowError: true };
        }
        case "CVS:ADD-WE": {
            return { ...state, addWePending: true, addWeShowError: false }
        }
        case "CVS:ADD-WE-SUCCESS": {
            const { credentialsId } = payload;
            const { cvsFlavour } = state;
            window.parent.postMessage({ action: "cvs-add-we:complete", payload: { uuid: credentialsId }, provider: cvsFlavour }, "*");
            return { ...state, addWeResolved: true, addWePending: false, addWeError: null, addWeShowError: false }
        }
        case "CVS:ADD-WE-FAILURE": {
            const { addWeError } = payload;
            return { ...state, addWeResolved: true, addWePending: false, addWeError, addWeShowError: true }
        }
        case "CVS:GET-GCP-PROJECTS": {
            return { ...state, getGcpProjectsPending: true, getGcpProjectsShowError: false }
        }
        case "CVS:GET-GCP-PROJECTS-SUCCESS": {
            const { gcpProjects } = payload;
            const projectOptions = _.map(gcpProjects, p => ({ label: p.name, value: p.id, projectNumber: p.projectNumber }));
            return { ...state, getGcpProjectsResolved: true, getGcpProjectsPending: false, getGcpProjectsError: null, gcpProjects: projectOptions, getGcpProjectsShowError: false }
        }
        case "CVS:GET-GCP-PROJECTS-FAILURE": {
            const { getGcpProjectsError } = payload;
            return { ...state, getGcpProjectsResolved: true, getGcpProjectsPending: false, getGcpProjectsError, getGcpProjectsShowError: true }
        }
        case "CVS:CLOSE-VOLUME-WIZARD": {
            setTimeout(() => {
                history.replace(`/components/cvs/${state.cvsFlavour}/${state.accountId}/${state.credentialsId}/volumes`);
            });
            return { ...state }
        }
        case "CVS:AZURE-GET-NETAPP-ACCOUNTS": {
            return { ...state, getNetappAccountsPending: true, getNetappAccountsShowError: false }
        }
        case "CVS:AZURE-GET-NETAPP-ACCOUNTS-SUCCESS": {
            const { netappAccounts } = payload;
            const netappAccountsMapped = _.map(netappAccounts, account => ({ ...account, label: `${account.name} | ${account.location}`, value: account.name }));
            return { ...state, getNetappAccountsResolved: true, netappAccounts: netappAccountsMapped, getNetappAccountsPending: false, getNetappAccountsError: null, getNetappAccountsShowError: false }
        }
        case "CVS:AZURE-GET-NETAPP-ACCOUNTS-FAILURE": {
            const { getNetappAccountsError } = payload;
            return { ...state, getNetappAccountsResolved: true, getNetappAccountsPending: false, getNetappAccountsError, getNetappAccountsShowError: true }
        }
        case "CVS:AZURE-GET-SUBSCRIPTIONS": {
            return { ...state, getSubscriptionsPending: true, getSubscriptionsShowError: null }
        }
        case "CVS:AZURE-GET-SUBSCRIPTIONS-SUCCESS": {
            const { subscriptions } = payload;
            const subscriptionsMapped = _.map(subscriptions, subscription => ({ ...subscription, label: subscription.displayName, value: subscription.subscriptionId }));
            return { ...state, getSubscriptionsResolved: true, subscriptions: subscriptionsMapped, getSubscriptionsPending: false, getSubscriptionsError: null, getSubscriptionsShowError: false }
        }
        case "CVS:AZURE-GET-SUBSCRIPTIONS-FAILURE": {
            const { getSubscriptionsError } = payload;
            return { ...state, getSubscriptionsResolved: true, getSubscriptionsPending: false, getSubscriptionsError, getSubscriptionsShowError: true }
        }
        case "CVS:AZURE-GET-RESOURCE-GROUPS": {
            return { ...state, getResourceGroupsPending: true, getResourceGroupsShowError: false }
        }
        case "CVS:AZURE-GET-RESOURCE-GROUPS-SUCCESS": {
            const { resourceGroups } = payload;
            const resourceGroupsMapped = _.map(resourceGroups, rg => ({ ...rg, label: rg.name, value: rg.name }));
            return { ...state, getResourceGroupsResolved: true, resourceGroups: resourceGroupsMapped, getResourceGroupsPending: false, getResourceGroupsError: null, getResourceGroupsShowError: false }
        }
        case "CVS:AZURE-GET-RESOURCE-GROUPS-FAILURE": {
            const { getResourceGroupsError } = payload;
            return { ...state, getResourceGroupsResolved: true, getResourceGroupsPending: false, getResourceGroupsError, getResourceGroupsShowError: true }
        }
        case "CVS:AZURE-CREATE-RESOURCE-GROUP": {
            return { ...state, createResourceGroupPending: true, createResourceGroupShowError: false }
        }
        case "CVS:AZURE-CREATE-RESOURCE-GROUP-SUCCESS": {
            return { ...state, createResourceGroupResolved: true, createResourceGroupPending: false, createResourceGroupError: null, createResourceGroupShowError: false }
        }
        case "CVS:AZURE-CREATE-RESOURCE-GROUP-FAILURE": {
            const { createResourceGroupError } = payload;
            return { ...state, createResourceGroupResolved: true, createResourceGroupPending: false, createResourceGroupError, createResourceGroupShowError: true }
        }
        case "CVS:AZURE-CREATE-NETAPP-ACCOUNT": {
            return { ...state, createNetappAccountPending: true, createNetappAccountShowError: false }
        }
        case "CVS:AZURE-CREATE-NETAPP-ACCOUNT-SUCCESS": {
            const { netappAccount, onSuccess } = payload;
            if (onSuccess) {
                setTimeout(() => {
                    onSuccess();
                }, 0);
            }
            return { ...state, createNetappAccountResolved: true, netappAccount, createNetappAccountPending: false, createNetappAccountError: null, createNetappAccountShowError: false }
        }
        case "CVS:AZURE-CREATE-NETAPP-ACCOUNT-FAILURE": {
            const { createNetappAccountError } = payload;
            return { ...state, createNetappAccountResolved: true, createNetappAccountPending: false, createNetappAccountError, createNetappAccountShowError: true }
        }
        case "CVS:AZURE-GET-CAPACITY-POOLS": {
            return { ...state, getCapacityPoolsPending: true, getCapacityPoolsShowError: false }
        }
        case "CVS:AZURE-GET-CAPACITY-POOLS-SUCCESS": {
            const { capacityPools, caller } = payload;
            let capacityPoolsMapped = [];
            if (caller === "volume-view") {
                const { poolName } = state.volume;
                capacityPoolsMapped = _(capacityPools).filter(pool => pool.name !== poolName).map(pool => ({ ...pool, label: `${pool.name}  (${numeral(pool.size).format('0.0 ib')}) | ${pool.serviceLevel}`, value: pool.name })).value();
            } else {
                capacityPoolsMapped = _.map(capacityPools, pool => ({ ...pool, label: `${pool.name} | ${pool.location}`, value: pool.name }));
            }
            return { ...state, getCapacityPoolsResolved: true, capacityPools: capacityPoolsMapped, getCapacityPoolsPending: false, getCapacityPoolsError: null, getCapacityPoolsShowError: false  }
        }
        case "CVS:AZURE-GET-CAPACITY-POOLS-FAILURE": {
            const { getCapacityPoolsError } = payload;
            return { ...state, getCapacityPoolsResolved: true, getCapacityPoolsPending: false, getCapacityPoolsError, getCapacityPoolsShowError: true }
        }
        case "CVS:AZURE-UPDATE-VOLUME-CAPACITY-POOL": {
            return { ...state, updateVolumePoolPending: true, updateVolumePoolShowError: false }
        }
        case "CVS:AZURE-UPDATE-VOLUME-CAPACITY-POOL-SUCCESS": {
            const { name } = state.volume;
            setTimeout(() => {
                history.replace(`/components/cvs/azure/${state.accountId}/${state.credentialsId}/volumes`);
            });
            return { ...state, updateVolumePoolResolved: true, updateVolumePoolPending: false, updateVolumePoolError: null, updateVolumePoolShowError: false, showVolumeViewSuccessMessage: true, volumeViewSuccessMessage: `${name} capacity pool was changed successfully ` }
        }
        case "CVS:AZURE-UPDATE-VOLUME-CAPACITY-POOL-FAILURE": {
            const { updateVolumePoolError } = payload;
            return { ...state, updateVolumePoolResolved: true, updateVolumePoolPending: false, updateVolumePoolError, updateVolumePoolShowError: true }
        }
        case "CVS:AZURE-CREATE-CAPACITY-POOL": {
            return { ...state, createCapacityPoolPending: true, createCapacityPoolShowError: false }
        }
        case "CVS:AZURE-CREATE-CAPACITY-POOL-SUCCESS": {
            const { capacityPool, onSuccess } = payload;
            if (onSuccess) {
                setTimeout(() => {
                    onSuccess();
                }, 0);
            }
            return { ...state, createCapacityPoolResolved: true, createCapacityPoolPending: false, capacityPool, createCapacityPoolShowError: false, createCapacityPoolError: null }
        }
        case "CVS:AZURE-CREATE-CAPACITY-POOL-FAILURE": {
            const { createCapacityPoolError } = payload;
            return { ...state, createCapacityPoolResolved: true, createCapacityPoolPending: false, createCapacityPoolError, createCapacityPoolShowError: true }
        }
        case "CVS:AZURE-GET-VIRTUAL-NETWORKS": {
            return { ...state, getVirtualNetworksPending: true, getVirtualNetworksShowError: false }
        }
        case "CVS:AZURE-GET-VIRTUAL-NETWORKS-SUCCESS": {
            const { virtualNetworks } = payload;
            const virtualNetworksMapped = _.map(virtualNetworks, vNet => {
                const subnets = _.map(vNet.subnets, subnet => ({ ...subnet, label: subnet.isDelegated ? `${subnet.name} | ${subnet.addressPrefix}` : `${subnet.name} | ${subnet.addressPrefix} (Not delegated)`, value: subnet.name, isDisabled: !subnet.isDelegated }));
                return { ...vNet, subnets, label: `${vNet.name} | ${vNet.resourceGroupName}`, value: vNet.name }
            });
            return { ...state, getVirtualNetworksResolved: true, virtualNetworks: virtualNetworksMapped, getVirtualNetworksPending: false, getVirtualNetworksError: null, getVirtualNetworksShowError: false }
        }
        case "CVS:AZURE-GET-VIRTUAL-NETWORKS-FAILURE": {
            const { getVirtualNetworksError } = payload;
            return { ...state, getVirtualNetworksResolved: true, getVirtualNetworksPending: false, getVirtualNetworksError, getVirtualNetworksShowError: true }
        }
        case "CVS:AZURE-GET-ACTIVE-DIRECTORY": {
            return { ...state, getActiveDirectoryPending: true, getActiveDirectoryShowError: false }
        }
        case "CVS:AZURE-GET-ACTIVE-DIRECTORY-SUCCESS": {
            const { activeDirectory, onSuccess } = payload;
            const ad = !_.isEmpty(activeDirectory?.activeDirectories) ? activeDirectory.activeDirectories[0] : {};
            if (onSuccess) {
                setTimeout(() => {
                    onSuccess();
                }, 0);
            }
            return { ...state, getActiveDirectoryResolved: true, activeDirectory: ad, getActiveDirectoryPending: false, getActiveDirectoryError: null, getActiveDirectoryShowError: false }
        }
        case "CVS:AZURE-GET-ACTIVE-DIRECTORY-FAILURE": {
            const { getActiveDirectoryError } = payload;
            return { ...state, getActiveDirectoryResolved: true, getActiveDirectoryPending: false, getActiveDirectoryError, getActiveDirectoryShowError: true }
        }
        case "CVS:AZURE-EDIT-ACTIVE-DIRECTORY": {
            return { ...state, editActiveDirectoryPending: true, editActiveDirectoryShowError: false }
        }
        case "CVS:AZURE-EDIT-ACTIVE-DIRECTORY-SUCCESS": {
            const { activeDirectory, onSuccess } = payload;
            if (onSuccess) {
                setTimeout(() => {
                    onSuccess();
                }, 0);
            }
            return { ...state, activeDirectory, editActiveDirectoryResolved: true, editActiveDirectoryPending: false, editActiveDirectoryError: null, editActiveDirectoryShowError: false }
        }
        case "CVS:AZURE-EDIT-ACTIVE-DIRECTORY-FAILURE": {
            const { editActiveDirectoryError } = payload;
            return { ...state, editActiveDirectoryResolved: true, editActiveDirectoryPending: false, editActiveDirectoryError, editActiveDirectoryShowError: true }
        }
        case "CVS:AZURE-CREATE-ACTIVE-DIRECTORY": {
            return { ...state, createActiveDirectoryPending: true, createActiveDirectoryShowError: false }
        }
        case "CVS:AZURE-CREATE-ACTIVE-DIRECTORY-SUCCESS": {
            const { activeDirectory, onSuccess } = payload;
            if (onSuccess) {
                setTimeout(() => {
                    onSuccess();
                }, 0);
            }
            return { ...state, activeDirectory, createActiveDirectoryResolved: true, createActiveDirectoryPending: false, createActiveDirectoryError: null, createActiveDirectoryShowError: false }
        }
        case "CVS:AZURE-CREATE-ACTIVE-DIRECTORY-FAILURE": {
            const { createActiveDirectoryError } = payload;
            return { ...state, createActiveDirectoryResolved: true, createActiveDirectoryPending: false, createActiveDirectoryError, createActiveDirectoryShowError: true }
        }
        case "CVS:AZURE-CREATE-VOLUME": {
            return { ...state, createVolumePending: true, createVolumeShowError: false }
        }
        case "CVS:AZURE-CREATE-VOLUME-SUCCESS": {
            const { anfVolume } = payload;
            setTimeout(() => {
                history.replace(`/components/cvs/azure/${state.accountId}/${state.credentialsId}/volumes`);
            });
            return { ...state, createVolumeResolved: true, newlyCreatedAnfVolume: anfVolume, createVolumePending: false, createVolumeError: null, createVolumeShowError: false }
        }
        case "CVS:AZURE-CREATE-VOLUME-FAILURE": {
            const { createVolumeError } = payload;
            return { ...state, createVolumeResolved: true, createVolumePending: false, createVolumeError, createVolumeShowError: true }
        }
        case "CVS:GCP-GET-VIRTUAL-NETWORKS": {
            return { ...state, getVirtualNetworksPending: true, getVirtualNetworksShowError: false }
        }
        case "CVS:GCP-GET-VIRTUAL-NETWORKS-SUCCESS": {
            const { virtualNetworks } = payload;
            const virtualNetworksMapped = _.map(virtualNetworks, vNet => ({ label: vNet.name, value: vNet.id }));
            return { ...state, getVirtualNetworksResolved: true, virtualNetworks: virtualNetworksMapped, getVirtualNetworksPending: false, getVirtualNetworksError: null, getVirtualNetworksShowError: false }
        }
        case "CVS:GCP-GET-VIRTUAL-NETWORKS-FAILURE": {
            const { getVirtualNetworksError } = payload;
            return { ...state, getVirtualNetworksResolved: true, getVirtualNetworksPending: false, getVirtualNetworksError, getVirtualNetworksShowError: true }
        }
        case "CVS:GCP-GET-ACTIVE-DIRECTORY": {
            return { ...state, getActiveDirectoryPending: true, getActiveDirectoryShowError: null }
        }
        case "CVS:GCP-GET-ACTIVE-DIRECTORY-SUCCESS": {
            const { activeDirectory, onSuccess } = payload;
            const ad = !_.isEmpty(activeDirectory) ? activeDirectory[0] : {};
            if (onSuccess) {
                setTimeout(() => {
                    onSuccess();
                }, 0);
            }
            return { ...state, getActiveDirectoryResolved: true, activeDirectory: ad, getActiveDirectoryPending: false, getActiveDirectoryError: null, getActiveDirectoryShowError: false }
        }
        case "CVS:GCP-GET-ACTIVE-DIRECTORY-FAILURE": {
            const { getActiveDirectoryError } = payload;
            return { ...state, getActiveDirectoryResolved: true, getActiveDirectoryPending: false, getActiveDirectoryError, getActiveDirectoryShowError: true }
        }
        case "CVS:GCP-CREATE-ACTIVE-DIRECTORY": {
            return { ...state, createActiveDirectoryPending: true, createActiveDirectoryShowError: false }
        }
        case "CVS:GCP-CREATE-ACTIVE-DIRECTORY-SUCCESS": {
            const { formState, onSuccess } = payload;
            const ad = _.pick(formState, activeDirectoryProps);
            if (onSuccess) {
                setTimeout(() => {
                    onSuccess();
                }, 0);
            }
            return { ...state, createActiveDirectoryResolved: true, activeDirectory: ad, createActiveDirectoryPending: false, createActiveDirectoryError: null, createActiveDirectoryShowError: false }
        }
        case "CVS:GCP-CREATE-ACTIVE-DIRECTORY-FAILURE": {
            const { createActiveDirectoryError } = payload;
            return { ...state, createActiveDirectoryResolved: true, createActiveDirectoryPending: false, createActiveDirectoryError, createActiveDirectoryShowError: true }
        }
        case "CVS:GCP-EDIT-ACTIVE-DIRECTORY": {
            return { ...state, editActiveDirectoryPending: true, editActiveDirectoryShowError: false }
        }
        case "CVS:GCP-EDIT-ACTIVE-DIRECTORY-SUCCESS": {
            const { formState, onSuccess } = payload;
            const ad = _.pick(formState, activeDirectoryProps);
            if (onSuccess) {
                setTimeout(() => {
                    onSuccess();
                }, 0);
            }
            return { ...state, editActiveDirectoryResolved: true, activeDirectory: ad, editActiveDirectoryPending: false, editActiveDirectoryError: null, editActiveDirectoryShowError: false }
        }
        case "CVS:GCP-EDIT-ACTIVE-DIRECTORY-FAILURE": {
            const { editActiveDirectoryError } = payload;
            return { ...state, editActiveDirectoryResolved: true, editActiveDirectoryPending: false, editActiveDirectoryError, editActiveDirectoryShowError: true }
        }
        case "CVS:GCP-GET-SNAPSHOTS": {
            return { ...state, getSnapshotsPending: true, getSnapshotsShowError: null }
        }
        case "CVS:GCP-GET-SNAPSHOTS-SUCCESS": {
            const { snapshots } = payload;
            const snapshotsMapped = _.map(snapshots, snapshot => ({ ...snapshot, value: snapshot.snapshotId, label: snapshot.name }));
            return { ...state, getSnapshotsResolved: true, snapshots: snapshotsMapped, getSnapshotsPending: false, getSnapshotsError: null, getSnapshotsShowError: false }
        }
        case "CVS:GCP-GET-SNAPSHOTS-FAILURE": {
            const { getSnapshotsError } = payload;
            return { ...state, getSnapshotsResolved: true, getSnapshotsPending: false, getSnapshotsError, getSnapshotsShowError: true }
        }
        case "CVS:GCP-CREATE-VOLUME": {
            return { ...state, createVolumePending: true, createVolumeShowError: false }
        }
        case "CVS:GCP-CREATE-VOLUME-SUCCESS": {
            setTimeout(() => {
                history.replace(`/components/cvs/gcp/${state.accountId}/${state.credentialsId}/volumes`);
            });
            return { ...state, createVolumeResolved: true, createVolumePending: false, createVolumeError: null, createVolumeShowError: false }
        }
        case "CVS:GCP-CREATE-VOLUME-FAILURE": {
            const { createVolumeError } = payload;
            return { ...state, createVolumeResolved: true, createVolumePending: false, createVolumeError, createVolumeShowError: true }
        }
        case "CVS:AWS-IS-ON-BOARDED": {
            return { ...state, isOnBoardedPending: true, isOnBoardedShowError: false }
        }
        case "CVS:AWS-IS-ON-BOARDED-SUCCESS": {
            const { isOnBoarded } = payload;
            return { ...state, isOnBoarded, isOnBoardedResolved: true, isOnBoardedPending: false, isOnBoardedError: null, isOnBoardedShowError: false }
        }
        case "CVS:AWS-IS-ON-BOARDED-FAILURE": {
            const { isOnBoardedError } = payload;
            return { ...state, isOnBoardedResolved: true, isOnBoardedPending: false, isOnBoardedError, isOnBoardedShowError: true }
        }
        case "CVS:AWS-GET-ACTIVE-DIRECTORY": {
            return { ...state, getActiveDirectoryPending: true, getActiveDirectoryShowError: false }
        }
        case "CVS:AWS-GET-ACTIVE-DIRECTORY-SUCCESS": {
            const { activeDirectory } = payload;
            const ad = !_.isEmpty(activeDirectory) ? activeDirectory[0] : {};
            return { ...state, activeDirectory: ad, getActiveDirectoryResolved: true, getActiveDirectoryPending: false, getActiveDirectoryError: null, getActiveDirectoryShowError: false }
        }
        case "CVS:AWS-GET-ACTIVE-DIRECTORY-FAILURE": {
            const { getActiveDirectoryError } = payload;
            return { ...state, getActiveDirectoryResolved: true, getActiveDirectoryPending: false, getActiveDirectoryError, getActiveDirectoryShowError: true }
        }
        case "CVS:AWS-CREATE-ACTIVE-DIRECTORY": {
            return { ...state, createActiveDirectoryPending: true, createActiveDirectoryShowError: false }
        }
        case "CVS:AWS-CREATE-ACTIVE-DIRECTORY-SUCCESS": {
            const { activeDirectory, caller, onSuccess } = payload;
            if (onSuccess) {
                setTimeout(() => {
                    onSuccess();
                }, 0);
            }
            let volumeViewSuccessMessage, showSuccessMessage;
            if (caller === "volumes-gallery") {
                showSuccessMessage = true;
                volumeViewSuccessMessage = "Active directory was created successfully";
                setTimeout(() => {
                    history.replace(`/components/cvs/aws/${state.accountId}/${state.credentialsId}/volumes`);
                });
            }
            return { ...state, activeDirectory, createActiveDirectoryResolved: true, createActiveDirectoryPending: false, createActiveDirectoryError: null, createActiveDirectoryShowError: false, showVolumeViewSuccessMessage: showSuccessMessage, volumeViewSuccessMessage }
        }
        case "CVS:AWS-CREATE-ACTIVE-DIRECTORY-FAILURE": {
            const { createActiveDirectoryError } = payload;
            return { ...state, createActiveDirectoryResolved: true, createActiveDirectoryPending: false, createActiveDirectoryError, createActiveDirectoryShowError: true }
        }
        case "CVS:AWS-EDIT-ACTIVE-DIRECTORY": {
            return { ...state, editActiveDirectoryPending: true, editActiveDirectoryShowError: false }
        }
        case "CVS:AWS-EDIT-ACTIVE-DIRECTORY-SUCCESS": {
            const { activeDirectory, caller, onSuccess } = payload;
            if (onSuccess) {
                setTimeout(() => {
                    onSuccess();
                }, 0);
            }
            let volumeViewSuccessMessage, showSuccessMessage;
            if (caller === "volumes-gallery") {
                showSuccessMessage = true;
                volumeViewSuccessMessage = "Active directory was updated successfully";
                setTimeout(() => {
                    history.replace(`/components/cvs/aws/${state.accountId}/${state.credentialsId}/volumes`);
                });
            }
            return { ...state, activeDirectory, editActiveDirectoryResolved: true, editActiveDirectoryPending: false, editActiveDirectoryError: null, editActiveDirectoryShowError: false, showVolumeViewSuccessMessage: showSuccessMessage, volumeViewSuccessMessage }
        }
        case "CVS:AWS-EDIT-ACTIVE-DIRECTORY-FAILURE": {
            const { editActiveDirectoryError } = payload;
            return { ...state, editActiveDirectoryResolved: true, editActiveDirectoryPending: false, editActiveDirectoryError, editActiveDirectoryShowError: true }
        }
        case "CVS:AWS-DELETE-ACTIVE-DIRECTORY": {
            return { ...state, deleteActiveDirectoryPending: true, deleteActiveDirectoryShowError: false }
        }
        case "CVS:AWS-DELETE-ACTIVE-DIRECTORY-SUCCESS": {
            setTimeout(() => {
                history.replace(`/components/cvs/aws/${state.accountId}/${state.credentialsId}/volumes`);
            });
            return { ...state, deleteActiveDirectoryResolved: true, deleteActiveDirectoryPending: false, deleteActiveDirectoryError: null, showVolumeViewSuccessMessage: true, deleteActiveDirectoryShowError: false, volumeViewSuccessMessage: "Active directory was deleted successfully" }
        }
        case "CVS:AWS-DELETE-ACTIVE-DIRECTORY-FAILURE": {
            const { deleteActiveDirectoryError } = payload;
            return { ...state, deleteActiveDirectoryResolved: true, deleteActiveDirectoryPending: false, deleteActiveDirectoryError, deleteActiveDirectoryShowError: true }
        }
        case "CVS:AWS-NETWORK-SETUP": {
            return { ...state, networkSetupPending: true, networkSetupShowError: false  }
        }
        case "CVS:AWS-NETWORK-SETUP-SUCCESS": {
            return { ...state, networkSetupResolved: true, networkSetupPending: false, networkSetupError: null, networkSetupShowError: false }
        }
        case "CVS:AWS-NETWORK-SETUP-FAILURE": {
            const { networkSetupError } = payload;
            return { ...state, networkSetupResolved: true, networkSetupPending: false, networkSetupError, networkSetupShowError: true }
        }
        case "CVS:AWS-GET-SNAPSHOTS": {
            return { ...state, getSnapshotsPending: true, getSnapshotsShowError: false }
        }
        case "CVS:AWS-GET-SNAPSHOTS-SUCCESS": {
            const { snapshots } = payload;
            const snapshotsMapped = _.map(snapshots, snapshot => ({ ...snapshot, value: snapshot.snapshotId, label: snapshot.name }));
            return { ...state, snapshots: snapshotsMapped, getSnapshotsResolved: true, getSnapshotsPending: false, getSnapshotsError: null, getSnapshotsShowError: false }
        }
        case "CVS:AWS-GET-SNAPSHOTS-FAILURE": {
            const { getSnapshotsError } = payload;
            return { ...state, getSnapshotsResolved: true, getSnapshotsPending: false, getSnapshotsError, getSnapshotsShowError: true }
        }
        case "CVS:AWS-CREATE-VOLUME": {
            return { ...state, awsCreateVolumePending: true, awsCreateVolumeShowError: false  }
        }
        case "CVS:AWS-CREATE-VOLUME-SUCCESS": {
            setTimeout(() => {
                history.replace(`/components/cvs/aws/${state.accountId}/${state.credentialsId}/volumes`);
            });
            return { ...state, awsCreateVolumeResolved: true, awsCreateVolumePending: false, awsCreateVolumeError: null, awsCreateVolumeShowError: false }
        }
        case "CVS:AWS-CREATE-VOLUME-FAILURE": {
            const { createVolumeError } = payload;
            return { ...state, createVolumeResolved: true, createVolumePending: false, createVolumeError, createVolumeShowError: true }
        }
        case "CVS:OPEN-DELETE-VOLUME-MODAL": {
            const { volumeToDelete } = payload;
            return { ...state, isDeleteVolumeModalOpen: true, volumeToDelete };
        }
        case "CVS:CLOSE-DELETE-VOLUME-MODAL": {
            return { ...state, isDeleteVolumeModalOpen: false };
        }
        case "CVS:AWS-DELETE-VOLUME": {
            return { ...state, deleteVolumePending: true, deleteVolumeShowError: false }
        }
        case "CVS:AWS-DELETE-VOLUME-SUCCESS": {
            const { volumeToDelete } = state;
            const { volumes } = state;
            const updatedVolumes = updateVolumeStatus("aws")(volumes, volumeToDelete, "deleting");
            return { ...state, volumes: updatedVolumes, deleteVolumeResolved: true, deleteVolumePending: false, deleteVolumeError: null, isDeleteVolumeModalOpen: false, deleteVolumeShowError: false }
        }
        case "CVS:AWS-DELETE-VOLUME-FAILURE": {
            const { deleteVolumeError } = payload;
            return { ...state, deleteVolumeResolved: true, deleteVolumePending: false, deleteVolumeError, deleteVolumeShowError: true }
        }
        case "CVS:AZURE-DELETE-VOLUME": {
            return { ...state, deleteVolumePending: true, deleteVolumeShowError: false }
        }
        case "CVS:AZURE-DELETE-VOLUME-SUCCESS": {
            const { volumeToDelete } = state;
            const { volumes } = state;
            const updatedVolumes = updateVolumeStatus("azure")(volumes, volumeToDelete, "deleting");
            return { ...state, volumes: updatedVolumes, deleteVolumeResolved: true, deleteVolumePending: false, deleteVolumeError: null, isDeleteVolumeModalOpen: false, deleteVolumeShowError: false }
        }
        case "CVS:AZURE-DELETE-VOLUME-FAILURE": {
            const { deleteVolumeError } = payload;
            return { ...state, deleteVolumeResolved: true, deleteVolumePending: false, deleteVolumeError, deleteVolumeShowError: true }
        }
        case "CVS:GCP-DELETE-VOLUME": {
            return { ...state, deleteVolumePending: true, deleteVolumeShowError: false }
        }
        case "CVS:GCP-DELETE-VOLUME-SUCCESS": {
            const { volumeToDelete } = state;
            const { volumes } = state;
            const updatedVolumes = updateVolumeStatus("gcp")(volumes, volumeToDelete, "deleting");
            return { ...state, volumes: updatedVolumes, deleteVolumeResolved: true, deleteVolumePending: false, deleteVolumeError: null, isDeleteVolumeModalOpen: false, deleteVolumeShowError: false }
        }
        case "CVS:GCP-DELETE-VOLUME-FAILURE": {
            const { deleteVolumeError } = payload;
            return { ...state, deleteVolumeResolved: true, deleteVolumePending: false, deleteVolumeError, deleteVolumeShowError: true }
        }
        case "CVS:AWS-GET-VOLUME": {
            return { ...state, getVolumePending: true, getVolumeShowError: false }
        }
        case "CVS:AWS-GET-VOLUME-SUCCESS": {
            const { volume } = payload;
            return { ...state, volume, getVolumeResolved: true, getVolumePending: false, getVolumeError: null, getVolumeShowError: false }
        }
        case "CVS:AWS-GET-VOLUME-FAILURE": {
            const { getVolumeError } = payload;
            return { ...state, getVolumeResolved: true, getVolumePending: false, getVolumeError, getVolumeShowError: true }
        }
        case "CVS:GCP-GET-VOLUME": {
            return { ...state, getVolumePending: true, getVolumeShowError: false }
        }
        case "CVS:GCP-GET-VOLUME-SUCCESS": {
            const { volume } = payload;
            return { ...state, volume, getVolumeResolved: true, getVolumePending: false, getVolumeError: null, getVolumeShowError: false }
        }
        case "CVS:GCP-GET-VOLUME-FAILURE": {
            const { getVolumeError } = payload;
            return { ...state, getVolumeResolved: true, getVolumePending: false, getVolumeError, getVolumeShowError: true }
        }
        case "CVS:AZURE-GET-VOLUME": {
            return { ...state, getVolumePending: true, getVolumeShowError: false }
        }
        case "CVS:AZURE-GET-VOLUME-SUCCESS": {
            const { volume } = payload;
            return { ...state, volume, getVolumeResolved: true, getVolumePending: false, getVolumeError: null, getVolumeShowError: false }
        }
        case "CVS:AZURE-GET-VOLUME-FAILURE": {
            const { getVolumeError } = payload;
            return { ...state, getVolumeResolved: true, getVolumePending: false, getVolumeError, getVolumeShowError: true }
        }
        case "CVS:AWS-GET-VOLUMES": {
            return { ...state, getVolumesPending: true, getVolumesShowError: false }
        }
        case "CVS:AWS-GET-VOLUMES-SUCCESS": {
            const { volumes } = payload;
            return { ...state, volumes, getVolumesResolved: true, getVolumesPending: false, getVolumesError: null, getVolumesShowError: false }
        }
        case "CVS:AWS-GET-VOLUMES-FAILURE": {
            const { getVolumesError } = payload;
            return { ...state, getVolumesResolved: true, getVolumesPending: false, getVolumesError, getVolumesShowError: true }
        }
        case "CVS:GCP-GET-VOLUMES": {
            return { ...state, getVolumesPending: true, getVolumesShowError: false }
        }
        case "CVS:GCP-GET-VOLUMES-SUCCESS": {
            const { gcpVolumes } = payload;
            return { ...state, volumes: gcpVolumes, getVolumesResolved: true, getVolumesPending: false, getVolumesError: null, getVolumesShowError: false }
        }
        case "CVS:GCP-GET-VOLUMES-FAILURE": {
            const { getVolumesError } = payload;
            return { ...state, getVolumesResolved: true, getVolumesPending: false, getVolumesError, getVolumesShowError: true }
        }
        case "CVS:AZURE-GET-VOLUMES": {
            return { ...state, getVolumesPending: true, getVolumesShowError: false }
        }
        case "CVS:AZURE-GET-VOLUMES-SUCCESS": {
            const { volumes } = payload;
            return { ...state, volumes: volumes.map(parseAnfVolume), getVolumesResolved: true, getVolumesPending: false, getVolumesError: null, getVolumesShowError: false }
        }
        case "CVS:AZURE-GET-VOLUMES-FAILURE": {
            const { getVolumesError } = payload;
            return { ...state, getVolumesResolved: true, getVolumesPending: false, getVolumesError, getVolumesShowError: true }
        }
        case "CVS:AWS-GET-VOLUME-SNAPSHOTS": {
            return { ...state, getVolumeSnapshotsPending: true, getVolumeSnapshotsShowError: false }
        }
        case "CVS:AWS-GET-VOLUME-SNAPSHOTS-SUCCESS": {
            const { volumeSnapshots } = payload;
            const volumeSnapshotsMapped = _.map(volumeSnapshots, snapshot => ({ ...snapshot, label: snapshot.name, value: snapshot.snapshotId }));
            return { ...state, volumeSnapshots: volumeSnapshotsMapped, getVolumeSnapshotsResolved: true, getVolumeSnapshotsPending: false, getVolumeSnapshotsError: null, getVolumeSnapshotsShowError: false }
        }
        case "CVS:AWS-GET-VOLUME-SNAPSHOTS-FAILURE": {
            const { getVolumeSnapshotsError } = payload;
            return { ...state, getVolumeSnapshotsResolved: true, getVolumeSnapshotsPending: false, getVolumeSnapshotsError, getVolumeSnapshotsShowError: true }
        }
        case "CVS:GCP-GET-VOLUME-SNAPSHOTS": {
            return { ...state, getVolumeSnapshotsPending: true, getVolumeSnapshotsShowError: false }
        }
        case "CVS:GCP-GET-VOLUME-SNAPSHOTS-SUCCESS": {
            const { volumeSnapshots } = payload;
            const updatedSnapshots = _.map(volumeSnapshots, snapshot => ({ ...snapshot, label: snapshot.name, value: snapshot.snapshotId }));
            return { ...state, volumeSnapshots: updatedSnapshots, getVolumeSnapshotsResolved: true, getVolumeSnapshotsPending: false, getVolumeSnapshotsError: null, getVolumeSnapshotsShowError: false }
        }
        case "CVS:GCP-GET-VOLUME-SNAPSHOTS-FAILURE": {
            const { getVolumeSnapshotsError } = payload;
            return { ...state, getVolumeSnapshotsResolved: true, getVolumeSnapshotsPending: false, getVolumeSnapshotsError, getVolumeSnapshotsShowError: true }
        }
        case "CVS:AZURE-GET-VOLUME-SNAPSHOTS": {
            return { ...state, getVolumeSnapshotsPending: true, getVolumeSnapshotsShowError: false }
        }
        case "CVS:AZURE-GET-VOLUME-SNAPSHOTS-SUCCESS": {
            const { volumeSnapshots } = payload;
            const volumeSnapshotsMapped = _.map(volumeSnapshots, snapshot => ({ ...snapshot, label: snapshot.name, value: snapshot.id }));
            return { ...state, volumeSnapshots: volumeSnapshotsMapped, getVolumeSnapshotsResolved: true, getVolumeSnapshotsPending: false, getVolumeSnapshotsError: null, getVolumeSnapshotsShowError: false }
        }
        case "CVS:AZURE-GET-VOLUME-SNAPSHOTS-FAILURE": {
            const { getVolumeSnapshotsError } = payload;
            return { ...state, getVolumeSnapshotsResolved: true, getVolumeSnapshotsPending: false, getVolumeSnapshotsError, getVolumeSnapshotsShowError: true }
        }
        case "CVS:AWS-REVERT-VOLUME": {
            return { ...state, revertVolumePending: true, revertVolumeShowError: false }
        }
        case "CVS:AWS-REVERT-VOLUME-SUCCESS": {
            setTimeout(() => {
                history.replace(`/components/cvs/aws/${state.accountId}/${state.credentialsId}/volumes`);
            });
            return { ...state, revertVolumeResolved: true, revertVolumePending: false, revertVolumeError: null, revertVolumeShowError: false, showVolumeViewSuccessMessage: true, volumeViewSuccessMessage: "Volume was reverted successfully" }
        }
        case "CVS:AWS-REVERT-VOLUME-FAILURE": {
            const { revertVolumeError } = payload;
            return { ...state, revertVolumeResolved: true, revertVolumePending: false, revertVolumeError, revertVolumeShowError: true }
        }
        case "CVS:AZURE-REVERT-VOLUME": {
            return { ...state, revertVolumePending: true, revertVolumeShowError: false }
        }
        case "CVS:AZURE-REVERT-VOLUME-SUCCESS": {
            setTimeout(() => {
                history.replace(`/components/cvs/azure/${state.accountId}/${state.credentialsId}/volumes`);
            });
            return { ...state, revertVolumeResolved: true, revertVolumePending: false, revertVolumeError: null, revertVolumeShowError: false, showVolumeViewSuccessMessage: true, volumeViewSuccessMessage: "Volume was reverted successfully" }
        }
        case "CVS:AZURE-REVERT-VOLUME-FAILURE": {
            const { revertVolumeError } = payload;
            return { ...state, revertVolumeResolved: true, revertVolumePending: false, revertVolumeError, revertVolumeShowError: true }
        }
        case "CVS:GCP-REVERT-VOLUME": {
            return { ...state, revertVolumePending: true, revertVolumeShowError: false }
        }
        case "CVS:GCP-REVERT-VOLUME-SUCCESS": {
            setTimeout(() => {
                history.replace(`/components/cvs/gcp/${state.accountId}/${state.credentialsId}/volumes`);
            });
            return { ...state, revertVolumeResolved: true, revertVolumePending: false, revertVolumeError: null, revertVolumeShowError: false, showVolumeViewSuccessMessage: true, volumeViewSuccessMessage: "Volume was reverted successfully" }
        }
        case "CVS:GCP-REVERT-VOLUME-FAILURE": {
            const { revertVolumeError } = payload;
            return { ...state, revertVolumeResolved: true, revertVolumePending: false, revertVolumeError, revertVolumeShowError: true }
        }
        case "CVS:AWS-CREATE-SNAPSHOT": {
            return { ...state, createSnapshotPending: true, createSnapshotShowError: null }
        }
        case "CVS:AWS-CREATE-SNAPSHOT-SUCCESS": {
            setTimeout(() => {
                history.replace(`/components/cvs/aws/${state.accountId}/${state.credentialsId}/volumes`);
            });
            return { ...state, createSnapshotResolved: true, createSnapshotPending: false, createSnapshotError: null, createSnapshotShowError: false, showVolumeViewSuccessMessage: true, volumeViewSuccessMessage: "Snapshot was created successfully" }
        }
        case "CVS:AWS-CREATE-SNAPSHOT-FAILURE": {
            const { createSnapshotError } = payload;
            return { ...state, createSnapshotResolved: true, createSnapshotPending: false, createSnapshotError, createSnapshotShowError: true }
        }
        case "CVS:GCP-CREATE-SNAPSHOT": {
            return { ...state, createSnapshotPending: true, createSnapshotShowError: null }
        }
        case "CVS:GCP-CREATE-SNAPSHOT-SUCCESS": {
            setTimeout(() => {
                history.replace(`/components/cvs/gcp/${state.accountId}/${state.credentialsId}/volumes`);
            });
            return { ...state, createSnapshotResolved: true, createSnapshotPending: false, createSnapshotError: null, createSnapshotShowError: false, showVolumeViewSuccessMessage: true, volumeViewSuccessMessage: "Snapshot was created successfully" }
        }
        case "CVS:GCP-CREATE-SNAPSHOT-FAILURE": {
            const { createSnapshotError } = payload;
            return { ...state, createSnapshotResolved: true, createSnapshotPending: false, createSnapshotError, createSnapshotShowError: true }
        }
        case "CVS:AZURE-CREATE-SNAPSHOT": {
            return { ...state, createSnapshotPending: true, createSnapshotShowError: null }
        }
        case "CVS:AZURE-CREATE-SNAPSHOT-SUCCESS": {
            setTimeout(() => {
                history.replace(`/components/cvs/azure/${state.accountId}/${state.credentialsId}/volumes`);
            });
            return { ...state, createSnapshotResolved: true, createSnapshotPending: false, createSnapshotError: null, createSnapshotShowError: false, showVolumeViewSuccessMessage: true, volumeViewSuccessMessage: "Snapshot was created successfully" }
        }
        case "CVS:AZURE-CREATE-SNAPSHOT-FAILURE": {
            const { createSnapshotError } = payload;
            return { ...state, createSnapshotResolved: true, createSnapshotPending: false, createSnapshotError, createSnapshotShowError: true }
        }
        case "CVS:OPEN-DELETE-SNAPSHOT-MODAL": {
            return { ...state, isDeleteSnapshotModalOpen: true };
        }
        case "CVS:CLOSE-DELETE-SNAPSHOT-MODAL": {
            return { ...state, isDeleteSnapshotModalOpen: false };
        }
        case "CVS:AWS-DELETE-SNAPSHOT": {
            return { ...state, deleteSnapshotPending: true, deleteSnapshotShowError: false }
        }
        case "CVS:AWS-DELETE-SNAPSHOT-SUCCESS": {
            setTimeout(() => {
                history.replace(`/components/cvs/aws/${state.accountId}/${state.credentialsId}/volumes`);
            });
            return { ...state, isDeleteSnapshotModalOpen: false, deleteSnapshotResolved: true, deleteSnapshotPending: false, deleteSnapshotError: null, deleteSnapshotShowError: false, showVolumeViewSuccessMessage: true, volumeViewSuccessMessage: "Snapshot was deleted successfully" }
        }
        case "CVS:AWS-DELETE-SNAPSHOT-FAILURE": {
            const { deleteSnapshotError } = payload;
            return { ...state, deleteSnapshotResolved: true, deleteSnapshotPending: false, deleteSnapshotError, deleteSnapshotShowError: true }
        }
        case "CVS:GCP-DELETE-SNAPSHOT": {
            return { ...state, deleteSnapshotPending: true, deleteSnapshotShowError: false }
        }
        case "CVS:GCP-DELETE-SNAPSHOT-SUCCESS": {
            setTimeout(() => {
                history.replace(`/components/cvs/gcp/${state.accountId}/${state.credentialsId}/volumes`);
            });
            return { ...state, isDeleteSnapshotModalOpen: false, deleteSnapshotResolved: true, deleteSnapshotPending: false, deleteSnapshotError: null, deleteSnapshotShowError: false, showVolumeViewSuccessMessage: true, volumeViewSuccessMessage: "Snapshot was deleted successfully" }
        }
        case "CVS:GCP-DELETE-SNAPSHOT-FAILURE": {
            const { deleteSnapshotError } = payload;
            return { ...state, deleteSnapshotResolved: true, deleteSnapshotPending: false, deleteSnapshotError, deleteSnapshotShowError: true }
        }
        case "CVS:AZURE-DELETE-SNAPSHOT": {
            return { ...state, deleteSnapshotPending: true, deleteSnapshotShowError: false }
        }
        case "CVS:AZURE-DELETE-SNAPSHOT-SUCCESS": {
            setTimeout(() => {
                history.replace(`/components/cvs/azure/${state.accountId}/${state.credentialsId}/volumes`);
            });
            return { ...state, isDeleteSnapshotModalOpen: false, deleteSnapshotResolved: true, deleteSnapshotPending: false, deleteSnapshotError: null, deleteSnapshotShowError: false, showVolumeViewSuccessMessage: true, volumeViewSuccessMessage: "Snapshot was deleted successfully" }
        }
        case "CVS:AZURE-DELETE-SNAPSHOT-FAILURE": {
            const { deleteSnapshotError } = payload;
            return { ...state, deleteSnapshotResolved: true, deleteSnapshotPending: false, deleteSnapshotError, deleteSnapshotShowError: true }
        }
        case "CVS:RETURN-TO-GALLERY": {
            setTimeout(() => {
                history.replace(`/components/cvs/${state.cvsFlavour}/${state.accountId}/${state.credentialsId}/volumes`);
            });
            return { ...state }
        }
        case "CVS:AZURE-EDIT-VOLUME": {
            return { ...state, editVolumePending: true, editVolumeError: null }
        }
        case "CVS:AZURE-EDIT-VOLUME-SUCCESS": {
            setTimeout(() => {
                history.replace(`/components/cvs/azure/${state.accountId}/${state.credentialsId}/volumes`);
            });
            const { volumes } = state;
            const { anfVolume } = payload;
            const updatedVolumes = updateVolumeStatus("azure")(volumes, anfVolume, "updating");
            return { ...state, volumes: updatedVolumes, editVolumePending: false, editVolumeError: null, editVolumeShowError: false, showVolumeViewSuccessMessage: true, volumeViewSuccessMessage: "Volume was edited successfully" }
        }
        case "CVS:AZURE-EDIT-VOLUME-FAILURE": {
            const { editVolumeError } = payload;
            return { ...state, editVolumePending: false, editVolumeError, editVolumeShowError: true }
        }
        case "CVS:AWS-EDIT-VOLUME": {
            return { ...state, editVolumePending: true, editVolumeError: null }
        }
        case "CVS:AWS-EDIT-VOLUME-SUCCESS": {
            setTimeout(() => {
                history.replace(`/components/cvs/aws/${state.accountId}/${state.credentialsId}/volumes`);
            });
            return { ...state, editVolumePending: false, editVolumeError: null, editVolumeShowError: false, showVolumeViewSuccessMessage: true, volumeViewSuccessMessage: "Volume was edited successfully" }
        }
        case "CVS:AWS-EDIT-VOLUME-FAILURE": {
            const { editVolumeError } = payload;
            return { ...state, editVolumePending: false, editVolumeError, editVolumeShowError: true }
        }
        case "CVS:GCP-EDIT-VOLUME": {
            return { ...state, editVolumePending: true, editVolumeError: null }
        }
        case "CVS:GCP-EDIT-VOLUME-SUCCESS": {
            setTimeout(() => {
                history.replace(`/components/cvs/gcp/${state.accountId}/${state.credentialsId}/volumes`);
            });
            return { ...state, editVolumePending: false, editVolumeError: null, editVolumeShowError: false, showVolumeViewSuccessMessage: true, volumeViewSuccessMessage: "Volume was edited successfully" }
        }
        case "CVS:GCP-EDIT-VOLUME-FAILURE": {
            const { editVolumeError } = payload;
            return { ...state, editVolumePending: false, editVolumeError, editVolumeShowError: true }
        }
        default: {
            return state;
        }
    }
};
