import React, {Suspense, useEffect, useState} from 'react';
import { useDispatch } from "react-redux";
import querystring from 'query-string';
import './Buttons.scss';
import './components/Components.scss';
import {Route, Switch} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from "./actions";
import BlankPage from './components/BlankPage';
import App from './App';
import CVS from './modules/cvs';
import {Modal, ModalHeader, ModalContent, Button } from "@netapp/bxp-design-system-react";
import axios from "axios";
import { setServicesArray } from "./consts/sevicesMap"
import lazy from "utils/lazy";
import Loader from "./Loader";

const AuditComponent = lazy(() => import('./modules/audit' /* webpackChunkName: "Audit" */).then(module => module.AuditComponent));
const AccountsManagement = lazy(() => import('./components/accounts-management/AccountsManagement' /* webpackChunkName: "AccountsManagement" */));
const ComponentPlayground = lazy(() => import('./components/playground/ComponentPlayground' /* webpackChunkName: "ApiDoc" */));

const TimeoutModal = () => {
    const dispatch = useDispatch();
    const renew = () => {
      dispatch({
          type: "AUTH:RENEW-SSO"
      })
    };

    return <Modal closeTrigger={renew}>
        <ModalHeader>
            Authentication Renew
        </ModalHeader>
        <ModalContent>
            Your login session as expired. Make sure you have internet connectivity. <Button onClick={renew}>Refresh.</Button>
        </ModalContent>
    </Modal>;
};

const AppContainer = ({ auth }) => {
    const [loadedServices, setLoadedServices] = useState(false);

    useEffect(() => {
        let p = null;
        if(process.env.REACT_APP_SERVICES_ARRAY_ENDPOINT){
            p = axios.get(process.env.REACT_APP_SERVICES_ARRAY_ENDPOINT).then(({data}) => data);
        } else {
            p = import("ui-services/build/services").then(module => module.default);
        }

        p.then(json => {
            setServicesArray(json);
            setLoadedServices(true);
        });
    }, []);

    const search = querystring.parse(window.location.search);
    if (!loadedServices || auth.status === actions.STATUS_PENDING || search.gotoCvoFreeTrialCampaign || search.gotoCvoFreeTrialCampaign2 || search.gotoCloudTieringFreeTrialCampaign || auth.redirecting) {
        return <BlankPage/>;
    }
    return <>
        {auth.renewTimeout && <TimeoutModal/>}
        <Suspense fallback={<Loader/>}>
            <Switch>
                <Route path="/components/playground" render={() => <ComponentPlayground/>}/>
                <Route path="/components/account" component={AccountsManagement}/>
                <Route path="/components/cvs/:flavour/:accountId?/:credentialsId?" render={ () => <CVS/>}/>
                <Route path="/components/audit" render={({location}) => {
                    const search = querystring.parse(location.search);
                    const resources = search.resources ? search.resources.split(",") : null;
                    const agents = search.agents ? search.agents.split(",") : null;
                    const services = search.services ? search.services.split(",") : null;
                    const managementMode = search.managementMode === 'true';
                    return <AuditComponent accountId={search.accountId} resources={resources} agents={agents} services={services} managementMode={managementMode}/>
                }}/>
                <Route path="/" render={() => <App/>}/>
            </Switch>
        </Suspense>
    </>;
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(AppContainer);
