import React from "react";
import {Wizard, WizardStepTransitionRenderer, useWizard} from "@netapp/bxp-design-system-react";
import {Header} from "../../../create-volume/Page";
import {PageLayout} from "../../../Page";
import * as DetailsAndLocation from "./DetailsAndTagsGcp";
import * as Protocol from "./ProtocolGcp";
import * as SnapshotPolicy from "./SnapshotPolicy";

const stepsMap = {
    "details-and-location": DetailsAndLocation,
    "protocol": Protocol,
    "snapshot-policy": SnapshotPolicy
};

const stepPaths = {
    "edit": [
        {label: "Details & Location", key: "details-and-location"},
        {label: "Protocol", key: "protocol"},
        {label: "Snapshot Policy", key: "snapshot-policy"}
    ]
};

const EditVolume = ({volume}) => {
    const {currentStep} = useWizard();
    const {Footer: StepFooter} = stepsMap[currentStep];

    return (
        <PageLayout>
            <Header flavour={"gcp"} title={`Edit Volume ${volume.name} Wizard`}/>
            <div style={{position: "relative", overflowX: "hidden"}}>
                <WizardStepTransitionRenderer>
                    {({Content: StepContent}) => <StepContent volume={volume}/>}
                </WizardStepTransitionRenderer>
            </div>
            <StepFooter volume={volume}/>
        </PageLayout>
    );
};

export default ({ volume }) => {
    return (
        <Wizard stepsMap={stepsMap} stepPaths={stepPaths} initialStep={"details-and-location"} initialPath={"edit"}>
            <EditVolume volume={volume}/>
        </Wizard>
    );
};
