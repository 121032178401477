import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import React, {useEffect} from "react";
import {Modal, ModalFooter, ModalHeader, Button} from "@netapp/bxp-design-system-react";
import {ModalContent} from "../../../Modal";

export default ({ volume, snapshotToDelete, target, closeTrigger }) => {
    const dispatch = useDispatch();
    const flavour = useSelector(state => _.get(state, "cvs.cvsFlavour"));
    const showError = useSelector(state => _.get(state, "cvs.deleteSnapshotShowError"));
    const error = useSelector(state => _.get(state, "cvs.deleteSnapshotError"));
    const pending = useSelector(state => _.get(state, "cvs.deleteSnapshotPending"));

    useEffect(() => {
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: { deleteSnapshotResolved: false, deleteSnapshotError: null, deleteSnapshotShowError: false }
        });
    }, [dispatch]);

    const onActionClicked = () => dispatch({
        type: `CVS:${flavour.toUpperCase()}-DELETE-SNAPSHOT`,
        payload: { volume, snapshotToDelete }
    });

    return (
        <Modal>
            <ModalHeader>Delete a Snapshot Copy</ModalHeader>
            <ModalContent>{`Are you sure you want to delete snapshot copy: ${snapshotToDelete.name}?`}</ModalContent>
            <ModalFooter error={showError && error}>
                <Button disabled={pending} isSubmitting={pending} onClick={onActionClicked}>{"Delete"}</Button>
                <Button color="secondary" disabled={pending} onClick={closeTrigger}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};
