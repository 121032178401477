import React from "react";
import {useSelector} from "react-redux";
import _ from "lodash";
import {Wizard, WizardStepTransitionRenderer, useWizard} from "@netapp/bxp-design-system-react";
import {Header, HeaderWrapper} from "./Page";
import {PageLayout, PageLayoutNoFooter} from "../Page";
import Loader from "../../../../Loader";
import * as DetailsAndLocation from "./DetailsAndTagsGcp";
import * as Protocol from "./ProtocolGcp";
import * as SnapshotCopy from "./SnapshotCopy";
import * as SnapshotPolicy from "./SnapshotPolicy";

const stepsMap = {
    "details-and-location": DetailsAndLocation,
    "protocol": Protocol,
    "snapshot-copy": SnapshotCopy,
    "snapshot-policy": SnapshotPolicy
};

const stepPaths = {
    "create": [
        {label: "Details & Location", key: "details-and-location"},
        {label: "Protocol", key: "protocol"},
        {label: "Snapshot Copy", key: "snapshot-copy"},
        {label: "Snapshot Policy", key: "snapshot-policy"}
    ],
};

export const CreateVolume = () => {
    const {currentStep} = useWizard();
    const {Footer: StepFooter} = stepsMap[currentStep];

    return (
        <PageLayout>
            <Header flavour={"gcp"} title={"Add Volume Wizard"}/>
            <div style={{position: "relative", overflowX: "hidden"}}>
                <WizardStepTransitionRenderer>
                    {({Content: StepContent}) => <StepContent/>}
                </WizardStepTransitionRenderer>
            </div>
            <StepFooter/>
        </PageLayout>
    );
};

export const GcpCreateVolumePageWrapper = () => {
    const urlParamsAreSet = useSelector(state => _.get(state, "cvs.urlParamsAreSet"));

    const render = () => {
        if (!urlParamsAreSet) {
            return (
                <PageLayoutNoFooter>
                    <HeaderWrapper flavour={"gcp"}/>
                    <Loader/>
                </PageLayoutNoFooter>
            );
        }

        return (
            <Wizard stepsMap={stepsMap} stepPaths={stepPaths} initialStep={"details-and-location"} initialPath={"create"}>
                <CreateVolume/>
            </Wizard>
        );
    };

    return render();
};
