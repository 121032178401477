import ExternalLink from "../widgets/ExternalLink";
import classNames from "classnames";
import styles from "./Dashboard.module.scss";
import React from "react";

const GettingStartedBox = ({Icon, title, text, linkKey, className}) => <ExternalLink className={className} linkKey={linkKey} newTab>
    <Icon/>
    <h4>{title}</h4>
    <p>{text}</p>
</ExternalLink>;

export default ({ gettingStartedContent }) => <div>
    <h3>Learn How to Get Started</h3>
    <div className={styles["grid-row"]}>
        {gettingStartedContent.map(({ title, Icon, text, linkKey }) =>
            <GettingStartedBox className={classNames(styles["getting-started-box"], styles[`getting-started-box-x${gettingStartedContent.length}`], {[styles['getting-started-box-no-text']]: !text})} linkKey={linkKey} Icon={Icon} title={title} key={title} text={text}/>
        )}
    </div>
</div>;
