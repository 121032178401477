import React, { useState } from 'react';
import connect from "react-redux/es/connect/connect";

import { NavLink } from 'react-router-dom';
import { withRoutingContext } from "./../RoutingContext";
import ExternalLink from "../widgets/ExternalLink";

import { NetAppHeaderLogoBlueIcon as NetAppLogo} from "@netapp/bxp-design-system-react/icons/netapp-services";
import { FixedLeftNav } from "@netapp/bxp-design-system-react"

import { servicesMap } from '../../consts/sevicesMap';
import {FabricViewIcon} from "@netapp/bxp-design-system-react/icons/nav";
import {ProductsIcon} from "@netapp/bxp-design-system-react/icons/nav";
import {ToolsIcon} from "@netapp/bxp-design-system-react/icons/nav";
import {InternalIcon} from "@netapp/bxp-design-system-react/icons/nav";
import {ApiNavIconsIcon as DeveloperHubIcon} from "@netapp/bxp-design-system-react/icons/nav";
import {NewsIcon as FeatureSpotlightIcon} from "@netapp/bxp-design-system-react/icons/nav";
import {StatusIcon as ServicesStatusesIcon} from "@netapp/bxp-design-system-react/icons/nav";
import {ContactIcon as ContactUsIcon} from "@netapp/bxp-design-system-react/icons/nav";
import {TimelineIcon} from "@netapp/bxp-design-system-react/icons/nav";
import {LinkIcon as ExternalLinkIcon} from "@netapp/bxp-design-system-react/icons/monochrome";
import {MenuArrowIcon as ArrowIcon} from "@netapp/bxp-design-system-react/icons/monochrome";

import spotAnalyzerIcon from './Spot_Analyzer_Icon.png';
import spotEcoIcon from './Spot_Eco_Icon.png';
import spotElasticgroupIcon from './Spot_Elastigroup_Icon.png';
import spotOceanIcon from './Spot_Ocean_Icon.png';
import spotPCIcon from './big-SpotPC_216x216-1.webp';

import ContactUsModal from "./../dialog/ContactUsModal";

const mapStateToProps = (state) => {
    return {
        activeFeatures: state.features.active,
        auth: state.auth
    };
};

const HeaderButton = () => <ExternalLink title="NetApp logo" linkKey="marketingPageRedirect"><NetAppLogo/></ExternalLink>;

const NavWrapperDumb = ({activeFeatures, auth}) => {
    const [contactUsDialogParent, setContactUsDialogParent] = useState(null);

    const ONTAPCloudLogo = servicesMap["cloud-volumes-ontap"].logo;
    const CloudVolumesLogo = servicesMap["cloud-volumes-service"].logo;
    const CloudSyncLogo = servicesMap["cloud-sync"].logo;
    const CloudBackupLogo = servicesMap["cloud-backup"].logo;
    const OCILogo = servicesMap["observability"].logo;
    const CloudTieringLogo = servicesMap["cloud-tiering"].logo;
    const VirtualDesktopServiceLoco = servicesMap["virtual-desktop-service"].logo;
    const AstraLogo = servicesMap["astra"].logo;

    const products = [
        {Icon: ONTAPCloudLogo, title: "Cloud Volumes ONTAP", href: "/dashboard/cloud-volumes-ontap"},
        {Icon: CloudVolumesLogo, title: "CVS-AWS/GCP & ANF", href: "/dashboard/cloud-volumes-service"},
        {Icon: OCILogo, title: "Cloud Insights", href: "/dashboard/cloud-insights"},
        {Icon: CloudSyncLogo, title: "Cloud Sync", href: "/dashboard/cloud-sync"},
        {Icon: CloudTieringLogo, title: "Cloud Tiering", href: "/dashboard/cloud-tiering"},
        {Icon: CloudBackupLogo, title: "Cloud Backup Service", href: "/dashboard/cloud-backup-service"},
        {Icon: VirtualDesktopServiceLoco, title: "Virtual Desktop Service", href: "/dashboard/virtual-desktop-service"},
        {Icon: AstraLogo, title: "Astra", linkKey: "launchPad.services.astra", isExternalLink: true},
        {Icon: () => <img src={spotAnalyzerIcon} title="Cloud Analyzer" style={{width: 29, height: 29, position: "absolute", top: 11}}/>, title: "Cloud Analyzer", linkKey: "launchPad.services.spotAnalyzer", isExternalLink: true},
        {Icon: () => <img src={spotEcoIcon} title="Eco" style={{width: 29, height: 29, position: "absolute", top: 11}}/>, title: "Eco", linkKey: "launchPad.services.spotEco", isExternalLink: true},
        {Icon: () => <img src={spotElasticgroupIcon} title="Elastigroup" style={{width: 29, height: 29, position: "absolute", top: 11}}/>, title: "Elastigroup", linkKey: "launchPad.services.spotElasticgroup", isExternalLink: true},
        {Icon: () => <img src={spotOceanIcon} title="Ocean" style={{width: 29, height: 29, position: "absolute", top: 11}}/>, title: "Ocean", linkKey: "launchPad.services.spotOcean", isExternalLink: true},
        {Icon: () => <img src={spotPCIcon} title="Ocean" style={{width: 29, height: 29, position: "absolute", top: 11}}/>, title: "Spot PC", linkKey: "launchPad.services.spotPC", isExternalLink: true}
    ];

    const tools = [
        {title: "Cloud Assessment", href: "/cloud-assessment"},
        {isExternalLink: true, title: "TCO Azure Calculator", linkKey: "mainNav.azureTcoCalculator"},
        {isExternalLink: true, title: "TCO AWS Calculator", linkKey: "mainNav.awsTcoCalculator"},
        {isExternalLink: true, title: "TCO GC Calculator", linkKey: "mainNav.gcpTcoCalculator"},
        {isExternalLink: true, title: "Global File Cache ROI Calculator", linkKey: "mainNav.gfcRoiCalculator"},
        {isExternalLink: true, title: "CVO sizer", linkKey: "mainNav.cvoSizer"}
    ];

    const mainLinks = [
        {requiresAuth: true, href: "/fabric-view", Icon: FabricViewIcon, title: "Cloud Volumes"},
        {requiresAuth: true, href: "/feature-spotlight", Icon: FeatureSpotlightIcon, title: "Feature Spotlight"},
        {requiresAuth: true, isDropdown: true, Icon: ProductsIcon, title: "Products", links: products},
        {requiresAuth: true, isDropdown: true, Icon: ToolsIcon, title: "Tools", links: tools},
        {requiresFeatureFlag: "timeline", requiresAuth: true, href: "/audit", isDropdown: false, Icon: TimelineIcon, title: "Timeline"},
        {requiresAuth: true, onlyShowInternal: true, href: "/netapp-internal", Icon: InternalIcon, title: "NetApp Internal"},
        {href: "/developer-hub", Icon: DeveloperHubIcon, title: "API"},
        {isExternalLink: true, linkKey: "mainNav.statusPage", Icon: ServicesStatusesIcon, title: "Services Status"},
        {isAction: true, Icon: ContactUsIcon, title: "Contact Us", onClick: (el) => setContactUsDialogParent(el.target)}
    ];

    return <React.Fragment>
        {contactUsDialogParent && <ContactUsModal target={contactUsDialogParent} closeTrigger={() => setContactUsDialogParent(null)}/>}
        <FixedLeftNav ArrowIcon={ArrowIcon} ExternalLinkIcon={ExternalLinkIcon} HeaderButton={HeaderButton} InternalLink={NavLink} ExternalLink={ExternalLink} mainLinks={mainLinks.filter(link => {
            if(link.requiresAuth && !auth.authenticated) {
                return false;
            }

            if(link.onlyShowInternal && !auth.isNetAppInternal) {
                return false
            }

            if (link.requiresFeatureFlag && !activeFeatures[link.requiresFeatureFlag]) {
                return false
            }

            return true;
        })}/>
    </React.Fragment>
};

export default withRoutingContext(connect(mapStateToProps)(NavWrapperDumb));
