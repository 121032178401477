import React from "react";
import {ChevronIcon as Chevron} from "@netapp/bxp-design-system-react/icons/monochrome";
import ExternalLink from "../../../../components/widgets/ExternalLink";
import commonStyles from "../../styles/common.module.scss";
import styles from "./Info.module.scss";

export default () => {
    return (
        <>
            <div className={styles["info-item-container"]}>
                <Chevron/>
                <div>
                    <span>Create a GCP service account with the</span>
                    <ExternalLink className={commonStyles["in-text-button"]} href={"https://docs.netapp.com/us-en/occm/task_setup_cvs_gcp.html"} newTab>required permissions</ExternalLink>
                </div>
            </div>
            <div className={styles["info-item-container"]}>
                <Chevron/>
                <span>Download the service account credentials and paste them in the box on the left.</span>
            </div>
            <div className={styles["info-item-container"]}>
                <span>To learn more about Cloud Volumes Service,</span>
                <ExternalLink className={commonStyles["in-text-button"]} href={"https://cloud.netapp.com/cloud-volumes-service-for-gcp"} newTab>click here</ExternalLink>
            </div>
        </>
    );
};
