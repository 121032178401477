import _ from 'lodash';

/* eslint default-case: 0 */

const enhanceAgentsWithWorkspacesData = ({ agents, workspaces }) => _.map(agents, agent => {
    agent.workspaces = _.filter(workspaces, workspace => _.includes(workspace.associatedAgents, agent.agentId));
    return agent;
});

const defaultState = () => (
    { activeTab: 'Overview', loadingAccounts: true , loadingUsersAndWorkspaces: true, loadingAgents: true, loadingSubscriptions: true}
);

export default (state = defaultState(), { payload, type }) => {
    switch (type) {
        case "ACCOUNTS-MANAGEMENT:SET-ACCOUNT-ID": {
            return { ...state, accountId: payload.accountId };
        }
        case "ACCOUNTS-MANAGEMENT:FETCH-ACCOUNTS-PENDING": {
            return { ...state, fetchAccountsError: null, loadingAccounts: true };
        }
        case "ACCOUNTS-MANAGEMENT:FETCH-ACCOUNTS-SUCCESS": {
            return {
                ...state,
                accounts: payload.accounts,
                account: payload.account,
                loadingAccounts: false
            };
        }
        case "ACCOUNTS-MANAGEMENT:FETCH-ACCOUNTS-ERROR": {
            return { ...state, fetchAccountsError: payload.errorMessage, loadingAccounts: false };
        }
        case "ACCOUNTS-MANAGEMENT:FETCH-USERS-AND-WORKSPACES-PENDING": {
            return { ...state, fetchUsersAndWorkspacesError: null, loadingUsersAndWorkspaces: true };
        }
        case "ACCOUNTS-MANAGEMENT:FETCH-USERS-AND-WORKSPACES-SUCCESS": {
            return {
                ...state,
                accountUsers: payload.users,
                accountWorkspaces: payload.workspaces,
                agents: enhanceAgentsWithWorkspacesData({ agents: state.agents, workspaces: payload.workspaces }),
                loadingUsersAndWorkspaces: false
            };
        }
        case "ACCOUNTS-MANAGEMENT:FETCH-USERS-AND-WORKSPACES-ERROR": {
            return { ...state, fetchUsersAndWorkspacesError: payload.errorMessage, loadingUsersAndWorkspaces: false };
        }
        case "ACCOUNTS-MANAGEMENT:FETCH-AGENTS-PENDING": {
            return { ...state, fetchAgentsError: null, loadingAgents: true };
        }
        case "ACCOUNTS-MANAGEMENT:FETCH-AGENTS-SUCCESS": {
            return {
                ...state,
                agents: enhanceAgentsWithWorkspacesData({ agents: payload.agents, workspaces: state.workspaces }),
                loadingAgents: false
            };
        }
        case "ACCOUNTS-MANAGEMENT:FETCH-AGENTS-ERROR": {
            return { ...state, fetchAgentsError: payload.errorMessage, loadingAgents: false };
        }
        case "ACCOUNTS-MANAGEMENT:FETCH-SUBSCRIPTIONS-PENDING": {
            return { ...state, fetchSubscriptionsError: null, loadingSubscriptions: true };
        }
        case "ACCOUNTS-MANAGEMENT:FETCH-SUBSCRIPTIONS-SUCCESS": {
            return {
                ...state,
                subscriptions: payload.subscriptions,
                loadingSubscriptions: false
            };
        }
        case "ACCOUNTS-MANAGEMENT:FETCH-SUBSCRIPTIONS-ERROR": {
            return { ...state, fetchSubscriptionsError: payload.errorMessage, loadingSubscriptions: false };
        }
        case "ACCOUNTS-MANAGEMENT:SET-TAB": {
            return {
                ...state,
                activeTab: payload.tabName,
                addUserOverlayState: null,
                userForWorkspacesManagement: null,
                userForRemoval: null,
                agentForWorkspacesManagement: null,
                subscriptionForRenaming: null,
                subscriptionForAccountsManagement: null
            };
        }
        case "ACCOUNTS-MANAGEMENT:DISASSOCIATE-USER-PROMPT": {
            return { ...state, userForRemoval: payload, disassociateUserError: null };
        }
        case "ACCOUNTS-MANAGEMENT:DISASSOCIATE-USER-PENDING": {
            return { ...state, disassociateUserError: null, disassociateUserPending: true };
        }
        case "ACCOUNTS-MANAGEMENT:DISASSOCIATE-USER-SUCCESS": {
            return {
                ...state,
                userForRemoval: null,
                accountUsers: _.without(state.accountUsers, _.find(state.accountUsers, { userPublicId: payload.associatedUserPublicId })),
                disassociateUserPending: null
            };
        }
        case "ACCOUNTS-MANAGEMENT:DISASSOCIATE-USER-ERROR": {
            return { ...state, disassociateUserError: payload.errorMessage, disassociateUserPending: null };
        }
        case "ACCOUNTS-MANAGEMENT:MANAGE-USER-WORKSPACES-PROMPT": {
            return { ...state, userForWorkspacesManagement: payload };
        }
        case "ACCOUNTS-MANAGEMENT:MANAGE-USER-WORKSPACES-PENDING": {
            return { ...state, manageUserWorkspacesPending: true, manageUserWorkspacesError: null };
        }
        case "ACCOUNTS-MANAGEMENT:MANAGE-USER-WORKSPACES-SUCCESS": {
            return { ...state, manageUserWorkspacesPending: null, userForWorkspacesManagement: null };
        }
        case "ACCOUNTS-MANAGEMENT:MANAGE-USER-WORKSPACES-ERROR": {
            return { ...state, manageUserWorkspacesPending: null, manageUserWorkspacesError: payload.errorMessage };
        }
        case "ACCOUNTS-MANAGEMENT:EDIT-WORKSPACE-NAME-PENDING": {
            return { ...state, workspaceRenamePending: payload, manageWorkspaceError: null };
        }
        case "ACCOUNTS-MANAGEMENT:EDIT-WORKSPACE-NAME-SUCCESS": {
            return { ...state, workspaceRenamePending: null };
        }
        case "ACCOUNTS-MANAGEMENT:EDIT-WORKSPACE-NAME-ERROR": {
            return {
                ...state,
                workspaceRenamePending: null,
                manageWorkspaceError: payload.errorMessage,
                failedRenameWorkspace: payload.workspace
            };
        }
        case "ACCOUNTS-MANAGEMENT:DELETE-WORKSPACE-PENDING": {
            return {
                ...state,
                workspaceDeletePending: payload,
                manageWorkspaceError: null,
                failedRenameWorkspace: null
            };
        }
        case "ACCOUNTS-MANAGEMENT:DELETE-WORKSPACE-SUCCESS": {
            return { ...state, workspaceDeletePending: null };
        }
        case "ACCOUNTS-MANAGEMENT:DELETE-WORKSPACE-ERROR": {
            return { ...state, workspaceDeletePending: null, manageWorkspaceError: payload.errorMessage };
        }
        case "ACCOUNTS-MANAGEMENT:ADD-NEW-WORKSPACE-PENDING": {
            return { ...state, addNewWorkspacePending: true, manageWorkspaceError: null };
        }
        case "ACCOUNTS-MANAGEMENT:ADD-NEW-WORKSPACE-SUCCESS": {
            return {
                ...state,
                addNewWorkspacePending: null,
                accountWorkspaces: _.sortBy(state.accountWorkspaces.concat(payload), [workspace => workspace.workspaceName.toLowerCase()])
            };
        }
        case "ACCOUNTS-MANAGEMENT:ADD-NEW-WORKSPACE-ERROR": {
            return { ...state, addNewWorkspacePending: null, manageWorkspaceError: payload.errorMessage };
        }
        case "ACCOUNTS-MANAGEMENT:DISPLAY-WORKSPACES-ERROR": {
            return { ...state, manageWorkspaceError: payload.errorMessage };
        }
        case "ACCOUNTS-MANAGEMENT:SET-ADD-USER-OVERLAY-STATE": {
            return {
                ...state,
                addUserOverlayState: payload,
                userForWorkspacesManagement: null,
                userForRemoval: null,
                addUserError: null
            };
        }
        case "ACCOUNTS-MANAGEMENT:ASSOCIATE-USER-PENDING": {
            return { ...state, addUserPending: true, addUserError: null };
        }
        case "ACCOUNTS-MANAGEMENT:ASSOCIATE-USER-SUCCESS": {
            return { ...state, addUserOverlayState: null, addUserPending: null };
        }
        case "ACCOUNTS-MANAGEMENT:ASSOCIATE-USER-ERROR": {
            return { ...state, addUserError: payload.errorMessage, addUserPending: null };
        }
        case "ACCOUNTS-MANAGEMENT:MANAGE-AGENT-WORKSPACES-PROMPT": {
            return { ...state, agentForWorkspacesManagement: payload.agent, manageAgentWorkspacesError: null };
        }
        case "ACCOUNTS-MANAGEMENT:MANAGE-AGENT-WORKSPACES-PENDING": {
            return { ...state, manageAgentWorkspacesPending: true, manageAgentWorkspacesError: null };
        }
        case "ACCOUNTS-MANAGEMENT:MANAGE-AGENT-WORKSPACES-SUCCESS": {
            return { ...state, manageAgentWorkspacesPending: null, agentForWorkspacesManagement: null };
        }
        case "ACCOUNTS-MANAGEMENT:MANAGE-AGENT-WORKSPACES-ERROR": {
            return { ...state, manageAgentWorkspacesPending: null, manageAgentWorkspacesError: payload.errorMessage };
        }
        case "ACCOUNTS-MANAGEMENT:RENAME-SUBSCRIPTION-PROMPT": {
            return { ...state, subscriptionForRenaming: payload.subscription, subscriptionRenamingError: null };
        }
        case "ACCOUNTS-MANAGEMENT:RENAME-SUBSCRIPTION-PENDING": {
            return { ...state, subscriptionRenamingPending: true, subscriptionRenamingError: null };
        }
        case "ACCOUNTS-MANAGEMENT:RENAME-SUBSCRIPTION-SUCCESS": {
            return { ...state, subscriptionRenamingPending: null, subscriptionForRenaming: null };
        }
        case "ACCOUNTS-MANAGEMENT:RENAME-SUBSCRIPTION-ERROR": {
            return { ...state, subscriptionRenamingPending: null, subscriptionRenamingError: payload.errorMessage };
        }
        case "ACCOUNTS-MANAGEMENT:MANAGE-SUBSCRIPTION-ACCOUNTS-PROMPT": {
            return {
                ...state,
                subscriptionForAccountsManagement: payload.subscription,
                manageSubscriptionAccountsError: null
            };
        }
        case "ACCOUNTS-MANAGEMENT:MANAGE-SUBSCRIPTION-ACCOUNTS-PENDING": {
            return { ...state, manageSubscriptionAccountsPending: true, manageSubscriptionAccountsError: null };
        }
        case "ACCOUNTS-MANAGEMENT:MANAGE-SUBSCRIPTION-ACCOUNTS-SUCCESS": {
            return { ...state, manageSubscriptionAccountsPending: null, subscriptionForAccountsManagement: null };
        }
        case "ACCOUNTS-MANAGEMENT:MANAGE-SUBSCRIPTION-ACCOUNTS-ERROR": {
            return {
                ...state,
                manageSubscriptionAccountsPending: null,
                manageSubscriptionAccountsError: payload.errorMessage
            };
        }
        case "ACCOUNTS-MANAGEMENT:CLOSE-ALL-OVERLAYS": {
            return {
                ...state,
                currentUserWorkspacesPopUp: null,
                userForRemoval: null,
                userForWorkspacesManagement: null,
                addUserOverlayState: null,
                agentForWorkspacesManagement: null,
                subscriptionForAccountsManagement: null
            };
        }
        case "ACCOUNTS-MANAGEMENT:RENAME-ACCOUNT": {
            return {
                ...state,
                renamingAccount: true
            }
        }
        case "ACCOUNTS-MANAGEMENT:RENAME-ACCOUNT-SUCCESS": {
            return {
                ...state,
                renamingAccount: false,
                account: {...state.account, accountName: payload.newName}
            }
        }
        case "ACCOUNTS-MANAGEMENT:RENAME-ACCOUNT-ERROR": {
            return {
                ...state,
                renamingAccount: false,
                renameAccountErrorMessage: payload.errorMessage
            }
        }
        case "ACCOUNTS-MANAGEMENT:CLEAR-RENAME-ACCOUNT-ERROR": {
            return {
                ...state,
                renameAccountErrorMessage: null
            }
        }
        case "ACCOUNTS-MANAGEMENT:TOGGLE-SAAS-ENABLED": {
            return {
                ...state,
                saasEnabledPending: true,
                disableSaaSWarning: !payload.value
            }
        }
        case "ACCOUNTS-MANAGEMENT:TOGGLE-SAAS-ENABLED-SUCCESS": {
            return {
                ...state,
                saasEnabledPending: false,
                account: {
                    ...state.account,
                    isSaas: payload.enabled
                }
            }
        }
        case "ACCOUNTS-MANAGEMENT:TOGGLE-SAAS-ENABLED-ERROR": {
            return {
                ...state,
                saasEnabledPending: false,
                saasEnabledErrorMessage: payload.errorMessage
            }
        }
        case "ACCOUNTS-MANAGEMENT:CLEAR-TOGGLE-SAAS-ENABLED-ERROR": {
            return {
                ...state,
                saasEnabledErrorMessage: null
            }
        }
        case "ACCOUNTS-MANAGEMENT:CLEAR-DISABLE-SAAS-WARNING": {
            return {
                ...state,
                disableSaaSWarning: false
            }
        }
    }

    return state;
};
