import React, { useEffect, useState } from 'react'
import * as actions from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, CardHeader, Heading, Modal, ModalContent, ModalFooter, ModalHeader, Text, Button, ExternalLink } from "@netapp/bxp-design-system-react";
import _ from "lodash";
import style from './InviteToDemo.module.scss'

const OccmDemoDialogInternal = ({username, password, setShowCredentialsModal}) => {
    return (
    <Modal closeTrigger={()=>setShowCredentialsModal(false)}>
        <ModalHeader>
            Your demo credentials
        </ModalHeader>
        <ModalContent>
            <Heading level={4}>User Name:</Heading>
            <Text>{username}</Text>
            <Heading level={4}>Password:</Heading>
            <Text style={{marginBottom:28}}>{password}</Text>
            <ExternalLink variant="text" newTab href='mailto:neat@netapp.com?subject=Add Self&body=ng-occm-demous-users'>Register to Mailing list</ExternalLink>
        </ModalContent>
        <ModalFooter>
            <ExternalLink href={`https://console.bluexp.netapp.com/?accountId=account-j3aZttuL`}>START NOW</ExternalLink>
        </ModalFooter>
    </Modal>

)};
export default () => {
    const dispatch = useDispatch();
    const occmDemo = useSelector(state => _.get(state, "internal.occmDemo"));
    const [showCredentialsModal, setShowCredentialsModal] = useState(false);
    const {isNetAppInternal} = useSelector(state => state.auth);

    useEffect(()=>{
        if(occmDemo.status === actions.STATUS_SUCCESS){
            setShowCredentialsModal(true);
        }
    },[occmDemo])

    return <Card className={style.card}>
        <CardHeader className={style['card-header']}>
            Demo Environment
        </CardHeader>
        <CardContent>
            {(occmDemo.status === actions.STATUS_NOT_SENT || occmDemo.status === actions.STATUS_PENDING) && <div>
                <Text level={15} className={style['content-header']}>Welcome to the BlueXP simulator environment!</Text>
                <Text style={{marginBottom:42}}>This is a simulated BlueXP environment that enables you to gain experience
                    with BlueXP and to quickly demo NetApp cloud services to your customers.</Text>
                <Text style={{marginBottom:30}}>The simulation is comprehensive and allows you to test all BlueXP functionality.</Text>
                <Button className={style['register-button']} large variant="text" onClick={() => {
                    dispatch({
                        type: "INTERNAL:OCCM-DEMO"
                    })}}>
                    REGISTER TO THE DEMO ENVIRONMENT
                </Button>
                <Text>Already registered?</Text>
                <Text style={{marginBottom:12}}>From now on you can go straight to</Text>
                <div  className={style['cm-link']}><ExternalLink variant="text" newTab href={`https://console.bluexp.netapp.com/?accountId=account-j3aZttuL`}>console.bluexp.netapp.com</ExternalLink></div>
                <Text>(If you already have a NetApp account, just log in and switch to the Demo account.)</Text>
            </div>}
            {occmDemo.status === actions.STATUS_SUCCESS && <div>
                {<Text>{occmDemo.data.created ?
                    'Your new user has been created in the Demo Environment' : '' +
                    'Our records show that you already have a user in the Demo Environment'
                }. go to <ExternalLink variant="text" newTab href={`https://console.bluexp.netapp.com/?accountId=account-j3aZttuL`}>console.bluexp.netapp.com</ExternalLink>. If you already have a NetApp account, just log in and switch to the Demo account. If you require assistance, contact <ExternalLink newTab href='mailto:ng-demo-contactus@netapp.com'>ng-demo-contactus@netapp.com</ExternalLink></Text>}
                {(showCredentialsModal && isNetAppInternal) && <OccmDemoDialogInternal setShowCredentialsModal={setShowCredentialsModal} username={occmDemo.data.username} password={occmDemo.data.password} />}
            </div>}
            {occmDemo.status === actions.STATUS_ERROR && <div>
                <Text bold>Sorry, an error has occurred</Text>
                <Text>An unknown error has occurred while trying to create your Demo Environment user. Please contact <ExternalLink newTab href='mailto:ng-demo-contactus@netapp.com'>ng-demo-contactus@netapp.com</ExternalLink> for further assistance.</Text>
            </div>}
        </CardContent>
    </Card>
};