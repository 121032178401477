import React from "react";
import classNames from "classnames";
import { CloseMonochromeIconsIcon as Close } from "@netapp/bxp-design-system-react/icons/monochrome";
import {iconMapper} from "../utils/common";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import styles from "./Page.module.scss";

export const HeaderServiceInfo = ({title, flavour, className}) => {
    const FlavorIcon = iconMapper[flavour];
    return (
        <div className={classNames(styles["header-info"], className)}>
            <FlavorIcon/>
            <div className={classNames(typography.h02, styles["title"])}>{title}</div>
        </div>
    );
};

export const BaseHeader = ({ children, onClose, className }) => {
    const closable = !!onClose;
    return (
        <div className={classNames(styles["page-grid-header"], className)}>
            {children}
            {closable && <button onClick={onClose} className={styles["close-button"]}><Close/></button>}
        </div>
    );
};

export const SimpleHeader = ({children, flavour, title}) => {
    return (
        <BaseHeader>
            <div className={styles["header-content"]}>
                <HeaderServiceInfo flavour={flavour} title={title}/>
                {children}
            </div>
        </BaseHeader>
    );
};

export const BaseFooter = ({children, className}) => {
    return (
        <footer id={"form-footer"} className={classNames(styles["page-grid-footer"], className)}>
            {children}
        </footer>
    );
};

export const ContentLayout = ({children, className}) => {
    return (
        <div className={classNames(styles["page-grid-content"], className)}>
            {children}
        </div>
    );
};

export const PageLayoutNoFooter = ({children}) => {
    return (
        <div className={styles["page-grid-layout-no-footer"]}>
            {children}
        </div>
    );
};

export const PageLayout = ({children}) => {
    return (
        <div className={styles["page-grid-layout"]}>
            {children}
        </div>
    );
};


