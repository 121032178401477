import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {useWizard, ActionButton, Checkbox, useForm, Notification} from "@netapp/bxp-design-system-react";
import {ContentLayout, FooterLayout} from "../../../create-volume/Page";
import {schema, SnapshotForm} from "../../../create-volume/SnapshotPolicy";
import {snapshotPolicyMapper} from "./utils";
import styles from "../../../create-volume/SnapshotPolicy.module.scss";

export const Footer = ({volume}) => {
    const dispatch = useDispatch();
    const flavour = useSelector(state => _.get(state, `cvs.cvsFlavour`));
    const createVolumePending = useSelector(state => _.get(state, `cvs.editVolumePending`));
    const {state} = useWizard();
    const snapshotPolicyForm = state.snapshotPolicyForm || {};
    const { handleFormSubmit } = snapshotPolicyForm;

    const submitForm = e => {
        e.preventDefault();
        dispatch({
            type: `CVS:${flavour.toUpperCase()}-EDIT-VOLUME`,
            payload: { createVolumeForm: state, volumeId: volume.volumeId }
        });
    };

    return (
        <FooterLayout><ActionButton disabled={!handleFormSubmit} isSubmitting={createVolumePending} onClick={e => handleFormSubmit(submitForm)(e)}>Edit</ActionButton></FooterLayout>
    );
};

export const Content = ({volume}) => {
    const dispatch = useDispatch();
    const flavour = useSelector(state => _.get(state, `cvs.cvsFlavour`));
    const {setState, state} = useWizard();
    const currentState = state?.snapshotPolicyForm?.formState || {};
    const snapshotPolicyForm = useForm({...snapshotPolicyMapper(volume.snapshotPolicy, volume.snapshotDirectory), ...currentState}, schema);
    const editVolumeError = useSelector(state => _.get(state, `cvs.editVolumeError`));
    const editVolumeShowError = useSelector(state => _.get(state, `cvs.editVolumeShowError`));

    useEffect(() => {
        setState({snapshotPolicyForm});
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: { editVolumeError: null, editVolumeResolved: false, editVolumeShowError: false }
        });
    }, [setState, snapshotPolicyForm, dispatch]);


    const deleteError = () => {
        dispatch({
            type: "CVS:CLEAR-STATE",
            payload: { editVolumeError: null }
        });
    };

    return (
        <ContentLayout className={styles["container"]} title={"Snapshot Policy"}>
            {flavour === "aws" && <Checkbox form={snapshotPolicyForm} className={styles["show-directory-checkbox"]} name="snapshotDirectory">Show snapshot directory (read-only)</Checkbox>}
            <SnapshotForm form={snapshotPolicyForm}/>
            {editVolumeError &&
            <div className={styles["error-area"]}>
                <Notification type="error" onClose={deleteError}>{editVolumeShowError}</Notification>.
            </div>}
        </ContentLayout>
    );
};
