import _ from 'lodash';
/* eslint default-case: 0 */

const defaultState = () => (
    { loading: null }
);


export default (state = defaultState(), { payload, type }) => {
    switch (type) {
        case "FEDERATION-SETUP:RESEND-VERIFICATION-EMAIL-PENDING": {
            return {
                ...state,
                loading: true,
                resendVerificationEmailError: null,
                resendVerificationEmailSuccess: null
            };
        }
        case "FEDERATION-SETUP:RESEND-VERIFICATION-EMAIL-SUCCESS": {
            return { ...state, loading: null, resendVerificationEmailSuccess: true };
        }
        case "FEDERATION-SETUP:RESEND-VERIFICATION-EMAIL-ERROR": {
            return { ...state, resendVerificationEmailError: true, loading: null };
        }
        case "FEDERATION-SETUP:GET-CONNECTION-PENDING": {
            return { ...state, getConnectionError: null, creatorUserInfo: null, loading: true };
        }
        case "FEDERATION-SETUP:GET-CONNECTION-SUCCESS": {
            const { connection, status } = payload;
            return { ...state, connection, status, activeStrategy: _.get(connection, 'strategy'), loading: null };
        }
        case "FEDERATION-SETUP:GET-CONNECTION-ERROR": {
            return { ...state, getConnectionError: payload, loading: null };
        }
        case "FEDERATION-SETUP:CREATE-CONNECTION-PENDING": {
            return { ...state, createConnectionError: null, loading: true };
        }
        case "FEDERATION-SETUP:CREATE-CONNECTION-SUCCESS": {
            const { connection, status } = payload;
            return { ...state, connection, status, loading: null };
        }
        case "FEDERATION-SETUP:CREATE-CONNECTION-ERROR": {
            return { ...state, createConnectionError: payload, loading: null };
        }
        case "FEDERATION-SETUP:UPDATE-CONNECTION-PENDING": {
            return { ...state, updateConnectionError: null, loading: true };
        }
        case "FEDERATION-SETUP:UPDATE-CONNECTION-SUCCESS": {
            const { connection, status } = payload;
            return { ...state, connection, status, loading: null };
        }
        case "FEDERATION-SETUP:UPDATE-CONNECTION-ERROR": {
            return { ...state, updateConnectionError: payload, loading: null };
        }
        case "FEDERATION-SETUP:SET-ACTIVE-STRATEGY": {
            return { ...state, activeStrategy: payload.strategy };
        }
        case "FEDERATION-SETUP:TOGGLE-UPDATE-MODE": {
            return {
                ...state,
                status: state.status === "CONNECTION-CREATED" ? "UPDATE-CONNECTION" : "CONNECTION-CREATED"
            };
        }
        case "FEDERATION-SETUP:ENABLE-CONNECTION-PENDING": {
            return { ...state, enableConnectionError: null, loading: true };
        }
        case "FEDERATION-SETUP:ENABLE-CONNECTION-SUCCESS": {
            const { connection, status } = payload;
            return { ...state, connection, status, loading: null };
        }
        case "FEDERATION-SETUP:ENABLE-CONNECTION-ERROR": {
            return { ...state, enableConnectionError: payload, loading: null };
        }
        case "FEDERATION-SETUP:SELECT-SUPPORT-EMAIL": {
            return { ...state,getConnectionError: null, supportEmail: payload.email };
        }
        case "FEDERATION-SETUP:UPDATE-CREATOR-USER-PENDING": {
            return { ...state, changeCreatorUserPending: true, changeCreatorUserError:null };
        }
        case "FEDERATION-SETUP:UPDATE-CREATOR-USER-SUCCESS": {
            return { ...state, changeCreatorUserSuccess: true,changeCreatorUserPending: false , changeCreatorUserError:null };
        }
        case "FEDERATION-SETUP:UPDATE-CREATOR-USER-ERROR": {
            return { ...state, changeCreatorUserError: payload, changeCreatorUserPending:false };
        }
    }

    return state;
};
