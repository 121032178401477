import * as actions from "../actions";
import _ from "lodash";

/*eslint default-case: 0*/

const defaultState = () => ({
    occmDemo: {status: actions.STATUS_NOT_SENT},
    refreshToken: {status: actions.STATUS_NOT_SENT},
    integrationInstructionsOpenRow: "Introduction",
    itemForDeletion: null,
    deleteSalesItemError: null,
    deleteSalesItemPending: null,
    itemForUpdate: null,
    saveSalesContentAction: null,
    saveItemContentPending: false,
    saveItemContentError: null,
    salesItems: [],
    salesItemsFetchPending: true,
    salesItemsFetchError: null
});

const sortSalesItems = salesItems => _.orderBy(salesItems, ['serviceName', 'topic', 'updateTime'], ['asc', 'asc', 'desc']);

export default (state = defaultState(), {payload, type, status}) => {
    switch(type){
        case "INTERNAL:OCCM-DEMO": {
            switch (status){
                case actions.STATUS_SUCCESS:
                    return { ...state, occmDemo: {status: actions.STATUS_SUCCESS, data: payload.data}};
                case actions.STATUS_ERROR:
                    return { ...state, occmDemo: {status: actions.STATUS_ERROR}};
                case actions.STATUS_PENDING:
                    return { ...state, occmDemo: {status: actions.STATUS_PENDING}};
            }
            break;
        }
        case "OCCM-DEMO-RESET": {
            return { ...state, occmDemo: {status: actions.STATUS_NOT_SENT }};
        }
        case "GET-REFRESH-TOKEN": {
            switch (status) {
                case actions.STATUS_SUCCESS:
                    return {...state, refreshToken: {status: actions.STATUS_SUCCESS, data: payload.data}};
                case actions.STATUS_ERROR:
                    return {...state, refreshToken: {status: actions.STATUS_ERROR, data: payload.data}};
                case actions.STATUS_PENDING:
                    return { ...state, refreshToken: {status: actions.STATUS_PENDING}};
            }
            break;
        }
        case "INTERNAL:INTEGRATION-INSTRUCTIONS-TOGGLE-ROW": {
            let newRow = null;
            if(state.integrationInstructionsOpenRow !== payload){
                newRow = payload;
            }
            return {...state, integrationInstructionsOpenRow: newRow}
        }
        case "INTERNAL:GLOBAL-FILE-CACHE-LICENSE-REQUEST-FORM-CLEAR-SUCCESS-MESSAGE": {
            const existingId = state.licenseRequestFormSuccessMessageId;
            if(payload !== existingId) {
                return {...state}
            } else {
                return {...state, licenseRequestFormSuccessMessage: null}
            }
        }
        case "INTERNAL:GLOBAL-FILE-CACHE-LICENSE-REQUEST-FORM-PENDING": {
            return {...state, isLicenseRequestFormSaving: true, licenseRequestFormSuccessMessage: null, licenseRequestFormSaveError: null}
        }
        case "INTERNAL:GLOBAL-FILE-CACHE-LICENSE-REQUEST-FORM-SUCCESS": {
            return {...state, isLicenseRequestFormSaving: false, licenseRequestFormSuccessMessage: "Form successfully sent.", licenseRequestFormSuccessMessageId: payload}
        }
        case "INTERNAL:GLOBAL-FILE-CACHE-LICENSE-REQUEST-FORM-ERROR": {
            return {...state, isLicenseRequestFormSaving: false, licenseRequestFormSaveError: _.get(payload, "response.data.message", "Failed to send form email.")}
        }
        case "INTERNAL:OPEN-MODAL-SALES-CONTENT": {
            const { saveSalesContentAction, itemForUpdate } = payload;
            return { ...state, saveSalesContentAction, itemForUpdate };
        }
        case "INTERNAL:CLOSE-MODAL-SALES-CONTENT": {
            return { ...state, saveSalesContentAction: null, itemForUpdate: null, saveItemContentPending: false, saveItemContentError: null };
        }
        case "INTERNAL:SAVE-SALES-ITEM-CONTENT": {
            const { itemForUpdate } = payload;
            return { ...state, itemForUpdate, saveItemContentPending: true };
        }
        case "INTERNAL:SAVE-SALES-ITEM-CONTENT-SUCCESS": {
            const { newSalesItem } = payload;
            const saveSalesContentAction = state.saveSalesContentAction;
            const salesItems = state.salesItems;
            let sortedItems;
            if (saveSalesContentAction === "update") {
                sortedItems = _.map(salesItems, item => item.id === newSalesItem.id ? newSalesItem : item);
            } else {
                sortedItems = sortSalesItems([...salesItems, newSalesItem]);
            }
            return { ...state, saveSalesContentAction: null, modalSalesItemState: null, salesItems: sortedItems, saveItemContentPending: false, saveItemContentError: null };
        }
        case "INTERNAL:SAVE-SALES-ITEM-CONTENT-FAILURE": {
            const { saveItemContentError } = payload;
            return { ...state, modalSalesItemState: null, saveItemContentError, saveItemContentPending: false };
        }
        case "INTERNAL:OPEN-MODAL-DELETE-SALES-ITEM": {
            const { itemForDeletion } = payload;
            return {...state, itemForDeletion }
        }
        case "INTERNAL:CLOSE-MODAL-DELETE-SALES-ITEM": {
            return { ...state, itemForDeletion: null }
        }
        case "INTERNAL:DELETE-SALES-ITEM": {
            return { ...state, deleteSalesItemError: null, deleteSalesItemPending: true };
        }
        case "INTERNAL:DELETE-SALES-ITEM-SUCCESS": {
            const { itemForDeletion, salesItems } = state;
            const newSalesItems = _.reject(salesItems, item => item.id === itemForDeletion.id);
            return { ...state, deleteSalesItemError: null, deleteSalesItemPending: false, salesItems: newSalesItems, itemForDeletion: null };
        }
        case "INTERNAL:DELETE-SALES-ITEM-FAILURE": {
            const { deleteSalesItemError } = payload;
            return { ...state, deleteSalesItemError, deleteSalesItemPending: false };
        }
        case "INTERNAL:FETCH-SALES-ITEMS": {
            return {...state, salesItemsFetchPending: true }
        }
        case "INTERNAL:FETCH-SALES-ITEMS-SUCCESS": {
            const { salesItems } = payload;
            const sortedItems = sortSalesItems(salesItems);
            return {...state, salesItems: sortedItems, salesItemsFetchPending: false }
        }
        case "INTERNAL:FETCH-SALES-ITEMS-FAILURE": {
            const { salesItemsFetchError } = payload;
            return {...state, salesItemsFetchError, salesItemsFetchPending: false }
        }
    }
    return state;
};
