import axios from "axios"
import links from "./../../consts/links"
import _ from "lodash";
import store from "../../store";
import * as actions from "../../actions";

export default ({getState, dispatch, next, action}) => {
    const {type, payload} = action;

    if (type === "LAUNCHPAD:UPDATE-OCCM-URIS") {
        const {occmId, uris: redirectUris, accountId} = payload;
        const promise = axios.post("/occm/manual-update-uris", {
            occmId,
            redirectUris,
            accountId
        });

        promise.then(
            r => dispatch({type: "LAUNCHPAD:UPDATE-OCCM-URIS-SUCCESS", payload: r}),
            e => dispatch({type: "LAUNCHPAD:UPDATE-OCCM-URIS-ERROR", payload: e}));

        return next({type: "LAUNCHPAD:UPDATE-OCCM-URIS-PENDING"});
    } else if (type === "LAUNCHPAD:REMOVE-OCCM") {
        const {occmId, accountId} = payload;
        let promise = null;

        if(accountId) {
            promise = axios.delete(`${links.launchPad.services.agentsManagementBackend}/agent/${occmId}clients`, {
                headers: {
                    'X-User-Token': axios.defaults.headers.common['Authorization'],
                    'X-Tenancy-Account-Id': accountId
                }, data: {
                    occmId,
                    accountId
                }});
        } else {
            promise =  axios.post("/occm/deassociate-self", {
                occmId,
                accountId
            });
        }

        promise.then(
            r => dispatch({type: "LAUNCHPAD:REMOVE-OCCM-SUCCESS", payload: r}),
            e => dispatch({type: "LAUNCHPAD:REMOVE-OCCM-ERROR", payload: e}));

        return next({type: "LAUNCHPAD:REMOVE-OCCM-PENDING"});
    } else if (type === "LAUNCHPAD:FETCH-CLOUD-SYNC-USER-INFO") {
        const selectedAccount = getState().auth.userAccount;
        if(selectedAccount) {
            const promise = axios.get(links.launchPad.services.cloudSyncBackend + "/api/accounts/info?noCreate=true", {
                headers: {
                    "x-account-id": selectedAccount.value
                }
            });

            promise.then(
                r => dispatch({type: "LAUNCHPAD:FETCH-CLOUD-SYNC-USER-INFO-SUCCESS", payload: r}),
                e => dispatch({type: "LAUNCHPAD:FETCH-CLOUD-SYNC-USER-INFO-ERROR", payload: e}));

            return next({type: "LAUNCHPAD:FETCH-CLOUD-SYNC-USER-INFO-PENDING"});
        } else {
            setTimeout(() => {
                dispatch({type: "LAUNCHPAD:FETCH-CLOUD-SYNC-USER-INFO-ERROR", payload: ""})
            });
        }

        return next({type: "LAUNCHPAD:FETCH-CLOUD-SYNC-USER-INFO-PENDING"});
    } else if (type === "LAUNCHPAD:FETCH-CLOUD-INSIGHT-USER-INFO") {
        const promise = axios.get(links.launchPad.services.cloudInsightsBackend + "/rest/v1/user");

        promise.then(
            r => dispatch({type: "LAUNCHPAD:FETCH-CLOUD-INSIGHT-USER-INFO-SUCCESS", payload: r}),
            e => dispatch({type: "LAUNCHPAD:FETCH-CLOUD-INSIGHT-USER-INFO-ERROR", payload: e}));

        return next({type: "LAUNCHPAD:FETCH-CLOUD-INSIGHT-USER-INFO-PENDING"});
    } else if (type === "LAUNCHPAD:FETCH-CLOUD-VOLUMES-SERVICE-AWS-USER-INFO") {
        if(_.get(store.getState(), "features.active.cloudVolumesRegisteredQuery", false)){
            const promise = axios.get(links.launchPad.services.cloudVolumesServiceAwsBackend + "/api/cloud-volumes/account-status");

            promise.then(
                r => dispatch({type: "LAUNCHPAD:FETCH-CLOUD-VOLUMES-SERVICE-AWS-USER-INFO-SUCCESS", payload: r}),
                e => dispatch({type: "LAUNCHPAD:FETCH-CLOUD-VOLUMES-SERVICE-AWS-USER-INFO-ERROR", payload: e}));

            return next({type: "LAUNCHPAD:FETCH-CLOUD-VOLUMES-SERVICE-AWS-USER-INFO-PENDING"});
        } else {
            dispatch({type: "LAUNCHPAD:FETCH-CLOUD-VOLUMES-SERVICE-AWS-USER-INFO-SUCCESS", payload: {
                data: {
                    registered: true
                }
            }})
        }
    } else if (type === "LAUNCHPAD:LOAD-SERVICE-DEFAULT-STATES") {
        const activeFeatures = _.get(store.getState(), "features.active", {});

        return next({
            type: "LAUNCHPAD:LOAD-SERVICE-DEFAULT-STATES",
            payload: {
                cloudCompliance: {status: actions.STATUS_SUCCESS, text: "Go to Cloud Data Sense", serviceKey: "cloudCompliance"},
                cloudSecure: {status: actions.STATUS_SUCCESS, text: "Register for Preview", serviceKey: activeFeatures.cloudSecureLink ? "cloudSecure" : "cloudSecurePreview"},
                cloudTiering: {status: actions.STATUS_SUCCESS, text: "Setup Tiering with BlueXP", serviceKey: "cloudTiering"},
                virtualDesktopService: {status: actions.STATUS_SUCCESS, text: "Go to Virtual Desktop Service", serviceKey: "virtualDesktopService"},
                cloudBackup: {status: actions.STATUS_SUCCESS, text: "Go to Cloud Backup", serviceKey: "cloudBackup"},
                astra: {status: actions.STATUS_SUCCESS, text: "Go to Astra", serviceKey: "astra"}
            }
        })
    }


    return next(action);
};
