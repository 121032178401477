import React from "react";
import connect from "react-redux/es/connect/connect";
import { Modal, ModalContent, ModalFooter, ModalHeader, Button, Text, BulletList, HorizontalInnerNav, CodeSnippet } from "@netapp/bxp-design-system-react";
import { Link } from "react-router-dom"

const authBodyFederationJsonText =
`{
    "grant_type": "refresh_token",
    "refresh_token": "YOUR_REFRESH_TOKEN",
    "client_id": "${window.__auth__.refreshTokenClientId}"
}`;

const authBodyRegularJsonText = {
    grant_type: "password",
    username: "YOUR_EMAIL_ADDRESS",
    password: "YOUR_PASSWORD",
    audience: window.__auth__.audience,
    client_id: window.__auth__.clientId
};

const AuthenticationDialog = ({closeTrigger, dialogState, switchToRefreshTokenState, switchToAuthenticationState}) => {

     return <Modal>
        <ModalHeader>
            Authentication Information
        </ModalHeader>
        <ModalContent>
            <Text>
                NetApp Cloud Central Services use OAuth 2.0, an industry-standard protocol, for authorization.
            </Text>
            <Text>
                Communicating with an authenticated endpoint is a two step-process.
            </Text>
            <BulletList>
                <>Acquire a JWT access token from the OAuth token endpoint.</>
                <>Call an API endpoint with the JWT access token.</>
            </BulletList>
            <Text>Non-federated users can use regular access or refresh token access, federated users must use refresh token access.</Text>
            <HorizontalInnerNav>
                <button isActive={dialogState === "AUTHENTICATION"} disabled={dialogState === "AUTHENTICATION"} onClick={switchToAuthenticationState}>Regular Access</button>
                <button isActive={dialogState === "REFRESH-TOKEN"} style={{ width: "initial", padding: "0 16px" }} disabled={dialogState === "REFRESH-TOKEN"} onClick={switchToRefreshTokenState}>Refresh Token Access (Required for federated users)</button>
            </HorizontalInnerNav>
            {dialogState === "AUTHENTICATION" && <div>
                <h4>How to Acquire a JWT Access Token via regular token access</h4>
                <ol>
                    <li>
                        <div>Make an HTTP POST request to the endpoint <pre style={{display: "inline-block"}}>https://{window.__auth__.domain}/oauth/token</pre></div>
                        <div>Include the header <pre style={{display: "inline-block"}}>Content-Type: application/json</pre></div>
                        <div>Include the body:</div>
                        <CodeSnippet text={authBodyRegularJsonText} highLightLanguage="json"/>
                    </li>
                    <li>Extract the <pre style={{display: "inline-block"}}>access_token</pre> from the response</li>
                </ol>
                <Text style={{marginLeft: 25}}>Note: Certain applications may require a different audience or different clientID. Please refer to each service's specific API documentation for more information.</Text>
            </div>}
            { dialogState === "REFRESH-TOKEN" &&<div>
                <h4>How to Acquire a JWT Access Token via refresh token access (Required for federated users)</h4>
                <ol>
                    <li>Generate a long-lived refresh token from the <Link to="/refresh-token">Refresh Token Generator</Link></li>
                    <li>
                        <div>Make an HTTP POST request to the endpoint <pre style={{display: "inline-block"}}>https://{window.__auth__.domain}/oauth/token</pre></div>
                        <div>Include the header: <pre style={{display: "inline-block"}}>Content-Type: application/json</pre></div>
                        <div>Include the body:</div>
                        <CodeSnippet text={authBodyFederationJsonText} highLightLanguage="json"/>
                    </li>
                    <li>Extract the <pre style={{display: "inline-block"}}>access_token</pre> from the response</li>
                </ol>
            </div>}
            <h4>How to use a JWT Access Token</h4>
            <ol>
                <li>
                    Include the following header in all calls to an authenticated endpoint. <pre style={{display: "inline-block"}}>Authorization: Bearer YOUR_TOKEN</pre>
                </li>
            </ol>
        </ModalContent>
        <ModalFooter>
            <Button color="secondary" onClick={closeTrigger}>OK</Button>
        </ModalFooter>
    </Modal>;
};

const mapStateToProps = state => {
    return {
        dialogState: state.developerHub.authenticationDialogState
    };
};

const mapDispatchToProps = dispatch => {
    return {
        switchToRefreshTokenState: () => {
            dispatch({
                type: "DEVELOPER-HUB:SWITCH-STATE-REFRESH-TOKEN"
            })
        },
        switchToAuthenticationState: () => {
            dispatch({
                type: "DEVELOPER-HUB:SWITCH-STATE-AUTHENTICATION"
            })
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationDialog)