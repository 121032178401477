import React from "react";
import classNames from "classnames";
import { ErrorMonochromeIconsIcon as Error } from '@netapp/bxp-design-system-react/icons/monochrome';
import {NoticeIcon as Warning} from "@netapp/bxp-design-system-react/icons/monochrome";
import {SuccessCircleIcon as Success} from "@netapp/bxp-design-system-react/icons/monochrome";
import styles from "./InlineNotification.module.scss";

const iconTypeMapper = {
    error: { icon: Error },
    urgent: { icon: Error },
    warning: { icon: Warning },
    success: { icon: Success },
    information: { icon: Warning }
};

export default ({text, type, className}) => {
    const Icon = iconTypeMapper[type].icon;
    return (
        <div className={classNames(styles["container"], styles[type], className)}>
            <Icon className={styles["type-icon"]}/><span title={text}>{text}</span>
        </div>
    );
};
