import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import _ from "lodash";
import * as yup from "yup";
import {useWizard, FormFieldInputNew, FormFieldSelect, ActionButton, useForm, Notifications, Popover} from "@netapp/bxp-design-system-react";
import {ContentLayout, FooterLayout} from "./Page";
import {RadioButtonCard} from "../RadioButtonWidget";
import {serviceLevelOptions, capacityPoolNameRegex, getAzureProps} from "../../utils/azure";
import commonStyles from "../../styles/common.module.scss";
import styles from "./CapacityPoolAzure.module.scss";

const widgetButtons = [
    { label: "Select existing capacity pool ", name: "poolOption", value: "existing" },
    { label: "Create new capacity pool", name: "poolOption", value: "new" }
];

export const Footer = () => {
    const dispatch = useDispatch();
    const createCapacityPoolPending = useSelector(state => _.get(state, "cvs.createCapacityPoolPending"));
    const {setStep, state} = useWizard();
    const capacityPoolForm = state.capacityPoolForm || {};
    const { formState, handleFormSubmit } = capacityPoolForm;
    const { subscriptionId, location, netappAccountName, resourceGroupName } = getAzureProps(state);

    useEffect(() => {
        return () => dispatch({
            type:"CVS:CLEAR-STATE",
            payload:{
                azureCapacityPool: {},
                createCapacityPoolResolved: false,
                createCapacityPoolError: null,
                createCapacityPoolShowError: false
            }
        });
    }, [dispatch]);

    const submitForm = () => {
        if (formState.poolOption === "existing") {
            setStep("details-and-tags");
        } else {
            dispatch({
                type: "CVS:AZURE-CREATE-CAPACITY-POOL",
                payload: {
                    subscriptionId,
                    location,
                    netappAccountName,
                    resourceGroupName,
                    capacityPoolName: formState.poolName,
                    sizeInTiB: Number(formState.sizeInTiB),
                    serviceLevel: formState.serviceLevel.value,
                    onSuccess: () => setStep("details-and-tags")
                }
            });
        }
    };

    return (
        <FooterLayout><ActionButton disabled={!handleFormSubmit} isSubmitting={createCapacityPoolPending} onClick={e => handleFormSubmit(submitForm)(e)}>Continue</ActionButton></FooterLayout>
    );
};

const initialState = {
    poolOption: "existing", // existing or new
    capacityPool: null,
    poolName: "",
    sizeInTiB: "",
    serviceLevel: serviceLevelOptions[0]
};

const schema = yup.object().shape({
    poolOption: yup.string().oneOf(["existing", "new"]),
    capacityPool: yup.object().nullable().when("poolOption", { is: "existing", then: yup.object().required("Capacity pool selection is required.")}),
    poolName: yup.string().when("poolOption", { is: "new", then: yup.string().matches(capacityPoolNameRegex, "Invalid capacity pool name.").required("Capacity pool name is required.")}),
    sizeInTiB: yup.string().test('pool-size', null, function () {
        if (this.parent.poolOption === "existing") {
            return true;
        }
        if (_.isEmpty(this.parent.sizeInTiB)) {
            return new yup.ValidationError('Capacity pool size is required.', null, 'sizeInTiB');
        } else {
            const sizeInt = Number(this.parent.sizeInTiB);
            if (isNaN(sizeInt)) {
                return new yup.ValidationError('Size must be a number.', null, 'sizeInTiB');
            } else {
                if (!Number.isInteger(sizeInt)) {
                    return new yup.ValidationError('Size must be an integer.', null, 'sizeInTiB');
                } else if (sizeInt < 4) {
                    return new yup.ValidationError('Size must be >= 4 TiB.', null, 'sizeInTiB');
                } else {
                    return true;
                }
            }
        }
    }),
    serviceLevel: yup.object(),
});

const NewPool = ({form}) => {
    return (
        <div className={styles["new-capacity-pool-container"]}>
            <div className={styles["name-and-size"]}>
                <FormFieldInputNew
                    form={form}
                    name="poolName"
                    label="Capacity Pool Name"
                    maxChars={256}
                    autoFocus={true}
                    className={classNames(commonStyles["form-field"], styles["pool-name"])}
                />
                <FormFieldInputNew
                    form={form}
                    name="sizeInTiB"
                    label="Size (TiB)"
                    placeholder=">=4 TiB"
                    maxChars={256}
                    className={classNames(commonStyles["form-field"], styles["pool-size"])}
                    labelChildren={<Popover>The minimum capacity pool size is 4 TiB. You can create a pool with a size that is multiples of 4 TiB</Popover>}
                />
            </div>
            <FormFieldSelect
                form={form}
                name="serviceLevel"
                label="Service Level"
                options={serviceLevelOptions}/>
        </div>
    );
};

const ExistingPool = ({form}) => {
    const dispatch = useDispatch();
    const {state} = useWizard();
    const pools = useSelector(state => _.get(state, "cvs.capacityPools"));
    const getPoolsPending = useSelector(state => _.get(state, "cvs.getCapacityPoolsPending"));
    const { subscriptionId, netappAccountName, resourceGroupName } = getAzureProps(state);

    useEffect(() => {
        dispatch({
            type: "CVS:AZURE-GET-CAPACITY-POOLS",
            payload: {
                subscriptionId,
                resourceGroupName,
                netappAccountName
            }
        });
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: {
                capacityPools: [],
                getCapacityPoolsResolved: false,
                getCapacityPoolsError: null,
                getCapacityPoolsShowError: false
            }
        });
    }, [dispatch, subscriptionId, netappAccountName, resourceGroupName]);

    return (
        <FormFieldSelect
            form={form}
            name="capacityPool"
            label="Capacity Pool"
            placeholder="- Select a capacity pool -"
            options={pools}
            isLoading={getPoolsPending}
            className={classNames(commonStyles["form-field"], styles["existing-pool"])}
        />
    );
};

export const Content = () => {
    const dispatch = useDispatch();
    const {setState, state} = useWizard();
    const currentState = state?.capacityPoolForm?.formState || {};
    const capacityPoolForm = useForm({...initialState, ...currentState }, schema);
    const poolOption = capacityPoolForm.formState.poolOption;
    const getPoolsError = useSelector(state => _.get(state, "cvs.getCapacityPoolsError"));
    const getPoolsShowError = useSelector(state => _.get(state, "cvs.getCapacityPoolsShowError"));
    const createPoolError = useSelector(state => _.get(state, "cvs.createCapacityPoolError"));
    const createPoolShowError = useSelector(state => _.get(state, "cvs.createCapacityPoolShowError"));

    useEffect(() => {
        setState({capacityPoolForm});
    }, [setState, capacityPoolForm]);

    const onPoolSelection = e => {
        if (!_.isEqual(e.target.value, capacityPoolForm.formState.poolOption)) {
            capacityPoolForm.resetSubmissionAttempted();
            if (e.target.value === "existing") {
                capacityPoolForm.resetForm({ ...initialState, poolName: capacityPoolForm.formState.poolName, sizeInTiB: capacityPoolForm.formState.sizeInTiB });
            } else {
                capacityPoolForm.resetForm({ ...capacityPoolForm.formState, capacityPool: null, poolOption: "new" });
            }
        }
    };

    const notifications = [{
        type: "error",
        description: getPoolsError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { getCapacityPoolsShowError: false }}),
        showError: getPoolsShowError
    }, {
        type: "error",
        description: createPoolError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { createCapacityPoolShowError: false }}),
        showError: createPoolShowError
    }];

    return (
        <ContentLayout className={styles["container"]} title={"Capacity Pool"}>
            <RadioButtonCard
                onChange={onPoolSelection}
                name={"poolOption"}
                checkedValue={poolOption}
                buttons={widgetButtons}
                widgetLabel="Choose a capacity pool:"/>
            {poolOption === "new" ? <NewPool form={capacityPoolForm}/> : <ExistingPool form={capacityPoolForm}/>}
            <Notifications className={commonStyles["error-area"]} notifications={_.filter(notifications, { showError: true })}/>
        </ContentLayout>
    );
};
