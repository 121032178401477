import React from "react";
import classNames from "classnames";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import styles from "./ContentLayout.module.scss";

export const Title = ({children, className}) => {
    return (
        <div className={classNames(typography.h02, styles["title"], className)}>{children}</div>
    );
};

export default ({children, className}) => {
    return (
        <div className={classNames(styles["content"], className)}>
            {children}
        </div>
    );
};
