import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import * as yup from "yup";
import classNames from "classnames";
import {useWizard, ActionButton, useForm, FormFieldInputNew, FormFieldSelect, Popover, Notifications} from "@netapp/bxp-design-system-react";
import {FooterLayout, ContentLayout} from "./Page";
import {gcpRegions, serviceLevelOptions} from "../../utils/gcp";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import commonStyles from "../../styles/common.module.scss";
import styles from "./DetailsAndTags.module.scss";

export const Footer = () => {
    const dispatch = useDispatch();
    const getActiveDirectoryPending = useSelector(state => _.get(state, "cvs.getActiveDirectoryPending"));
    const {setStep, state} = useWizard();
    const detailsAndTagsForm = state.detailsAndTagsForm || {};
    const { formState, handleFormSubmit } = detailsAndTagsForm;

    const submitForm = () => {
        dispatch({
            type: "CVS:GCP-GET-ACTIVE-DIRECTORY",
            payload: { region: formState.region.value, onSuccess: () => setStep("protocol")}
        });
    };

    return (
        <FooterLayout><ActionButton isSubmitting={getActiveDirectoryPending} disabled={!handleFormSubmit} onClick={e => handleFormSubmit(submitForm)(e)}>Continue</ActionButton></FooterLayout>
    );
};

const initialState =  {
    volumeName: "",
    sizeInGiB: "",
    serviceLevel: serviceLevelOptions[0],
    region: null,
    vpc: null
};

export const schema = yup.object().shape({
    vpc: yup.object().nullable().required("VPC network selection is required."),
    region: yup.object().nullable().required("Region selection is required."),
    volumeName: yup.string().required("volume name is required."),
    sizeInGiB: yup.string().test('vol-size', null, function () {
        if (_.isEmpty(this.parent.sizeInGiB)) {
            return new yup.ValidationError('Volume size is required.', null, 'sizeInGiB');
        } else {
            const sizeInt = Number(this.parent.sizeInGiB);
            if (isNaN(sizeInt)) {
                return new yup.ValidationError('Size must be a number.', null, 'sizeInGiB');
            } else {
                if (!Number.isInteger(sizeInt)) {
                    return new yup.ValidationError('Size must be an integer.', null, 'sizeInGiB');
                } else if (sizeInt < 1024) {
                    return new yup.ValidationError('Size must be >= 1024 GiB.', null, 'sizeInGiB');
                } else if (sizeInt > 102400) {
                    return new yup.ValidationError('Size must be >= 102400 GiB.', null, 'sizeInGiB');
                }else {
                    return true;
                }
            }
        }
    }),
    serviceLevel: yup.object(),
});


export const Content = () => {
    const dispatch = useDispatch();
    const {setState, state} = useWizard();
    const currentState = state?.detailsAndTagsForm?.formState || {};
    const detailsAndTagsForm = useForm({...initialState, ...currentState, vpc: null, region: null }, schema);
    const vNetOptions = useSelector(state => _.get(state, "cvs.virtualNetworks"));
    const getVirtualNetworksPending = useSelector(state => _.get(state, "cvs.getVirtualNetworksPending"));
    const getVirtualNetworksError = useSelector(state => _.get(state, "cvs.getVirtualNetworksError"));
    const getVirtualNetworksShowError = useSelector(state => _.get(state, "cvs.getVirtualNetworksShowError"));
    const getActiveDirectoryError = useSelector(state => _.get(state, "cvs.getActiveDirectoryError"));
    const getActiveDirectoryShowError = useSelector(state => _.get(state, "cvs.getActiveDirectoryShowError"));

    useEffect(() => {
        setState({detailsAndTagsForm});
    }, [detailsAndTagsForm, setState]);

    useEffect(() => {
        dispatch({
            type: "CVS:GCP-GET-VIRTUAL-NETWORKS"
        });
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: {
                virtualNetworks: [],
                getVirtualNetworksResolved: false,
                getVirtualNetworksError: null,
                getVirtualNetworksShowError: false
            }
        });
    }, [dispatch]);

    const notifications = [{
        type: "error",
        description: getVirtualNetworksError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { getVirtualNetworksShowError: false }}),
        showError: getVirtualNetworksShowError
    }, {
        type: "error",
        description: getActiveDirectoryError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { getActiveDirectoryShowError: false }}),
        showError: getActiveDirectoryShowError
    }];

    return (
        <ContentLayout className={styles["container"]} title={"Details & Location"}>
            <div className={styles["content-grid"]}>
                <div className={styles["details"]}>
                    <div className={typography.body16}>Details</div>
                    <div>
                        <div className={styles["name-and-size"]}>
                            <FormFieldInputNew
                                form={detailsAndTagsForm}
                                name="volumeName"
                                label="Volume Name"
                                className={classNames(commonStyles["form-field"], styles["volume-name"])}
                                autoFocus={true}
                                maxChars={255}/>
                            <FormFieldInputNew
                                form={detailsAndTagsForm}
                                name="sizeInGiB"
                                label="Size (GiB)"
                                placeholder=">=1024"
                                maxChars={256}
                                className={classNames(commonStyles["form-field"], styles["volume-size"])}
                                labelChildren={<Popover>Volume size should be greater then or equal to 1024 GiB and smaller then or equal to 102400 GiB</Popover>}
                            />
                        </div>
                        <FormFieldSelect
                            form={detailsAndTagsForm}
                            name="serviceLevel"
                            label="Service level"
                            placeholder="- Select a service level -"
                            options={serviceLevelOptions}
                            className={classNames(commonStyles["form-field"])}
                            labelChildren={<Popover>This is the target performance for the volume resource that is being created</Popover>}
                        />
                    </div>
                </div>
                <div className={styles["tags"]}>
                    <div className={typography.body16}>Location</div>
                    <div>
                        <FormFieldSelect
                            form={detailsAndTagsForm}
                            isSearchable={true}
                            name="region"
                            label="Region"
                            placeholder="- Select a region -"
                            options={gcpRegions}
                            className={classNames(commonStyles["form-field"], styles["region"])}
                        />
                        <FormFieldSelect
                            form={detailsAndTagsForm}
                            isSearchable={true}
                            name="vpc"
                            label="VPC Network"
                            placeholder="- Select a vpc -"
                            options={vNetOptions}
                            isLoading={getVirtualNetworksPending}
                        />
                    </div>
                </div>
            </div>
            <Notifications className={commonStyles["error-area"]} notifications={_.filter(notifications, { showError: true })}/>
        </ContentLayout>
    );
};
