import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import _ from "lodash";
import * as yup from "yup";
import {useWizard, RadioButton, FormFieldInputNew, FormFieldSelect, ActionButton, useForm, Notifications, Popover} from "@netapp/bxp-design-system-react";
import {ContentLayout, FooterLayout} from "./Page";
import {RadioButtonCard} from "../RadioButtonWidget";
import InlineNotification from "../InlineNotification";
import {azureLocations, netappAccountRegex, isResourceGroupNameValid} from "../../utils/azure";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import commonStyles from "../../styles/common.module.scss";
import styles from "./NetappAccountAzure.module.scss";

const widgetButtons = [
    { label: "Select existing account", name: "accountOption", value: "existing" },
    { label: "Create new account", name: "accountOption", value: "new" }
];

export const Footer = () => {
    const dispatch = useDispatch();
    const getActiveDirectoryPending = useSelector(state => _.get(state, "cvs.getActiveDirectoryPending"));
    const createAccountPending = useSelector(state => _.get(state, "cvs.createNetappAccountPending"));
    const {setStep, state} = useWizard();
    const netappAccountForm = state.netappAccountForm || {};
    const { formState, handleFormSubmit } = netappAccountForm;

    useEffect(() => {
        return () => dispatch({
            type:"CVS:CLEAR-STATE",
            payload:{
                netappAccount: {},
                createNetappAccountResolved: false,
                createNetappAccountError: null,
                createNetappAccountShowError: false,
                getActiveDirectoryResolved: false,
                getActiveDirectoryError: null,
                getActiveDirectoryShowError: false
            }
        });
    }, [dispatch]);

    const submitForm = () => {
        if (formState.accountOption === "existing") {
            dispatch({
                type: "CVS:AZURE-GET-ACTIVE-DIRECTORY",
                payload: {
                    subscriptionId: formState.netappAccount.subscriptionId,
                    netappAccountName: formState.netappAccount.name,
                    resourceGroupName: formState.netappAccount.resourceGroupName,
                    onSuccess: () => setStep("capacity-pool")
                }
            });
        } else {
            dispatch({
                type: "CVS:AZURE-CREATE-NETAPP-ACCOUNT",
                payload: {
                    subscriptionId: formState.subscription.subscriptionId,
                    location: formState.location.value,
                    accountName: formState.accountName,
                    resourceGroupName: formState.resourceGroupOption === "new" ? formState.resourceGroupName : formState.resourceGroup.value,
                    onSuccess: () => setStep("capacity-pool")
                }
            });
        }
    };

    return (
        <FooterLayout><ActionButton disabled={!handleFormSubmit} isSubmitting={getActiveDirectoryPending || createAccountPending} onClick={e => handleFormSubmit(submitForm)(e)}>Continue</ActionButton></FooterLayout>
    );
};

const initialState = {
    accountOption: "existing", // existing or new
    resourceGroupOption: "existing", // existing or new
    netappAccount: null,
    accountName: "",
    subscription: null,
    location: null,
    resourceGroupName: "",
    resourceGroup: null,
};

const schema = yup.object().shape({
    accountOption: yup.string().oneOf(["existing", "new"]),
    resourceGroupOption: yup.string().oneOf(["existing", "new"]),
    netappAccount: yup.object().nullable().when("accountOption", { is: "existing", then: yup.object().required("Account selection is required.") }),
    accountName: yup.string().when("accountOption", { is: "new", then: yup.string().matches(netappAccountRegex, "Invalid account name.").required("Account name is required.") }),
    subscription: yup.object().nullable().when("accountOption", { is: "new", then: yup.object().required("Subscription selection is required.") }),
    location: yup.object().nullable().when("accountOption", { is: "new", then: yup.object().required("Location selection is required.") }),
    resourceGroup: yup.object().nullable().test('existing-rg', null, function () {
        if (this.parent.accountOption === "existing" || this.parent.resourceGroupOption === "new") {
            return true;
        }
        if (this.parent.resourceGroupOption === "existing" && !_.isEmpty(this.parent.resourceGroup)) {
            return true;
        }
        return new yup.ValidationError(
            'Resource Group selection is required.',
            null,
            'resourceGroup'
        );
    }),
    resourceGroupName: yup.string(),
}).test('new-rg-name', null, obj => {
    if (obj.accountOption === "existing" || obj.resourceGroupOption === "existing") {
        return true;
    }
    if (obj.resourceGroupOption === "new") {
        if (_.isEmpty(obj.resourceGroupName)) {
            return new yup.ValidationError(
                'Resource group name is required.',
                null,
                'resourceGroupName'
            );
        } else {
            if (!isResourceGroupNameValid(obj.resourceGroupName)) {
                return new yup.ValidationError(
                    'Invalid resource group name.',
                    null,
                    'resourceGroupName'
                );
            } else {
                return true;
            }
        }
    }
});

const ExistingResourceGroup = ({form}) => {
    const resourceGroups = useSelector(state => _.get(state, "cvs.resourceGroups"));
    const getResourceGroupsPending = useSelector(state => _.get(state, "cvs.getResourceGroupsPending"));

    return (
        <FormFieldSelect
            form={form}
            name="resourceGroup"
            label="Resource Group"
            placeholder="- Select a resource group -"
            options={resourceGroups}
            disabled={_.isNil(form.formState.location) || _.isNil(form.formState.subscription)}
            isLoading={getResourceGroupsPending}
            className={commonStyles["form-field"]}
            labelChildren={<Popover>Before you can create or select a resource group, you need to choose an Azure subscription and location</Popover>}
        />
    );
};

const CreateResourceGroup = ({form}) => {
    const dispatch = useDispatch();
    const createResourceGroupError = useSelector(state => _.get(state, "cvs.createResourceGroupError"));
    const createResourceGroupPending = useSelector(state => _.get(state, "cvs.createResourceGroupPending"));
    const createResourceGroupResolved = useSelector(state => _.get(state, "cvs.createResourceGroupResolved"));

    useEffect(() => {
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: { createResourceGroupResolved: false, createResourceGroupError: null, createResourceGroupShowError: false }
        });
    }, [dispatch]);

    const createNewResourceGroup = () => {
        dispatch({
            type: "CVS:AZURE-CREATE-RESOURCE-GROUP",
            payload: { subscriptionId: form.formState.subscription.value, location: form.formState.location.value, resourceGroupName: form.formState.resourceGroupName }
        });
    };

    return (
        <div className={styles["create-resource-group-field"]}>
            <FormFieldInputNew
                form={form}
                name="resourceGroupName"
                label="Resource Group Name"
                maxChars={256}
                style={{ marginTop: 19 }}
                disabled={_.isNil(form.formState.location) || _.isNil(form.formState.subscription)}
                labelChildren={<Popover>Before you can create or select a resource group, you need to choose an Azure subscription and location</Popover>}
            />
            <ActionButton
                disabled={_.isEmpty(form.formState.resourceGroupName) || _.isEmpty(form.formState.location) || _.isEmpty(form.formState.subscription) || (createResourceGroupResolved && !createResourceGroupError)}
                isSubmitting={createResourceGroupPending}
                onClick={createNewResourceGroup}
                className={styles["create-resource-group-field-button"]}>Create</ActionButton>
            {createResourceGroupResolved && <InlineNotification className={styles["create-rg-notification"]} type={createResourceGroupError ? "error" : "success"} text={createResourceGroupError ? createResourceGroupError : "Resource Group was created successfully"}>{createResourceGroupError ? createResourceGroupError : "Resource Group was created successfully"}</InlineNotification>}
        </div>
    );
};

const NewAccount = ({form}) => {
    const dispatch = useDispatch();
    const subscriptions = useSelector(state => _.get(state, "cvs.subscriptions"));
    const getSubscriptionsPending = useSelector(state => _.get(state, "cvs.getSubscriptionsPending"));

    useEffect(() => {
        dispatch({
            type: "CVS:AZURE-GET-SUBSCRIPTIONS"
        });
        return ()=>{
            dispatch({
                type:"CVS:CLEAR-STATE",
                payload:{
                    getResourceGroupsResolved: false,
                    getResourceGroupsError: null,
                    getResourceGroupsShowError: false,
                    getSubscriptionsResolved: false,
                    getSubscriptionsError: null,
                    getSubscriptionsShowError: false,
                }
            });
        }
    }, [dispatch]);

    const onSubscriptionSelected = option => {
        if (!_.isEqual(option, form.formState.subscription)) {
            form.resetForm({ ...form.formState, resourceGroup: null, subscription: option });
            if (!_.isNil(option)) {
                dispatch({
                    type: "CVS:AZURE-GET-RESOURCE-GROUPS",
                    payload: { subscriptionId: option.value }
                });
            }
        }
    };

    const onRgOptionSelection = e => {
        if (!_.isEqual(e.target.value, form.formState.resourceGroupOption)) {
            if (e.target.value === "new") {
                form.resetForm({ ...form.formState, resourceGroup: null, resourceGroupOption: e.target.value });
            } else {
                form.resetForm({ ...form.formState, resourceGroupName: "", resourceGroupOption: e.target.value });
            }
        }
    };

    return (
        <div className={styles["new-account-container"]}>
            <div className={styles["resource-group-radio"]}>
                <div className={classNames(typography.body14, styles["title-and-popover"])}>Resource Group</div>
                <div className={styles["radio-buttons"]}>
                    <RadioButton onChange={onRgOptionSelection} name="resourceGroupOption" value="new" checked={form.formState.resourceGroupOption === "new"}>Create new</RadioButton>
                    <RadioButton onChange={onRgOptionSelection} name="resourceGroupOption" value="existing" checked={form.formState.resourceGroupOption === "existing"}>Use existing</RadioButton>
                </div>
                {form.formState.resourceGroupOption === "new" ?  <CreateResourceGroup form={form}/> : <ExistingResourceGroup form={form}/>}
            </div>
            <FormFieldInputNew
                form={form}
                name="accountName"
                label="Account Name"
                className={classNames(commonStyles["form-field"], styles["accountName"])}
                maxChars={256}
                autoFocus={true}
                autoComplete="off"
            />
            <FormFieldSelect
                name="subscription"
                label="Azure Subscription"
                isSearchable={true}
                onChange={onSubscriptionSelected}
                value={form.formState.subscription}
                className={classNames(commonStyles["form-field"], styles["subscription"])}
                placeholder="- Select subscription -"
                options={subscriptions}
                errorMessage={form.formErrors.subscription}
                showError={form.formErrors.subscription && form.submissionAttempted}
                isLoading={getSubscriptionsPending}
            />
            <FormFieldSelect
                form={form}
                isSearchable={true}
                className={classNames(commonStyles["form-field"], styles["location"])}
                name="location"
                label="Location"
                placeholder="- Select location -"
                options={azureLocations}
            />
        </div>
    );
};

const ExistingAccount = ({form}) => {
    const dispatch = useDispatch();
    const accounts = useSelector(state => _.get(state, "cvs.netappAccounts"));
    const getAccountsPending = useSelector(state => _.get(state, "cvs.getNetappAccountsPending"));

    useEffect(() => {
        dispatch({
            type: "CVS:AZURE-GET-NETAPP-ACCOUNTS"
        });
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: { getNetappAccountsResolved: false, getNetappAccountsError: null, getNetappAccountsShowError: false }
        });
    }, [dispatch]);

    return (
        <FormFieldSelect
            form={form}
            name="netappAccount"
            label="Account Name"
            placeholder="- Select an account -"
            options={accounts}
            isLoading={getAccountsPending}
            className={classNames(commonStyles["form-field"], styles["existing-account"])}
        />
    );
};

export const Content = () => {
    const dispatch = useDispatch();
    const {setState, state} = useWizard();
    const currentState = state?.netappAccountForm?.formState || {};
    const netappAccountForm = useForm({...initialState, ...currentState}, schema);
    const accountOption = netappAccountForm.formState.accountOption;
    const getAccountsError = useSelector(state => _.get(state, "cvs.getNetappAccountsError"));
    const getAccountsShowError = useSelector(state => _.get(state, "cvs.getNetappAccountsShowError"));
    const getSubscriptionsError = useSelector(state => _.get(state, "cvs.getSubscriptionsError"));
    const getSubscriptionsShowError = useSelector(state => _.get(state, "cvs.getSubscriptionsShowError"));
    const createNetappAccountError = useSelector(state => _.get(state, "cvs.createNetappAccountError"));
    const createNetappAccountShowError = useSelector(state => _.get(state, "cvs.createNetappAccountShowError"));
    const getResourceGroupsError = useSelector(state => _.get(state, "cvs.getResourceGroupsError"));
    const getResourceGroupsShowError = useSelector(state => _.get(state, "cvs.getResourceGroupsShowError"));
    const getActiveDirectoryError = useSelector(state => _.get(state, "cvs.getActiveDirectoryError"));
    const getActiveDirectoryShowError = useSelector(state => _.get(state, "cvs.activeDirectoryShowError"));

    useEffect(() => {
        setState({netappAccountForm});
    }, [setState, netappAccountForm]);

    const onAccountOptionSelection = e => {
        if (!_.isEqual(e.target.value, netappAccountForm.formState.accountOption)) {
            netappAccountForm.resetSubmissionAttempted();
            if (e.target.value === "existing") {
                netappAccountForm.resetForm({ ...initialState, accountName: netappAccountForm.formState.accountName });
            } else {
                netappAccountForm.resetForm({ ...netappAccountForm.formState, resourceGroup: null, accountOption: "new" });
            }
        }
    };

    const notifications = [{
        type: "error",
        description: getActiveDirectoryError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { activeDirectoryShowError: false }}),
        showError: getActiveDirectoryShowError
    }, {
        type: "error",
        description: getAccountsError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { getNetappAccountsShowError: false }}),
        showError: getAccountsShowError
    }, {
        type: "error",
        description: getSubscriptionsError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { getSubscriptionsShowError: false }}),
        showError: getSubscriptionsShowError
    }, {
        type: "error",
        description: createNetappAccountError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { createNetappAccountShowError: false }}),
        showError: createNetappAccountShowError
    }, {
        type: "error",
        description: getResourceGroupsError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { getResourceGroupsShowError: false }}),
        showError: getResourceGroupsShowError
    }];

    return (
        <ContentLayout className={styles["container"]} title={"Azure NetApp Files Account"}>
            <RadioButtonCard
                onChange={onAccountOptionSelection}
                name={"accountOption"}
                checkedValue={accountOption}
                buttons={widgetButtons}
                widgetLabel="Choose an Azure NetApp Files account:"/>
           {accountOption === "new" ? <NewAccount form={netappAccountForm}/> : <ExistingAccount form={netappAccountForm}/>}
            <Notifications className={commonStyles["error-area"]} notifications={_.filter(notifications, { showError: true })}/>
        </ContentLayout>
    );
};
