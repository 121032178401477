import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import * as yup from "yup";
import classNames from "classnames";
import {Checkbox, useForm, FormFieldInputNew, ActionButton, Toggle, FormFieldSelect, useWizard, Notification} from "@netapp/bxp-design-system-react";
import {FooterLayout, ContentLayout} from "./Page";
import styles from "./SnapshotPolicy.module.scss";

export const Footer = () => {
    const dispatch = useDispatch();
    const flavour = useSelector(state => _.get(state, `cvs.cvsFlavour`));
    const createVolumePending = useSelector(state => _.get(state, `cvs.createVolumePending`));
    const {state} = useWizard();
    const snapshotPolicyForm = state.snapshotPolicyForm || {};
    const { handleFormSubmit } = snapshotPolicyForm;

    const submitForm = e => {
        e.preventDefault();
        dispatch({
            type: `CVS:${flavour.toUpperCase()}-CREATE-VOLUME`,
            payload: { createVolumeForm: state }
        });
    };

    return (
        <FooterLayout><ActionButton disabled={!handleFormSubmit} isSubmitting={createVolumePending} onClick={e => handleFormSubmit(submitForm)(e)}>Create</ActionButton></FooterLayout>
    );
};

export const daysOfWeek = [
    {label: "Monday", value: 0},
    {label: "Tuesday", value: 1},
    {label: "Wednesday", value: 2},
    {label: "Thursday", value: 3},
    {label: "Friday", value: 4},
    {label: "Saturday", value: 5},
    {label: "Sunday", value: 6}
];

export const daysOfMonth = _.range(1,32).map(i => ({label: i.toString(), value: i}));

export const initialState = {
    enabled: true,
    snapshotDirectory: true,
    hourly: false,
    hourlyPolicyNumberOfSnapshots: "",
    hourlyPolicyMinute: "",
    daily: false,
    dailyPolicyNumberOfSnapshots: "",
    dailyPolicyHour: "",
    dailyPolicyMinute: "",
    weekly: false,
    weeklyPolicyNumberOfSnapshots: "",
    weeklyPolicyDaysOfWeek: null,
    weeklyPolicyHour: "",
    weeklyPolicyMinute: "",
    monthly: false,
    monthlyPolicyNumberOfSnapshots: "",
    monthlyPolicyDaysOfMonth: null,
    monthlyPolicyHour: "",
    monthlyPolicyMinute: ""
};

export const schema = yup.object().shape({
    enabled: yup.boolean(),
    snapshotDirectory: yup.boolean(),
    hourly: yup.boolean(),
    hourlyPolicyNumberOfSnapshots: yup.string().when("hourly", { is: true, then: yup.string().required("Select") }),
    hourlyPolicyMinute: yup.string().when("hourly", { is: true, then: yup.string().required("Select") }),
    daily: yup.boolean(),
    dailyPolicyNumberOfSnapshots: yup.string().when("daily", { is: true, then: yup.string().required("Select") }),
    dailyPolicyHour: yup.string().when("daily", { is: true, then: yup.string().required("Select") }),
    dailyPolicyMinute: yup.string().when("daily", { is: true, then: yup.string().required("Select") }),
    weekly: yup.boolean(),
    weeklyPolicyNumberOfSnapshots: yup.string().when("weekly", { is: true, then: yup.string().required("Select") }),
    weeklyPolicyDaysOfWeek: yup.array().nullable().when("weekly", { is: true, then: yup.array().required("Select") }),
    weeklyPolicyHour: yup.string().when("weekly", { is: true, then: yup.string().required("Select") }),
    weeklyPolicyMinute: yup.string().when("weekly", { is: true, then: yup.string().required("Select") }),
    monthly: yup.boolean(),
    monthlyPolicyNumberOfSnapshots: yup.string().when("monthly", { is: true, then: yup.string().required("Select") }),
    monthlyPolicyDaysOfMonth: yup.array().nullable().when("monthly", { is: true, then: yup.array().required("Select") }),
    monthlyPolicyHour: yup.string().when("monthly", { is: true, then: yup.string().required("Select") }),
    monthlyPolicyMinute: yup.string().when("monthly", { is: true, then: yup.string().required("Select") })
});

const ScheduleFormFieldInput = props => {
    const { min, max, value } = props;
    const val = value > max || value < min || !Number.isInteger(Number(value)) ? "" : value;

    return (
        <FormFieldInputNew
            {...props}
            type="number"
            value={val}
        />
    );
};

export const SnapshotForm = ({form}) => {
    const {handleFormChange, wrappedHandleFormChange, formState, formErrors, submissionAttempted} = form;
    const { enabled } = formState;

    return <div className={classNames(styles["snapshot-form"], {[styles["snapshot-form-enabled"]]: enabled})}>
        <div className={styles["snapshot-toggle-row"]}>
            <div className={styles["snapshot-toggle-title"]}>Enable automatic Snapshot copies</div>
            <div className={styles["snapshot-toggle-description"]}>
                {enabled ? "When enabled, Cloud Volumes Service automatically creates Snapshot copies according to the schedule below." : "When disabled, Cloud Volumes Service does not create Snapshot copies of your volumes."}
            </div>
            <Toggle className={styles["snapshot-toggle"]} value={enabled} toggle={(value) => {handleFormChange(value, {name: "enabled"})}}/>
        </div>
        <div className={classNames(styles["snapshot-details-row"], {[styles["snapshot-details-row-enabled"]]: enabled})}>
            <div className={styles["policies-title"]}>Schedule Snapshot Policies:</div>
            <div className={styles["policies"]}>
                <Checkbox
                    checked={formState.hourly}
                    onChange={handleFormChange}
                    value="hourly"
                    className={styles["policy-checkbox-label"]}
                    name="hourly">Hourly</Checkbox>
                    <div className={styles["policy"]}>
                        <ScheduleFormFieldInput
                            min={1}
                            max={255}
                            type="number"
                            name="hourlyPolicyNumberOfSnapshots"
                            value={formState.hourly ? formState.hourlyPolicyNumberOfSnapshots : ""}
                            onChange={handleFormChange}
                            maxChars={256}
                            showError={submissionAttempted}
                            errorMessage={formErrors.hourlyPolicyNumberOfSnapshots}
                            className={styles["snapshot-field"]}
                            label="Number of snapshots"
                            disabled={!formState.hourly}
                        />
                        <ScheduleFormFieldInput
                            min={0}
                            max={59}
                            type="number"
                            name="hourlyPolicyMinute"
                            value={formState.hourly ? formState.hourlyPolicyMinute : ""}
                            onChange={handleFormChange}
                            showError={submissionAttempted}
                            errorMessage={formErrors.hourlyPolicyMinute}
                            className={classNames(styles["snapshot-field"], styles["shorter-filed"], styles["with-margin"])}
                            label="Minute"
                            disabled={!formState.hourly}
                        />
                    </div>
                <Checkbox
                    checked={formState.daily}
                    onChange={handleFormChange}
                    value="daily"
                    className={styles["policy-checkbox-label"]}
                    name="daily">Daily</Checkbox>
                <div className={styles["policy"]}>
                    <ScheduleFormFieldInput
                        min={1}
                        max={255}
                        type="number"
                        name="dailyPolicyNumberOfSnapshots"
                        value={formState.daily ? formState.dailyPolicyNumberOfSnapshots : ""}
                        onChange={handleFormChange}
                        maxChars={256}
                        showError={submissionAttempted}
                        errorMessage={formErrors.dailyPolicyNumberOfSnapshots}
                        className={styles["snapshot-field"]}
                        label="Number of snapshots"
                        disabled={!formState.daily}
                    />
                    <ScheduleFormFieldInput
                        min={0}
                        max={23}
                        type="number"
                        name="dailyPolicyHour"
                        value={formState.daily ? formState.dailyPolicyHour : ""}
                        onChange={handleFormChange}
                        showError={submissionAttempted}
                        errorMessage={formErrors.dailyPolicyHour}
                        className={classNames(styles["snapshot-field"], styles["shorter-filed"], styles["with-margin"])}
                        label="Hour"
                        disabled={!formState.daily}
                    />
                    <ScheduleFormFieldInput
                        min={0}
                        max={59}
                        type="number"
                        name="dailyPolicyMinute"
                        value={formState.daily ? formState.dailyPolicyMinute : ""}
                        onChange={handleFormChange}
                        showError={submissionAttempted}
                        errorMessage={formErrors.dailyPolicyMinute}
                        className={classNames(styles["snapshot-field"], styles["shorter-filed"], styles["with-margin"])}
                        label="Minute"
                        disabled={!formState.daily}
                    />
                </div>
                <Checkbox
                    checked={formState.weekly}
                    onChange={handleFormChange}
                    value="weekly"
                    className={styles["policy-checkbox-label"]}
                    name="weekly">Weekly</Checkbox>
                <div className={styles["policy"]}>
                    <ScheduleFormFieldInput
                        min={1}
                        max={255}
                        type="number"
                        name="weeklyPolicyNumberOfSnapshots"
                        value={formState.weekly ? formState.weeklyPolicyNumberOfSnapshots : ""}
                        onChange={handleFormChange}
                        maxChars={256}
                        showError={submissionAttempted}
                        errorMessage={formErrors.weeklyPolicyNumberOfSnapshots}
                        className={styles["snapshot-field"]}
                        label="Number of snapshots"
                        disabled={!formState.weekly}
                    />
                    <FormFieldSelect
                        isClearable={true}
                        isMulti={true}
                        name="weeklyPolicyDaysOfWeek"
                        label="Days"
                        placeholder="- Select days -"
                        onChange={wrappedHandleFormChange("weeklyPolicyDaysOfWeek")}
                        options={daysOfWeek}
                        value={formState.weekly ? formState.weeklyPolicyDaysOfWeek : null}
                        // errorMessage={submissionAttempted && formErrors.weeklyPolicyDaysOfWeek}
                        showError={submissionAttempted}
                        errorMessage={formErrors.weeklyPolicyDaysOfWeek}
                        className={classNames(styles["snapshot-field"], styles["wider-filed"], styles["with-margin"])}
                        disabled={!formState.weekly}
                    />
                    <ScheduleFormFieldInput
                        min={0}
                        max={23}
                        type="number"
                        name="weeklyPolicyHour"
                        value={formState.weekly ? formState.weeklyPolicyHour : ""}
                        onChange={handleFormChange}
                        showError={submissionAttempted}
                        errorMessage={formErrors.weeklyPolicyHour}
                        className={classNames(styles["snapshot-field"], styles["shorter-filed"], styles["with-margin"])}
                        label="Hour"
                        disabled={!formState.weekly}
                    />
                    <ScheduleFormFieldInput
                        min={0}
                        max={59}
                        type="number"
                        name="weeklyPolicyMinute"
                        value={formState.weekly ? formState.weeklyPolicyMinute : ""}
                        onChange={handleFormChange}
                        showError={submissionAttempted}
                        errorMessage={formErrors.weeklyPolicyMinute}
                        className={classNames(styles["snapshot-field"], styles["shorter-filed"], styles["with-margin"])}
                        label="Minute"
                        disabled={!formState.weekly}
                    />
                </div>
                <Checkbox
                    checked={formState.monthly}
                    onChange={handleFormChange}
                    value="monthly"
                    className={styles["policy-checkbox-label"]}
                    name="monthly">Monthly</Checkbox>
                <div className={styles["policy"]}>
                    <ScheduleFormFieldInput
                        min={1}
                        max={255}
                        type="number"
                        name="monthlyPolicyNumberOfSnapshots"
                        value={formState.monthly ? formState.monthlyPolicyNumberOfSnapshots : ""}
                        onChange={handleFormChange}
                        maxChars={256}
                        showError={submissionAttempted}
                        errorMessage={formErrors.monthlyPolicyNumberOfSnapshots}
                        className={styles["snapshot-field"]}
                        label="Number of snapshots"
                        disabled={!formState.monthly}
                    />
                    <FormFieldSelect
                        isClearable={true}
                        isMulti={true}
                        name="monthlyPolicyDaysOfMonth"
                        label="Days of the month"
                        placeholder="- Select days -"
                        onChange={wrappedHandleFormChange("monthlyPolicyDaysOfMonth")}
                        options={daysOfMonth}
                        value={formState.monthly ? formState.monthlyPolicyDaysOfMonth : null}
                        showError={submissionAttempted}
                        errorMessage={formErrors.monthlyPolicyDaysOfMonth}
                        className={classNames(styles["snapshot-field"], styles["wider-filed"], styles["with-margin"])}
                        disabled={!formState.monthly}
                    />
                    <ScheduleFormFieldInput
                        min={0}
                        max={23}
                        type="number"
                        name="monthlyPolicyHour"
                        value={formState.monthly ? formState.monthlyPolicyHour : ""}
                        onChange={handleFormChange}
                        showError={submissionAttempted}
                        errorMessage={formErrors.monthlyPolicyHour}
                        className={classNames(styles["snapshot-field"], styles["shorter-filed"], styles["with-margin"])}
                        label="Hour"
                        disabled={!formState.monthly}
                    />
                    <ScheduleFormFieldInput
                        min={0}
                        max={59}
                        type="number"
                        name="monthlyPolicyMinute"
                        value={formState.monthly ? formState.monthlyPolicyMinute : ""}
                        onChange={handleFormChange}
                        showError={submissionAttempted}
                        errorMessage={formErrors.monthlyPolicyMinute}
                        className={classNames(styles["snapshot-field"], styles["shorter-filed"], styles["with-margin"])}
                        label="Minute"
                        disabled={!formState.monthly}
                    />
                </div>
            </div>
        </div>
    </div>
};

export const Content = () => {
    const dispatch = useDispatch();
    const flavour = useSelector(state => _.get(state, `cvs.cvsFlavour`));
    const {setState, state} = useWizard();
    const currentState = state?.snapshotPolicyForm?.formState || {};
    const snapshotPolicyForm = useForm({...initialState, ...currentState}, schema);
    const createVolumeError = useSelector(state => _.get(state, `cvs.createVolumeError`));
    const createVolumeShowError = useSelector(state => _.get(state, `cvs.createVolumeShowError`));

    useEffect(() => {
        setState({snapshotPolicyForm});
    }, [setState, snapshotPolicyForm]);

    useEffect(() => {
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: { createVolumeError: null, createVolumeResolved: false, createVolumeShowError: false }
        });
    }, [dispatch]);

    const deleteError = () => {
        dispatch({
            type: "CVS:CLEAR-STATE",
            payload: { createVolumeShowError: null }
        });
    };

    return (
        <ContentLayout className={styles["container"]} title={"Snapshot Policy"}>
            {flavour === "aws" && <Checkbox form={snapshotPolicyForm} className={styles["show-directory-checkbox"]} name="snapshotDirectory">Show snapshot directory (read-only)</Checkbox>}
            <SnapshotForm form={snapshotPolicyForm}/>
            {createVolumeShowError &&
            <div className={styles["error-area"]}>
                <Notification type="error" onClose={deleteError}>{createVolumeError}</Notification>.
            </div>}
        </ContentLayout>
    );
};
