import React, {useMemo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {ActionButton, FormFieldInputNew, Checkbox, useForm, useWizard, Notifications} from "@netapp/bxp-design-system-react";
import {RadioButtonCard} from "../RadioButtonWidget";
import {activeDirectoryProps} from "../../utils/aws";
import {ActiveDirectory} from "./ProtocolSmb";
import {Nfs} from "./ProtocolNfs";
import {Dual} from "./ProtocolDual";
import {initialState, validator} from "./ProtocolSchema";
import {FooterLayout, ContentLayout} from "./Page";
import commonStyles from "../../styles/common.module.scss";
import styles from "./Protocol.module.scss";

const validateForm = validator("aws");

const widgetButtons = [
    { label: "NFS Protocol", name: "protocol", value: "nfs" },
    { label: "SMB Protocol", name: "protocol", value: "smb" },
    { label: "Dual Protocol", name: "protocol", value: "dual" }
];

export const Footer = () => {
    const dispatch = useDispatch();
    const activeDirectory = useSelector(state => _.get(state, "cvs.activeDirectory"));
    const createActiveDirectoryPending = useSelector(state => _.get(state, "cvs.createActiveDirectoryPending"));
    const editActiveDirectoryPending = useSelector(state => _.get(state, "cvs.editActiveDirectoryPending"));
    const {setStep, state} = useWizard();
    const protocolForm = state.protocolForm || {};
    const {isValid, handleFormChange, formState, handleFormSubmit, resetSubmissionAttempted} = protocolForm;

    const moveToNextInternalStep = () => {
        handleFormChange(1, { name: "internalStep" });
    };

    const submitForm = () => {
        resetSubmissionAttempted();
        if (formState.protocolOption === "nfs") {
            if (isValid) {
                setStep("snapshot-copy");
            }
        }
        if (formState.protocolOption === "smb" || formState.protocolOption === "dual") {
            if (formState.internalStep === 0) {
                if (isValid) {
                    moveToNextInternalStep();
                }
            } else {
                if (isValid) {
                    if (_.isEmpty(activeDirectory)) {
                        dispatch({
                            type: "CVS:AWS-CREATE-ACTIVE-DIRECTORY",
                            payload: { activeDirectory: { ..._.pick(formState, activeDirectoryProps) }, onSuccess: () => setStep("snapshot-copy") }
                        });
                    } else {
                        if (!formState.editActiveDirectoryMode) {
                            // if AD exists and not in edit mode move to next step
                            setStep("snapshot-copy");
                        } else {
                            dispatch({
                                type: "CVS:AWS-EDIT-ACTIVE-DIRECTORY",
                                payload: { activeDirectory: { ..._.pick(formState, activeDirectoryProps), activeDirectoryId: activeDirectory.UUID }, onSuccess: () => setStep("snapshot-copy") }
                            });
                        }
                    }
                }
            }
        }
    };

    return (
        <FooterLayout><ActionButton disabled={!handleFormSubmit} isSubmitting={createActiveDirectoryPending || editActiveDirectoryPending} onClick={e => handleFormSubmit(submitForm)(e)}>Continue</ActionButton></FooterLayout>
    );
};

const Smb = ({ form }) => {
    const {dirty, formErrors} = form;
    return (
        <div className={styles["two-col-form"]}>
            <div className={styles["left-col"]}>
                <div className={styles["title"]}>Protocol</div>
                <FormFieldInputNew
                    form={form}
                    name="volumePath"
                    label="Volume Path"
                    maxChars={256}
                    errorMessage={dirty.volumePath && formErrors.volumePath}
                    autoFocus={true}
                    className={commonStyles["form-field"]}/>
            </div>
            <div className={styles["right-col"]}>
                <div className={styles["title"]}>Encryption</div>
                <div className={styles["policy-title"]}>
                    SMB Protocol Encryption
                </div>
                <div className={styles["smb-encryption"]}>
                    <div className={styles["checkboxes"]}>
                        <Checkbox form={form} name="encryption">SMB3</Checkbox>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const Content = () => {
    const dispatch = useDispatch();
    const existingActiveDirectory = useSelector(state => _.get(state, `cvs.activeDirectory`));
    const createActiveDirectoryError = useSelector(state => _.get(state, `cvs.createActiveDirectoryError`));
    const createActiveDirectoryShowError = useSelector(state => _.get(state, `cvs.createActiveDirectoryShowError`));
    const editActiveDirectoryError = useSelector(state => _.get(state, `cvs.editActiveDirectoryError`));
    const editActiveDirectoryShowError = useSelector(state => _.get(state, `cvs.editActiveDirectoryShowError`));
    const activeDirectoryForm = !_.isEmpty(existingActiveDirectory) ? _.pick(existingActiveDirectory, activeDirectoryProps) : _.pick(initialState, activeDirectoryProps);
    const {setState, state} = useWizard();
    const currentState = state?.protocolForm?.formState || {};
    const protocolForm = useForm({...initialState, ...currentState, ...activeDirectoryForm, internalStep: 0, editActiveDirectoryMode: false}, validateForm);
    const protocolOption = protocolForm.formState.protocolOption;
    const internalStep = protocolForm.formState.internalStep;

    useEffect(() => {
        setState({protocolForm});
    }, [setState, protocolForm]);

    const Form = useMemo(() => {
        switch (protocolOption) {
            case "nfs": {
                return Nfs;
            }
            case "smb": {
                return internalStep === 0 ? Smb : ActiveDirectory;
            }
            case "dual": {
                return internalStep === 0 ? Dual : ActiveDirectory;
            }
            default: {
                return  Nfs
            }
        }
    }, [internalStep, protocolOption]);

    const onProtocolSelection = e => {
        protocolForm.resetForm({ ...protocolForm.formState, protocolOption: e.target.value, internalStep: 0, editActiveDirectoryMode: false  });
        protocolForm.resetSubmissionAttempted();
    };

    const notifications = [{
        type: "error",
        description: createActiveDirectoryError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { createActiveDirectoryShowError: false }}),
        showError: createActiveDirectoryShowError
    }, {
        type: "error",
        description: editActiveDirectoryError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { editActiveDirectoryShowError: false }}),
        showError: editActiveDirectoryShowError
    }];

    return (
        <ContentLayout className={styles["container"]} title={"Protocol"}>
            <RadioButtonCard
                onChange={onProtocolSelection}
                name={"protocolOption"}
                checkedValue={protocolOption}
                buttons={widgetButtons}
                widgetLabel="Select the volume's protocol:"
                className={styles["widget"]}/>
            <Form form={protocolForm}/>
            <Notifications className={commonStyles["error-area"]} notifications={_.filter(notifications, { showError: true })}/>
        </ContentLayout>
    );
};
