import _ from "lodash";
import {gibToByte, mapRules, mapSnapshotPolicyForm} from "./common";

export const awsRegions = [
    { value: 'us-east-1', label: 'us-east-1 | US East (N. Virginia)' },
    { value: 'us-west-1', label: 'us-west-1 | US West (N. California)' },
    { value: 'us-west-2', label: 'us-west-2 | US West (Oregon)' },
    { value: 'eu-central-1', label: 'eu-central-1 | EU (Frankfurt)' },
    { value: 'eu-west-1', label: 'eu-west-1 | EU (Ireland)' },
    { value: 'eu-west-2', label: 'eu-west-2 | EU (London)' },
    { value: 'ap-northeast-1', label: 'ap-northeast-1 | Asia Pacific (Tokyo)' },
    { value: 'ap-southeast-2', label: 'ap-southeast-2 | Asia Pacific (Sydney)' }
];

export const awsAccountRegex = /^\d{12}$/img;
export const awsVolumePathRegex = /^[a-zA-Z][a-zA-Z0-9-_]{0,79}$/img;

export const isAWsAccountValid = awsAccount => {
    const awsAccountRegex = /^\d{12}$/img;
    return awsAccount.match(awsAccountRegex);
};

export const isVolumePathValid = volumePath => {
    const volumePathRegex = /^[a-zA-Z][a-zA-Z0-9-_]{0,79}$/img;
    return volumePath.match(volumePathRegex);
};

export const serviceLevelOptions = [
    { label: "Standard", value: "low" },
    { label: "Premium", value: "medium" },
    { label: "Extreme", value: "high" },
];

export const activeDirectoryProps = ['DNS', 'domain', 'username', 'password', 'netBIOS', 'organizationalUnit'];

export const createVolumeData = (createVolumeForm) => {
    const {detailsAndTagsForm, protocolForm, snapshotCopyForm, snapshotPolicyForm} = createVolumeForm;
    let volumeData = {};
    if (snapshotPolicyForm.formState.enabled) {
        volumeData.snapshotPolicy = mapSnapshotPolicyForm(snapshotPolicyForm.formState);
    }
    volumeData.snapshotDirectory = snapshotPolicyForm.formState.snapshotDirectory;
    const { volumeName, sizeInGiB, serviceLevel, tags } = detailsAndTagsForm.formState;
    let protocolTypes = [];
    let volumePath;
    let securityStyle;
    let labels = [];

    volumeData.name = volumeName;
    volumeData.quotaInBytes = gibToByte(sizeInGiB);
    volumeData.serviceLevel = serviceLevel.value.toLowerCase();
    if (!_.isEmpty(tags[0].name)) {
        labels = _.map(tags, tag => tag.name);
    }

    const { snapshot } = snapshotCopyForm.formState;
    if (snapshot) {
        volumeData.snapshotId = snapshot.value;
    }

    if (protocolForm.formState.protocolOption === "nfs") {
        volumePath = protocolForm.formState.volumePath;
        securityStyle = "unix";
        volumeData.rules = mapRules(protocolForm.formState.exportPolicies);
        if (protocolForm.formState.NFSv3) {
            protocolTypes.push("NFSv3");
        }
        if (protocolForm.formState.NFSv4) {
            protocolTypes.push("NFSv4");
        }
    }
    if (protocolForm.formState.protocolOption === "smb") {
        volumePath = protocolForm.formState.volumePath;
        if (protocolForm.formState.encryption) {
            volumeData.smbShareSettings = ["encrypt_data"]
        }
        securityStyle = "ntfs";
        protocolTypes.push("CIFS");
    }
    if (protocolForm.formState.protocolOption === "dual") {
        volumePath = protocolForm.formState.volumePath;
        if (protocolForm.formState.encryption) {
            volumeData.smbShareSettings = ["encrypt_data"]
        }
        securityStyle = protocolForm.formState.security.toLocaleLowerCase();
        volumeData.rules = mapRules(protocolForm.formState.exportPolicies);
        protocolTypes.push("CIFS");
        if (protocolForm.formState.NFSv3) {
            protocolTypes.push("NFSv3");
        }
        if (protocolForm.formState.NFSv4) {
            protocolTypes.push("NFSv4");
        }
    }
    return { ...volumeData, protocolTypes, volumePath, securityStyle, labels };
};

