import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import * as yup from "yup";
import {ChevronIcon as Chevron} from "@netapp/bxp-design-system-react/icons/monochrome";
import {useWizard, ActionButton, useForm, Notification, FormFieldInputNew} from "@netapp/bxp-design-system-react";
import {cidrRegex} from "../../utils/common";
import {awsAccountRegex} from "../../utils/aws";
import ExternalLink from "../../../../components/widgets/ExternalLink";
import {FooterLayout, ContentLayout} from "./Page";
import commonStyles from "../../styles/common.module.scss";
import styles from "./NetworkSetupAws.module.scss";

export const Footer = () => {
    const dispatch = useDispatch();
    const {setStep, state} = useWizard();
    const networkSetupResolved = useSelector(state => _.get(state, "cvs.networkSetupResolved"));
    const networkSetupError = useSelector(state => _.get(state, "cvs.networkSetupError"));
    const networkSetupPending = useSelector(state => _.get(state, "cvs.networkSetupPending"));
    const networkSetupForm = state.networkSetupForm || {};
    const { formState, handleFormSubmit } = networkSetupForm;

    const submitForm = () => {
        if (networkSetupResolved && !networkSetupError) {
            setStep("details-and-tags");
        } else {
            dispatch({
                type: "CVS:AWS-NETWORK-SETUP",
                payload: { awsAccountId: formState.awsAccountId, cidr: formState.cidr }
            });
        }
    };

    return (
        <FooterLayout><ActionButton disabled={!handleFormSubmit} isSubmitting={networkSetupPending} onClick={e => handleFormSubmit(submitForm)(e)}>Continue</ActionButton></FooterLayout>
    );
};

const BulletItem = ({ children }) => {
    return (
        <div className={styles["bullet-item-container"]}>
            <Chevron className={styles["chevron"]}/>
            {children}
        </div>
    );
};

export const SetupDetails = () => {
    return (
        <ContentLayout className={styles["setup-details-container"]} title={"Accepting Virtual Interfaces"}>
            <div className={styles["main-text"]}>
                The following steps must be completed before the volume can be created with the networking that you just configured. You can complete these steps after you finish this wizard.
            </div>
            <div className={styles["bullets"]}>
                <BulletItem><span style={{ marginRight: 4 }}>Open the</span><ExternalLink className={commonStyles["in-text-button"]} href={"https://console.aws.amazon.com/console/home"} newTab>AWS DirectConnect Management console</ExternalLink></BulletItem>
                <BulletItem>Accept the virtual interfaces "NetApp-CloudVolumes-1A" and "NetApp-CloudVolumes-2B”. They should appear momentarily.</BulletItem>
                <BulletItem>When accepting the virtual interfaces, make sure to attach them to Virtual/DirectConnect gateways.</BulletItem>
                <BulletItem>The Cloud Volumes Service will then attempt to establish a BGP session with your provided network configuration. This can take up to 10 minutes.</BulletItem>
                <BulletItem>When this is complete, your new volume will be ready.</BulletItem>
            </div>
        </ContentLayout>
    );
};

const networkSetupInitialFormState = {
    cidr: "",
    awsAccountId: ""
};

const networkSetupSchema = yup.object().shape({
    cidr: yup.string()
        .matches(cidrRegex, "Invalid CIDR.")
        .required("CIDR is required"),
    awsAccountId: yup.string()
        .matches(awsAccountRegex, "Invalid AWS account ID.")
        .required("AWS account id is required.")
});

export const Setup = () => {
    const dispatch = useDispatch();
    const {setState} = useWizard();
    const networkSetupError = useSelector(state => _.get(state, "cvs.networkSetupError"));
    const networkSetupShowError = useSelector(state => _.get(state, "cvs.networkSetupShowError"));
    const networkSetupForm = useForm(networkSetupInitialFormState, networkSetupSchema);

    useEffect(() => {
        setState({networkSetupForm})
    }, [networkSetupForm, setState]);

    const hideNotification = () => dispatch({
        type: "CVS:CLEAR-STATE",
        payload: { networkSetupShowError: false }
    });

    return (
        <ContentLayout className={styles["setup-container"]} title={"Network Setup"}>
            <Notification
                className={commonStyles["notification"]}
                type={"warning"}>
                <span>Your Cloud Volumes Service account isn't connected to your AWS account yet. Enter information about your AWS networking to connect the accounts. For details, see the </span>
                <ExternalLink href={"https://docs.netapp.com/us-en/cloud_volumes/aws/task_quick_start.html"} newTab>
                    Cloud Volumes Service for AWS Account Setup document.
                </ExternalLink>
            </Notification>
            <div className={styles["form"]}>
                <FormFieldInputNew
                    form={networkSetupForm}
                    className={commonStyles["form-field"]}
                    autoFocus={true}
                    maxChars={256}
                    name="cidr"
                    label="CIDR (IPv4)"
                    placeholder="192.168.0.0/28"/>
                <FormFieldInputNew
                    form={networkSetupForm}
                    className={commonStyles["form-field"]}
                    name="awsAccountId"
                    label="AWS Account ID"
                    autoComplete="off"/>
            </div>
            {networkSetupShowError && <div className={commonStyles["error-area"]}>
                <Notification type={'error'} onClose={hideNotification}>{networkSetupError}</Notification>
            </div>}
        </ContentLayout>
    );
};

export const Content = () => {
    const networkSetupResolved = useSelector(state => _.get(state, "cvs.networkSetupResolved"));
    const networkSetupError = useSelector(state => _.get(state, "cvs.networkSetupError"));

    const getContent = () => {
        if (networkSetupError || !networkSetupResolved) {
            return <Setup/>;
        } else {
            return <SetupDetails/>;
        }
    };
    return getContent()
};
