import React from "react";
import {ChevronIcon as Chevron} from "@netapp/bxp-design-system-react/icons/monochrome";
import ExternalLink from "../../../../components/widgets/ExternalLink";
import commonStyles from "../../styles/common.module.scss";
import styles from "./Info.module.scss";

export default () => {
    return (
        <>
            <div style={{ marginBottom: "18px" }} className={styles["info-item-container"]}>
                <Chevron/>
                <div>
                    <span>Create an Azure AD client that meets the</span>
                    <ExternalLink className={commonStyles["in-text-button"]} href={"https://docs.netapp.com/us-en/occm/task_manage_anf.html"} newTab>required permissions</ExternalLink>
                </div>
            </div>
            <div style={{ marginBottom: "9px" }} className={styles["info-item-container"]}>
                <Chevron/>
                <span>Enter credentials on the left:</span>
            </div>
            <div style={{ marginBottom: "18px" }} className={styles["info-item-container"]}>
                <Chevron className={styles["transparent"]}/>
                <span><b>Application (client) ID</b> - The ID of an Active Directory service principal that BlueXP can use to authenticate with Azure Active Directory.</span>
            </div>
            <div style={{ marginBottom: "18px" }} className={styles["info-item-container"]}>
                <Chevron className={styles["transparent"]}/>
                <span><b>Client Secret</b> - The value of a client secret for the service principal application.</span>
            </div>
            <div className={styles["info-item-container"]}>
                <Chevron className={styles["transparent"]}/>
                <span><b>Directory (tenant) ID</b> - The Active Directory ID for your organization. This is sometimes referred to as a Tenant ID.</span>
            </div>
            <div className={styles["info-item-container"]}>
                <span>To learn more about Azure NetApp Files,</span>
                <ExternalLink className={commonStyles["in-text-button"]} href={"https://docs.microsoft.com/en-us/azure/azure-netapp-files/"} newTab>click here</ExternalLink>
            </div>
        </>
    );
};
