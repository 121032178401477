import * as actions from "../actions";

/*eslint default-case: 0*/

const defaultState = () => ({subscriptionStatus: actions.STATUS_PENDING, subscriptionMappingOpen: false});
export default (state = defaultState(), {payload, type}) => {
    switch(type){
        case "SUBSCRIPTIONS:RESOLVE-AWS-TOKEN-SUCCESS": {
            const subscription = payload.data;
            return {...state, subscriptionStatus: actions.STATUS_SUCCESS, subscription};
        }
        case "SUBSCRIPTIONS:RESOLVE-AWS-TOKEN-ERROR": {
            return {...state, subscriptionStatus: actions.STATUS_ERROR, subscriptionError: payload};
        }
        case "SUBSCRIPTIONS:RESOLVE-AWS-TOKEN-PENDING": {
            return {...state, subscriptionMappingOpen: true}
        }
        case "SUBSCRIPTIONS:RESOLVE-GCP-TOKEN-SUCCESS": {
            const subscription = payload.data;
            return {...state, subscriptionStatus: actions.STATUS_SUCCESS, subscription};
        }
        case "SUBSCRIPTIONS:RESOLVE-GCP-TOKEN-ERROR": {
            return {...state, subscriptionStatus: actions.STATUS_ERROR, subscriptionError: payload};
        }
        case "SUBSCRIPTIONS:RESOLVE-GCP-TOKEN-PENDING": {
            return {...state, subscriptionMappingOpen: true}
        }
        case "SUBSCRIPTIONS:RESOLVE-AZURE-TOKEN-SUCCESS": {
            const subscription = payload.data;
            return {...state, subscriptionStatus: actions.STATUS_SUCCESS, subscription};
        }
        case "SUBSCRIPTIONS:RESOLVE-AZURE-TOKEN-ERROR": {
            return {...state, subscriptionStatus: actions.STATUS_ERROR, subscriptionError: payload};
        }
        case "SUBSCRIPTIONS:RESOLVE-AZURE-TOKEN-PENDING": {
            return {...state, subscriptionMappingOpen: true}
        }
        case "SUBSCRIPTIONS:SAVE-SUCCESS": {
            return {...state, subscriptionSaveFinished: true, subscriptionSavePending: false};
        }
        case "SUBSCRIPTIONS:SAVE-ERROR": {
            console.log("Failed to save subscription.");
            console.log(payload);
            return {...state, subscriptionSavePending: false, subscriptionSaveError: payload};
        }
        case "SUBSCRIPTIONS:REPLACE-CONFIG-SUCCESS": {
            console.log("payload", payload.data);
            return {...state, subscriptionConfigFinished: true, autoConfigStatus: payload.data.status};
        }
        case "SUBSCRIPTIONS:REPLACE-CONFIG-ERROR": {
            return {...state, subscriptionConfigError: payload};
        }
        case "SUBSCRIPTIONS:SAVE-PENDING": {
            return {...state, subscriptionSavePending: true}
        }
    }
    return state;
};