import React, {useState} from "react";
import {useSelector} from "react-redux";
import _ from "lodash";
import classNames from "classnames";
import {
    createVolumeRoute,
    displayProtocolTypes,
    isDisabled,
    isInProgress
} from "../../../../utils/common";
import {BarLoader, Popover, CapacityPieChart} from "@netapp/bxp-design-system-react";
import {serviceLevelMapper} from "../../../../utils/gcp";
import {SquareIndicator} from "./SquareIndicator";
import {ErrorTooltip} from "./ErrorTooltip";
import { MoreIcon as MoreIcon } from "@netapp/bxp-design-system-react/icons/monochrome";
import { CloseMonochromeIconsIcon as XClose } from "@netapp/bxp-design-system-react/icons/monochrome";
import {DeleteVolumeIcon as DeleteIcon} from "@netapp/bxp-design-system-react/icons/monochrome";
import {VolumeInfoIcon as InfoIcon} from "@netapp/bxp-design-system-react/icons/monochrome";
import {OccmVolumeIcon as VolumeIcon} from "@netapp/bxp-design-system-react/icons/monochrome";
import {EditVolumeIcon as EditVolume} from "@netapp/bxp-design-system-react/icons/monochrome";
import {MountVolumeIcon as MountVolume} from "@netapp/bxp-design-system-react/icons/monochrome";
import {CreateSnapshotIcon as CreateSnapshot} from "@netapp/bxp-design-system-react/icons/monochrome";
import {RestoreMonochromeIconsIcon as RestoreVolume} from "@netapp/bxp-design-system-react/icons/monochrome";
import {DeleteSnapshotIcon as DeleteSnapshot} from "@netapp/bxp-design-system-react/icons/monochrome";
import {PyramidIcon as Pyramid} from "@netapp/bxp-design-system-react/icons/monochrome";
import numeral from "numeral";
import {Link} from "react-router-dom";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import styles from "./Card.module.scss";

const CapacityChart = ({ allocatedBytes, usedBytes }) => {
    const usedPercentage = usedBytes / allocatedBytes;
    const occmBlue = '#006DC9';

    return <CapacityPieChart allocatedDisplaySize={numeral(allocatedBytes).format('0.0 ib')}
                             usedFraction={usedPercentage}
                             primaryColor={occmBlue}/>;
};

const Info = ({volume}) => {
    const serviceLevel = volume.provider === "gcp" ? serviceLevelMapper[volume.serviceLevel].label : volume.serviceLevel;
    return (
        <div className={styles["info"]}>
            <div className={styles["title"]}>INFO</div>
            <div className={styles["sLevel"]}>Service Level</div>
            <div className={styles["sLevelInfo"]}>{serviceLevel}</div>
            <div className={styles["sRegion"]}>{volume.provider === "azure" ? "Location" : "Region"}</div>
            <div className={styles["sRegionInfo"]}>{volume.provider === "azure" ? volume.regionDisplayName : volume.region}</div>
            <div className={styles["protocol"]}>Protocols</div>
            <div className={styles["protocolInfo"]}>{displayProtocolTypes(volume.protocolTypes).join(', ')}</div>
            {isDisabled(volume.lifeCycleState) && <div className={styles["error"]}>
                <Popover white={true} Trigger={() => <ErrorTooltip/>} triggerClassName={styles["popover"]}>
                    {volume.lifeCycleStateDetails}
                </Popover>
            </div>}
        </div>
    );
};

const Capacity = ({volume}) => {
    return (
        <div className={styles["capacity"]}>
            <div className={styles["title"]}>CAPACITY</div>
            <div className={styles["data"]}>
                <div className={styles["chart"]}>
                    <CapacityChart allocatedBytes={volume.quotaInBytes} usedBytes={volume.usedBytes}/>
                </div>
                <div className={styles["details-layout"]}>
                    <div className={styles["quantity"]}>
                        <SquareIndicator state={"note"}/>
                        <span>{volume.usedBytes > 0 ? numeral(volume.usedBytes).format('0.0 ib') : "0 GiB"}</span>
                    </div>
                    <div className={styles["lighter"]}>Used Capacity</div>
                </div>
            </div>
        </div>
    );
};

const FrontHeader = ({volume, flip}) => {
    return (
        <div className={styles["front-header"]}>
            <div className={styles["details"]}>
                <VolumeIcon/>
                <span title={volume.name} className={styles["name"]}>{volume.name}</span>
            </div>
            <div className={styles['actions']}>
                <SquareIndicator state={volume.lifeCycleState}/>
                <div className={classNames(typography.body14, styles["status"])}>{volume.lifeCycleState.toUpperCase()}</div>
                {!isInProgress(volume.lifeCycleState) &&
                <button className={classNames(styles['actions-btn'])} onClick={flip}>
                    <MoreIcon/>
                </button>}
            </div>
        </div>
    );
};

const Front = ({volume, flip}) => {
    return (
        <div className={styles["front-layout"]}>
            <FrontHeader volume={volume} flip={flip}/>
            {isInProgress(volume.lifeCycleState) ? <BarLoader className={styles["loader"]}/> : <div className={styles["loader"]}/>}
            <Info volume={volume}/>
            <Capacity volume={volume}/>
        </div>
    );
};

const BackHeader = ({volume, flip, openConfirmDeleteDialog}) => {
    const accountId = useSelector(state => _.get(state, "cvs.accountId"));
    const credentialsId = useSelector(state => _.get(state, "cvs.credentialsId"));

    const openDeleteDialog = () => {
        flip();
        openConfirmDeleteDialog(volume);
    };

    return (
        <div className={styles["back-header"]}>
            <div className={styles["details"]}>
                <VolumeIcon/>
                <span title={volume.name} className={styles["name"]}>{volume.name}</span>
            </div>
            <div className={styles['actions']}>
                <Link className={styles["vol-info"]} to={`/components/cvs/${volume.provider}/${accountId}/${credentialsId}${createVolumeRoute("info", volume)}`}>
                    <InfoIcon/> Info
                </Link>
                <button className={styles["vol-delete"]} onClick={openDeleteDialog}>
                    <DeleteIcon/> Delete
                </button>
                <button className={styles["flip-back"]} onClick={flip}>
                    <XClose className={styles["flip-back-icon"]}/>
                </button>
            </div>
        </div>
    );
};

const Back = ({volume, flip, openConfirmDeleteDialog}) => {
    const accountId = useSelector(state => _.get(state, "cvs.accountId"));
    const credentialsId = useSelector(state => _.get(state, "cvs.credentialsId"));
    const disabled = isDisabled(volume.lifeCycleState);
    return (
        <div className={styles["back-layout"]}>
            <BackHeader volume={volume} flip={flip} openConfirmDeleteDialog={openConfirmDeleteDialog}/>
            <div className={styles["loader"]}/>
            <div className={classNames(styles["actions1"], {[styles["disabled-action"]]: disabled})}>
                <Link disabled={disabled} className={styles["action"]} to={`/components/cvs/${volume.provider}/${accountId}/${credentialsId}${createVolumeRoute("edit-volume", volume)}`}>
                    <EditVolume/> Edit
                </Link>
                <Link disabled={disabled} className={styles["action"]}  to={`/components/cvs/${volume.provider}/${accountId}/${credentialsId}${createVolumeRoute("mount-volume", volume)}`}>
                    <MountVolume/> Mount the volume
                </Link>
                <Link disabled={disabled} className={styles["action"]}  to={`/components/cvs/${volume.provider}/${accountId}/${credentialsId}${createVolumeRoute("create-snapshot", volume)}`}>
                    <CreateSnapshot/> Create a Snapshot copy
                </Link>
            </div>
            <div className={classNames(styles["actions2"], {[styles["disabled-action"]]: disabled})}>
                {volume.provider !== "azure" &&
                <Link disabled={disabled} className={styles["action"]}  to={`/components/cvs/${volume.provider}/${accountId}/${credentialsId}${createVolumeRoute("revert-volume", volume)}`}>
                    <RestoreVolume/> Revert volume to Snapshot
                </Link>}
                <Link disabled={disabled} className={styles["action"]}  to={`/components/cvs/${volume.provider}/${accountId}/${credentialsId}${createVolumeRoute("delete-snapshot", volume)}`}>
                    <DeleteSnapshot/> Delete a Snapshot copy
                </Link>
                {volume.provider === "azure" &&
                <Link disabled={disabled} className={styles["action"]}  to={`/components/cvs/${volume.provider}/${accountId}/${credentialsId}${createVolumeRoute("change-pool", volume)}`}>
                    <Pyramid/> Change Service Level
                </Link>}
            </div>
        </div>
    );
};

export default ({ volume, openConfirmDeleteDialog }) => {
    const [cardFlipped, setCardFlipped] = useState(false);

    const flip = () => {
        setCardFlipped(!cardFlipped);
    };

    return (
        <div className={classNames(styles["card"], {[styles["flipped"]]: cardFlipped})}>
            {cardFlipped ? <Back openConfirmDeleteDialog={openConfirmDeleteDialog} flip={flip} volume={volume}/> : <Front volume={volume} flip={flip}/>}
        </div>)
};
