export default {
    "cloud-tiering": {
        title: "Cloud Tiering",
        link: "launchPad.services.cloudTiering"
    },
    "cloud-volumes-ontap": {
        title: "BlueXP / Cloud Volumes ONTAP"
    },
    "test-service": {
        title: "[TEST] Listing Service",
        link: "/"
    },
    "cloud-volumes-ontap-contract": {
        title: "[TEST] BlueXP / Cloud Volumes ONTAP",
        link: "/"
    },
    "astra" : {
        title: "Astra Control Service",
        link: "launchPad.services.astraSubscription",
        hideDone: true,
        hideCancel: true
    }
};
