import axios from "axios";

export default ({ getState, dispatch, next, action }) => {
    const { type, payload } = action;

    const authManagementAxios = axios.create({
        baseURL: `${process.env.REACT_APP_AUTH_MANAGER_API_URL}`,
        headers: { Authorization: `Bearer ${getState().auth.accessToken}` }
    });

    if (type === "FEDERATION-SETUP:RESEND-VERIFICATION-EMAIL") {
        axios.post("/portal/resend-verification-email")
            .then(() => {
                dispatch({ type: "FEDERATION-SETUP:RESEND-VERIFICATION-EMAIL-SUCCESS" });
            }, (error) => {
                dispatch({
                    type: "FEDERATION-SETUP:RESEND-VERIFICATION-EMAIL-ERROR",
                    payload: error.response?.data || error.message
                });
            });

        return next({ type: "FEDERATION-SETUP:RESEND-VERIFICATION-EMAIL-PENDING" });
    } else if (type === "FEDERATION-SETUP:GET-CONNECTION") {
        authManagementAxios.get(`/connections/get-connection-by-email`, { params: { email: payload.email } })
            .then(({ data: connection }) => {
                let status;
                const isSupporter = "cc:federation-support" in getState().auth.scopes;
                if (connection === '') {
                    status = "NO-CONNECTION-YET";
                } else if (connection.enabled && connection.testSucceeded) {
                    status = "CONNECTION-ENABLED";
                } else if (!isSupporter && !connection.creatorUsers.includes(getState().auth.data.sub)) {
                    status = "STARTED-BY-OTHER-USER";
                } else if (connection.testSucceeded) {
                    status = "TEST-SUCCEEDED";
                } else {
                    status = "CONNECTION-CREATED";
                }
                dispatch({
                    type: "FEDERATION-SETUP:GET-CONNECTION-SUCCESS",
                    payload: { connection, status }
                });
            }, (error) => {
                dispatch({
                    type: "FEDERATION-SETUP:GET-CONNECTION-ERROR",
                    payload: error.response?.data?.message || error.message
                });
            });

        return next({ type: "FEDERATION-SETUP:GET-CONNECTION-PENDING" });
    } else if (type === "FEDERATION-SETUP:CREATE-CONNECTION") {
        authManagementAxios.post(`/connections`, { ...payload.params, email: payload.email })
            .then(({ data: connection }) => {
                dispatch({
                    type: "FEDERATION-SETUP:CREATE-CONNECTION-SUCCESS",
                    payload: { connection, status: "CONNECTION-CREATED" }
                });
            }, (error) => {
                dispatch({
                    type: "FEDERATION-SETUP:CREATE-CONNECTION-ERROR",
                    payload: (error.response?.data?.message || error.message || "Failed to create connection") + " Please try again"
                });
            });
        return next({ type: "FEDERATION-SETUP:CREATE-CONNECTION-PENDING" });
    } else if (type === "FEDERATION-SETUP:UPDATE-CONNECTION") {
        const { connectionId, params } = payload;
        authManagementAxios.patch(`/connections/${connectionId}`, { params })
            .then(({ data: connection }) => {
                dispatch({
                    type: "FEDERATION-SETUP:UPDATE-CONNECTION-SUCCESS",
                    payload: { connection, status: "CONNECTION-CREATED" }
                });
                dispatch({ type: "MANAGEMENT:FETCH-CONNECTIONS" });
            }, (error) => {
                dispatch({
                    type: "FEDERATION-SETUP:UPDATE-CONNECTION-ERROR",
                    payload: error.response?.data?.message || error.message
                });
            });

        return next({ type: "FEDERATION-SETUP:UPDATE-CONNECTION-PENDING" });
    } else if (type === "FEDERATION-SETUP:ENABLE-CONNECTION") {
        const { connectionId } = payload;
        const companyName = getState().auth.data['http://cloud.netapp.com/user_metadata']?.company;
        authManagementAxios.post(`/connections/${connectionId}/enable`, { companyName })
            .then(({ data: connection }) => {
                dispatch({
                    type: "FEDERATION-SETUP:ENABLE-CONNECTION-SUCCESS",
                    payload: { connection, status: "CONNECTION-ENABLED" }
                });
            }, (error) => {
                dispatch({
                    type: "FEDERATION-SETUP:ENABLE-CONNECTION-ERROR",
                    payload: error.response?.data?.message || error.message
                });
            });

        return next({ type: "FEDERATION-SETUP:UPDATE-CONNECTION-PENDING" });
    } else if (type === "FEDERATION-SETUP:UPDATE-CREATOR-USER") {
        const { connectionId, users } = payload;
        console.log(users)
        authManagementAxios.patch(`/connections/${connectionId}/change-creator-user`, { creatorUser: users })
            .then(({ data: connection }) => {
                dispatch({
                    type: "FEDERATION-SETUP:UPDATE-CREATOR-USER-SUCCESS",
                    payload: { connection }
                });
            })
            .catch((error) => {
                dispatch({
                    type: "FEDERATION-SETUP:UPDATE-CREATOR-USER-ERROR",
                    payload: error.response?.data?.message || error.message
                });
            });

        return next({ type: "FEDERATION-SETUP:UPDATE-CREATOR-USER-PENDING" });
    }

    return next(action);
};
