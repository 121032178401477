import _ from 'lodash'

/*eslint default-case: 0*/

 const defaultState = () => ({stage: "CREDENTIALS"});
// const defaultState = () => ({stage: "PENDING"});
// const defaultState = () => ({stage: "SUMMARY", summary: JSON.parse('{"totalCapacity":0,"totalVolumesIncludingOntap":0,"totalVolumes":1,"totalImages":0,"totalSnapshots":2,"totalAmiSnapshots":0,"totalRelevantSnapshots":0,"reportTime":1533561729652,"protection":{"averageRPO":-1,"averageNumberOfSnapshots":0,"volumesWithoutSnapshotsCount":0,"volumesWithSnapshotsCount":0,"f_averageRPO":"- -","f_volumesWithSnapshotsCount":"0","f_volumesWithoutSnapshotsCount":"0"},"efficiency":{"snapshotsWithoutVolumesCount":0,"unattachedVolumesCount":0,"unusedVolumesCost":0,"unusedSnapshotsCost":0,"totalCost":0,"f_totalCost":"$0","f_unusedVolumesCost":"$0","f_unusedSnapshotsCost":"$0","f_snapshotsWithoutVolumesCount":"0","f_unattachedVolumesCount":"0"},"region":"eu-central-1","f_reportTime":"Aug 6, 2018","f_totalCapacity":"0.0 B","f_totalVolumes":"0"}')})

export default (state = defaultState(), {payload, type}) => {
    switch(type){
        case "CLOUD-ASSESSMENT:CLEAR-ERROR": {
            return {...state, errorMessage: null}
        }
        case "CLOUD-ASSESSMENT:RESET": {
            return {stage: "CREDENTIALS", azureUserEmail: state.azureUserEmail, azureAccessToken: state.azureAccessToken, azureSubscriptions: state.azureSubscriptions, credentials: state.credentials}
        }
        case "CLOUD-ASSESSMENT:AWS-STARTED": {
            return {...state, stage: "PENDING", errorMessage: null, credentials: {accessKey: payload.accessKey, secretKey: payload.secretKey}};
        }
        case "CLOUD-ASSESSMENT:AWS-SUCCESS-API": {
            return state;
        }
        case "CLOUD-ASSESSMENT:AWS-SUCCESS-UI": {
            return {...state, stage: "SUMMARY", data: payload, summary: payload.allRegions[0].result};
        }
        case "CLOUD-ASSESSMENT:AWS-ERROR-API": {
            return {...state, stage: "CREDENTIALS", errorMessage: payload.message};
        }
        case "CLOUD-ASSESSMENT:AWS-ERROR-UI": {
            if(payload.noRegion ) {
                payload.message = "Please select a region"
            } else if (payload.noAccessKey && payload.noSecretKey) {
                payload.message = "Please provide Access Key and Secret Key"
            } else if(payload.noAccessKey){
                payload.message = "Please provide Access Key"
            } else if (payload.noSecretKey){
                payload.message = "Please provide Secret Key"
            }

            return {...state, stage: "CREDENTIALS", errorMessage: payload.message};
        }
        case "CLOUD-ASSESSMENT:STORE-AZURE-ACCESS-TOKEN": {
            return {...state, azureAccessToken: payload.accessToken, azureUserEmail: payload.idTokenPayload.upn};
        }
        case "CLOUD-ASSESSMENT:AZURE-ACCESS-TOKEN-FAILURE": {
            return {...state, stage: "CREDENTIALS-AZURE-ACCESS-TOKEN-FAILURE", errorMessage: payload.message}
        }
        case "CLOUD-ASSESSMENT:CLEAR-AZURE-ACCESS-TOKEN": {
            return {
                ...state,
                stage: _.startsWith(state.stage, "CREDENTIALS") ? "CREDENTIALS" : state.stage,
                azureAccessToken: null,
                azureUserEmail: null,
                azureSubscriptions: null
            }
        }
        case "CLOUD-ASSESSMENT:FETCH-AZURE-SUBSCRIPTIONS": {
            return {...state, stage: "CREDENTIALS-FETCHING-SUBSCRIPTIONS"}
        }
        case "CLOUD-ASSESSMENT:FETCH-AZURE-SUBSCRIPTIONS-SUCCESS": {
            const stage = payload.subscriptions.length === 0 ? "CREDENTIALS-NO-SUBSCRIPTIONS" : "CREDENTIALS-CHOOSE-SUBSCRIPTION";
            const azureSubscriptions = payload.subscriptions.map(subscription => ({
                value: subscription.subscriptionId,
                label: `${subscription.displayName} | ${subscription.subscriptionId}`,
                displayName: subscription.displayName
            }));
            return {...state, stage, azureSubscriptions}
        }
        case "CLOUD-ASSESSMENT:FETCH-AZURE-SUBSCRIPTIONS-FAILED": {
            return {...state, stage: "CREDENTIALS-FETCH-SUBSCRIPTIONS-FAILED", errorMessage: payload.message}
        }
        case "CLOUD-ASSESSMENT:AZURE-SELECT-SUBSCRIPTION": {
            const {selectedSubscription} = payload;
            return {...state, selectedSubscription }
        }
        case "CLOUD-ASSESSMENT:AZURE-STARTED": {
            return {...state, stage: "PENDING", errorMessage: null};
        }
        case "CLOUD-ASSESSMENT:AZURE-SUCCESS": {
            const selectedSubscriptionDisplayName = _.find(state.azureSubscriptions, {value: state.selectedSubscription.value}).displayName;

            return {...state, stage: "SUMMARY", summary: _.assign(payload.result, {subscription: selectedSubscriptionDisplayName})};
        }
        case "CLOUD-ASSESSMENT:AZURE-ERROR-UI": {
            return {...state, stage: "CREDENTIALS-CHOOSE-SUBSCRIPTION", errorMessage: "Please select a subscription."};
        }
        case "CLOUD-ASSESSMENT:AZURE-ERROR": {
            return {...state, stage: "CREDENTIALS-CHOOSE-SUBSCRIPTION", errorMessage: payload.message};
        }
        case "CLOUD-ASSESSMENT:GCP-LOGIN-FAILED": {
            const { message } = payload;
            return { ...state, stage: "CREDENTIALS-GCP-ACCESS-TOKEN-FAILURE", errorMessage: message, gcpUserEmail: null, gcpToken: null, gcpAuthObj: null };
        }
        case "CLOUD-ASSESSMENT:GCP-LOGIN-SUCCESS": {
            const { gcpUserEmail, gcpToken, gcpAuthObj } = payload;
            return { ...state, stage: "CREDENTIALS-FETCHING-PROJECTS", gcpUserEmail, gcpToken, gcpAuthObj };
        }
        case "CLOUD-ASSESSMENT:FETCH-GCP-PROJECTS-SUCCESS": {
            const stage = payload.gcpProjects.length === 0 ? "CREDENTIALS-NO-PROJECTS" : "CREDENTIALS-CHOOSE-PROJECT";
            const gcpProjects = _(payload.gcpProjects)
                .filter({ lifecycleState: 'ACTIVE' })
                .map(project => ({
                    value: project.projectId,
                    label: `${project.name}`,
                    displayName: project.name
                }))
                .value();
            return { ...state, stage, gcpProjects };
        }
        case "CLOUD-ASSESSMENT:FETCH-GCP-PROJECTS-FAILED": {
            const { message } = payload;
            return { ...state, stage: "CREDENTIALS-FETCH-PROJECTS-FAILED", errorMessage: message };
        }
        case "CLOUD-ASSESSMENT:GCP-SELECT-PROJECTS": {
            const { selectedProject } = payload;
            return {...state, selectedProject };
        }
        case "CLOUD-ASSESSMENT:GCP-LOGOUT-SUCCESS": {
            window.location.href = `https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${window.location.href}`;
            return {...state, stage: "CREDENTIALS", ...payload };
        }
        case "CLOUD-ASSESSMENT:GCP-LOGOUT-FAILED": {
            const { message } = payload;
            return {...state, stage: "CREDENTIALS-CHOOSE-PROJECT", errorMessage: message };
        }
        case "CLOUD-ASSESSMENT:GCP-ERROR-UI": {
            return { ...state, stage: "CREDENTIALS-CHOOSE-PROJECT", errorMessage: "Please select a project." };
        }
        case "CLOUD-ASSESSMENT:GCP-ASSESSMENT-PENDING": {
            return { ...state, stage: "PENDING", errorMessage: null };
        }
        case "CLOUD-ASSESSMENT:GCP-SUCCESS": {
            const { summary } = payload;
            const { selectedProject } = state;
            return {...state, stage: "SUMMARY", summary: { project: selectedProject.label, ...summary.result } };
        }
        case "CLOUD-ASSESSMENT:GCP-ERROR": {
            return {...state, stage: "CREDENTIALS-CHOOSE-PROJECT", errorMessage: payload.message};
        }
        case "CLOUD-ASSESSMENT:CLEAR-GCP-ACCESS-TOKEN": {
            return {
                ...state,
                stage: "CREDENTIALS",
                gcpUserEmail: null,
                gcpToken: null,
                gcpAuthObj: null
            }
        }
    }
    return state;
};
