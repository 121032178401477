import React, { Component, useState } from 'react';
import ReactDOM from "react-dom";
import { PencilIcon as EditIcon} from "@netapp/bxp-design-system-react/icons/monochrome";
import { PassHintIcon as PasswordIcon } from '@netapp/bxp-design-system-react/icons/monochrome';
import { CheckCircleIcon as Checked } from '@netapp/bxp-design-system-react/icons/monochrome';
import { InfoIIcon as Info } from "@netapp/bxp-design-system-react/icons/monochrome";
import { connect, useDispatch, useSelector } from 'react-redux';
import styles from "./UserMenu.module.scss";
import { FormFieldInputNew, SlidingHeaderMenu } from "@netapp/bxp-design-system-react";
import Select from 'react-select';
import buttons from "../../../../styles/button/button.module.scss";
import classNames from 'classnames';
import { UserIcon } from "@netapp/bxp-design-system-react/icons/monochrome";
import button from "../../../../styles/button/button.module.scss";
import { parentIs } from "./../../../../utils/utils"
import axios from "axios";
import _ from "lodash";

const selectStyle = {
    menuList: base => ({ ...base,zIndex:2}),
    menu: base => ({ ...base,zIndex:2, borderRadius:0, padding:'10px'}),
    control: base => ({ borderWidth: 0 }),
    indicatorSeparator: () => ({display:'none'}),
    singleValue: () => ({color:'#adadad',fontSize:'13px'}),
    option: (base,{isFocused,isSelected}) => ({...base,'backgroundColor':isSelected?'#3d84ff':isFocused?'#f5f5f5':null, cursor:'pointer',textOverflow: 'ellipsis',overflow:'hidden'}),
    dropdownIndicator: () => ({position: 'absolute', right: 0, top: '2px', color:'#adadad',transform: 'rotate(-90deg)',width:'5px',height:'10px'}),
    valueContainer: () => ({paddingLeft:0})
};

class UserMenuContentDumb extends Component{
    constructor(props){
        super(props);
        this.name = this.props.auth.data["http://cloud.netapp.com/user_metadata"].name;
        this.isAuthorized = this.props.auth.scopes["cc:update-password"];
        this.state = {
            userName: this.name,
            editName: false,
            editPassword: false,
            nameLengthError: false
        };
        this.handleEdit = this.handleEdit.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleNameSubmit = this.handleNameSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleUpdatePassword = this.handleUpdatePassword.bind(this);

        this.selectRef = React.createRef();
        this.marketingMode = props.marketingMode;
    }

    changeUserAccount( selectedAccount) {
        this.props.changeAccount(selectedAccount)
    }

    handleNameChange(event) {
        this.setState({userName: event.target.value});
    }

    handleNameSubmit() {
        if(this.state.userName.length > 255 || this.state.userName.length < 1){
            this.setState({nameLengthError: true});
            return;
        }
        if (this.state.userName !== this.name) {
            this.props.updateUserInfo(this.state.userName);
        }
        this.setState({editName: false, nameLengthError: false});
    }

    handleCancel(field){
        switch (field){
            case 'Name':
                this.setState({editName: false, userName: this.name, nameLengthError: false});
                break;
            case 'Password':
                this.setState({editPassword: false});
                break;
            default:
                break;
        }
    }

    handleEdit(field) {
        switch (field){
            case 'Name':
                this.setState({editName: true, editPassword: false});
                break;
            case 'Password':
                this.setState({editPassword: true, editName: false});
                break;
            default:
                break;
        }
    }

    handleUpdatePassword() {
        this.props.changePasssword();
        this.setState({editPassword: false});
    }

    render() {
        return (
            <React.Fragment>
                <div className={styles["user-content-container"]}>
                    {this.state.editName ?
                        <UserNameEditForm handleNameChange={this.handleNameChange} handleCancel={this.handleCancel} handleSubmit={this.handleNameSubmit} userName={this.state.userName} error={this.state.nameLengthError}/> :
                        <StaticInformation isEditable={true} info={this.state.userName} infoTitle="Name" handleEdit={this.handleEdit}/>}
                    {this.state.editPassword ?
                        <PasswordUpdateForm isAuthorized={this.isAuthorized} handleCancel={this.handleCancel} handleUpdatePassword={this.handleUpdatePassword}/> :
                        <StaticInformation isEditable={true} infoTitle="Password" handleEdit={this.handleEdit}/>}
                    <StaticInformation isEditable={false} info={this.props.auth.data["http://cloud.netapp.com/user_metadata"].email} infoTitle="Email"/>
                    <StaticInformation isEditable={false} info={this.props.auth.data["http://cloud.netapp.com/user_metadata"].company} infoTitle="Company"/>
                    <div onClick={(event) => {
                      if(!parentIs(event.target, ReactDOM.findDOMNode(this.selectRef.current))) {
                        if(this.selectRef.current.state.menuIsOpen) {
                          this.selectRef.current.onMenuClose()
                        } else {
                          this.selectRef.current.onMenuOpen()
                        }
                      }
                    }} className={styles["static-details"]}>
                        <div className={styles.info} style={{marginBottom:"8px", fontWeight:"600", color:'var(--text-primary)'}}>Account</div>
                        <Select styles={selectStyle}
                                onChange={this.changeUserAccount.bind(this)}
                                options={this.props.userAccountOptions}
                                value={this.props.userAccount}
                                isSearchable={ true }
                                ref={this.selectRef}
                                className="select-component"
                                components={{Option: (option) =>
                                        <div ref={option.innerRef}  {...option.innerProps} style={option.getStyles("option",option)} title={option.label}>
                                            {option.label}
                                        </div>
                                }}/>
                    </div>
                    <EmailVerification  className={styles["email-section"]}/>
                    <div className={styles["action-button"]}>
                        <ActionButton marketingMode={this.props.marketingMode}/>    
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        userAccount: state.auth.userAccount,
        userAccountOptions: state.auth.userAccountOptions,
        marketingMode: ownProps.marketingMode,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateUserInfo: (newName) => {
            dispatch({
                type: "USER:UPDATE-INFO",
                payload: {
                    newName
                }
            })
        },
        changePasssword: () => {
            dispatch({
                type: "USER:CHANGE-PASSWORD"
            })
        },
        changeAccount: (selectedAccount) => {
            dispatch({
                type: "AUTH:USER-ACCOUNT-CHANGE",
                payload: {
                    accountId: selectedAccount.value
                }
            })
        }
    }
};

const UserMenuContent = connect(mapStateToProps, mapDispatchToProps)(UserMenuContentDumb);

export default ({isActive, triggerClass, closeTrigger, marketingMode}) => <SlidingHeaderMenu top="35px" isActive={isActive} maskClassName={marketingMode? styles["mask-class-name"] : ''} triggerClass={triggerClass}
    closeTrigger={closeTrigger} title="User Settings" Icon={UserIcon} showOnlyTitle={true} className={styles["user-details-container"]} headerClassName={styles["user-details-header"]} containerClassName={styles['sliding-menu-bottom']}>
    <UserMenuContent marketingMode={marketingMode}/>
</SlidingHeaderMenu>



const StaticInformation = ({ isEditable, info, infoTitle, handleEdit }) => {
    return (
        <div className={isEditable ? styles["static-details"] : classNames(styles["not-editable"], styles["static-details"])} onClick={isEditable ? (e) => {
            e.stopPropagation();
            handleEdit(infoTitle);
        } : null}>
            <div className={styles.content}>
                <div className={styles.text}>
                    <div className={styles.info} style={{marginTop: infoTitle === "Password" ? "-3px" : "0px"}}>{info || <PasswordIcon />}</div>
                    <div className={styles["info-name"]}>{infoTitle}</div>
                </div>
                {isEditable && <EditIcon className={styles["edit-svg"]}/>}
            </div>
        </div>
    );
};

const ActionButton = ({marketingMode}) => {
    if(marketingMode) {
        return <a className={classNames(button.secondary, "logout-button",styles["log-button"])} href="/redirect-to-login?logout=true" target="_parent">Logout</a>
    } else {
        return <button className={classNames(button.secondary, "logout-button", styles["log-button"])} onClick={() => window.auth.logout()}>Logout</button>
    }
}

const PasswordUpdateForm = ({ handleUpdatePassword, handleCancel, isAuthorized }) => (
    <div className={styles["edit-password"]}>
        <div className={styles["info-name"]}>Password</div>
        {isAuthorized ?
            <div className={styles.info}>Click '<strong>Change Password</strong>' to receive an email with further instructions.</div> :
            <div className={styles.info}>To change your password, please contact your IT department.</div>}
        <div className={styles["button-wrapper"]}>
            {isAuthorized && <button type="button" className={buttons["widget-cancel"]} onClick={(e) => {
                e.stopPropagation();
                handleCancel("Password")
            }}>Cancel</button>}
            <button type="button" className={buttons["widget-save"]} onClick={isAuthorized ? (e) => {
                e.stopPropagation();
                handleUpdatePassword()
            } : (e) => {
                e.stopPropagation();
                handleCancel("Password")
            }}>{isAuthorized ? `Change Password` : `Close`}</button>
        </div>
    </div>
);

const UserNameEditForm = ({ handleNameChange, handleCancel, handleSubmit, userName, error }) => (
    <div className={styles["edit-name"]}>
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}}>
            <div className={styles.content}>
                <div className={styles["input-restrict"]}>
                    <FormFieldInputNew
                        type="text"
                        name="Name"
                        label="Name"
                        value={userName}
                        onChange={event => handleNameChange(event)}
                        errorMessage={error && `Must be between 1-255 characters`}
                    />
                </div>
                <div className={styles["button-wrapper"]}>
                    <button type="button" className={buttons["widget-cancel"]}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCancel("Name")
                            }}>Cancel</button>
                    <button type="submit" className={buttons["widget-save"]}>
                        Rename
                    </button>
                </div>
            </div>
        </form>
    </div>
);

const EmailVerification = () => {
    const dispatch = useDispatch();
    const [emailSent, setEmailSent] = useState(false);
    const [emailSentError, setEmailSentError] = useState(null);
    const [emailSending, setEmailSending] = useState(false);
    const { isFederatedUser, isEmailVerified } = useSelector(state => state.auth);

    const sendEmail = () => {
        dispatch({
            type: "USER:SEND-VERIFICATION-EMAIL"
        });

        setEmailSending(true);
        setEmailSent(false);
        setEmailSentError(null);

        axios.post("/portal/resend-verification-email").then(
            () => setEmailSent(true),
            e => setEmailSentError(_.get(e, 'response.data.message', _.get(e, 'response.data'), e.toString()))
        ).then(() => {
            setEmailSending(false);
        });
    };

    if(emailSending){
        return <div>
            <div className={styles["info-name"]}>Email Verification</div>
            <div className={styles["info"]}>Sending...</div>
        </div>
    } else if(emailSentError){
        return <div>
            <div className={styles["info-name"]}>Email Verification</div>
            <div className={styles["info-error"]}>{emailSentError}</div>
            <div className={styles["button-wrapper"]}>
                <button type="button" className={classNames(buttons["widget-save"], styles["resend-button"])} onClick={sendEmail}>Resend Email</button>
            </div>
        </div>
    }
    else if(emailSent) {
        return <div className={styles["email-sent"]}>
            <div className={styles["info-name"]}>Email Verification</div>
            <div className={styles.info}>We sent you an Email with a verification link.</div>
            <div className={styles["button-wrapper"]}>
                <button type="button" className={classNames(buttons["widget-save"], styles["resend-button"])} onClick={sendEmail}>Resend Email</button>
            </div>
        </div>
    } else if (isFederatedUser) {
        return <div className={styles["email-sent"]}>
            <div className={styles["info-name"]}>Email Verification</div>
            <div className={styles.info}>Users that logged in through a federated IdP do not need to verify their email.</div>
        </div>
    } else {
        return <div className={classNames(styles["not-editable"], styles["static-details"])}>
            <div className={styles.content}>
                <div className={styles.text}>
                    <div className={styles["email-text"]} style={{display: "flex", alignItems: "center", marginBottom: "10px"}}>
                        {isEmailVerified ?
                            <React.Fragment>
                                <Checked className={styles["verification-svg"]}/><span>Verified</span>
                            </React.Fragment> :
                            <React.Fragment>
                                <Info className={classNames(styles["verification-svg"], styles["pending-svg"])}/><span>Pending</span>
                            </React.Fragment>
                        }
                    </div>
                    <div className={styles["info-name"]}>Email Verification</div>
                </div>
                {!isEmailVerified && <button type="button" className={classNames(buttons["widget-save"], styles["resend-button"])} onClick={sendEmail}>Resend Email</button>}
            </div>
        </div>
    }
};