import React, {useMemo, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import classNames from "classnames";
import uuidv4 from "uuid/v4";
import {useWizard, ActionButton, useForm, FormFieldInputNew, FormFieldSelect, Popover, Notifications} from "@netapp/bxp-design-system-react";
import {FooterLayout, ContentLayout} from "./Page";
import {AddItemIcon as AddIcon} from "@netapp/bxp-design-system-react/icons/monochrome";
import { CloseMonochromeIconsIcon as Close } from "@netapp/bxp-design-system-react/icons/monochrome";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import commonStyles from "../../styles/common.module.scss";
import styles from "./DetailsAndTags.module.scss";

export const Footer = () => {
    const {setStep, state} = useWizard();
    const detailsAndTagsForm = state.detailsAndTagsForm || {};
    const { handleFormSubmit } = detailsAndTagsForm;

    const submitForm = () => {
        setStep("protocol");
    };

    return (
        <FooterLayout><ActionButton disabled={!handleFormSubmit} onClick={e => handleFormSubmit(submitForm)(e)}>Continue</ActionButton></FooterLayout>
    );
};

const tagItemInitialState = { key: "", value: "", id: uuidv4() };

const detailsAndTagsInitialState =  {
    volumeName: "",
    sizeInGiB: "",
    vNet: null,
    subnet: null,
    tags: [{...tagItemInitialState}]
};

const tagFormValidator = tag => {
    const errors = {};
    if (!tag.key) {
        errors[`${tag.id}-key`] = "Tag key is required."
    }
    if (!tag.value) {
        errors[`${tag.id}-value`] = "Tag value is required."
    }
    return errors;
};

const tagsFormValidator = tags => {
    let errors = {};
    _.forEach(tags, tag => {
        const itemErrors = tagFormValidator(tag);
        if (!_.isEmpty(itemErrors)) {
            errors = { ...errors, ...itemErrors };
        }
    });
    return errors;
};

export const validator = state => {
    const errors = {};
    const tagsErrors = tagsFormValidator(state.tags);
    if (!state.volumeName) {
        errors.volumeName = "Volume name is required."
    }
    if (state.sizeInGiB) {
        const sizeInt = Number(state.sizeInGiB);
        if (isNaN(sizeInt)) {
            errors.sizeInGiB = "Size must be a number."
        }
        if (sizeInt < 100) {
            errors.sizeInGiB = "Size must be >= 100 GiB."
        }
        if (sizeInt > 100000) {
            errors.sizeInGiB = "Volume size must <= 100000 GiB."
        }
    }
    if (!state.sizeInGiB) {
        errors.sizeInGiB = "Volume size is required."
    }
    if (!state.vNet) {
        errors.vNet = "VNet selection is required."
    }
    if (!state.subnet) {
        errors.subnet = "Subnet selection is required."
    }
    if (state.tags.length === 1 && _.isEmpty(state.tags[0].key) && _.isEmpty(state.tags[0].value)) {
        // tags are optional - allow user to continue if first tag form is empty (by removing tag form errors from form errors)
        return errors;
    } else {
        return { ...tagsErrors, ...errors };
    }
};

export const Tags = ({form}) => {
    const [showListItemsErrors, setShowListItemsErrors] = useState(false);
    const { formState, handleFormChange } = form;
    const showRemovalButton = formState?.tags.length > 1;
    const tagsErrors = useMemo(() => {
        return tagsFormValidator(formState.tags)
    }, [formState]);

    const handleItemChange = index => e => {
        const updatedTags = _.map(formState.tags, (tag, i) => {
            return i === index ? ({ ...tag, [e.target.name]: e.target.value }) : tag;
        });
        handleFormChange(updatedTags, { name: "tags" });
        if (updatedTags.length === 1 && _.isEmpty(updatedTags[0].key) && _.isEmpty(updatedTags[0].value)) {
            setShowListItemsErrors(false);
        }
    };

    const handleItemRemoval = index => {
        const filteredItems = [
            ...formState.tags.slice(0, index),
            ...formState.tags.slice(index + 1, formState.tags.length)
        ];
        handleFormChange(filteredItems, { name: "tags" });
    };

    const handleItemAddition = () => {
        if (_.isEmpty(tagsErrors)) {
            const withNewItem = [...formState.tags, { ...tagItemInitialState, id: uuidv4() }];
            handleFormChange(withNewItem, { name: "tags" });
            setShowListItemsErrors(false);
        } else {
            setShowListItemsErrors(true);
        }
    };

    if (_.isEmpty(form)) {
        return null;
    }

    return (
        <div className={commonStyles["dynamic-list-form"]}>
            {_.map(formState.tags, (tag, i) => <div key={tag.id} className={styles["tag-form"]}>
                <FormFieldInputNew
                    name={"key"}
                    label="Tag Key"
                    value={formState.tags[i].key}
                    onChange={handleItemChange(i)}
                    className={classNames(commonStyles["form-field"], styles["tag-key"])}
                    showError={showListItemsErrors}
                    errorMessage={tagsErrors[`${formState.tags[i].id}-key`]}
                    maxChars={256}/>
                <FormFieldInputNew
                    name={"value"}
                    label="Tag Value"
                    value={formState.tags[i].value}
                    onChange={handleItemChange(i)}
                    className={classNames(commonStyles["form-field"], styles["tag-value"])}
                    showError={showListItemsErrors}
                    errorMessage={tagsErrors[`${formState.tags[i].id}-value`]}
                    maxChars={256}/>
                { showRemovalButton &&
                <button onClick={() => handleItemRemoval(i)}>
                    <Close/>
                </button>}
            </div>)}
            <div className={commonStyles["add-button"]}>
                <button onClick={handleItemAddition}><AddIcon/><span>Add More Tags</span></button>
            </div>
        </div>
    );
};

export const Content = () => {
    const dispatch = useDispatch();
    const {setState, state} = useWizard();
    const currentState = state?.detailsAndTagsForm?.formState || {};
    const detailsAndTagsForm = useForm({...detailsAndTagsInitialState, ...currentState, vNet: null, subnet: null }, validator);
    const {formErrors, formState, submissionAttempted} = detailsAndTagsForm;
    const vNetOptions = useSelector(state => _.get(state, "cvs.virtualNetworks"));
    const getVirtualNetworksPending = useSelector(state => _.get(state, "cvs.getVirtualNetworksPending"));
    const getVirtualNetworksError = useSelector(state => _.get(state, "cvs.getVirtualNetworksError"));
    const getVirtualNetworksShowError = useSelector(state => _.get(state, "cvs.getVirtualNetworksShowError"));
    const netappAccountFormState = state?.netappAccountForm?.formState;
    const subscriptionId = netappAccountFormState?.accountOption === "existing" ? netappAccountFormState?.netappAccount?.subscriptionId : netappAccountFormState?.subscription?.value;
    const location = netappAccountFormState?.accountOption === "existing" ? netappAccountFormState?.netappAccount?.location : netappAccountFormState?.location?.value;

    useEffect(() => {
        setState({detailsAndTagsForm});
    }, [detailsAndTagsForm, setState]);

    useEffect(() => {
        dispatch({
            type: "CVS:AZURE-GET-VIRTUAL-NETWORKS",
            payload: { subscriptionId, location }
        });
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: {
                virtualNetworks: [],
                getVirtualNetworksResolved: false,
                getVirtualNetworksError: null,
                getVirtualNetworksShowError: false
            }
        });
    }, [dispatch, subscriptionId, location]);

    const onVnetSelection = vnetOption => {
        if (!_.isEqual(vnetOption, detailsAndTagsForm.formState.vNet)) {
            detailsAndTagsForm.resetForm({ ...detailsAndTagsForm.formState, vNet: vnetOption, subnet: null });
        }
    };

    const notifications = [{
        type: "error",
        description: getVirtualNetworksError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { getVirtualNetworksShowError: false }}),
        showError: getVirtualNetworksShowError
    }];

    return (
        <ContentLayout className={styles["container"]} title={"Details & Tags"}>
            <div className={styles["content-grid"]}>
                <div className={styles["details"]}>
                    <div className={typography.body16}>Details</div>
                    <div>
                        <div className={styles["name-and-size"]}>
                            <FormFieldInputNew
                                form={detailsAndTagsForm}
                                name="volumeName"
                                label="Volume Name"
                                className={classNames(commonStyles["form-field"], styles["volume-name"])}
                                autoFocus={true}
                                maxChars={255}/>
                            <FormFieldInputNew
                                form={detailsAndTagsForm}
                                name="sizeInGiB"
                                label="Size (GiB)"
                                placeholder=">=100"
                                maxChars={256}
                                className={classNames(commonStyles["form-field"], styles["volume-size"])}
                                labelChildren={<Popover>Volume size should be greater then or equal to 100 GiB and smaller then or equal to 100000 GiB</Popover>}
                            />
                        </div>
                        <FormFieldSelect
                            name="vNet"
                            label="VNet"
                            onChange={onVnetSelection}
                            value={formState?.vNet}
                            isSearchable={true}
                            placeholder="- Select a VNet -"
                            errorMessage={formErrors.vNet}
                            showError={submissionAttempted}
                            isLoading={getVirtualNetworksPending}
                            options={vNetOptions}
                            className={classNames(commonStyles["form-field"], styles["vnet-select"])}/>
                        <FormFieldSelect
                            form={detailsAndTagsForm}
                            name="subnet"
                            label="Subnet"
                            isSearchable={true}
                            placeholder="- Select a subnet -"
                            options={formState.vNet?.subnets || []}/>
                    </div>
                </div>
                <div className={styles["tags"]}>
                    <div className={typography.body16}>Tags <i>(Optional)</i></div>
                    <Tags form={detailsAndTagsForm}/>
                </div>
            </div>
            <Notifications className={commonStyles["error-area"]} notifications={_.filter(notifications, { showError: true })}/>
        </ContentLayout>
    );
};
