import React from 'react'
import { Link, Route } from 'react-router-dom'

const BlankPage = () => <div style={{width: "100%", height: "100%", position: "fixed", top: 0, left: 0, zIndex: 99999999, backgroundColor: "#fff"}}/>;

const contentMap = {
    cds_commissions_faq: "https://fieldportal.netapp.com/content/750176"
};

export default ({auth}) => <Route path="/content/:content" exact render={({match: {params: {content}}}) => {
    const contentMatch = contentMap[content];
    if(contentMatch){
        if(!auth.authenticated) {
            window.auth.loginRedirect({}, {service: "portal", startOnSignup: false}, { pathname: `/content/${content}`});
            return <BlankPage/>
        }
        else if(contentMatch && auth.isNetAppInternal){
            window.location.replace(contentMatch);
            return <BlankPage/>
        }
    }

    return <div>
        The requested page is not available.
        <br/>
        <Link to="/">Back to Home</Link>
    </div>
}}/>