import _ from 'lodash';
import axios from 'axios';
import querystring from 'query-string';
import uuidv4 from 'uuid/v4';
import { getUserIdForGoogleAnalytics } from "cloud-central-utils/encryption";
import { history } from '../store';
import { removeLoader } from '../Loader';
import { trackCustomAction, trackPageView } from '../utils/mrTracker';
import links from "../consts/links";
import { cloudAssessmentViewLoader } from "./../chunks";

const setGoogleAnalytics = authResult => {
    if (authResult.idTokenPayload) {
        getUserIdForGoogleAnalytics(authResult.idTokenPayload.sub)
            .then(userId => {
                window.ga('set', 'userId', userId);
            });

        if (authResult.idTokenPayload['http://cloud.netapp.com/internal'] === "NetAppTest") {
            console.log("Analytics Disabled for Test Account");
            window['ga-disable-UA-115286337-1'] = true;
        } else if (authResult.idTokenPayload['http://cloud.netapp.com/internal']) {
            window.ga('set', 'dimension1', authResult.idTokenPayload['http://cloud.netapp.com/internal']);
        }
    }
};

const setAxiosToken = authResult => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${authResult.accessToken}`;
};

const handleLocationReplacements = authResult => {
    const search = querystring.parse(window.location.search);

    if (authResult.paramsForUI.pathname) { // pathname received from login page. Navigate to it.
        const path = authResult.paramsForUI.pathname.startsWith('/') ? authResult.paramsForUI.pathname : `/${authResult.paramsForUI.pathname}`;
        history.replace(path);
    } else if (search.gotoCloudAssessment || authResult.paramsForUI.continueCloudAssessment) {
        const state = {};
        if (authResult.paramsForUI.continueCloudAssessment) {
            state.from = "hubspot-continue";
            state.tracking = authResult.paramsForUI.continueCloudAssessment;
        } else {
            state.from = "hubspot-logged-in";
        }

        history.replace('/cloud-assessment', state);
    } else if (search.gotoCvoFreeTrialCampaign || authResult.paramsForUI.continueToCvoTrial) {
        const { email } = authResult.idTokenPayload;
        const extra = { tracking: uuidv4() };
        trackCustomAction({ category: "SpecialCampaign", label: "cvoFreeTrial", email, extra });
        window.location.replace(_.get(links, 'launchPad.services.occmSaas'));
    } else if (search.gotoCvoFreeTrialCampaign2 || authResult.paramsForUI.continueToCvoTrial2) {
        const { email } = authResult.idTokenPayload;
        trackCustomAction({
            category: "SpecialCampaign",
            label: "cvoFreeTrial2RedirectToSetup",
            email,
            extra: { tracking: authResult.paramsForUI.tracking }
        });
        window.location.replace(_.get(links, 'launchPad.services.occmSaas'));
    } else if (search.gotoCloudTieringFreeTrialCampaign || authResult.paramsForUI.continueToCloudTieringFreeTrialCampaign) {
        const { email } = authResult.idTokenPayload;
        trackCustomAction({
            category: "SpecialCampaign",
            label: "cloudTieringFreeTrialRedirect",
            email,
            extra: { tracking: authResult.paramsForUI.tracking }
        });
        window.location.replace(_.get(links, 'launchPad.services.cloudTiering'));
    } else {
        delete search.redirectToLogin;
        delete search.paramsForLoginPage;
        const searchString = querystring.stringify(search);

        if (redirectToAzure) {
            history.replace(`/cloud-assessment/azure${searchString ? `?${searchString}` : ''}`);
        } else {
            history.replace(window.location.pathname + (searchString ? `?${searchString}` : ''));
        }
    }
};

let delayAzureDispatch = _.noop;
let redirectToAzure = false;
export default ({ dispatch }) => next => action => {
    if (action.type === "AUTH:REFRESH-SSO") {
        if (window.location.pathname.startsWith("/misc") || window.location.pathname.startsWith("/error-page") || window.location.pathname.startsWith("/styleguide")) {
            dispatch({
                type: "AUTH:NO-AUTH"
            });
            removeLoader(true);
            trackPageView({ location: { pathname: window.location.pathname, search: window.location.search } });
            return null;
        }
        if (window.location.pathname === "/federated-connection-test") {
            removeLoader(true);

            const loginSuccess = authResult => {
                setAxiosToken(authResult);
                const authManagementAxios = axios.create({
                    baseURL: `${process.env.REACT_APP_AUTH_MANAGER_API_URL}`,
                    headers: { Authorization: `Bearer ${authResult.accessToken}` }
                });
                authManagementAxios.post(`/connections/${authResult.idTokenPayload['http://cloud.netapp.com/connection_id']}/mark-as-tested`)
                    .then(() => {
                        dispatch({
                            type: "AUTH:TEST-FEDERATION-CONNECTION-SUCCESS",
                            payload: authResult
                        });
                    }, error => {
                        loginFail({ error: 'Error in testing connection', errorDescription: error.response?.data?.message || error.message });
                    });
            };

            const loginFail = (err => {
                dispatch({
                    type: "AUTH:TEST-FEDERATION-CONNECTION-ERROR",
                    payload: err
                });
            });
            window.testAppAuth.testRefreshSso().then(loginSuccess, loginFail);
        } else if (window.location.pathname === "/redirect-to-login") {
            const search = querystring.parse(window.location.search);
            trackPageView({ location: { pathname: window.location.pathname, search: window.location.search } });
            if (search.logout) {
                window.auth.logout(links.marketingPageRedirect[0] === "/" ? null : { returnTo: links.marketingPageRedirect });
            } else {
                window.auth.loginRedirect({}, {
                    service: search.service || "portal",
                    startOnSignup: search.startOnSignup === 'true',
                    email: search.emailAddress
                });
            }

            return null;
        } else if (window.location.pathname === "/header") {
            const loginSuccess = authResult => {
                setGoogleAnalytics(authResult);
                setAxiosToken(authResult);

                dispatch({
                    type: "AUTH:REFRESH-SSO-SUCCESS",
                    payload: authResult
                });
            };

            const loginFail = (() => {
                dispatch({
                    type: "AUTH:REFRESH-SSO-FAILED"
                });
            });

            trackPageView({ location: { pathname: window.location.pathname, search: window.location.search } });
            window.auth.refreshSso(null, null, true).then(loginSuccess, loginFail);
        } else {
            const loginSuccess = authResult => {
                setGoogleAnalytics(authResult);
                setAxiosToken(authResult);

                dispatch({
                    type: "AUTH:REFRESH-SSO-SUCCESS",
                    payload: authResult
                });

                delayAzureDispatch();

                removeLoader();

                setTimeout(() => {
                    dispatch({
                        type: "AUTH:RENEW-SSO"
                    });
                }, authResult.expiresIn * 1000 - 35000);


                handleLocationReplacements(authResult);
            };

            const loginFail = ((err) => {
                const search = querystring.parse(window.location.search);
                if (search.redirectToLogin === 'true') {
                    window.history.replaceState(null, {}, `/?${querystring.stringify({
                        ...search,
                        redirectToLogin: undefined,
                        redirectBack: true
                    })}`);

                    if (search.gotoCloudAssessment) {
                        const email = search.emailAddress;
                        const extra = { tracking: uuidv4() };
                        trackCustomAction({
                            category: "CloudAssessment",
                            label: "redirectToLoginFromHubspot",
                            email,
                            extra
                        });
                        window.auth.loginRedirect({}, {
                            service: "assessment-aws",
                            startOnSignup: true,
                            email
                        }, { continueCloudAssessment: extra.tracking });
                    } else if (search.gotoCvoFreeTrialCampaign) {
                        window.auth.loginRedirect({}, {
                            service: "cvo-free-trial",
                            startOnSignup: true
                        }, { continueToCvoTrial: true });
                    } else if (search.gotoCvoFreeTrialCampaign2) {
                        const tracking = uuidv4();
                        trackCustomAction({
                            category: "SpecialCampaign",
                            label: "cvoFreeTrial2SignUp",
                            email: search.email,
                            extra: { tracking }
                        });

                        window.auth.loginRedirect({}, {
                            service: "cvo-free-trial-2",
                            email: search.email,
                            company: search.company,
                            name: `${search.firstName} ${search.lastName}`,
                            startOnSignup: true
                        }, { continueToCvoTrial2: true, tracking });
                    } else if (search.gotoCloudTieringFreeTrialCampaign) {
                        const tracking = uuidv4();
                        trackCustomAction({
                            category: "SpecialCampaign",
                            label: "cloudTieringFreeTrialSignUp",
                            email: search.email,
                            extra: { tracking }
                        });

                        window.auth.loginRedirect({}, {
                            service: "cloud-tiering-free-trial",
                            email: search.email,
                            company: search.company,
                            name: `${search.firstName} ${search.lastName}`,
                            startOnSignup: true
                        }, { continueToCloudTieringFreeTrialCampaign: true, tracking });
                    } else {
                        window.auth.loginRedirect({}, {
                            service: search.service || "portal",
                            startOnSignup: true,
                            email: search.emailAddress
                        });
                    }
                } else {
                    removeLoader();
                    dispatch({
                        type: "AUTH:REFRESH-SSO-FAILED",
                        payload: err
                    });
                    history.replace(window.location.pathname + window.location.search);
                }
            });
            if (window.location.pathname === "/cloud-assessment/azure-redirect" && window.location.hash) {
                const azureLoginToken = window.location.hash;
                delayAzureDispatch = () => {
                    cloudAssessmentViewLoader().then(() => {
                        dispatch({
                            type: 'CLOUD-ASSESSMENT:PARSE-AZURE-LOGIN-TOKEN',
                            payload: { azureLoginToken }
                        });
                    });
                }; // Delay dispatch until after logging in, but need to catch hash now
                redirectToAzure = true;
            }
            window.auth.refreshSso().then(loginSuccess, loginFail);
        }
    }
    else if (action.type === "AUTH:RENEW-SSO") {
        const loginSuccess = (authResult) => {
            setGoogleAnalytics(authResult);
            setAxiosToken(authResult);

            dispatch({
                type: "AUTH:REFRESH-SSO-SUCCESS",
                payload: authResult
            });

            setTimeout(() => {
                dispatch({
                    type: "AUTH:RENEW-SSO"
                });
            }, authResult.expiresIn * 1000 - 35000);

        };

        const loginFail = (err) => {
            if(err.error === "timeout"){
                dispatch({
                    type: "AUTH:RENEW-SSO-TIMEOUT"
                });
            } else {
                window.location.reload();
            }
        };

        window.auth.refreshSso().then(loginSuccess, loginFail);
    }
    else if(action.type === "AUTH:GET-ACCOUNTS"){
        axios.get(`${links.launchPad.services.tenancyBackend}/account`)
            .then(({ data : accounts}) => {
                dispatch({
                    payload: {
                        accounts
                    },
                    type:"AUTH:GET-ACCOUNTS-SUCCESS"
                })
            })
            .catch( error => {
                console.log("Unable to fetch accounts.");
                console.log(error);
                dispatch({
                    payload: error,
                    type:"AUTH:GET-ACCOUNTS-FAILED"
                })
            })

    }
    return next(action);
};
