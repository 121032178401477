import {gibToByte, mapSnapshotPolicyForm, mapRules} from "./common";

const _ = require("lodash");

export const gcpRegions = [
    { value: 'us-east4', label: 'us-east4 | N. Virginia' },
    { value: 'us-central1', label: 'us-central1 | Iowa' },
    { value: 'us-west2', label: 'us-west2 | Los Angeles' },
    { value: 'europe-west3', label: 'europe-west3 | Frankfurt' },
    { value: 'europe-west2', label: 'europe-west2 | London' },
    { value: 'australia-southeast1', label: 'australia-southeast1 | Sydney' },
];

export const regionMapper = _.groupBy(gcpRegions, "value");

export const serviceLevelOptions = [
    { label: "Standard", value: "low" },
    { label: "Premium", value: "medium" },
    { label: "Extreme", value: "high" }
];

export const serviceLevelMapper = {
    basic: { label: "Standard", value: "low" },
    standard: { label: "Premium", value: "medium" },
    extreme: { label: "Extreme", value: "high" },
    low: { label: "Standard", value: "low" },
    medium: { label: "Premium", value: "medium" },
    high: { label: "Extreme", value: "high" }
};

export const createVolumeData = (createVolumeForm) => {
    const {detailsAndTagsForm, protocolForm, snapshotCopyForm, snapshotPolicyForm} = createVolumeForm;
    let volumeData = {};
    if (snapshotPolicyForm.formState.enabled) {
        volumeData.snapshotPolicy = mapSnapshotPolicyForm(snapshotPolicyForm.formState);
    }

    const { volumeName, sizeInGiB, serviceLevel, vpc } = detailsAndTagsForm.formState;
    let protocolTypes = [];
    let volumePath;

    volumeData.name = volumeName;
    volumeData.quotaInBytes = gibToByte(sizeInGiB);
    volumeData.serviceLevel = serviceLevel.value.toLowerCase();
    volumeData.networkName = vpc.label;

    const { snapshot } = snapshotCopyForm.formState;
    if (snapshot) {
        volumeData.snapshotId = snapshot.value;
    }

    if (protocolForm.formState.protocolOption === "nfs") {
        volumePath = protocolForm.formState.volumePath;
        volumeData.rules = mapRules(protocolForm.formState.exportPolicies);
        if (protocolForm.formState.NFSv3) {
            protocolTypes.push("NFSv3");
        }
        if (protocolForm.formState.NFSv4) {
            protocolTypes.push("NFSv4");
        }
    }
    if (protocolForm.formState.protocolOption === "smb") {
        volumePath = protocolForm.formState.volumePath;
        protocolTypes.push("CIFS");
    }
    return { ...volumeData, protocolTypes, volumePath };
};


