import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import React, {useEffect} from "react";
import {Modal, ModalFooter, ModalHeader, Button} from "@netapp/bxp-design-system-react";
import {ModalContent} from "../../../Modal";

export default ({ volumeToDelete, target, closeTrigger}) => {
    const dispatch = useDispatch();
    const showError = useSelector(state => _.get(state, "cvs.deleteVolumeShowError"));
    const error = useSelector(state => _.get(state, "cvs.deleteVolumeError"));
    const pending = useSelector(state => _.get(state, "cvs.deleteVolumePending"));

    useEffect(() => {
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: { deleteVolumeResolved: false, deleteVolumeError: null, deleteVolumeShowError: false }
        });
    }, [dispatch]);

    const onActionClicked = () => dispatch({
        type: `CVS:${volumeToDelete.provider.toUpperCase()}-DELETE-VOLUME`,
        payload: { volumeToDelete }
    });

    return (
        <Modal>
            <ModalHeader>Delete Volume</ModalHeader>
            <ModalContent>{`Are you sure you want to delete the volume ${volumeToDelete.name}?`}</ModalContent>
            <ModalFooter error={showError && error}>
                <Button disabled={pending} isSubmitting={pending} onClick={onActionClicked}>{"Delete"}</Button>
                <Button color="secondary" disabled={pending} onClick={closeTrigger}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};
