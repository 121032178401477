import React, { Component } from 'react';
import { connect } from 'react-redux';
import querystring from 'query-string';
import { Link } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import "./RefreshTokenGenerator.scss";
import { history } from '../../store';
import * as actions from "../../actions";

const cloudAudience = 'https://api.cloud.netapp.com';
const governanceAudience = 'https://governance.cloud.netapp.com';

class RefreshTokenGenerator extends Component {
    constructor(props) {
        super(props);
        const search = querystring.parse(window.location.search);
        this.state = { includeGovernance: search.governance === 'true' };
    }

    componentWillMount() {
        const search = querystring.parse(window.location.search);
        if (search.refreshToken) {
            this.setState({ refreshToken: search.refreshToken });
            history.replace(window.location.pathname);
        } else if (this.props.currentTokens.status === actions.STATUS_NOT_SENT) {
            this.props.getCurrentTokens();
        }
    }

    render() {
        return <div className="component-container refresh-token-generator">
            <br/>
            <br/>
            <div className="component-header">
                <h3>Refresh Token Generator</h3>
            </div>
            {this.state.refreshToken &&
                <div>
                    <p>You can use this refresh token to obtain an access tokens for users. Store this refresh token securely. If necessary, you can revoke the token at a later time by navigating to the <Link to="/refresh-token"> Refresh Token Generator.</Link></p>
                    <br/>
                    <p>Note that this token is displayed on this page only—it is not stored on our servers. The token will no longer be displayed if you refresh or leave this page.</p>
                    <br/>
                    <div className="center">
                        <CopyToClipboard text={this.state.refreshToken}>
                            <button className="button primary large" style={{ float: "right" }}>Copy to clipboard
                            </button>
                        </CopyToClipboard>
                        <p>REFRESH TOKEN:</p>
                        <p><strong>{this.state.refreshToken}</strong></p>
                    </div>
                </div>}

            {!this.state.refreshToken && this.props.currentTokens.status === actions.STATUS_SUCCESS &&
                <div>
                    <p>If you log in to Cloud Central with a federated user account and you want to use the APIs, then you need to generate a <i>refresh</i> token. The refresh token enables you to generate <i>access tokens</i> which you add to the Authorization header for each API call</p>
                    <p>To learn how to use the <i>refresh token</i> to generate an <i>access token</i>, visit the <Link to="/developer-hub">API page</Link></p>
                    <br/>
                    {!this.props.currentTokens.data[cloudAudience] &&
                    <div>
                        <br/>
                        <br/>
                        <p>For general access to all Cloud Central APIs:</p>
                        <br />
                        <button className="button primary large"
                                onClick={() => this.props.generateRefreshToken(this.props.accessToken, cloudAudience)}>
                            Generate Refresh Token
                        </button>
                    </div>}

                    {this.state.includeGovernance && !this.props.currentTokens.data[governanceAudience] &&
                    <div>
                        <br/>
                        <br/>
                        <p>For general access to Governance APIs:</p>
                        <br />
                        <button className="button primary large"
                                onClick={() => this.props.generateRefreshToken(this.props.accessToken, governanceAudience, "offline_access cc:clients cc:course-admin")}>
                            Generate Refresh Token
                        </button>
                    </div>}

                    <br/>
                    <br/>
                    <br/>
                    {console.log(this.props.currentTokens)}
                    {(this.props.currentTokens.data[cloudAudience] || (this.props.currentTokens.data[governanceAudience] && this.state.includeGovernance)) &&
                    <div>
                        <br />
                        <p>Your account already has a valid refresh token. You can use your refresh token to obtain access tokens for API access.</p>
                        <p>If your refresh token was lost or stolen, you should revoke it by using the button below. This action will revoke <b>all</b> refresh tokens for your account. You will then be able to generate new refresh tokens.</p>
                        <br/>
                        <div className="center">
                            <button className="button primary large center-button"
                                    onClick={() => this.props.revokeTokens()}>
                                Revoke Token(s)
                            </button>
                        </div>
                    </div>}
                </div>}
        </div>;
    }
}

const mapDispatchToProps = dispatch => ({
        generateRefreshToken: (accessToken, audience, scope) => {
            dispatch({
                type: "USER:GENERATE-REFRESH-TOKEN",
                payload: { accessToken, audience, scope }
            });
        },
        getCurrentTokens: () => {
            dispatch({
                type: "USER:FETCH-REFRESH-TOKENS"
            });
        },
        revokeTokens: () => {
            dispatch({
                type: "USER:REVOKE-REFRESH-TOKENS"
            });
        }
    }
);

const mapStateToProps = state => ({
    accessToken: state.auth.accessToken,
    currentTokens: state.internal.refreshToken
});

export default connect(mapStateToProps, mapDispatchToProps)(RefreshTokenGenerator);
