import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import Loader from "../../../../Loader";
import NotFoundPageNew from "../NotFoundPage";
import {Header, HeaderWrapper} from "./Page";
import {PageLayout, PageLayoutNoFooter} from "../Page";
import {VolumeIcon} from "@netapp/bxp-design-system-react/icons/monochrome";
import {Wizard, WizardStepTransitionRenderer, useWizard} from "@netapp/bxp-design-system-react";
import * as OnBoarding from "./NetworkSetupAws";
import * as DetailsAndTags from "./DetailsAndTagsAws";
import * as Protocol from "./ProtocolAws";
import * as SnapshotCopy from "./SnapshotCopy";
import * as SnapshotPolicy from "./SnapshotPolicy";

const stepsMap = {
    "network-setup": OnBoarding,
    "details-and-tags": DetailsAndTags,
    "protocol": Protocol,
    "snapshot-copy": SnapshotCopy,
    "snapshot-policy": SnapshotPolicy
};

const stepPaths = {
    "not-boarded": [
        {label: "Network Setup", key: "network-setup"},
        {label: "Details & tags", key: "details-and-tags"},
        {label: "Protocol", key: "protocol"},
        {label: "Snapshot Copy", key: "snapshot-copy"},
        {label: "Snapshot Policy", key: "snapshot-policy"}
    ],
    "boarded": [
        {label: "Details And tags", key: "details-and-tags"},
        {label: "Protocol", key: "protocol"},
        {label: "Snapshot Copy", key: "snapshot-copy"},
        {label: "Snapshot Policy", key: "snapshot-policy"}
    ],
};

export const CreateVolume = () => {
    const {currentStep} = useWizard();
    const {Footer: StepFooter} = stepsMap[currentStep];

    return (
        <PageLayout>
            <Header flavour={"aws"} title={"Add Volume Wizard"}/>
            <div style={{position: "relative", overflowX: "hidden"}}>
                <WizardStepTransitionRenderer>
                    {({Content: StepContent}) => <StepContent/>}
                </WizardStepTransitionRenderer>
            </div>
            <StepFooter/>
        </PageLayout>
    );
};

export const AwsCreateVolumePageWrapper = () => {
    const dispatch = useDispatch();
    const urlParamsAreSet = useSelector(state => _.get(state, "cvs.urlParamsAreSet"));
    const isOnBoarded = useSelector(state => _.get(state, "cvs.isOnBoarded"));
    const isOnBoardedResolved = useSelector(state => _.get(state, "cvs.isOnBoardedResolved"));
    const isOnBoardedError = useSelector(state => _.get(state, "cvs.isOnBoardedError"));
    const isOnBoardedShowError = useSelector(state => _.get(state, "cvs.isOnBoardedShowError"));
    const activeDirectoryResolved = useSelector(state => _.get(state, "cvs.getActiveDirectoryResolved"));
    const activeDirectoryError = useSelector(state => _.get(state, "cvs.getActiveDirectoryError"));
    const activeDirectoryShowError = useSelector(state => _.get(state, "cvs.getActiveDirectoryShowError"));

    useEffect(() => {
        if (urlParamsAreSet) {
            dispatch({
                type: "CVS:AWS-IS-ON-BOARDED"
            });
            dispatch({
                type: "CVS:AWS-GET-ACTIVE-DIRECTORY"
            });
        }
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: {
                isOnBoardedResolved: false,
                isOnBoardedError: null,
                isOnBoardedShowError: false,
                getActiveDirectoryResolved: false,
                getActiveDirectoryError: null,
                getActiveDirectoryShowError: false,
                networkSetupResolved: false,
                networkSetupError: null,
                networkSetupShowError: false
            }
        });
    }, [urlParamsAreSet, dispatch]);


    const hideNotification = () => dispatch({
        type: "CVS:CLEAR-STATE",
        payload: { isOnBoardedShowError: false, getActiveDirectoryShowError: false }
    });

    const closeWizard = () => {
        dispatch({
            type: "CVS:CLOSE-CREATE-VOLUME-WIZARD"
        });
    };

    const render = () => {
        if (isOnBoardedError || activeDirectoryError) {
            return (
                <NotFoundPageNew
                    deleteNotification={hideNotification}
                    showError={isOnBoardedShowError || activeDirectoryShowError}
                    errorMessage={isOnBoardedError || activeDirectoryError}
                    icon={VolumeIcon} notFoundText={"Unable to load data"}
                    flavour={"aws"}
                    onPageClose={closeWizard}
                    headerText={"Cloud Volumes Services"}/>
            );
        }
        if (!isOnBoardedResolved || !activeDirectoryResolved) {
            return (
                <PageLayoutNoFooter>
                    <HeaderWrapper flavour={"aws"}/>
                    <Loader/>
                </PageLayoutNoFooter>
            );
        }

        return (
            <Wizard stepsMap={stepsMap} stepPaths={stepPaths} initialStep={isOnBoarded ? "details-and-tags" : "network-setup"} initialPath={isOnBoarded ? "boarded" : "not-boarded"}>
                <CreateVolume/>
            </Wizard>
        );
    };

    return render();
};
