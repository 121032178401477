import React, { useEffect, useState } from 'react'
import ReactDOM from "react-dom";
import styles from "./copyTextToClipboardButton.module.scss";
import { BubbleIcon } from "@netapp/bxp-design-system-react/icons/monochrome";
import { CSSTransition } from 'react-transition-group';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default ({text, Button}) => {
	const [showBubble, setShowBubble] = useState(false);
	const [bubblePosition, setBubblePosition] = useState({});
	const buttonRef = React.createRef();
	useEffect(() => {
		if(showBubble && buttonRef.current){
			const {top, left} = buttonRef.current.getBoundingClientRect();
			setBubblePosition({top: top - 31, left: left + 17})
		}
	}, [showBubble, buttonRef]);

	return <CopyToClipboard text={text} onCopy={() => {
		setShowBubble(true);
		setTimeout(() => {
			setShowBubble(false);
		})
	}}>
		<div ref={buttonRef}>
			<Button>
			</Button>
			<CSSTransition in={showBubble} unmountOnExit timeout={1000}>
					<span>
						{ReactDOM.createPortal(<span className={styles['copy-bubble']} style={bubblePosition}><BubbleIcon/><span>Copied!</span></span>, document.body)}
					</span>
			</CSSTransition>
		</div>
	</CopyToClipboard>
};