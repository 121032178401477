import React, { Component } from 'react';
import _ from "lodash";
import styles from '../../styles/header/AppHeader.module.scss';
import { HeaderWidgets } from './../../modules'
import { connect } from 'react-redux';
import * as actions from "../../actions";

const sendPostMessage = _.once(authenticated => {
    window.parent.postMessage({ message: "login-status", status: authenticated ? "success" : "fail" }, "*");
});

let fetched = false;

class StandaloneHeader extends Component {
    constructor(props) {
        super(props);
        window.document.body.classList.add("marketing-mode");
    }

    render() {
        if (this.props.auth.status !== actions.STATUS_PENDING) {
            if(this.props.auth.authenticated && !fetched) {
                fetched = true;
                this.props.getAccounts();
            }

            return <div className={[styles.marketing, styles.header].join(' ')}>
                {sendPostMessage(this.props.auth.authenticated)}
                <HeaderWidgets marketingMode={true}/>
            </div>;
        }

        return <div/>;
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    getAccounts: () => {
        dispatch({
            type: "AUTH:GET-ACCOUNTS"
        })
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(StandaloneHeader);
