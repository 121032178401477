import React, { useState } from 'react'
import _ from 'lodash'
import queryString from "query-string";
import { Layout, Card, Heading, Text, Button, Span } from "@netapp/bxp-design-system-react";
import { NetAppLogoIcon as NetAppLogo } from '@netapp/bxp-design-system-react/icons/netapp-services';
import { CloudFailedIcon as FailedCloudIcon } from "@netapp/bxp-design-system-react/icons/monochrome";

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'

const pageCss = css`
  display: grid;
  grid-template-rows: 72px 1fr;
  height: 100vh;
`

const headerCss = css`
  height: 72px;
  background-color: var(--main-header);
  display: flex;
  padding-left: 26px;

  div {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    display: inline-flex;
    align-items: center;
  }

  svg {
    width: 104px;
    height: 19px;
    --icon-primary: var(--icon-white);
    margin-right: 24px;
  }
`;

const cardCss = css`
  padding: 40px 152px;
  width: 792px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const extractUrlRegex1 = /Callback URL mismatch. (.+) is not in the list of allowed callback URLs: (.+)/;
const extractUrlRegex2 = /Callback URL mismatch. (.+) is not in the list of allowed callback URLs/;

const isChild = window.top !== window;
const FAILURE_TYPE_GTC_VALDATION = 'gtc_validation_failed';
const UNVERIFIED_EMAIL_USE = 'unverified_email_error'

const ErrorPage = () => {
    const [isDetailedErrorOpen, setDetailedErrorOpen] = useState(false);

    const parsedParams = queryString.parse(window.location.search);
    const error = parsedParams.error;
    const errorDescription = parsedParams.error_description;
    const loggedOut = parsedParams.loggedOut
    window.ga('send', {
        hitType: 'event',
        eventCategory: 'ErrorPage',
        eventAction: error,
        eventLabel: errorDescription
    });
    if (!isChild && error === "server_error" && errorDescription === "Unable to construct sso user.") {
        window.auth.logout();
        return <div />
    }

    if (!isChild && error === "invalid_request" && _.includes(errorDescription, "unexpected SAMLP Logout response")) {
        window.auth.auth.logout();
        return <div />
    }

    if (error && errorDescription && errorDescription.includes("deny_user_already_exist") && !loggedOut) {
        window.auth.auth.logout({ returnTo: window.location.href + "&loggedOut=true", federated: true });
        return <div />
    }

    if (error && errorDescription && isChild) {
        console.log("Silent callback error: ", `error=${error}&error_description=${errorDescription}`);
        window.parent.postMessage({ type: "netapp:silent-authentication", hash: `error=${error}&error_description=${errorDescription}` }, "*");
        window.parent.postMessage({ type: "callback_mismatch_error", hash: `error=${error}&error_description=${errorDescription}` }, "*"); //for old wrapper ( < 0.0.3)
        window.parent.postMessage({ type: "auth0:silent-authentication", hash: `error=${error}&error_description=${errorDescription}` }, "*"); //for old wrapper ( < 0.0.1)
    }
    let failureType = "default";
    let errorText = "You may have pressed the back button, refreshed during login, opened too many login dialogs, or there is some issue with cookies, since we couldn't find your session. Try logging in again. If the problem persists please contact NetApp Cloud Support.";

    let attemptedCallbackUrl = null;
    let possibleCallbackUrls = null;

    if (error && error.includes("unauthorized_client")) {
        failureType = "callback_url_failure";

        const regex1Match = errorDescription.match(extractUrlRegex1);
        const regex2Match = !regex1Match && errorDescription.match(extractUrlRegex2);

        if (regex1Match) {
            attemptedCallbackUrl = regex1Match[1];
            possibleCallbackUrls = regex1Match[2];
        }

        if (regex2Match) {
            attemptedCallbackUrl = regex2Match[1];
        }
    } else if (errorDescription.includes("federation_no_email")) {
        errorText = "You have attempted to login via federated connection, but your federation user does not have an email address or the federation server is not providing the email address with the authentication request. It is not possible to login without an email address. For more information, please contact NetApp Cloud Support."
    } else if (errorDescription.includes("federation_association")) {
        errorText = "You tried to log in using a federated connection, but your federation user doesn’t match the allowed domains for this federation. Check with your federation provider or contact the NetApp cloud team for help."
    } else if (errorDescription.includes("deny_user_already_exist_netapp")) {
        errorText = "The provided email address was already registered using the \"Sign in with NetApp Support Site\" option. You’ll need to log in using that option. Using multiple login methods with the same email address is not supported."
    } else if (errorDescription.includes("deny_user_already_exist_regular")) {
        errorText = "The provided email address was already registered using the \"Sign in to BlueXP\" option. You’ll need to log in using that option. Using multiple login methods with the same email address is not supported."
    } else if (errorDescription.includes("gtc_validation_error")) {
        failureType = FAILURE_TYPE_GTC_VALDATION;
    } else if (errorDescription.includes("unverified_email_error")) {
        failureType = UNVERIFIED_EMAIL_USE;
    }

    return <div css={pageCss}>
        <div css={headerCss}>
            <div><NetAppLogo />BlueXP</div>
        </div>
        <Layout.Page>
            <Layout.Content style={{ paddingTop: 104 }}>
                <Layout.Column>
                    <Card css={cardCss}>
                        <FailedCloudIcon style={{ "--icon-primary": "var(--text)", width: 40, height: 40, marginBottom: 16 }} />
                        <Heading level={2} bold style={{ marginBottom: 16 }}>Login Failed</Heading>
                        <div style={{ marginBottom: 16 }}>
                            {failureType === "callback_url_failure" && <div>
                                <Text center>The application has attempted to redirect back to <Span bold style={{ color: "var(--infographic-1)" }}>{attemptedCallbackUrl}</Span> but this URL has not been configured as a valid
                                    redirect URL. <br /><br />
                                    {possibleCallbackUrls &&
                                        <Span>You may try to connect to the application using one of the following URLs: <Span bold>{possibleCallbackUrls}</Span></Span>}
                                </Text>
                            </div>}
                            {failureType === "default" && <Text center>{errorText}</Text>}
                        </div>
                        {
                            failureType === FAILURE_TYPE_GTC_VALDATION &&
                            <Text center>
                                <Span bold>Access denied:</Span> Please contact NetApp Support at <a href="https://mysupport.netapp.com/site/help">https://mysupport.netapp.com/site/help</a> for questions or assistance.
                            </Text>
                        }

                        {
                            failureType === UNVERIFIED_EMAIL_USE &&
                            <Text center>
                                <Span>Before you can log in to BlueXP, you need to verify your email address. Check your inbox for an email from NetApp <a href="mailto:service@console.bluexp.netapp.com">service@console.bluexp.netapp.com</a> that includes instructions to verify your email address.</Span>
                            </Text>
                        }

                        {(failureType !== FAILURE_TYPE_GTC_VALDATION) && (failureType !== UNVERIFIED_EMAIL_USE) && !isDetailedErrorOpen && <Button type="text" onClick={() => setDetailedErrorOpen(!isDetailedErrorOpen)}>Show error code</Button>}
                        {isDetailedErrorOpen && <div>
                            <Span bold>{error}:</Span><Span> {errorDescription}</Span>
                        </div>}
                    </Card>
                </Layout.Column>
            </Layout.Content>
        </Layout.Page>
    </div>;
}

export default ErrorPage;