import axios from "axios";
import _ from "lodash";
import links from "../../consts/links";

export default ({ next, action, dispatch, getState }) => {
    const { type } = action;
    if (type === "TENANCY:GET-USERS") {
        const accountId = _.get(getState(), "auth.userAccount.value");
        const promise = axios.get(`${links.launchPad.services.tenancyBackend}/account/${accountId}/users`);
        promise.then(
            r => {
                dispatch({type: "TENANCY:GET-USERS-SUCCESS", payload: { users: r.data } });
                dispatch({type: "AUDIT:GET-USERS-SUCCESS", payload: { users: r.data } });
            },
            () => {
                dispatch({type: "TENANCY:GET-USERS-ERROR"});
                dispatch({type: "AUDIT:GET-USERS-SUCCESS", payload: { users: [] } });
            });
    }

    return next(action);
};
