import React from "react";
import {useSelector} from "react-redux";
import _ from "lodash";
import numeral from "numeral";
import ContentLayout, {Title} from "../ContentLayout";
import classNames from "classnames";
import {displayProtocolTypes} from "../../../../utils/common";
import {serviceLevelMapper} from "../../../../utils/gcp";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import styles from "./Content.module.scss";

const infoMapper = {
    aws: {
        state: { label: "State", value: volume => volume.lifeCycleState },
        region: { label: "Region", value: volume => volume.region },
        serviceLevel: { label: "Service Level", value: volume => volume.serviceLevel },
        protocol: { label: "Protocol", value: volume => displayProtocolTypes(volume.protocolTypes).join(', ') },
        size: { label: "Volume Size", value: volume => numeral(volume.quotaInBytes).format('0.0 ib') },
        usage: { label: "Volume Usage", value: volume => numeral(volume.usedBytes).format('0.0 ib') }

    },
    azure: {
        state: { label: "state", value: volume => volume.lifeCycleState },
        region: { label: "Location", value: volume => volume.region },
        account: { label: "NetApp Account", value: volume => volume.netAppAccountName },
        serviceLevel: { label: "Service Level", value: volume => volume.serviceLevel },
        protocol: { label: "Protocol", value: volume => displayProtocolTypes(volume.protocolTypes).join(', ') },
        size: { label: "Volume Size", value: volume => numeral(volume.quotaInBytes).format('0.0 ib') },
        usage: { label: "Volume Usage", value: volume => numeral(volume.usedBytes).format('0.0 ib') },
        poolName: { label: "Capacity Pool Name", value: volume => volume.poolName },
        poolSize: { label: "Capacity Pool Size", value: volume => numeral(volume.poolSize).format('0.0 ib') }
    },
    gcp: {
        state: { label: "state", value: volume => volume.lifeCycleState },
        region: { label: "Region", value: volume => volume.region },
        serviceLevel: { label: "Service Level", value: volume => serviceLevelMapper[volume.serviceLevel].label },
        protocol: { label: "Protocol", value: volume => displayProtocolTypes(volume.protocolTypes).join(', ') },
        size: { label: "Volume Size", value: volume => numeral(volume.quotaInBytes).format('0.0 ib') },
        usage: { label: "Volume Usage", value: volume => numeral(volume.usedBytes).format('0.0 ib') }
    }
};

export default ({volume}) => {
    const flavour = useSelector(state => _.get(state, "cvs.cvsFlavour"));
    return (
         <ContentLayout className={styles["content"]}>
             <Title className={classNames("not-spaced", styles["title"])}>Information - <span className={"vol-name"} title={volume.name}>{volume.name}</span></Title>
             <div className={styles["volume-info"]}>
                 <div className={classNames(typography.body16, styles["grid-item"], styles["overview-title"])}>
                     OVERVIEW
                 </div>
                 <div className={classNames(typography.body16, styles["grid-item"], styles["capacity-title"])}>
                     CAPACITY
                 </div>
                 <div className={classNames(styles["grid-item"], styles["state"])}>
                     <span className={styles["info-label"]}>{infoMapper[flavour].state.label}</span>
                     <span className={styles["data"]}>{infoMapper[flavour].state.value(volume)}</span>
                 </div>
                 <div className={classNames(styles["grid-item"], styles["region"])}>
                     <span className={styles["info-label"]}>{infoMapper[flavour].region.label}</span>
                     <span className={styles["data"]}>{infoMapper[flavour].region.value(volume)}</span>
                 </div>
                 {flavour === "azure" && <div className={classNames(styles["grid-item"], styles["account"])}>
                     <span className={styles["info-label"]}>{infoMapper[flavour].account.label}</span>
                     <span className={styles["data"]}>{infoMapper[flavour].account.value(volume)}</span>
                 </div>}
                 <div className={classNames(styles["grid-item"], styles["level"])}>
                     <span className={styles["info-label"]}>{infoMapper[flavour].serviceLevel.label}</span>
                     <span className={styles["data"]}>{infoMapper[flavour].serviceLevel.value(volume)}</span>
                 </div>
                 <div className={classNames(styles["grid-item"], styles["protocol"])}>
                     <span className={styles["info-label"]}>{infoMapper[flavour].protocol.label}</span>
                     <span className={styles["data"]}>{infoMapper[flavour].protocol.value(volume)}</span>
                 </div>
                 <div className={classNames(styles["grid-item"], styles["size"])}>
                     <span className={styles["info-label"]}>{infoMapper[flavour].size.label}</span>
                     <span className={styles["data"]}>{infoMapper[flavour].size.value(volume)}</span>
                 </div>
                 <div className={classNames(styles["grid-item"], styles["usage"])}>
                     <span className={styles["info-label"]}>{infoMapper[flavour].usage.label}</span>
                     <span className={styles["data"]}>{infoMapper[flavour].usage.value(volume)}</span>
                 </div>

                 {flavour === "azure" && <div className={classNames(styles["grid-item"], styles["pool-name"])}>
                     <span className={styles["info-label"]}>{infoMapper[flavour].poolName.label}</span>
                     <span className={styles["data"]}>{infoMapper[flavour].poolName.value(volume)}</span>
                 </div>}
                 {flavour === "azure" && <div className={classNames(styles["grid-item"], styles["pool-size"])}>
                     <span className={styles["info-label"]}>{infoMapper[flavour].poolSize.label}</span>
                     <span className={styles["data"]}>{infoMapper[flavour].poolSize.value(volume)}</span>
                 </div>}
             </div>
         </ContentLayout>);
};
