import * as actions from "../actions";
import _ from "lodash";

/*eslint default-case: 0*/
const defaultState = () => ({
    cloudSync: {status: actions.STATUS_PENDING},
    cloudVolumesServiceAws: {status: actions.STATUS_PENDING},
    cloudInsights: {status: actions.STATUS_PENDING},
    cloudVolumesOntap: {status: actions.STATUS_PENDING},
    cloudSecure: {status: actions.STATUS_SUCCESS},
    cloudTiering: {status: actions.STATUS_PENDING},
    cloudBackup: {status: actions.STATUS_PENDING}
});

const calculateOccmsView = (occms, hadPreviousOccm) => {
    let view = "loading";
    if(occms.length === 0 && !hadPreviousOccm) {
        view = "start-free-trial";
    } else if (occms.length === 0 && hadPreviousOccm) {
        view = "create-new";
    } else if (occms.length === 1 && occms[0].status === "ready") {
        view = "has-one-ready";
    } else if (occms.length === 1 && occms[0].status === "initializing") {
        view = "has-one-initializing";
    } else if (occms.length === 1 && occms[0].status === "failed") {
        view = "has-one-failed";
    } else if (occms.length > 1) {
        view = "has-many";
    }

    return view;
};

export default (state = defaultState(), {payload, type, status}) => {
    switch(type){
        case "LAUNCHPAD:OPEN-CLOUD-VOLUMES-SERVICE-DIALOG": {
            return {...state, cloudVolumesDialogState: payload.origin}
        }
        case "LAUNCHPAD:CLOSE-CLOUD-VOLUMES-SERVICE-DIALOG": {
            return {...state, cloudVolumesDialogState: null}
        }
        case "LAUNCHPAD:SET-OCCM-DIALOG-STATE": {
            return {...state, occmDialogState: payload}
        }
        case "LAUNCHPAD:FETCH-CLOUD-SYNC-USER-INFO-SUCCESS": {
            const data = payload.data ? payload.data : {neverSeen: true};
            const text = data.neverSeen ? "Start Free Trial" : "Go to Cloud Sync";

            return { ...state, cloudSync: {status: actions.STATUS_SUCCESS, serviceKey: "cloudSync", text, data}};
        }
        case "LAUNCHPAD:FETCH-CLOUD-SYNC-USER-INFO-ERROR": {
            return {
                ...state,
                cloudSync: { status: actions.STATUS_ERROR, serviceKey: "cloudSync", text: "Start Free Trial" }
            }
        }
        case "OCCMS": {
            switch (status){
                case actions.STATUS_SUCCESS: {
                    const { data } = payload;
                    const view = calculateOccmsView(data.occms, data.hadPreviousOccm);
                    return { ...state,
                        cloudVolumesOntap: {
                            status: actions.STATUS_SUCCESS,
                            data: { ...payload.data, occms: _.reject(payload.data.occms, 'failed') },
                            succeededOnce: true,
                            view
                        }
                    };
                }
                case actions.STATUS_ERROR:
                    return { ...state,
                        cloudVolumesOntap: {
                            status: actions.STATUS_ERROR,
                            succeededOnce: state.cloudVolumesOntap.succeededOnce,
                            view: "failed"
                        }
                    }
            }
            break;
        }
        case "LAUNCHPAD:REMOVE-OCCM-SUCCESS": {
            const occms = state.cloudVolumesOntap.data.occms;
            const newOccms = _.filter(occms, occm => {
                const json = JSON.parse(payload.config.data);
                return occm.occm !== json.occmId;
            });
            const view = calculateOccmsView(newOccms, true);
            return {
                ...state,
                occmDialogState: null,
                removeOccmState: undefined,
                cloudVolumesOntap: {
                    ...state.cloudVolumesOntap,
                    view,
                    data: { ...state.cloudVolumesOntap.data, occms: newOccms }
                }
            };
        }
        case "LAUNCHPAD:REMOVE-OCCM-ERROR": {
            return {...state, removeOccmState: {status: actions.STATUS_ERROR, data: payload}};
        }
        case "LAUNCHPAD:REMOVE-OCCM-PENDING": {
            return {...state, removeOccmState: {status: actions.STATUS_PENDING}};
        }
        case "LAUNCHPAD:UPDATE-OCCM-URIS-PENDING": {
            return {...state, editOccmState: {status: actions.STATUS_PENDING}};
        }
        case "LAUNCHPAD:UPDATE-OCCM-URIS-ERROR": {
            const data = _.get(payload, "response.data");
            const hasInvalidUris = data && _.startsWith(data.message, "Invalid URIs: ");
            let invalidUris = null;
            if(hasInvalidUris){
                invalidUris = data.message.substring(14).split(",").map(i => {
                    try{
                        return parseInt(i);
                    } catch (e) {
                        return -1;
                    }
                });
            }

            return {...state, editOccmState: {status: actions.STATUS_ERROR, data, hasInvalidUris, invalidUris}};
        }
        case "LAUNCHPAD:UPDATE-OCCM-URIS-SUCCESS": {
            const occms = state.cloudVolumesOntap.data.occms;
            const json = JSON.parse(payload.config.data);
            return {
                ...state,
                occmDialogState: null,
                editOccmState: undefined,
                cloudVolumesOntap: {
                    ...state.cloudVolumesOntap, data: {
                        ...state.cloudVolumesOntap.data, occms: _.map(occms, occm => {
                            if (occm.occm === json.occmId) {
                                const primaryCallbackUri = _.get(json.redirectUris.find(el => el.isDefault), "value", null) || occm.automaticCallbackUris[0];
                                return { ...occm, manualOverrideUris: json.redirectUris, primaryCallbackUri }
                            } else {
                                return occm;
                            }
                        })
                    }
                }
            };
        }
        case "CLEAR-UPDATE-OCCM-URIS-STATE": {
            return {...state, editOccmState: undefined}
        }
        case "LAUNCHPAD:FETCH-CLOUD-INSIGHT-USER-INFO-SUCCESS": {
            const {data: {messageText: text}} = payload;
            return {...state, cloudInsights: {status: actions.STATUS_SUCCESS, serviceKey: "cloudInsights", text}}
        }
        case "LAUNCHPAD:FETCH-CLOUD-INSIGHT-USER-INFO-ERROR": {
            return {...state, cloudInsights: {status: actions.STATUS_ERROR, serviceKey: "cloudInsights", text: "Start Free Trial"}}
        }
        case "LAUNCHPAD:FETCH-CLOUD-VOLUMES-SERVICE-AWS-USER-INFO-SUCCESS": {
            const registered = _.get(payload, "data.registered", false);
            return { ...state, cloudVolumesServiceAws: {status: actions.STATUS_SUCCESS, registered}};
        }
        case "LAUNCHPAD:FETCH-CLOUD-VOLUMES-SERVICE-AWS-USER-INFO-ERROR": {
            return { ...state, cloudVolumesServiceAws: {status: actions.STATUS_ERROR, registered: false}}
        }
        case "LAUNCHPAD:LOAD-SERVICE-DEFAULT-STATES": {
            return {...state, ...payload}
        }
    }
    return state;
};