import React, { Component } from 'react';
import classNames from "classnames";
import _ from "lodash";
import "./Dialog.scss";

export class DialogContainer extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {};
        this.closeDialogHandler = e => {
            if (e.keyCode === 8 && e.target.nodeName !== "INPUT" && e.target.nodeName !== "TEXTAREA") {
                e.preventDefault();
            }

            if (e.keyCode === 27) {
                this.closeDialog();
            }
        };
    }

    componentWillMount() {
        dialogContainer = this;
    }

    setDialog(dialog, parent, closer) {
        if (this.state.dialog === dialog) {
            return;
        }

        this.setState({ closer, dialog, style: (parent ? { opacity: 0 } : {}) });

        document.addEventListener('keydown', this.closeDialogHandler);

        if (parent) {
            const parentBr = parent.getBoundingClientRect();
            setTimeout(() => {
                const modalBr = this._modal.getBoundingClientRect();

                const x = (parentBr.left + parentBr.width / 2) - modalBr.left;
                const y = (parentBr.top + parentBr.height / 2) - (modalBr.top + modalBr.height);
                this.setState(Object.assign({}, this.state, {
                    style: {
                        opacity: 0,
                        transform: `translate(${x}px, ${y}px) scale(0)`
                    }
                }));

                setTimeout(() => {
                    this.setState(Object.assign({}, this.state, {
                        style: {
                            transition: "transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1)",
                            transformOrigin: "0 100%",
                            opacity: 1,
                            transform: "translate(0, 0) scale(1)"
                        }
                    }));
                }, 100);
            });
        }
    }

    closeDialog() {
        if (this.state.closer) {
            this.state.closer();
        } else {
            if (this.state.dialog) {
                document.removeEventListener('keydown', this.closeDialogHandler);
            }

            this.setState({ dialog: null, closer: null });
        }
    }

    closeDialogNew() {
        if (this.state.dialog) {
            document.removeEventListener('keydown', this.closeDialogHandler);
        }

        this.setState({ dialog: null, closer: null });
    }

    render() {
        const content = this.state.dialog ?
            React.cloneElement(this.state.dialog, { closeDialog: this.closeDialog.bind(this) }) :
            null;
        return (
            content ? (
                <div className={classNames("dialog", content.props.className)}>
                    <div className="mask" onClick={this.closeDialog.bind(this)}/>
                    <div className="modal" ref={el => {
                        this._modal = el;
                    }} style={this.state.style}>
                        {content}
                    </div>
                </div>) : <div/>
        );
    }
}

let dialogContainer;

export const openDialog = (dialog, target, closer) => {
    let parent = target;
    if (_.isFunction(target)) {
        parent = target();
    }
    dialogContainer.setDialog(dialog, parent, closer);
};

export class Dialog extends Component {
    constructor(props) {
        super(props);

        if (this.props.open) {
            openDialog(this.props.children, this.props.target, this.props.closeTrigger);
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            openDialog(this.props.children, this.props.target, this.props.closeTrigger);
        } else if (prevProps.open && !this.props.open) {
            dialogContainer.closeDialogNew();
        }
    }

    componentWillUnmount() {
        if (this.props.open) {
            this.props.closeTrigger();
            dialogContainer.closeDialogNew();
        }
    }

    render() {
        return "";
    }
}
