import React from 'react';
import { connect } from 'react-redux';
import classNames from "classnames";
import { CheckCircleTwoColorIconsIcon as CheckCircle } from '@netapp/bxp-design-system-react/icons/2-colors';
import { ErrorMonochromeIconsIcon as ErrorCircle } from '@netapp/bxp-design-system-react/icons/monochrome';
import AppHeader from '../AppHeader';
import styles from "./FederationSetup.module.scss";
import * as actions from "../../actions";

const FederationTestPage = ({ testStatus, error }) => <div className={classNames(styles['federation-setup'], styles['federation-setup-test-success'])}>
    <AppHeader hideWidgets={true}/>
    {testStatus === actions.STATUS_PENDING && <div className={styles["main-content-container"]}>
        <h4 style={{ paddingTop: 120 }}>Testing your connection, please wait...</h4>
    </div>}
    {testStatus === actions.STATUS_SUCCESS && <div className={styles["main-content-container"]}>
        <CheckCircle/>
        <h3>Federation setup test<br/>
            was completed successfully!
        </h3>
        <h4>To continue, please close this tab and refresh the federation setup page.</h4>
    </div>}
    {testStatus === actions.STATUS_ERROR && <div className={styles["main-content-container"]}>
        <ErrorCircle/>
        <h3>Oops! Something went wrong...</h3>
        {error && <h4>{error.error}: {error.errorDescription}</h4>}
        <p>To continue, please close this tab, go back to the federation setup page, try again or contact support.</p>
    </div>}
</div>;

const mapStateToProps = state => ({
    testStatus: state.auth.testStatus,
    error: state.auth.error
});

export default connect(mapStateToProps)(FederationTestPage);
