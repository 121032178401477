import React, {useRef, useState} from "react";
import _ from "lodash";
import classNames from "classnames";
import * as yup from "yup";
import ActiveDirectoryForm from "../../../ActiveDirectoryForm";
import {ActionButton, useForm, Notification, useModal, MenuPopover} from "@netapp/bxp-design-system-react";
import {dnsRegx, domainRegx} from "../../../../utils/common";
import {useDispatch, useSelector} from "react-redux";
import {activeDirectoryProps} from "../../../../utils/aws";
import DeleteActiveDirectoryModal from "./DeleteActiveDirectoryModal";
import Content, {Title} from "../../common/ContentLayout";
import ActionButtonContainer from "../../common/ActionButtonContainer";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import commonStyles from "../../../../styles/common.module.scss";
import styles from "./Content.module.scss";

const initialState = {
    DNS: "",
    netBIOS: "",
    domain: "",
    username: "",
    password: "",
    organizationalUnit: ""
};

const schema = yup.object().shape({
    DNS: yup.string().matches(dnsRegx, "Invalid DNS.").required("Primary IP is required."),
    netBIOS: yup.string().required("Primary IP is required."),
    domain: yup.string().matches(domainRegx, "Invalid domain.").required("Domain is required."),
    username: yup.string().required("User Name is required."),
    password: yup.string().required("Password is required."),
    organizationalUnit: yup.string().required("Organisational unit is required."),
});

const Info = ({activeDirectory}) => {
    return (
        <div className={styles["ad-info"]}>
            <div className={classNames(styles["ad-grid-item"], typography.body16)}>
                Details
            </div>
            <div className={styles["ad-grid-item"]}/>
            <div className={classNames(styles["ad-grid-item"])}>
                <span className={styles["info-label"]}>Active Directory Domain</span>
                <span className={styles["data"]}>{activeDirectory.domain}</span>
            </div>
            <div className={classNames(styles["ad-grid-item"])}>
                <span className={styles["info-label"]}>Organizational Unit</span>
                <span className={styles["data"]}>{activeDirectory.organizationalUnit}</span>
            </div>
            <div className={classNames(styles["ad-grid-item"])}>
                <span className={styles["info-label"]}>DNS Primary IP Address</span>
                <span className={styles["data"]}>{activeDirectory.DNS}</span>
            </div>
            <div className={classNames(styles["ad-grid-item"])}>
                <span className={styles["info-label"]}>SMB Server NetBIOS Name</span>
                <span className={styles["data"]}>{activeDirectory.netBIOS}</span>
            </div>
            <div className={classNames(styles["ad-grid-item"])}>
                <span className={styles["info-label"]}>User Name</span>
                <span className={styles["data"]}>{activeDirectory.username}</span>
            </div>
        </div>
    );
};

const stateMapper = {
    create: { title: "Join Active Directory", component: ({form}) => <ActiveDirectoryForm form={form}/> },
    info: { title: "Active Directory", component: ({activeDirectory}) => <Info activeDirectory={activeDirectory}/> },
    edit: { title: "Edit Active Directory", component: ({form}) => <ActiveDirectoryForm form={form}/> }
};

export default ({activeDirectory}) => {
    const dispatch = useDispatch();
    const createActiveDirectoryPending = useSelector(state => _.get(state, "cvs.createActiveDirectoryPending"));
    const createActiveDirectoryError = useSelector(state => _.get(state, "cvs.createActiveDirectoryError"));
    const createActiveDirectoryShowError = useSelector(state => _.get(state, "cvs.createActiveDirectoryShowError"));
    const editActiveDirectoryPending = useSelector(state => _.get(state, "cvs.editActiveDirectoryPending"));
    const editActiveDirectoryError = useSelector(state => _.get(state, "cvs.editActiveDirectoryError"));
    const editActiveDirectoryShowError = useSelector(state => _.get(state, "cvs.editActiveDirectoryShowError"));
    const [state, setStat] = useState(_.isEmpty(activeDirectory) ? "create" : "info");
    const target = useRef(null);
    const { isModalOpen, openModal, closeModal } = useModal();
    const form = useForm(state === "create" ? initialState : {...initialState, ..._.pick(activeDirectory, activeDirectoryProps)}, schema);

    const createActiveDirectory = () => dispatch({
        type: "CVS:AWS-CREATE-ACTIVE-DIRECTORY",
        payload: { activeDirectory: { ..._.pick(form.formState, activeDirectoryProps) }, caller: "volumes-gallery" }
    });

    const editActiveDirectory = () => dispatch({
        type: "CVS:AWS-EDIT-ACTIVE-DIRECTORY",
        payload: { activeDirectory: { ..._.pick(form.formState, activeDirectoryProps), activeDirectoryId: activeDirectory.UUID }, caller: "volumes-gallery" }
    });

    const editAd = () => {
        form.resetForm({ ...activeDirectory, password: "" });
        setStat("edit");
    };

    const hideNotification = () => dispatch({
        type: "CVS:CLEAR-STATE",
        payload: { editActiveDirectoryShowError: false, createActiveDirectoryShowError: false }
    });

    const menu = [
        { key: "edit-ad", label: "Edit Active Directory", onClick: editAd },
        { key: "delete-we", label: "Delete Active Directory",  onClick: openModal },
    ];

    return (
        <Content>
            <Title>
                <span>{stateMapper[state].title}</span>
                {state !== "create" && <MenuPopover menu={menu}/>}
            </Title>
            {stateMapper[state].component({form, activeDirectory})}
            {state !== "info" && <ActionButtonContainer>
                <ActionButton
                    isSubmitting={createActiveDirectoryPending || editActiveDirectoryPending}
                    onClick={e => form.handleFormSubmit(state === "create" ? createActiveDirectory : editActiveDirectory)(e)}>Save</ActionButton>
            </ActionButtonContainer>}
            {isModalOpen && <DeleteActiveDirectoryModal target={target} formState={form.formState} activeDirectory={activeDirectory} closeTrigger={closeModal}/>}
            {editActiveDirectoryShowError && <div className={commonStyles["error-area"]}>
                <Notification type={'error'} onClose={hideNotification}>{editActiveDirectoryError}</Notification>
            </div>}
            {createActiveDirectoryShowError && <div className={commonStyles["error-area"]}>
                <Notification type={'error'} onClose={hideNotification}>{createActiveDirectoryError}</Notification>
            </div>}
        </Content>
    );
};
