import React, {useState} from 'react';
import styles from "./FederationSetup.module.scss";
import {FormFieldInputNew, Button} from "@netapp/bxp-design-system-react";
import {SearchIcon as Search} from "@netapp/bxp-design-system-react/icons/monochrome";

const SelectSupportEmail = ({ selectSupportEmail }) => {
	const [supportEmail,setSupportEmail] = useState("");
	return <div className={styles['support-email-container']}>
		<p>Please Select user email for federation setup</p>
		<div className={styles['support-email-field']}>
			<form>
				<FormFieldInputNew
					type="email"
					value={supportEmail}
					onChange={e => setSupportEmail(e.target.value)}
					placeholder={"User email"}
				/>
				<Button onClick={()=>selectSupportEmail(supportEmail)}><Search/></Button>
			</form>

		</div>
	</div>
};
export default SelectSupportEmail;