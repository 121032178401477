import _ from "lodash";
import * as actions from "../actions";
/* eslint default-case: 0 */

const normalizeSelectedClient = selectedClient => ({
        ...selectedClient,
        allowedCallbackUrls: _.join(selectedClient.allowedCallbackUrls, ","),
        allowedWebOrigins: _.join(selectedClient.allowedWebOrigins, ","),
        allowedLogoutUrls: _.join(selectedClient.allowedLogoutUrls, ","),
        allowedCors: _.join(selectedClient.allowedCors, ",")
    }
);

export default (state = { managementClients: { status: actions.STATUS_PENDING } }, { payload, type }) => {
    switch (type) {
        case "DEVELOPERS:FETCH-CLIENTS-LIST": {
            return { ...state, managementClients: { status: actions.STATUS_PENDING } };
        }
        case "DEVELOPERS:FETCH-CLIENTS-LIST-SUCCESS": {
            const hasClients = payload.data.clients.length > 0;
            if (hasClients) {
                return {
                    ...state,
                    managementClients: {
                        status: "SUCCESS",
                        clients: payload.data.clients,
                        selectedClient: normalizeSelectedClient(payload.data.selectedClient),
                        selectedClientId: payload.data.selectedClient.clientId
                    }
                };
            }
            return { ...state, managementClients: { status: "NO-CLIENTS" } };
        }
        case "DEVELOPERS:FETCH-CLIENTS-LIST-ERROR": {
            const { response } = payload;
            let isForbidden = false;
            let errorMessage = null;

            if (response.status === 403) {
                isForbidden = true;
            } else {
                errorMessage = _.get(response, "data.message");
            }

            return { ...state, managementClients: { status: isForbidden ? "NO-CLIENTS" : "ERROR", errorMessage } };
        }
        case "DEVELOPERS:FETCH-CLIENT-DETAILS-PENDING": {
            return {
                ...state,
                managementClients: {
                    ...state.managementClients,
                    selectedClientId: payload.clientId,
                    selectedClient: null,
                    selectedClientState: "PENDING",
                    clientDetailsUpdateMessage: null
                }
            };
        }
        case "DEVELOPERS:FETCH-CLIENT-DETAILS-SUCCESS": {
            return {
                ...state,
                managementClients: {
                    ...state.managementClients,
                    selectedClient: normalizeSelectedClient(payload.data),
                    selectedClientState: "SUCCESS"
                }
            };
        }
        case "DEVELOPERS:FETCH-CLIENT-DETAILS-ERROR": {
            const errorMessage = _.get(payload, "response.data.message");
            return {
                ...state,
                managementClients: {
                    ...state.managementClients,
                    selectedClient: null,
                    selectedClientState: "ERROR",
                    selectedClientError: errorMessage
                }
            };
        }
        case "DEVELOPERS:CLIENTS-MANAGEMENT-SAVE-CLIENT": {
            return { ...state, managementClients: { ...state.managementClients, clientDetailsUpdateMessage: null } };
        }
        case "DEVELOPERS:CLIENTS-MANAGEMENT-SAVE-CLIENT-SUCCESS": {
            return {
                ...state,
                managementClients: {
                    ...state.managementClients,
                    clientDetailsUpdateMessage: "Client updated successfully!",
                    clientDetailsUpdateMessageType: "success"
                }
            };
        }
        case "DEVELOPERS:CLIENTS-MANAGEMENT-SAVE-CLIENT-ERROR": {
            return {
                ...state,
                managementClients: {
                    ...state.managementClients,
                    clientDetailsUpdateMessage: _.get(payload, "response.data.message", "Failed to save client"),
                    clientDetailsUpdateMessageType: "error"
                }
            };
        }
        case "DEVELOPERS:CLIENTS-MANAGEMENT-CLEAR-CLIENT-DETAILS-MESSAGE": {
            return { ...state, managementClients: { ...state.managementClients, clientDetailsUpdateMessage: null } };
        }
    }
    return state;
};
