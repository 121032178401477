import React from 'react';
import {FormFieldInputNew} from "@netapp/bxp-design-system-react";
import styles from "./FederationSetup.module.scss";
import buttons from "../../styles/button/button.module.scss";

const UploadFileComponent = ({ name, label, value, placeholder, uploadFile }) => (<div style={{ display: 'flex' }}>
	<div style={{ flexGrow: 1 }}>
		<FormFieldInputNew
			type="text"
			name={name}
			label={label}
			value={value}
			disabled={true}
			placeholder={placeholder}
		/>
	</div>
	<div className={styles["upload-button-wrapper"]}>
		<button type="button" className={buttons["secondary"]}>+ Upload file</button>
		<input type="file" name="myfile" onChange={el => uploadFile(el)}/>
	</div>
</div>);
export default UploadFileComponent;