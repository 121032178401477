import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import React, {useEffect} from "react";
import {Modal, ModalFooter, ModalHeader, Button} from "@netapp/bxp-design-system-react";
import {ModalContent} from "../../../Modal";

export default ({ activeDirectory, closeTrigger}) => {
    const dispatch = useDispatch();
    const error = useSelector(state => _.get(state, "cvs.deleteActiveDirectoryError"));
    const showError = useSelector(state => _.get(state, "cvs.deleteActiveDirectoryShowError"));
    const pending = useSelector(state => _.get(state, "cvs.deleteActiveDirectoryPending"));

    useEffect(() => {
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: { deleteActiveDirectoryResolved: false, deleteActiveDirectoryError: null, deleteActiveDirectoryShowError: false }
        });
    }, [dispatch]);

    const onActionClicked = () => dispatch({
        type: "CVS:AWS-DELETE-ACTIVE-DIRECTORY",
        payload: { activeDirectoryId: activeDirectory.UUID }
    });

    return (
        <Modal>
            <ModalHeader>Delete Active Directory</ModalHeader>
            <ModalContent>Are you sure you want to delete the configured active directory?</ModalContent>
            <ModalFooter error={showError && error}>
                <Button disabled={pending} isSubmitting={pending} onClick={onActionClicked}>{"Delete"}</Button>
                <Button color="secondary" disabled={pending} onClick={closeTrigger}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};
