import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import { BackIcon} from "@netapp/bxp-design-system-react/icons/monochrome";
import ActiveDirectoryForm from "../ActiveDirectoryForm";
import commonStyles from "../../styles/common.module.scss";
import styles from "./Protocol.module.scss";
import {FormFieldInputNew} from "@netapp/bxp-design-system-react";
import classNames from "classnames";

export const Smb = ({ form, disabled }) => {
    return (
        <FormFieldInputNew
            form={form}
            name="volumePath"
            label="Volume Path"
            maxChars={256}
            disabled={disabled}
            autoFocus={true}
            className={classNames(commonStyles["form-field"], styles["sole-filed"])}/>
    );
};

export const ActiveDirectory = ({ form }) => {
    const dispatch = useDispatch();
    const {handleFormChange, formState, resetForm} = form;
    const editMode = formState.editActiveDirectoryMode;
    const existingActiveDirectory = useSelector(state => _.get(state, `cvs.activeDirectory`));
    const disabled = !_.isEmpty(existingActiveDirectory) && !editMode;

    useEffect(() => {
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: {
                createActiveDirectoryError: null,
                createActiveDirectoryShowError: false,
                createActiveDirectoryResolved: false,
                editActiveDirectoryError: null,
                editActiveDirectoryShowError: false,
                editActiveDirectoryResolved: false,
            }
        });
    }, [dispatch]);

    const onBackClicked = () => {
        handleFormChange(0, { name: "internalStep" });
    };

    const setEditAdMode = () => {
        const nextEditModeState = !formState.editActiveDirectoryMode;
        resetForm({ ...formState, editActiveDirectoryMode: nextEditModeState, password: nextEditModeState ? "" : existingActiveDirectory.password });
    };

    return (
        <div className={styles["smb-second-step-container"]}>
            <div className={styles["back"]}>
                <button onClick={onBackClicked}><BackIcon/></button>
                <span>SMB Connectivity Setup</span>
            </div>
            {!_.isEmpty(existingActiveDirectory) && <div className={styles["edit-ad"]}><span>SMB connectivity was already set for this working environment.</span><button onClick={setEditAdMode} className={commonStyles["in-text-button"]}>{editMode ? "Back to default" : "Change setup"}</button></div>}
            <ActiveDirectoryForm form={form} disabled={disabled}/>
        </div>
    );
};

