import launchpadReducer from './launchpadReducer';
import internalReducer from './internalReducer';
import authReducer from './authReducer';
import persistedStateReducer from "./persistedStateReducer";
import cloudAssessmentReducer from "./cloudAssessmentReducer";
import featuresReducer from "./featuresReducer";
import managementReducer from "./managementReducer";
import developersReducer from "./developersReducer";
import developerHubReducer from "./developerHubReducer";
import accountsManagementReducer from "./accountsManagementReducer";
import federationSetupReducer from "./federationSetupReducer";
import demoReducer from "./demoReducer";
import dashboardsReducer from "./dashboardsReducer";
import subscriptionsReducer from "./subscriptionsReducer";
import uiReducer from "./uiReducer";
import chargingWhitelistingReducer from "./chargingWhitelistingReducer";
import tenancyReducer from "./tenancyReducer";

export default () => ({
    services: launchpadReducer,
    auth: authReducer,
    internal: internalReducer,
    cloudAssessment: cloudAssessmentReducer,
    persisted: persistedStateReducer,
    features: featuresReducer,
    management: managementReducer,
    developers: developersReducer,
    developerHub: developerHubReducer,
    accountsManagement: accountsManagementReducer,
    federationSetup: federationSetupReducer,
    demo: demoReducer,
    dashboards: dashboardsReducer,
    subscriptions: subscriptionsReducer,
    ui: uiReducer,
    charging: chargingWhitelistingReducer,
    tenancy: tenancyReducer
});
