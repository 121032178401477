import React from 'react';
import SmartBack from "../widgets/SmartBack";
import styles from "./CloudCentralSecondaryHeader.module.scss";

export default ({title, smartBack, children}) => <header className={styles.base}>
    <div className={styles.content}>
        {smartBack && <SmartBack/>}
        <h1>{title}</h1>
        {children}
    </div>
</header>;