import axios from "axios";
import _ from "lodash";
import pLimit from "p-limit";
import links from "../../consts/links";

export default ({ getState, dispatch, next, action }) => {
    const {type, payload} = action;
    const activeFeatures = getState().features.active;
    const constructURL = (endPath) => {
        if (activeFeatures.newSubServiceDevEnv) {
            return `${links.launchPad.services.subscriptionDevBackend}/${endPath}`
        } else if (activeFeatures.newSubServiceStgEnv) {
            return `${links.launchPad.services.subscriptionStgBackend}/${endPath}`
        } else return `/${endPath}`
    }
    if (type === "SUBSCRIPTIONS:RESOLVE-AWS-TOKEN") {
        const promise = axios.post(constructURL("subscriptions/resolve-aws"), {
            registrationToken: payload.registrationToken,
            suggestedName: getState().auth.email.split("@")[1]
        });

        promise.then(
            r => dispatch({type: "SUBSCRIPTIONS:RESOLVE-AWS-TOKEN-SUCCESS", payload: r}),
            e => dispatch({type: "SUBSCRIPTIONS:RESOLVE-AWS-TOKEN-ERROR", payload: _.get(e, "response.data.message")}));

        return next({type: "SUBSCRIPTIONS:RESOLVE-AWS-TOKEN-PENDING"});
    } else if (type === "SUBSCRIPTIONS:RESOLVE-GCP-TOKEN") {
        const promise = axios.post(constructURL("subscriptions/resolve-gcp"), {
            registrationToken: payload.registrationToken,
            suggestedName: getState().auth.email.split("@")[1],
            serviceName: payload.serviceName
        });

        promise.then(
            r => dispatch({type: "SUBSCRIPTIONS:RESOLVE-GCP-TOKEN-SUCCESS", payload: r}),
            e => dispatch({type: "SUBSCRIPTIONS:RESOLVE-GCP-TOKEN-ERROR", payload: _.get(e, "response.data.message")}));

        return next({type: "SUBSCRIPTIONS:RESOLVE-GCP-TOKEN-PENDING"});
    } else if (type === "SUBSCRIPTIONS:RESOLVE-AZURE-TOKEN") {
        //TODO: why do i get it with white space
        const promise = axios.post(constructURL("subscriptions/resolve-azure"), {
            registrationToken: payload.registrationToken.replace(/ /g, '+')
        });

        promise.then(
            r => dispatch({type: "SUBSCRIPTIONS:RESOLVE-AZURE-TOKEN-SUCCESS", payload: r}),
            e => dispatch({type: "SUBSCRIPTIONS:RESOLVE-AZURE-TOKEN-ERROR", payload: _.get(e, "response.data.message")}));

        return next({type: "SUBSCRIPTIONS:RESOLVE-AZURE-TOKEN-PENDING", payload: {}});
    } else if (type === "SUBSCRIPTIONS:SAVE") {
        const { newName: subscriptionName, accountId, subscriptionId, removeAccounts,automaticCredsAccount } = payload;
        const promise = axios.patch(`${links.launchPad.services.licenseBackend}/subscriptions/subscription`, {
            accountId,
            subscriptionId,
            subscriptionName
        });
        promise.then(
            r => {
                if(automaticCredsAccount){
                    const replacePromise = axios.patch(`${links.launchPad.services.saasMp}/account/${automaticCredsAccount}/subscription/${subscriptionId}`);
                    replacePromise.then((res)=>{
                        dispatch({ type: "SUBSCRIPTIONS:REPLACE-CONFIG-SUCCESS", payload: res });
                    })
                    replacePromise.catch(e=>{
                        dispatch({ type: "SUBSCRIPTIONS:REPLACE-CONFIG-ERROR", payload: _.get(e, "response.data.message", e.message || e)})
                    })
                }
                const limit = pLimit(1);
                const removeAccountsPromises = Promise.all(_.map(removeAccounts, ({ accountId }) =>
                    limit(() => axios.delete(`${links.launchPad.services.licenseBackend}/subscriptions/account/${accountId}`, { data: { subscriptionId } }))));

                return removeAccountsPromises.then(() => {
                    dispatch({ type: "SUBSCRIPTIONS:SAVE-SUCCESS", payload: r });
                });
            }).catch(e => {
                dispatch({type: "SUBSCRIPTIONS:SAVE-ERROR", payload: _.get(e, "response.data.message", e.message || e)})
        });

        return next({type: "SUBSCRIPTIONS:SAVE-PENDING"});

    }
    return next(action);
};
