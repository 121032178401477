import React, {useEffect, useRef} from "react";
import {PageLayout, BaseHeader, ContentLayout, BaseFooter} from "../Page";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import classNames from "classnames";
import SupportModal from "../SupportModal";
import {useModal, ActionButton, useForm, Notifications} from "@netapp/bxp-design-system-react";
import Loader from "../../../../Loader";
import {iconMapper} from "../../utils/common";
import {SupportMonochromeIconsIcon as Support} from "@netapp/bxp-design-system-react/icons/monochrome";
import {BackToPreviousStep} from "./ToPreviousStepButton";
import {initialState as gcpInitialState, schema as gcpValidator} from "./FormGcp";
import {initialState as awsInitialState, schema as awsValidator} from "./FormAws";
import {initialState as azureInitialState, schema as azureValidator} from "./FormAzure";
import InfoAzure from "./InfoAzure";
import InfoGcp from "./InfoGcp";
import InfoAws from "./InfoAws";
import FormAzure from "./FormAzure";
import FormAws from "./FormAws";
import FormGcp from "./FormGcp";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import commonStyles from "../../styles/common.module.scss";
import styles from "./Page.module.scss";

const mapper = {
    aws: {
        formTitle: "Cloud Volumes Service Credentials",
        sideTitle: "Add Cloud Volumes Service Wizard",
        mainTitle: "Cloud Volumes Service for AWS Details",
        initialState: awsInitialState,
        validator: awsValidator,
        info: InfoAws,
        form: FormAws
    },
    gcp: {
        formTitle: "Cloud Volumes Service Credentials",
        sideTitle: "Add Cloud Volumes Service Wizard",
        mainTitle: "Cloud Volumes Service for GCP Details",
        initialState: gcpInitialState,
        validator: gcpValidator,
        info: InfoGcp,
        form: FormGcp
    },
    azure: {
        formTitle: "Azure NetApp Files Credentials",
        sideTitle: "Add Azure NetApp Files Wizard",
        mainTitle: "Azure NetApp Files Details",
        initialState: azureInitialState,
        validator: azureValidator,
        info: InfoAzure,
        form: FormAzure
    },
};

const Header = ({flavour}) => {
    const supportModalRef = useRef(null);
    const { isModalOpen, openModal, closeModal } = useModal();

    const closeWizard = () => {
        window.parent.postMessage({ action: "cvs-add-we:close", provider: flavour }, "*");
    };

    return (
        <BaseHeader className={styles["header"]} onClose={closeWizard}>
            <div className={classNames(typography.body16, styles["header-title"])}>{mapper[flavour].sideTitle}</div>
            <span className={classNames(typography.h01, styles["flavour-title"])}>{mapper[flavour].mainTitle}</span>
            {flavour === "aws" && <button onClick={() => openModal()} className={styles["support-btn"]} ref={supportModalRef}><Support className={commonStyles["support-icon"]}/>Support Help</button>}
            {isModalOpen && <SupportModal closeTrigger={closeModal} target={supportModalRef}/>}
        </BaseHeader>
    );
};

export const FooterLayout = ({children}) => {
    return (
        <BaseFooter className={styles["footer"]}>
            {children}
        </BaseFooter>
    );
};

const PageContent = ({flavour, form, onFormSubmit}) => {
    const FlavorIcon = iconMapper[flavour];
    const Form = mapper[flavour].form;
    const Info = mapper[flavour].info;
    return (
        <ContentLayout>
            <BackToPreviousStep flavour={flavour}/>
            <div className={styles["main-content-container"]}>
                <div className={styles["from-area"]}>
                    <div className={styles["from-content"]}>
                        <div className={styles["title"]}>
                            <div className={classNames(typography.body16, styles["title-text"])}>{mapper[flavour].formTitle}</div>
                        </div>
                        <Form form={form} onFormSubmit={onFormSubmit}/>
                    </div>
                </div>
                <div className={styles["info-area"]}>
                    <div className={styles["info-content"]}>
                        <div className={styles["title"]}>
                            <FlavorIcon className={styles["service-logo"]}/>
                            <div className={classNames(typography.body16, styles["title-text"])}>How to get the credentials</div>
                        </div>
                        <Info/>
                    </div>
                </div>
            </div>
        </ContentLayout>
    );
};

const Page = ({flavour}) => {
    const dispatch = useDispatch();
    const form = useForm(mapper[flavour].initialState, mapper[flavour].validator);
    const addWePending = useSelector(state => _.get(state, `cvs.addWePending`));
    const addWeError = useSelector(state => _.get(state, `cvs.addWeError`));
    const addWeShowError = useSelector(state => _.get(state, `cvs.addWeShowError`));
    const getGcpProjectsError = useSelector(state => _.get(state, `cvs.getGcpProjectsError`));
    const getGcpProjectsShowError = useSelector(state => _.get(state, `cvs.getGcpProjectsShowError`));

    useEffect(() => {
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: { addWeResolved: false, addWeError: null, addWeShowError: false, getGcpProjectsResolved: false }
        });
    }, [dispatch]);

    const onFormSubmit = () => {
        if (!form.isValid) {
            return;
        }
        const credentials = { ...form.formState };
        if (form.formState.region) {
            credentials.region = form.formState.region.value;
        }
        if (form.formState.project) {
            credentials.project = form.formState.project.value;
            credentials.projectNumber = form.formState.project.projectNumber;
        }
        if (form.formState.serviceAccountCredentials) {
            credentials.serviceAccountCredentials = JSON.parse(form.formState.serviceAccountCredentials);
        }
        dispatch({
            type: "CVS:ADD-WE",
            payload: { ...credentials, provider: flavour }
        });
    };

    const notifications = [{
        type: "error",
        description: addWeError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { addWeShowError: false }}),
        showError: addWeShowError
    }, {
        type: "error",
        description: getGcpProjectsError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { getGcpProjectsShowError: false }}),
        showError: getGcpProjectsShowError
    }];

    return (
        <PageLayout>
            <Header flavour={flavour}/>
            <PageContent flavour={flavour} form={form} onFormSubmit={onFormSubmit}/>
            <FooterLayout><ActionButton isSubmitting={addWePending} onClick={form.handleFormSubmit(onFormSubmit)}>Continue</ActionButton></FooterLayout>
            <Notifications className={commonStyles["error-area"]} notifications={_.filter(notifications, { showError: true })}/>
        </PageLayout>
    );
};

export default () => {
    const urlParamsAreSet = useSelector(state => _.get(state, "cvs.urlParamsAreSet"));
    const flavour = useSelector(state => _.get(state, `cvs.cvsFlavour`));

    const render = () => {
        if (!urlParamsAreSet) {
            return <Loader/>
        } else {
            return (
                <Page flavour={flavour}/>
            );
        }
    };

    return render();
};
