import React from 'react'
import styles from "./DeveloperHub.module.scss";
import layout from "../../styles/app/layout.module.scss";
import ExternalLink from "../widgets/ExternalLink";
import CloudCentralSecondaryHeader from "../headers/CloudCentralSecondaryHeader";
import connect from "react-redux/es/connect/connect";
import AuthenticationDialog from "./AuthenticationDialog";
import classNames from "classnames";
import { Link } from "react-router-dom";

const DocCard = ({title, description, linkKey, link}) =>
    <div className={styles["doc-card"]}>
        <h4>{title}</h4>
        <p>{description}</p>
        {linkKey && <ExternalLink linkKey={linkKey} newTab>View API Documentation</ExternalLink>}
        {link && <Link to={link} target="_blank">View API Documentation</Link>}
    </div>;

const DeveloperHub = ({openAuthenticationDialog, closeAuthenticationDialog, authenticationDialogState}) => {
    let buttonRef = React.createRef();

    return <React.Fragment>
        <CloudCentralSecondaryHeader title="API Documentation">
            <button className="transparent" ref={buttonRef} onClick={openAuthenticationDialog} style={{position: "absolute", bottom: "10px", right: "0", fontSize: "12px", color: "#fff", fontWeight: "bolder"}}>
                Learn how to authenticate
            </button>
        </CloudCentralSecondaryHeader>
        <div className={classNames(styles.base, layout["main-content-container"])} style={{position: "relative"}}>
            <DocCard
                title="NetApp Cloud Central"
                description="Integrated hub for development information, help, documentation and launching services from NetApp Cloud Central."
                link="/api-doc/cloud-central"
            />
            <DocCard
                title="BlueXP Tenancy"
                description="Tenancy Service rest API for managing users, workspaces and accounts in BlueXP."
                link="/api-doc/tenancy"
            />
            <DocCard
                title="Cloud Volumes Service for AWS"
                description="A cloud native file service that provides NAS volumes over NFS and SMB with all-flash performance."
                linkKey="developerHub.cloudVolumesServiceAwsApiDoc"
            />

            <DocCard
                title="Cloud Volumes ONTAP"
                description="A powerful, cost-effective and easy-to-use solution for enterprise cloud storage."
                linkKey="developerHub.ontapCloudApiDoc"
            />

            <DocCard
                title="Cloud Sync"
                description="NetApp's service for rapid and secure data synchronization."
                linkKey="developerHub.cloudSyncApiDoc"
            />

            <DocCard
                title="Virtual Desktop Service"
                description="Simplified and automated virtual desktop deployment, orchestration, and scaling through a SaaS-delivered global control plane."
                linkKey="developerHub.virtualDesktopServiceApiDoc"
            />
        </div>

        {authenticationDialogState !== "CLOSED" && <AuthenticationDialog target={() => buttonRef.current} closeTrigger={closeAuthenticationDialog}/>}

    </React.Fragment>};

const mapStateToProps = state => {
    return {
        authenticationDialogState: state.developerHub.authenticationDialogState
    };
};

const mapDispatchToProps = dispatch => {
    return {
        openAuthenticationDialog: () => {
            dispatch({
                type: "DEVELOPER-HUB:OPEN-DIALOG"
            })
        },
        closeAuthenticationDialog: () => {
            dispatch({
                type: "DEVELOPER-HUB:CLOSE-DIALOG"
            })
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeveloperHub)