import _ from "lodash";
import * as actions from "../actions";

/* eslint default-case: 0 */

export default (state = { managementAxiosStatus: actions.STATUS_PENDING }, { payload, type }) => {
    switch (type) {
        case "MANAGEMENT:FETCH-MANAGEMENT-TOKEN-SUCCESS": {
            return { ...state, managementAxiosStatus: actions.STATUS_SUCCESS };
        }
        case "MANAGEMENT:FETCH-MANAGEMENT-TOKEN-FAILED": {
            return { ...state, managementAxiosStatus: actions.STATUS_ERROR };
        }
        case "MANAGEMENT:FETCH-CONNECTIONS": {
            return { ...state, connections: null };
        }
        case "MANAGEMENT:FETCH-CONNECTIONS-SUCCESS": {
            return { ...state, connections: payload.connections };
        }
        case "MANAGEMENT:ENABLE-CONNECTION": {
            return { ...state, successMessage: null, errorMessage: null };
        }
        case "MANAGEMENT:CONNECTION-ACTION-PENDING": {
            return { ...state, pendingRequest: true };
        }
        case "MANAGEMENT:ENABLE-CONNECTION-SUCCESS": {
            const occmClientsMessage = payload.occmClientIds.length ? `${payload.occmClientIds.length} OCCM clients (OCCM ids: ${payload.occmClientIds.join(',\n')}) associated with users with email domains: ${payload.emailDomains}` : `0 OCCM clients`;
            const successMessage = `Enabled connection "${payload.connectionName}" for company "${payload.companyName}":\n1. Successfully enabled all ${payload.generalClientsCount} general clients and ${occmClientsMessage}\n2. created rule-config.`;
            return { ...state, successMessage, pendingRequest: false };
        }
        case "MANAGEMENT:ENABLE-CONNECTION-ERROR": {
            return { ...state, errorMessage: payload, pendingRequest: false };
        }
        case "MANAGEMENT:DISABLE-CONNECTION": {
            return { ...state, successMessage: null, errorMessage: null };
        }
        case "MANAGEMENT:DISABLE-CONNECTION-SUCCESS": {
            return { ...state, successMessage: payload.message, pendingRequest: false  };
        }
        case "MANAGEMENT:DISABLE-CONNECTION-ERROR": {
            return { ...state, errorMessage: payload, pendingRequest: false };
        }
        case "MANAGEMENT:CLEAR-CONNECTIONS-MESSAGES": {
            return { ...state, errorMessage: null, successMessage: null };
        }
        case "MANAGEMENT:SEARCH-USERS-PENDING": {
            return {
                ...state,
                errorMessage: null,
                searchBarErrorMessage: null,
                users: null,
                userLogs: null,
                searchInputPending: true
            };
        }
        case "MANAGEMENT:SEARCH-USERS-SUCCESS": {
            const noResultsMessage = _.isEmpty(payload.users) ? `No users found for query "${payload.query}"` : null;
            const searchBarErrorMessage = payload.firstGetBlocksError || noResultsMessage;
            const users = payload.users.map(user => ({
                email: user.email,
                name: user.name,
                user_id: user.user_id,
                signup_date: user.created_at ? new Date(user.created_at).toDateString() : "",
                hasBlocks: _.get(user, "hasBlocks", false),
                email_verified:_.get(user,"email_verified",false),
                company: _.get(user.user_metadata,"company","N/A"),
                isFederation: user.identities.reduce((ans,identity) => {
                    if(identity.provider !== "auth0"){
                        ans = true;
                    }
                    return ans
                },false),
                businessAddress: _.get(user.user_metadata, "businessAddress", ""),
                city: _.get(user.user_metadata, "city", ""),
                state: _.get(user.user_metadata, "state", ""),
                country: _.get(user.user_metadata, "country", ""),
                postalCode: _.get(user.user_metadata, "postalCode", ""),
                phone: _.get(user.user_metadata, "phone", ""),
                gtcApproved: _.get(user.app_metadata, "gtcApproved", ""),
                gtcSubmitted: _.get(user.app_metadata, "gtcSubmitted", ""),
                gtcLastUpdated: user?.app_metadata?.gtcLastUpdated ? new Date(user.app_metadata.gtcLastUpdated).toDateString() : "",
                gtcReportLastUpdated: user?.app_metadata?.gtcReportLastUpdated ? new Date(user.app_metadata.gtcReportLastUpdated).toDateString() : "",
            }));
            return { ...state, users, searchBarErrorMessage, searchInputPending: null };
        }
        case "MANAGEMENT:SEARCH-USERS-ERROR": {
            return { ...state, searchBarErrorMessage: payload.message, searchInputPending: null };
        }
        case "MANAGEMENT:UNBLOCK-USER-PENDING": {
            return { ...state, userLogs: null, errorMessage: null, currentUserMenu: null };
        }
        case "MANAGEMENT:UNBLOCK-USER-ERROR": {
            return { ...state, errorMessage: payload.message };
        }
        case "MANAGEMENT:GENERATE-PASSWORD-CHANGE-TICKET-PENDING": {
            return { ...state, successMessage: null, errorMessage: null, currentUserMenu: null, loading: true };
        }
        case "MANAGEMENT:GENERATE-PASSWORD-CHANGE-TICKET-SUCCESS": {
            return { ...state, ticketMessage: {prefix: "Ticket URL: ",header:"Reset password url"}, ticketUrl: payload.resetPasswordUrl, loading: null };
        }
        case "MANAGEMENT:GENERATE-PASSWORD-CHANGE-TICKET-ERROR": {
            return { ...state, errorMessage: payload.message, loading: null };
        }
        case "MANAGEMENT:GENERATE-VERIFICATION-EMAIL-TICKET-PENDING": {
            return { ...state, successMessage: null, errorMessage: null, currentUserMenu: null, loading: true };
        }
        case "MANAGEMENT:GENERATE-VERIFICATION-EMAIL-TICKET-SUCCESS": {
            return { ...state, ticketMessage: {prefix:"Verification email ticket URL: ",header:"Email verification url"},ticketUrl: payload.emailVerificationUrl, loading: null  };
        }
        case "MANAGEMENT:GENERATE-VERIFICATION-EMAIL-TICKET-ERROR": {
            return { ...state, errorMessage: payload.message, loading: null };
        }
        case "MANAGEMENT:GET-USER-LOGS-PENDING": {
            return {
                ...state,
                userLogs: null,
                errorMessage: null,
                currentUserMenu: null,
                successMessage: null,
                loading: true
            };
        }
        case "MANAGEMENT:GET-USER-LOGS-SUCCESS": {
            return { ...state, userLogs: payload.logs, loading: null };
        }
        case "MANAGEMENT:GET-USER-LOGS-ERROR": {
            return { ...state, errorMessage: payload.message, loading: null };
        }
        case "MANAGEMENT:RESET-USER-MFA-PENDING": {
            return { ...state, errorMessage: null, currentUserMenu: null, successMessage: null, loading: true };
        }
        case "MANAGEMENT:RESET-USER-MFA-SUCCESS": {
            return { ...state, successMessage: 'Successfully reset MFA for user', loading: null };
        }
        case "MANAGEMENT:RESET-USER-MFA-ERROR": {
            return { ...state, errorMessage: payload.message, loading: null };
        }
        case "MANAGEMENT:OPEN-USER-MENU": {
            return { ...state, currentUserMenu: payload };
        }
        case "MANAGEMENT:CLOSE-USER-MENU": {
            return { ...state, currentUserMenu: null };
        }
        case "MANAGEMENT:FETCH-ACCOUNTS-OF-USER-PENDING": {
            return { ...state, accountsOfUserLoading: true };
        }
        case "MANAGEMENT:FETCH-ACCOUNTS-OF-USER-SUCCESS": {
            return { ...state, accountsOfUserLoading: false, accountsOfUser: payload.accounts, accountsOfUserError: null };
        }
        case "MANAGEMENT:FETCH-ACCOUNTS-OF-USER-FAILED": {
            return { ...state, accountsOfUserLoading: false, accountsOfUserError: payload };
        }
        case "MANAGEMENT:FETCH-USER-INFO-BY-EMAIL-PENDING": {
            return { ...state, userByEmailLoading: true };
        }
        case "MANAGEMENT:FETCH-USER-INFO-BY-EMAIL-SUCCESS": {
            return { ...state, userByEmailLoading: false, usersByEmail: payload.users, userByEmailError: null };
        }
        case "MANAGEMENT:FETCH-USER-INFO-BY-EMAIL-FAILED": {
            return { ...state, userByEmailLoading: false, userByEmailError: payload };
        }
        case "MANAGEMENT:FETCH-EMAIL-OF-USER-PENDING": {
            return { ...state, userEmailLoading: true };
        }
        case "MANAGEMENT:FETCH-EMAIL-OF-USER-SUCCESS": {
            return { ...state, userEmailLoading: false, userEmail: _.get(payload, "user.email"), userEmailError: null };
        }
        case "MANAGEMENT:FETCH-EMAIL-OF-USER-FAILED": {
            return { ...state, userEmailLoading: false, userEmailError: payload };
        }
        case "MANAGEMENT:FETCH-USERS-OF-ACCOUNT-PENDING": {
            return { ...state, usersOfAccountLoading: true };
        }
        case "MANAGEMENT:FETCH-USERS-OF-ACCOUNT-SUCCESS": {
            return { ...state, usersOfAccountLoading: false, usersOfAccount: payload.users || [], usersOfAccountError: null };
        }
        case "MANAGEMENT:FETCH-USERS-OF-ACCOUNT-FAILED": {
            return { ...state, usersOfAccountLoading: false, usersOfAccountError: payload };
        }
        case "MANAGEMENT:FETCH-WORKSPACES-OF-ACCOUNT-PENDING": {
            return { ...state, workspacesOfAccountLoading: true };
        }
        case "MANAGEMENT:FETCH-WORKSPACES-OF-ACCOUNT-SUCCESS": {
            return { ...state, workspacesOfAccountLoading: false, workspacesOfAccount: payload.workspaces || [], workspacesOfAccountError: null };
        }
        case "MANAGEMENT:FETCH-WORKSPACES-OF-ACCOUNT-FAILED": {
            return { ...state, workspacesOfAccountLoading: false, workspacesOfAccountError: payload };
        }
        case "MANAGEMENT:FETCH-NAME-OF-ACCOUNT-PENDING": {
            return { ...state, accountNameLoading: true };
        }
        case "MANAGEMENT:FETCH-NAME-OF-ACCOUNT-SUCCESS": {
            return { ...state, accountNameLoading: false, accountName: payload.accountName, accountNameError: null };
        }
        case "MANAGEMENT:FETCH-NAME-OF-ACCOUNT-FAILED": {
            return { ...state, accountNameLoading: false, accountNameError: payload };
        }
        case "MANAGEMENT:FETCH-RESOURCES-OF-ACCOUNT-PENDING": {
            return { ...state, resourcesOfAccountLoading: true };
        }
        case "MANAGEMENT:FETCH-RESOURCES-OF-ACCOUNT-SUCCESS": {
            return { ...state, resourcesOfAccountLoading: false, resourcesOfAccount: payload.resources || [], resourcesOfAccountError: null };
        }
        case "MANAGEMENT:FETCH-RESOURCES-OF-ACCOUNT-FAILED": {
            return { ...state, resourcesOfAccountLoading: false, resourcesOfAccountError: payload };
        }
        case "MANAGEMENT:FETCH-RESOURCES-OF-AGENT-PENDING": {
            return { ...state, resourcesOfAgentLoading: true };
        }
        case "MANAGEMENT:FETCH-RESOURCES-OF-AGENT-SUCCESS": {
            return { ...state, resourcesOfAgentLoading: false, resourcesOfAgent: payload.resources || [], resourcesOfAgentError: null };
        }
        case "MANAGEMENT:FETCH-RESOURCES-OF-AGENT-FAILED": {
            return { ...state, resourcesOfAgentLoading: false, resourcesOfAgentError: payload };
        }
        case "MANAGEMENT:FETCH-RESOURCE-DETAILS-PENDING": {
            return { ...state, resourceDetailsLoading: true };
        }
        case "MANAGEMENT:FETCH-RESOURCE-DETAILS-SUCCESS": {
            return { ...state, resourceDetailsLoading: false, resourceDetails: payload.resources || [], resourceDetailsError: null };
        }
        case "MANAGEMENT:FETCH-RESOURCE-DETAILS-FAILED": {
            return { ...state, resourceDetailsLoading: false, resourceDetailsError: payload };
        }
        case "MANAGEMENT:FETCH-AGENT-DETAILS-PENDING": {
            return { ...state, agentDetailsLoading: true };
        }
        case "MANAGEMENT:FETCH-AGENT-DETAILS-SUCCESS": {
            return { ...state, agentDetailsLoading: false, agentDetails: payload.agents || [], agentDetailsError: null };
        }
        case "MANAGEMENT:FETCH-AGENT-DETAILS-FAILED": {
            return { ...state, agentDetailsLoading: false, agentDetailsError: payload };
        }
        case "MANAGEMENT:FETCH-AGENTS-OF-ACCOUNT-PENDING": {
            return { ...state, agentsOfAccountLoading: true };
        }
        case "MANAGEMENT:FETCH-AGENTS-OF-ACCOUNT-SUCCESS": {
            return { ...state, agentsOfAccountLoading: false, agentsOfAccount: payload.agents || [], agentsOfAccountError: null };
        }
        case "MANAGEMENT:FETCH-AGENTS-OF-ACCOUNT-FAILED": {
            return { ...state, agentsOfAccountLoading: false, agentsOfAccountError: payload };
        }
        case "MANAGEMENT:CLOSE-TICKET-URL-DIALOG": {
            return {...state,ticketUrl:null}
        }
        case "MANAGEMENT:CLEAR-ALL-DATA": {
            return {
                ...state,
                accountsOfUser: undefined,
                accountsOfUserError: undefined,
                usersByEmail: undefined,
                usersByEmailError: undefined,
                userEmail: undefined,
                userEmailError: undefined,
                usersOfAccount: undefined,
                usersOfAccountError: undefined,
                workspacesOfAccount: undefined,
                workspacesOfAccountError: undefined,
                resourcesOfAccount: undefined,
                resourcesOfAccountError: undefined,
                resourcesOfAgent: undefined,
                resourcesOfAgentError: undefined,
                resourceDetails: undefined,
                resourceDetailsError: undefined,
                agentsOfAccount: undefined,
                agentsOfAccountError: undefined,
                accountName: undefined,
                accountNameError: undefined,
                subscriptionDetails: undefined,
                accountsOfSubscription: undefined,
                subscriptionDetailsByAccountId: undefined,
                byAccountIdError: undefined,
                bySubscriptionIdError: undefined
            }
        }
        case "MANAGEMENT:OPEN-CREATE-MODAL":{
            return {...state,openModal:true, connection: null}
        }
        case "MANAGEMENT:OPEN-UPDATE-MODAL":{
            return {...state,openModal:true}
        }
        case "MANAGEMENT:CLOSE-MODAL":{
            return {...state,openModal:false}
        }
        case "FEDERATION-SETUP:CREATE-CONNECTION-PENDING":{
            return {...state, connectionPending: true}
        }
        case "FEDERATION-SETUP:CREATE-CONNECTION-SUCCESS":{
            return {...state, connection: payload.connection, openModal:false}
        }
        case "FEDERATION-SETUP:UPDATE-CONNECTION-SUCCESS":{
            return {...state,openModal:false}
        }
        case "MANAGEMENT:FETCH-CONNECTION":{
            return {...state, isConnectionLoading: true, fetchConnectionError: null, connection: null}
        }
        case "MANAGEMENT:FETCH-CONNECTION-SUCCESS":{
            return {...state, connection: payload.connection, isConnectionLoading: false}
        }
        case "MANAGEMENT:FETCH-CONNECTION-FAILED":{
            return {...state, fetchConnectionError: payload.fetchConnectionError, isConnectionLoading: false}
        }
        case "MANAGEMENT:FETCH-SUBSCRIPTION-DETAILS-BY-SUBSCRIPTION-PENDING": {
            return { ...state, subscriptionsByIdLoading: true };
        }
        case "MANAGEMENT:FETCH-SUBSCRIPTION-DETAILS-BY-SUBSCRIPTION-SUCCESS": {
            const data = payload.data;
            data.accounts = data.accounts.map(accountId => ({accountId}));
            return { ...state, subscriptionsByIdLoading: false, subscriptionDetails: data , bySubscriptionIdError: null};
        }
        case "MANAGEMENT:FETCH-SUBSCRIPTION-DETAILS-BY-SUBSCRIPTION-FAILED": {
            return { ...state, subscriptionsByIdLoading: false, bySubscriptionIdError: payload };
        }
        case "MANAGEMENT:FETCH-SUBSCRIPTION-DETAILS-BY-ACCOUNT-PENDING": {
            return { ...state, subscriptionsByAccountLoading: true };
        }
        case "MANAGEMENT:FETCH-SUBSCRIPTION-DETAILS-BY-ACCOUNT-SUCCESS": {
            return { ...state, subscriptionsByAccountLoading: false, subscriptionDetailsByAccountId: payload.data, byAccountIdError: null };
        }
        case "MANAGEMENT:FETCH-SUBSCRIPTION-DETAILS-BY-ACCOUNT-FAILED": {
            return { ...state, subscriptionsByAccountLoading: false, byAccountIdError: payload };
        }
    }
    return state;
};
