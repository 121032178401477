import React from 'react';
import {connect} from "react-redux";
import CloudCentralSecondaryHeader from "./../../../components/headers/CloudCentralSecondaryHeader";
import { ErrorNoFillIcon as Error } from '@netapp/bxp-design-system-react/icons/monochrome';
import { CheckCircleTwoColorIconsIcon as OkTwoColor } from '@netapp/bxp-design-system-react/icons/2-colors';
import buttons from "../../../styles/button/button.module.scss";
import styles from "./EmailVerification.module.scss";
import { Link } from "react-router-dom";

const EmailVerificationPage = ({auth, sendVerificationEmail, success, code, message}) => {
    return (
        <React.Fragment>
            <CloudCentralSecondaryHeader title="Email Verification">
            </CloudCentralSecondaryHeader>
            <div className={styles["outer-container"]}>
                {success === "true" ?
                    <VerificationSucceeded authenticated={auth.authenticated}/>:
                    <VerificationFailed code={code} message={message} authenticated={auth.authenticated} sendVerificationEmail={sendVerificationEmail}/>
                }
            </div>
        </React.Fragment>
    );
};

const VerificationFailed = ({authenticated, code, message, sendVerificationEmail}) => {
    return (
        <React.Fragment>
            <Error/>
            <p>Oops! Something went wrong…</p>
            <p>{message}</p>
            <div className={styles["button-container"]}>
                {!authenticated ?
                    <button className={buttons.primary} onClick={() => window.auth.loginRedirect({}, {service: "portal", initialScreen: "login"})}>
                        Login to Cloud Central
                    </button> :
                    <React.Fragment>
                        {code === "already-verified" ?
                            <Link className={buttons.primary} to="/">
                                Back to Cloud Central
                            </Link> :
                            <React.Fragment>
                                <Link className={buttons.cancel} to="/">
                                    Back to Cloud Central
                                </Link>
                                <button className={buttons.primary} onClick={() => sendVerificationEmail()}>Resend Verification Link</button>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    );
};

const VerificationSucceeded = ({authenticated}) => {
    return (
        <React.Fragment>
            <OkTwoColor/>
            <p style={{marginBottom: "50px"}}>Your Email address was verified successfully!</p>
            <div className={styles["button-container"]}>
                {authenticated ?
                <Link className={buttons.primary} to="/">
                    Done
                </Link> :
                <button className={buttons.primary} onClick={() => window.auth.loginRedirect({}, {service: "portal", initialScreen: "login"})}>
                    Login to Cloud Central
                </button>}
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sendVerificationEmail: () => {
            dispatch({
                type: "USER:SEND-VERIFICATION-EMAIL"
            })
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerificationPage);