import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import styles from "./Dashboard.module.scss"
import classNames from "classnames";
import ExternalLink from "./../widgets/ExternalLink"
import connect from "react-redux/es/connect/connect";
import * as actions from "../../actions";
import ActionButton from "../widgets/ActionButton";
import {Link} from "react-router-dom";
import _ from "lodash";
import GettingStartedContent from "./GettingStartedContent"
import { servicesMap } from '../../consts/sevicesMap';
import ContactUsModal from "../dialog/ContactUsModal";

const ServiceLink = ({status, serviceKey, text}) => {
    const accountId = useSelector(state => _.get(state, "auth.userAccount.value"));

    if(!status || status === actions.STATUS_PENDING) {
        return <span className={styles.cta}>Loading...</span>;
    } else {
        return <ExternalLink linkKey={`launchPad.services.${serviceKey}`} queryString={accountId && serviceKey === "cloudTiering" && `?accountId=${accountId}`} trackCategory="dashboards" className={styles.cta}>{text}</ExternalLink>;
    }
};

const CVOTwoButtons = ({view}) => {
    const ServiceLink = ({text}) => <ExternalLink linkKey="launchPad.services.occmSaas" trackCategory="dashboards" className={styles.cta}>{text}</ExternalLink>;
    const ViewLink = ({text, className}) => <Link to="/cloud-managers" className={className || styles.cta}>{text}</Link>;

    if(view === 'start-free-trial' || view === 'create-new' || view === 'has-one-ready') {
        return <ServiceLink text="Go to BlueXP"/>
    } else if (view === 'has-one-initializing' || view === 'has-one-failed') {
        return <ViewLink text="View BlueXP"/>
    } else {
        return <ViewLink text="View BlueXPs"/>
    }
};

const DashboardSecondaryHeaderDumb = ({title, launchPadServices, serviceKey, Icon, category, iconStyle}) => {
    return <header className={classNames(styles.header, `category-${category}`)}>
        <div className="header-container">
            <h1>{title}</h1>
            <Icon className="banner-logo" style={iconStyle}/>
            {serviceKey !== "cloudVolumesService" && serviceKey !== "cloudVolumesOntap" && <ServiceLink {... (launchPadServices[serviceKey] || {})}/>}
            {serviceKey === "cloudVolumesOntap" && <CVOTwoButtons view={launchPadServices.cloudVolumesOntap.view} data={launchPadServices.cloudVolumesOntap.data}/>}
        </div>
    </header>;
};

const mapStateToProps = state => {
    return {
        launchPadServices: state.services
    };
};

export const DashboardSecondaryHeader = connect(mapStateToProps)(DashboardSecondaryHeaderDumb);

export const FooterContent = ({ footerContent }) => {
    return <footer className={styles.footer}><h4>{footerContent.title}</h4>
        <div>
            {footerContent.links.map(({ text, linkKey }) => <ExternalLink key={linkKey} linkKey={linkKey} newTab>
                {text}
            </ExternalLink>)}
        </div>
    </footer>
};

export default ({title, iconKey, productText, gettingStartedContent, footerContent, category, gettingStartedLink, serviceKey, contactUsEmail, ActivatedDashboard, CustomPane, iconStyle}) => {
    const hasGettingStartedContent = gettingStartedContent && gettingStartedContent.length > 0;
    const hasFooterContent = footerContent && footerContent.links.length > 0;
    const productTextIsArray = _.isArray(productText);
    const Icon = servicesMap[iconKey].banner;

    return <div className={classNames(styles.base, {[styles['has-footer']]: hasFooterContent})}>
        <DashboardSecondaryHeader category={category} title={title} serviceKey={serviceKey} Icon={Icon} iconStyle={iconStyle}/>
        {ActivatedDashboard && <ActivatedDashboard/>}
        {CustomPane && <CustomPane/>}
        {!CustomPane && <section className={styles.section}>
            {gettingStartedLink && !ActivatedDashboard && <ExternalLink className="getting-started-guide" linkKey={gettingStartedLink} newTab>Getting Started Guide</ExternalLink>}
            {!gettingStartedLink && !ActivatedDashboard && <div style={{height: 20}}/>}
            {!ActivatedDashboard && <p className={hasGettingStartedContent ? styles["intro-text"] : styles['no-content-intro-text']}>
                {productTextIsArray && productText.map(text => <div style={{marginBottom: 20}}>{text}</div>)}
                {!productTextIsArray && productText}
            </p>}
            {hasGettingStartedContent && <GettingStartedContent gettingStartedContent={gettingStartedContent}/>}
        </section>}
        { hasFooterContent && <FooterContent footerContent={footerContent} contactUsEmail={contactUsEmail} title={title}/>}
    </div>
};