import React, {useCallback, useEffect, useRef} from "react";
import {PageLayoutNoFooter, SimpleHeader} from "../../../Page";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {usePoller, MenuPopover, useModal, Notifications} from "@netapp/bxp-design-system-react";
import SupportModal from "../../../SupportModal";
import DeleteWeModal from "./DeleteWeModal";
import {pollingDelayInMillisecond, titleMapper} from "../../../../utils/common";
import {SupportMonochromeIconsIcon as Support} from "@netapp/bxp-design-system-react/icons/monochrome";
import Loader from "../../../../../../Loader";
import VolumesGallery from "./Gallery";
import commonStyles from "../../../../styles/common.module.scss";
import styles from "./Page.module.scss";

const mapper = {
    aws: "Remove Cloud Volumes Service",
    azure: "Remove Azure NetApp Files",
    gcp: "Remove Cloud Volumes Service"
};

const GalleryHeader = ({we, flavour, additionalActions}) => {
    const supportModalRef = useRef(null);
    const deleteWeModalRef = useRef(null);
    const { isModalOpen, openModal, closeModal } = useModal();
    const { isModalOpen: isDeleteModalOpen, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal();
    const commonActions = [
        { key: "remove-we", label: mapper[flavour],  onClick: () => openDeleteModal() },
    ];
    const actions = additionalActions ? [...additionalActions, ...commonActions] : commonActions;

    return (
        <SimpleHeader flavour={flavour} title={`${titleMapper[flavour]} | ${we.name}`}>
            <div className={styles["action-buttons"]}>
                {flavour === "aws" && <button onClick={() => openModal()} className={styles["support-btn"]} ref={supportModalRef}><Support className={commonStyles["support-icon"]}/>Support Help</button>}
                <MenuPopover menu={actions}/>
            </div>
            {isModalOpen && <SupportModal closeTrigger={closeModal} target={supportModalRef}/>}
            {isDeleteModalOpen && <DeleteWeModal we={we} flavour={flavour} target={deleteWeModalRef} closeTrigger={closeDeleteModal}/>}
        </SimpleHeader>
    );
};

export default ({ we, additionalHeaderActions }) => {
    const dispatch = useDispatch();
    const urlParamAreSet = useSelector(state => _.get(state, "cvs.urlParamsAreSet"));
    const flavour = useSelector(state => _.get(state, "cvs.cvsFlavour"));
    const successMessage = useSelector(state => _.get(state, "cvs.volumeViewSuccessMessage"));
    const showSuccessMessage = useSelector(state => _.get(state, "cvs.showVolumeViewSuccessMessage"));
    const volumes = useSelector(state => _.get(state, "cvs.volumes"));
    const getVolumesPending = useSelector(state => _.get(state, "cvs.getVolumesPending"));
    const getVolumesResolved = useSelector(state => _.get(state, "cvs.getVolumesResolved"));
    const getVolumesError = useSelector(state => _.get(state, "cvs.getVolumesError"));
    const getVolumesShowError = useSelector(state => _.get(state, "cvs.getVolumesShowError"));
    const shouldPoll = !getVolumesPending && urlParamAreSet;

    useEffect(() => {
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: {
                volumes: [],
                getVolumesResolved: false,
                getVolumesError: null,
                getVolumesShowError: false,
                volumeViewSuccessMessage: null,
                showVolumeViewSuccessMessage: false
            }
        });
    }, [dispatch]);

    const getVolume = useCallback(() => {
        dispatch({
            type: `CVS:${flavour.toUpperCase()}-GET-VOLUMES`
        });
    }, [dispatch, flavour]);

    const hideNotification = () => {
        dispatch({
            type: "CVS:CLEAR-STATE",
            payload: { getVolumesShowError: false }
        });
    };

    const hideSuccessMessage = () => {
        dispatch({
            type: "CVS:CLEAR-STATE",
            payload: { showVolumeViewSuccessMessage: false }
        });
    };

    usePoller(getVolume, pollingDelayInMillisecond, shouldPoll);

    const notifications = [{
        type: "error",
        description: getVolumesError,
        onClose: () => hideNotification(),
        showError: getVolumesShowError
    }, {
        type: "success",
        description: successMessage,
        onClose: () => hideSuccessMessage(),
        showError: showSuccessMessage
    }];

    return (
        <PageLayoutNoFooter>
            <GalleryHeader flavour={flavour} we={we} additionalActions={additionalHeaderActions}/>
            {(getVolumesPending && !getVolumesResolved) ? <Loader/> : <VolumesGallery volumes={volumes || []}/>}
            <Notifications className={commonStyles["error-area"]} notifications={_.filter(notifications, { showError: true })}/>
        </PageLayoutNoFooter>
    );
};
