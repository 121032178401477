import { Card, ModalFooter, ModalContent, StyledLink, Text, Button, Heading, Checkbox } from "@netapp/bxp-design-system-react";
import querystring from 'query-string';
import { useLocation } from "react-router-dom";
import { useState } from "react";
import ExternalLink from "components/widgets/ExternalLink";
import { NetAppLogoIcon as NetAppLogo } from '@netapp/bxp-design-system-react/icons/netapp-services';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'

const layoutCss = css`
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr min-content 2fr;
  justify-content: center;
  padding: 2px 0;

  &:before, &:after {
    display: block;
    content: "";
  }
  
  path {
    fill: #000000;
  }
`

const cardCss = css`
  width: 688px;
`

const NetAppFederationEula = () => {
    const location = useLocation();
    const search = querystring.parse(location.search);
    const [isAccepted, setAccepted] = useState(false);

    if (!search.state) {
        return <div>
            Invalid Redirect Option. State parameter missing.
        </div>
    }

    return <div css={layoutCss}>
        <Card css={cardCss}>
            <ModalContent>
                <NetAppLogo style={{ marginBottom: 32 }}/>
                <Text style={{ marginBottom: 24 }}>Before you can continue, please accept the End User Licence Agreement:</Text>
                <Heading level={3}>End User License Agreement</Heading>
                <Text style={{ marginBottom: 24 }}>
                    Welcome to NetApp Cloud Central. The products and services available at Cloud Central are governed by the following: Cloud Data Sense, Global File Cache and Cloud Volumes ONTAP (the “Software’) are governed by the NetApp
                    End User License Agreement at: <br/> <ExternalLink style={{ fontWeight: "normal" }} href="https://www.netapp.com/how-to-buy/sales-terms-and-conditions/"
                                                                       newTab>https://www.netapp.com/how-to-buy/sales-terms-and-conditions/</ExternalLink> and the NetApp Cloud Services are governed by the NetApp Cloud Services Terms of
                    Service
                    at: <br/> <ExternalLink style={{ fontWeight: "normal" }} href="https://www.netapp.com/how-to-buy/sales-terms-and-conditions/" newTab>https://www.netapp.com/how-to-buy/sales-terms-and-conditions/</ExternalLink> (the
                    “Terms”). By accepting these Terms, You agree that these Terms exclusively govern the licensing of the Software and the access and use NetApp’s Cloud Services, unless You have a separate applicable written agreement with
                    NetApp. If You are accepting these Terms on behalf of another person, company or other legal entity, whether as an employee, contractor, distributor, reseller, partner, agent or otherwise, You represent and warrant that
                    You have the full authority to bind Your organization. If You do not agree to these Terms then do not subscribe to, access, install, copy or use the Software or NetApp Cloud Services.
                </Text>
                <Checkbox onChange={() => setAccepted(!isAccepted)} checked={isAccepted}>I accept the End User License Agreement</Checkbox>
            </ModalContent>
            <ModalFooter>
                {/* Its a Button instead of a Link so that it can't be copied + pasted */}
                <Button disabled={!isAccepted} onClick={() => window.location = `https://${process.env.REACT_APP_AUTH_DOMAIN}/continue?state=${search.state}`}>Continue</Button>
                <StyledLink color="secondary" to="/">Cancel</StyledLink>
            </ModalFooter>
        </Card>
    </div>
}

export default NetAppFederationEula;