import React from "react";
import classNames from "classnames";
import {SuccessCircleIcon as Success} from "@netapp/bxp-design-system-react/icons/monochrome";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import styles from "./TabItem.module.scss";

export default ({ index, title, isActive, onTabSelected, done }) => {
    return (
        <button onClick={() => onTabSelected(index)} className={classNames(styles["container"], {[styles["disabled"]]: !done})} disabled={!done}>
            {done ? <Success className={classNames(styles["done-icon"], {[styles["selected"]]: isActive})}/> : <div className={classNames(styles["index"], {[styles["selected"]]: isActive})}>{index}</div>}
            <div className={classNames(typography.body15, styles["title"], {[styles["selected"]]: isActive})}>{title}</div>
        </button>
    );
};

