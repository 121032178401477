import React, {useEffect} from "react";
import {Route, Switch, useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import Loader from "../../../../../Loader";
import EditVolume from "./edit/Content";
import RevertVolume from "../common/revert-volume/Content";
import MountVolume from "../common/mount-volume/Content";
import VolumeInfo from "../common/volume-info/Content";
import CreateSnapshotCopy from "../common/create-snapshot/Content";
import DeleteSnapshot from "../common/delete-snapshot/Content";
import {VolumeIcon} from "@netapp/bxp-design-system-react/icons/monochrome";
import {BackToGallery} from "../common/BackToGalleryButton";
import {SimpleHeader, PageLayoutNoFooter} from "../../Page";
import {NotFoundBody} from "../../NotFoundPage";

export default ({ we }) => {
    const {volumeId} = useParams();
    const dispatch = useDispatch();
    const volume = useSelector(state => _.get(state, "cvs.volume"));
    const getVolumeError = useSelector(state => _.get(state, "cvs.getVolumeError"));
    const getVolumeResolved = useSelector(state => _.get(state, "cvs.getVolumeResolved"));
    const getVolumeShowError = useSelector(state => _.get(state, "cvs.getVolumeShowError"));

    useEffect(() => {
        dispatch({
            type: `CVS:AWS-GET-VOLUME`,
            payload: { volumeId }
        });
        return () => dispatch({
            type: 'CVS:CLEAR-STATE',
            payload: { volume: null, getVolumeResolved: false, getVolumeError: null, getVolumeShowError: false }
        })
    }, [dispatch, volumeId]);

    const hideNotification = () => {
        dispatch({
            type: "CVS:CLEAR-STATE",
            payload: { getVolumeShowError: null }
        });
    };

    const renderContent = () => {
        if (getVolumeError) {
            return <NotFoundBody
                errorMessage={getVolumeError}
                showError={getVolumeShowError}
                icon={VolumeIcon}
                deleteNotification={hideNotification}
                notFoundText={"Volume Not Found"}/>
        }

        if (!getVolumeResolved) {
            return <Loader/>
        }

        return (
            <Switch>
                <Route path="/components/cvs/aws/:accountId/:credentialsId/volumes/:volumeId/edit-volume" exact render={() => <EditVolume volume={volume}/>}/>
                <Route path="/components/cvs/aws/:accountId/:credentialsId/volumes/:volumeId/info" exact render={() => <VolumeInfo volume={volume}/>}/>
                <Route path="/components/cvs/aws/:accountId/:credentialsId/volumes/:volumeId/delete-snapshot" exact render={() => <DeleteSnapshot volume={volume}/>}/>
                <Route path="/components/cvs/aws/:accountId/:credentialsId/volumes/:volumeId/mount-volume" exact render={() => <MountVolume volume={volume}/>}/>
                <Route path="/components/cvs/aws/:accountId/:credentialsId/volumes/:volumeId/create-snapshot" exact render={() => <CreateSnapshotCopy volume={volume}/>}/>
                <Route path="/components/cvs/aws/:accountId/:credentialsId/volumes/:volumeId/revert-volume" exact render={() => <RevertVolume volume={volume}/>}/>
            </Switch>
        )
    };

    return (
        <PageLayoutNoFooter>
            <SimpleHeader flavour={"aws"} title={`Cloud Volumes Services | ${we.name}`}/>
            <BackToGallery/>
            {renderContent()}
        </PageLayoutNoFooter>
    )
};
