import React, {Suspense, useEffect} from 'react';
import querystring from 'query-string';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from "classnames";
import _ from "lodash";

import appStyles from './styles/app/app.module.scss';

import './Buttons.scss';
import './components/Components.scss';
import AppHeader from './components/AppHeader';
import RefreshTokenGenerator from './components/misc/RefreshTokenGenerator';
import DeveloperHub from './components/developer-hub/DeveloperHub';
import { EmailVerificationPage, PasswordUpdatePage } from './modules';
import ContentRedirector from './components/content/ContentRedirector';
import BlankPage from './components/BlankPage';
import links from './consts/links';
import FederationSetup from "./components/federation-setup/FederationSetup";
import LaunchPadLoader from "./components/launch-pad/LaunchPadLoader";
import LaunchPadLoaderWithAccounts from "./components/launch-pad/LaunchPadLoaderWithAccounts";
import FixedLeftNav from "./components/nav-menu/NavMenu";
import SubscriptionMapping from "./components/subscriptions/SubscriptionMapping";
import DemoView from "./components/demo/DemoView";
import * as Dashboards from "./components/dashboards/Dashboards";
import serviceMapping from "./components/subscriptions/subscriptionServices";
import lazy from "utils/lazy";
import Loader from "./Loader";
import DemoInvite from "./modules/invite-to-demo";

const AuditTab = lazy(() => import('modules/audit' /* webpackChunkName: "Audit" */).then(module => module.AuditTab));
const SpotlightTab = lazy(() => import("modules/spotlight/" /* webpackChunkName: "FeatureSpotlight" */));
const FabricViewTab = lazy(() => import('modules/fabric-view' /* webpackChunkName: "FabricView" */));

const NetAppInternalView = lazy(() => import('./components/internal/NetAppInternalView' /* webpackChunkName: "NetAppInternal" */));
const CloudAssessmentView = lazy(() => import('./components/cloud-assessment/CloudAssessmentView.js' /* webpackChunkName: "CloudAssessment" */));
const CloudManagersView = lazy(() => import('./components/launch-pad/CloudManagersView.js' /* webpackChunkName: "CloudManagers" */));
const ApiDocContainer = lazy(() => import('./components/developer-hub/ApiDocContainer' /* webpackChunkName: "ApiDoc" */));

const JamPage = lazy(() => import('./modules/jam' /* webpackChunkName: "jam" */));

const LoginRedirectToPath = () => {
    const isSubscriptionMapping = _.startsWith(window.location.pathname, "/subscription-mapping");
    const subscriptionServiceName = isSubscriptionMapping ? _.get(serviceMapping, `${_.get(querystring.parse(window.location.search), "serviceName")}.title`, "Unknown") : undefined;

    window.auth.loginRedirect({}, { service: "portal", startOnSignup: false, subscriptionMapping: isSubscriptionMapping, subscriptionServiceName }, { pathname: window.location.pathname + window.location.search });
    return <BlankPage/>;
};

const AuthenticatedRoute = props => (props.auth.authenticated ? <Route {...props}/> : <LoginRedirectToPath/>);
const InternalRoute = props => {
    if (props.auth.authenticated && props.auth.isNetAppInternal) return <Route {...props}/>;
    if (props.auth.authenticated) return <Redirect to="/"/>;
    return <LoginRedirectToPath/>;
};

const DevRedirect = () => <div>
    In dev/staging there is no redirect to marketing homepage.

    <br/><a href="https://cloud.netapp.com">Go to marketing.</a>
    <br/>(Note: If you login from marketing it will be to production.)
    <a href="/?redirectToLogin=true">Start free trial</a>
    <button style={{color: "black", display: "block"}} onClick={() => {
        window.auth.loginRedirect({connection: "google-oauth2"})
    }}>
        Login straight to Google
    </button>
    <button style={{color: "black", display: "block"}} onClick={() => {
        window.auth.loginRedirect({connection: "azuread"})
    }}>
        Login straight to Microsoft
    </button>
</div>;

const RootRoute = ({ auth }) => {
    if (!auth.authenticated) {
        if (links.marketingPageRedirect.startsWith("/")) {
            return <DevRedirect/>;
        }
        window.location.replace(links.marketingPageRedirect);
        return <BlankPage/>;
    }

    return <Redirect to="/fabric-view"/>;
};

const DashboardRoutes = () => <Switch>
    <Route path="/dashboard/cloud-volumes-ontap" render={() => <Dashboards.CloudVolumesOntapDashboard/>}/>
    <Route path="/dashboard/cloud-volumes-service" render={() => <Dashboards.CloudVolumesServiceDashboard/>}/>
    <Route path="/dashboard/cloud-insights" render={() => <Dashboards.CloudInsightsDashboard/>}/>
    <Route path="/dashboard/cloud-sync" render={() => <Dashboards.CloudSyncDashboard/>}/>
    <Route path="/dashboard/cloud-tiering" render={() => <Dashboards.CloudTieringDashboard/>}/>
    <Route path="/dashboard/cloud-backup-service" render={() => <Dashboards.CloudBackupServiceDashboard/>}/>
    <Route path="/dashboard/virtual-desktop-service" render={() => <Dashboards.VirtualDesktopServiceDashboard/>}/>
    <Redirect to="/"/>
</Switch>;

const MainRoutes = ({ auth, activeFeatures, subscriptionDialogOpen }) =>
    <Switch>
        <Route path="/" exact render={() => <RootRoute activeFeatures={activeFeatures} auth={auth}/>}/>
        <Route path="/login" render={() => <div/>}/>
        <AuthenticatedRoute auth={auth} path="/cloud-managers" exact render={({ location }) => {
            const search = querystring.parse(location.search);
            const cloudComplianceActivation = _.get(search, "cloudComplianceActivation", null) === "true";
            return <CloudManagersView cloudComplianceActivation={cloudComplianceActivation}/>
        }}/>
        <AuthenticatedRoute auth={auth} path="/cloud-assessment" render={() => <CloudAssessmentView/>}/>
        <AuthenticatedRoute auth={auth} path="/refresh-token" exact render={() => <RefreshTokenGenerator/>}/>
        <AuthenticatedRoute auth={auth} path="/fabric-view" exact render={() => <FabricViewTab/>}/>
        <AuthenticatedRoute auth={auth} path="/audit" exact render={() => <AuditTab/>}/>
        <AuthenticatedRoute auth={auth} path="/subscription-mapping" render={({ location }) => {
            const search = querystring.parse(location.search);
            if (search.token || subscriptionDialogOpen) return <SubscriptionMapping {...search}/>;
            return <Redirect to="/"/>;
        }}/>
        <AuthenticatedRoute auth={auth} path="/dashboard" render={() => <DashboardRoutes/>}/>
        <InternalRoute auth={auth} path="/netapp-internal" render={() => <NetAppInternalView/>}/>
        <InternalRoute auth={auth} path="/tiering-demo" render={() => <DemoView/>}/>
        <AuthenticatedRoute auth={auth} path="/federation-setup" render={() => <FederationSetup/>}/>
        <AuthenticatedRoute auth={auth} exact path="/demo" render={() => <DemoInvite/>}/>
        <Route path="/developer-hub" exact render={() => <DeveloperHub/>}/>
        <Route path="/email-verification" exact render={({ location }) => {
            const search = querystring.parse(location.search);
            return <EmailVerificationPage {...search}/>;
        }}/>
        <Route path="/password-update" exact render={({ location }) => {
            const search = querystring.parse(location.search);
            return <PasswordUpdatePage {...search}/>;
        }}/>
        <AuthenticatedRoute auth={auth} path="/feature-spotlight" render={() => <SpotlightTab/>}/>
        <ContentRedirector auth={auth} path="/content/:content" exact/>
        <Redirect to="/"/>
    </Switch>;

const ApiContainer = ({children}) =>
    <div className={classNames(appStyles.base)}>
        <AppHeader/>
        <Suspense fallback={<Loader/>}>
            {children}
        </Suspense>
    </div>;


const App = ({ auth, menuOpen, activeFeatures, subscriptionDialogOpen, getAccounts, userAccount, getTenantUsers }) => {
    useEffect(() => {
        if (auth.userId) {
            getAccounts();
        }
    }, [auth.userId, getAccounts]);

    useEffect(() => {
        if(userAccount){
            getTenantUsers();
        }
    }, [userAccount, getTenantUsers]);

    return <Switch>
            <Route path="/api-doc/cloud-central" render={() => <ApiContainer><ApiDocContainer openApiUrl={process.env.REACT_APP_PORTAL_FRONTEND_BASE_URL + process.env.REACT_APP_PORTAL_API_LOCATION}/></ApiContainer>}/>
            <Route path="/api-doc/tenancy" render={() => <ApiContainer><ApiDocContainer serverUrl={process.env.REACT_APP_CVO_API_URL} openApiUrl={process.env.REACT_APP_TENANCY_DOC_URL}/></ApiContainer>}/>
            <Route path="/jam" render={() => <JamPage auth={auth}/>}/>
            <Route path="/" render={() => (
                    <div className={classNames(appStyles.base, appStyles['with-nav-menu'])}>
                        <AppHeader/>
                        <FixedLeftNav/>
                        {auth.authenticated && <LaunchPadLoader/>}
                        {(auth.authenticated && auth.userAccountStatus !== "PENDING") && <LaunchPadLoaderWithAccounts/>}
                        <Suspense fallback={<Loader/>}>
                            <MainRoutes activeFeatures={activeFeatures} auth={auth} subscriptionDialogOpen={subscriptionDialogOpen}/>
                        </Suspense>
                    </div>
            )}/>
        </Switch>;
};

const mapStateToProps = state => ({
    auth: state.auth,
    activeFeatures: state.features.active,
    subscriptionDialogOpen: state.subscriptions.subscriptionMappingOpen,
    userAccount: state.auth.userAccount
});

const mapDispatchToProps = dispatch => ({
    getAccounts: () => {
        dispatch({
            type: "AUTH:GET-ACCOUNTS"
        })
    },
    getTenantUsers: () => {
        dispatch({
            type: "TENANCY:GET-USERS"
        })
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
