import React from "react";
import classNames from "classnames";
import { BackIcon} from "@netapp/bxp-design-system-react/icons/monochrome";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import styles from "./BackButton.module.scss";

export const BackButton = ({onBackClicked, className}) => {
    return <button className={classNames(styles["back"], className)} onClick={onBackClicked}><BackIcon/><span className={typography.body14}>Back</span></button>;
};

