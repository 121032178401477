import axios from "axios"
import * as actions from "./../../actions"
import _ from "lodash";
import uuidv4 from 'uuid/v4';

export default ({dispatch, next, action, getState}) => {
    const {type} = action;

    if (type === "INTERNAL:OCCM-DEMO" && ! action.status) {
        const promise = axios.post("/portal/occm-demo-signup");

        promise.then(
            r => dispatch({type: "INTERNAL:OCCM-DEMO", status: actions.STATUS_SUCCESS, payload: r}),
            e => dispatch({type: "INTERNAL:OCCM-DEMO", status: actions.STATUS_ERROR, payload: e}));

        return next({type: "INTERNAL:OCCM-DEMO", status: actions.STATUS_PENDING});
    } else if (type === "INTERNAL:FETCH-WIDGET-README" && !action.status) {
        const promise = axios.get("/WIDGET_README.md", {baseURL: "/"});

        promise.then(
            r => dispatch({type: "INTERNAL:FETCH-WIDGET-README", status: actions.STATUS_SUCCESS, payload: r}),
            e => dispatch({type: "INTERNAL:FETCH-WIDGET-README", status: actions.STATUS_ERROR, payload: e}));

        return next({type: "INTERNAL:FETCH-WIDGET-README", status: actions.STATUS_PENDING});
    }  else if (type === "INTERNAL:GLOBAL-FILE-CACHE-LICENSE-REQUEST-FORM") {
        const { payload } = action;
        const promise = axios.post("/portal/netapp-internal/global-file-cache-license-request", {
            ...payload
        });

        const successId = uuidv4();

        promise.then(
            r => dispatch({type: "INTERNAL:GLOBAL-FILE-CACHE-LICENSE-REQUEST-FORM-SUCCESS", payload: successId}),
            e => dispatch({type: "INTERNAL:GLOBAL-FILE-CACHE-LICENSE-REQUEST-FORM-ERROR", payload: e}));

        setTimeout(() => {
            dispatch({type: "INTERNAL:GLOBAL-FILE-CACHE-LICENSE-REQUEST-FORM-CLEAR-SUCCESS-MESSAGE", payload: successId});
        }, 5000);

        return next({type: "INTERNAL:GLOBAL-FILE-CACHE-LICENSE-REQUEST-FORM-PENDING"});
    } else if (type === "INTERNAL:FETCH-SALES-ITEMS") {
         axios.get("/portal/netapp-internal/sales-items")
            .then(response => {
                dispatch({
                    type: "INTERNAL:FETCH-SALES-ITEMS-SUCCESS",
                    payload: { salesItems: response.data }
                });
            })
            .catch(error => {
                const { message } = error;
                dispatch({
                    type: "INTERNAL:FETCH-SALES-ITEMS-FAILURE",
                    payload: { salesItemsFetchError: message }
                });
            })
    } else if (type === "INTERNAL:DELETE-SALES-ITEM") {
        const { itemForDeletion: { id, serviceName } } = getState().internal;
        axios.delete(`/portal/netapp-internal/sales-items/${serviceName}/${id}`, null, {
            query: {
                serviceName,
                id
            }
        })
            .then(response => {
                dispatch({
                    type: "INTERNAL:DELETE-SALES-ITEM-SUCCESS"
                });
            })
            .catch(error => {
                const { message } = error;
                dispatch({
                    type: "INTERNAL:DELETE-SALES-ITEM-FAILURE",
                    payload: { deleteSalesItemError: message }
                });
            })
    } else if (type === "INTERNAL:SAVE-SALES-ITEM-CONTENT") {
        const { payload: { itemForUpdate } } = action;
        const { saveSalesContentAction } = getState().internal;
        const promise = saveSalesContentAction === "add"
            ? axios.post(`/portal/netapp-internal/sales-items`, { ...itemForUpdate })
            : axios.put(`/portal/netapp-internal/sales-items/${itemForUpdate.serviceName}/${itemForUpdate.id}`, { ..._.omit(itemForUpdate, ['id', 'serviceName']) });
        promise.then(response => {
                dispatch({
                    type: "INTERNAL:SAVE-SALES-ITEM-CONTENT-SUCCESS",
                    payload: { newSalesItem: response.data }
                });
            })
            .catch(error => {
                const { message } = error;
                dispatch({
                    type: "INTERNAL:SAVE-SALES-ITEM-CONTENT-FAILURE",
                    payload: { saveItemContentError: message }
                });
            })
    }
    return next(action);
};
