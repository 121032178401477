import _ from "lodash";

const extractCategory = ({type}) => {
    const colonIndex = type.indexOf(":");
    return _.upperFirst(_.camelCase(type.substring(0, colonIndex)));
};

const features = {default: ({action, next}) => next(action)};

export const registerFeature = (name, middleware) => {
    features[_.upperFirst(_.camelCase(name))] = middleware;
};

export default ({getState, dispatch}) => next => (action) => {
    const category = extractCategory(action);
    (features[category] || features["default"])({getState, dispatch, next, action});
}

registerFeature("USER", require("./featureApis/userMiddleware").default);
registerFeature("NOTIFICATIONS", require("./featureApis/notificationsMiddleware").default);
registerFeature("LAUNCHPAD", require("./featureApis/launchpadMiddleware").default);
registerFeature("TENANCY", require("./featureApis/tenancyMiddleware").default);