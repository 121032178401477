import React, {useMemo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {ActionButton, useForm, useWizard, Notifications} from "@netapp/bxp-design-system-react";
import {activeDirectoryProps} from "../../../../utils/aws";
import {RadioButtonCard} from "../../../RadioButtonWidget";
import {FooterLayout, ContentLayout} from "../../../create-volume/Page";
import {ActiveDirectory, Smb} from "../../../create-volume/ProtocolSmb";
import {Nfs} from "../../../create-volume/ProtocolNfs";
import {validator} from "../../../create-volume/ProtocolSchema";
import commonStyles from "../../../../styles/common.module.scss";
import styles from "../../../create-volume/Protocol.module.scss";
import {volumeProtocolMapper} from "./utils";

const validateForm = validator("gcp");

const widgetButtons = [
    { label: "NFS Protocol", name: "protocol", value: "nfs", disabled: true },
    { label: "SMB Protocol", name: "protocol", value: "smb", disabled: true }
];

export const Footer = () => {
    const dispatch = useDispatch();
    const activeDirectory = useSelector(state => _.get(state, "cvs.activeDirectory"));
    const createActiveDirectoryPending = useSelector(state => _.get(state, "cvs.gcpCreateActiveDirectoryPending"));
    const editActiveDirectoryPending = useSelector(state => _.get(state, "cvs.editActiveDirectoryPending"));
    const {setStep, state} = useWizard();
    const protocolForm = state.protocolForm || {};
    const detailsAndTagsForm = state.detailsAndTagsForm || {};
    const {isValid, handleFormChange, formState, handleFormSubmit, resetSubmissionAttempted} = protocolForm;

    const moveToNextInternalStep = () => {
        handleFormChange(1, { name: "internalStep" });
    };

    const submitForm = () => {
        resetSubmissionAttempted();
        if (formState.protocolOption === "nfs") {
            setStep("snapshot-policy");
        }
        if (formState.protocolOption === "smb" || formState.protocolOption === "dual") {
            if (formState.internalStep === 0) {
                if (isValid) {
                    moveToNextInternalStep();
                }
            } else {
                if (isValid) {
                    if (_.isEmpty(activeDirectory)) {
                        dispatch({
                            type: "CVS:GCP-CREATE-ACTIVE-DIRECTORY",
                            payload: { region: detailsAndTagsForm.formState.region.value, activeDirectory: { ..._.pick(formState, activeDirectoryProps) }, onSuccess: () => setStep("snapshot-policy") }
                        });
                    } else {
                        if (!formState.editActiveDirectoryMode) {
                            // if AD exists and not in edit mode move to next step
                            setStep("snapshot-policy");
                        } else {
                            dispatch({
                                type: "CVS:GCP-EDIT-ACTIVE-DIRECTORY",
                                payload: { region: detailsAndTagsForm.formState.region.value, activeDirectory: { ..._.pick(formState, activeDirectoryProps), activeDirectoryId: activeDirectory.UUID }, onSuccess: () => setStep("snapshot-policy") }
                            });
                        }
                    }
                }
            }
        }
    };

    return (
        <FooterLayout><ActionButton disabled={!isValid} isSubmitting={createActiveDirectoryPending || editActiveDirectoryPending} onClick={e => handleFormSubmit(submitForm)(e)}>Continue</ActionButton></FooterLayout>
    );
};

const Dual = () => {
    return null
};

export const Content = ({volume}) => {
    const dispatch = useDispatch();
    const existingActiveDirectory = useSelector(state => _.get(state, `cvs.activeDirectory`));
    const editActiveDirectoryError = useSelector(state => _.get(state, `cvs.editActiveDirectoryError`));
    const editActiveDirectoryShowError = useSelector(state => _.get(state, `cvs.editActiveDirectoryShowError`));
    const activeDirectoryForm = _.pick(existingActiveDirectory, activeDirectoryProps);
    const {setState, state} = useWizard();
    const currentState = state?.protocolForm?.formState || {};
    const password = useMemo(() => {
        if (_.isEmpty(activeDirectoryForm)) {
            return "";
        } else {
            if (currentState.editActiveDirectoryMode) {
                return "";
            } else {
                return activeDirectoryForm.password;
            }
        }
    }, [currentState, activeDirectoryForm]);
    const protocolForm = useForm({...volumeProtocolMapper(volume), ...currentState, ...activeDirectoryForm, password, internalStep: 0}, validateForm);
    const protocolOption = protocolForm.formState.protocolOption;
    const internalStep = protocolForm.formState.internalStep;

    useEffect(() => {
        setState({protocolForm});
    }, [setState, protocolForm]);

    const Form = useMemo(() => {
        switch (protocolOption) {
            case "nfs": {
                return Nfs;
            }
            case "smb": {
                return internalStep === 0 ? Smb : ActiveDirectory;
            }
            case "dual": {
                return internalStep === 0 ? Dual : ActiveDirectory;
            }
            default: {
                return  Nfs
            }
        }
    }, [internalStep, protocolOption]);

    const notifications = [{
        type: "error",
        description: editActiveDirectoryError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { editActiveDirectoryShowError: false }}),
        showError: editActiveDirectoryShowError
    }];

    return (
        <ContentLayout className={styles["container"]} title={"Protocol"}>
            <RadioButtonCard
                name={"protocolOption"}
                checkedValue={protocolOption}
                buttons={widgetButtons}
                widgetLabel="Select the volume's protocol:"
                className={styles["widget"]}/>
            <Form form={protocolForm} disabled={true}/>
            <Notifications className={commonStyles["error-area"]} notifications={_.filter(notifications, { showError: true })}/>
        </ContentLayout>
    );
};
