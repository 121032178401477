import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {Route, Switch, useParams, useLocation} from "react-router";
import querystring from "query-string";
import AddCvsWeWizard from "./components/add-we/Page";
import {AzureCreateVolumePageWrapper as AzureCreateVolume} from "./components/create-volume/PageAzure";
import {AwsCreateVolumePageWrapper as AwsCreateVolume} from "./components/create-volume/PageAws";
import {GcpCreateVolumePageWrapper as GcpCreateVolume} from "./components/create-volume/PageGcp";
import AwsVolumeViewRoutes from './components/volume-view/aws/routes';
import AzureVolumeViewRoutes from './components/volume-view/azure/routes';
import GcpVolumeViewRoutes from './components/volume-view/gcp/routes';

export default () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { flavour, accountId, credentialsId } = useParams();
    const { isSimulated } = querystring.parse(location.search);

    useEffect(() => {
        dispatch({
            type: "CVS:URL-PARAMS",
            payload: { cvsFlavour: flavour, accountId, credentialsId, isSimulated }
        });
        // do not want useEffect to run if isSimulated has changed (want to keep the initial value simulation value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flavour, accountId, credentialsId, dispatch]);

    return (
        <>
            <Switch>
                <Route path="/components/cvs/:flavour/:accountId/add-working-environment" exact render={() => <AddCvsWeWizard/>}/>
                <Route path="/components/cvs/aws/:accountId/:credentialsId/create-volume" exact render={() => <AwsCreateVolume/>}/>
                <Route path="/components/cvs/azure/:accountId/:credentialsId/create-volume" exact render={() => <AzureCreateVolume/>}/>
                <Route path="/components/cvs/gcp/:accountId/:credentialsId/create-volume" exact render={() => <GcpCreateVolume/>}/>
                <Route path="/components/cvs/aws/:accountId/:credentialsId" render={() => <AwsVolumeViewRoutes/>}/>
                <Route path="/components/cvs/azure/:accountId/:credentialsId" render={() => <AzureVolumeViewRoutes/>}/>
                <Route path="/components/cvs/gcp/:accountId/:credentialsId" render={() => <GcpVolumeViewRoutes/>}/>
                <Route render={() => <div>
                    <div>Invalid URL</div>
                    <div>Check service, accountId or credentialsId in URL</div>
                </div>}/>
            </Switch>
        </>
    );
};
