import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import classNames from "classnames";
import * as yup from "yup";
import moment from "moment";
import ContentLayout, {Title} from "../ContentLayout";
import {ActionButton, useForm, FormFieldInputNew, Notifications} from "@netapp/bxp-design-system-react";
import ActionButtonContainer from "../ActionButtonContainer";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import commonStyles from "../../../../styles/common.module.scss";
import styles from "./Content.module.scss";

const initialState = {
    name: `manually-${moment(new Date()).format('YYYY-MM-DD_HHmm')}`
};

const schema = yup.object().shape({
    name: yup.string().required("Snapshot name is required.")
});

export default ({volume}) => {
    const dispatch = useDispatch();
    const flavour = useSelector(state => _.get(state, "cvs.cvsFlavour"));
    const createSnapshotPending = useSelector(state => _.get(state, "cvs.createSnapshotPending"));
    const createSnapshotError = useSelector(state => _.get(state, "cvs.createSnapshotError"));
    const createSnapshotShowError = useSelector(state => _.get(state, "cvs.createSnapshotShowError"));
    const form = useForm(initialState, schema);

    useEffect(() => {
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: { createSnapshotResolved: false, createSnapshotError: null, createSnapshotShowError: false }
        });
    }, [dispatch]);

    const createSnapshot = () => {
        dispatch({
            type: `CVS:${flavour.toUpperCase()}-CREATE-SNAPSHOT`,
            payload: { snapshotName: form.formState.name, volume }
        });
    };

    const hideNotification = () => dispatch({
        type: "CVS:CLEAR-STATE",
        payload: { createSnapshotShowError: false }
    });

    const notifications = [{
        type: "error",
        description: createSnapshotError,
        onClose: () => hideNotification(),
        showError: createSnapshotShowError
    }];

    return (
        <ContentLayout className={styles["content"]}>
            <Title className={"not-spaced"}>Create a Snapshot Copy - <span className={"vol-name"} title={volume.name}>{volume.name}</span></Title>
            <div className={classNames(typography.body14, styles["info"])}>A NetApp Snapshot copy is a read-only, point-in-time image of a volume. The image protects your data with no performance impact and requires minimal storage.</div>
            <FormFieldInputNew
                form={form}
                name="name"
                label="Snapshot Copy Name"
                maxChars={255}
                autoFocus={true}
                className={styles["form-field"]}/>
            <ActionButtonContainer className={styles["button-container"]}>
                <ActionButton isSubmitting={createSnapshotPending} onClick={e => form.handleFormSubmit(createSnapshot)(e)}>Create</ActionButton>
            </ActionButtonContainer>
            <Notifications className={commonStyles["error-area"]} notifications={_.filter(notifications, { showError: true })}/>
        </ContentLayout>
    );
};
