import axios from "axios";
import _ from "lodash";
import {createVolumeData as createVolumeDataAws} from "./utils/aws";
import {createVolumeData as createVolumeDataGcp} from "./utils/gcp";
import {createVolumeData as createVolumeDataAzure, getAzureProps} from "./utils/azure";
import {getErrorMessage, gibToByte, mapSnapshotPolicyForm, mapRules} from "./utils/common";
import {tibToByte} from "./utils/common";

let instance = null;

export default ({ dispatch, getState, next, action }) => {
    const { type, payload } = action;
    const { accountId, credentialsId } = getState().cvs;
    if (type === "CVS:URL-PARAMS") {
        const { isSimulated } = payload;
        const { isSimulated: currentSimulatorState } = getState().cvs;
        const shouldSimulate = currentSimulatorState ?  currentSimulatorState : isSimulated;
        const headers = {};
        if(shouldSimulate) {
            headers['X-Simulator'] = "true";
        }
        instance = axios.create({
            headers
        });
    } else if (type === "CVS:GET-WE") {
        instance.get(`/cvs/accounts/${accountId}/working-environments/${credentialsId}`)
            .then(res => {
                dispatch({
                    type: "CVS:GET-WE-SUCCESS",
                    payload: { we: res.data }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:GET-WE-FAILURE",
                    payload: { getWeError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:DELETE-WE") {
        instance.delete(`/cvs/accounts/${accountId}/working-environments/${credentialsId}`)
            .then(() => {
                dispatch({
                    type: "CVS:DELETE-WE-SUCCESS"
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:DELETE-WE-FAILURE",
                    payload: { deleteWeError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:ADD-WE") {
        instance.post(`/cvs/accounts/${accountId}/working-environments`, { ...payload })
            .then(res => {
                const { credentialsId } = res.data;
                dispatch({
                    type: "CVS:ADD-WE-SUCCESS",
                    payload: { credentialsId }
                })
            })
            .catch(error => {
                dispatch({
                    type: "CVS:ADD-WE-FAILURE",
                    payload: { addWeError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AWS-IS-ON-BOARDED") {
        instance.get(`/cvs/aws/accounts/${accountId}/credentials/${credentialsId}/is-on-boarded`)
            .then(res => {
                const { onboarded } = res.data;
                dispatch({
                    type: "CVS:AWS-IS-ON-BOARDED-SUCCESS",
                    payload: { isOnBoarded: onboarded }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AWS-IS-ON-BOARDED-FAILURE",
                    payload: { isOnBoardedError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AWS-NETWORK-SETUP") {
        const { awsAccountId, cidr } = payload;
        instance.post(`/cvs/aws/accounts/${accountId}/credentials/${credentialsId}/networks`, {
            amazonAccountId: awsAccountId,
            subnet: cidr
        })
            .then(() => {
                dispatch({
                    type: "CVS:AWS-NETWORK-SETUP-SUCCESS"
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AWS-NETWORK-SETUP-FAILURE",
                    payload: { networkSetupError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AWS-GET-ACTIVE-DIRECTORY") {
        instance.get(`/cvs/aws/accounts/${accountId}/credentials/${credentialsId}/activeDirectory`)
            .then(res => {
                dispatch({
                    type: "CVS:AWS-GET-ACTIVE-DIRECTORY-SUCCESS",
                    payload: { activeDirectory: res.data }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AWS-GET-ACTIVE-DIRECTORY-FAILURE",
                    payload: { getActiveDirectoryError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AWS-CREATE-ACTIVE-DIRECTORY") {
        const { activeDirectory, caller, onSuccess } = payload;
        const { DNS, netBIOS, domain, organizationalUnit, password, username } = activeDirectory;
        instance.post(`/cvs/aws/accounts/${accountId}/credentials/${credentialsId}/activeDirectory`, {
            DNS,
            domain,
            netBIOS,
            password,
            username,
            organizationalUnit
        })
            .then(() => {
                dispatch({
                    type: "CVS:AWS-CREATE-ACTIVE-DIRECTORY-SUCCESS",
                    payload: { activeDirectory, caller, onSuccess }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AWS-CREATE-ACTIVE-DIRECTORY-FAILURE",
                    payload: { createActiveDirectoryError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AWS-EDIT-ACTIVE-DIRECTORY") {
        const { activeDirectory, caller, onSuccess } = payload;
        const { DNS, netBIOS, domain, organizationalUnit, password, username, activeDirectoryId } = activeDirectory;
        instance.put(`/cvs/aws/accounts/${accountId}/credentials/${credentialsId}/activeDirectory/${activeDirectoryId}`, {
            DNS,
            domain,
            netBIOS,
            password,
            username,
            organizationalUnit
        })
            .then(() => {
                dispatch({
                    type: "CVS:AWS-EDIT-ACTIVE-DIRECTORY-SUCCESS",
                    payload: { activeDirectory, caller, onSuccess }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AWS-EDIT-ACTIVE-DIRECTORY-FAILURE",
                    payload: { editActiveDirectoryError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AWS-DELETE-ACTIVE-DIRECTORY") {
        const { activeDirectoryId } = payload;
        instance.delete(`/cvs/aws/accounts/${accountId}/credentials/${credentialsId}/activeDirectory/${activeDirectoryId}`)
            .then(() => {
                dispatch({
                    type: "CVS:AWS-DELETE-ACTIVE-DIRECTORY-SUCCESS"
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AWS-DELETE-ACTIVE-DIRECTORY-FAILURE",
                    payload: { deleteActiveDirectoryError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AWS-CREATE-VOLUME") {
        const { createVolumeForm } = payload;
        const data = createVolumeDataAws(createVolumeForm);
        instance.post(`/cvs/aws/accounts/${accountId}/credentials/${credentialsId}/volumes`, data)
            .then(() => {
                dispatch({
                    type: "CVS:AWS-CREATE-VOLUME-SUCCESS"
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AWS-CREATE-VOLUME-FAILURE",
                    payload: { createVolumeError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AWS-EDIT-VOLUME") {
        const { newState, volume } = payload;
        let labels = [];
        if (!_.isEmpty(newState.tags[0].name)) {
            labels = _.map(newState.tags, tag => tag.name);
        }
        const data = {
            name: volume.name,
            quotaInBytes: gibToByte(newState.sizeInGiB),
            serviceLevel: newState.serviceLevel.value.toLowerCase(),
            labels
        };
        instance.put(`/cvs/aws/accounts/${accountId}/credentials/${credentialsId}/volumes/${volume.volumeId}`, data)
            .then(() => {
                dispatch({
                    type: "CVS:AWS-EDIT-VOLUME-SUCCESS"
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AWS-EDIT-VOLUME-FAILURE",
                    payload: { editVolumeError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AWS-GET-SNAPSHOTS") {
        instance.get(`/cvs/aws/accounts/${accountId}/credentials/${credentialsId}/snapshots`)
            .then(res => {
                dispatch({
                    type: "CVS:AWS-GET-SNAPSHOTS-SUCCESS",
                    payload: { snapshots: res.data }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AWS-GET-SNAPSHOTS-FAILURE",
                    payload: { getSnapshotsError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AWS-GET-VOLUMES") {
        instance.get(`/cvs/aws/accounts/${accountId}/credentials/${credentialsId}/volumes`)
            .then(res => {
                dispatch({
                    type: "CVS:AWS-GET-VOLUMES-SUCCESS",
                    payload: { volumes: res.data }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AWS-GET-VOLUMES-FAILURE",
                    payload: { getVolumesError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AWS-DELETE-VOLUME") {
        const { volumeId } = payload.volumeToDelete;
        instance.delete(`/cvs/aws/accounts/${accountId}/credentials/${credentialsId}/volumes/${volumeId}`)
            .then(res => {
                dispatch({
                    type: "CVS:AWS-DELETE-VOLUME-SUCCESS",
                    payload: { awsDeletedVolume: res.data }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AWS-DELETE-VOLUME-FAILURE",
                    payload: { deleteVolumeError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AWS-CREATE-SNAPSHOT") {
        const { snapshotName, volume } = payload;
        instance.post(`/cvs/aws/accounts/${accountId}/credentials/${credentialsId}/volumes/${volume.volumeId}/snapshots`, {
            name: snapshotName
        })
            .then(() => {
                dispatch({
                    type: "CVS:AWS-CREATE-SNAPSHOT-SUCCESS"
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AWS-CREATE-SNAPSHOT-FAILURE",
                    payload: { awsCreateSnapshotError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AWS-DELETE-SNAPSHOT") {
        const { snapshotToDelete, volume } = payload;
        instance.delete(`/cvs/aws/accounts/${accountId}/credentials/${credentialsId}/volumes/${volume.volumeId}/snapshots/${snapshotToDelete.value}`)
            .then(() => {
                dispatch({
                    type: "CVS:AWS-DELETE-SNAPSHOT-SUCCESS"
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AWS-DELETE-SNAPSHOT-FAILURE",
                    payload: { deleteSnapshotError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AWS-GET-VOLUME-SNAPSHOTS") {
        const { volume } = payload;
        instance.get(`/cvs/aws/accounts/${accountId}/credentials/${credentialsId}/volumes/${volume.volumeId}/snapshots`)
            .then(res => {
                dispatch({
                    type: "CVS:AWS-GET-VOLUME-SNAPSHOTS-SUCCESS",
                    payload: { volumeSnapshots: res.data }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AWS-GET-VOLUME-SNAPSHOTS-FAILURE",
                    payload: { getVolumeSnapshotsError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AWS-REVERT-VOLUME") {
        const { snapshotId, volume } = payload;
        instance.post(`/cvs/aws/accounts/${accountId}/credentials/${credentialsId}/volumes/${volume.volumeId}/snapshots/${snapshotId}/restore-from-snapshot`)
            .then(() => {
                dispatch({
                    type: "CVS:AWS-REVERT-VOLUME-SUCCESS"
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AWS-REVERT-VOLUME-FAILURE",
                    payload: { revertVolumeError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AWS-GET-VOLUME") {
        const { volumeId } = payload;
        instance.get(`/cvs/aws/accounts/${accountId}/credentials/${credentialsId}/volumes/${volumeId}`)
            .then(res => {
                dispatch({
                    type: "CVS:AWS-GET-VOLUME-SUCCESS",
                    payload: { volume: res.data }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AWS-GET-VOLUME-FAILURE",
                    payload: { getVolumeError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:GET-GCP-PROJECTS") {
        const { serviceAccountCredentials } = payload;
        instance.post(`/cvs/gcp/projects`, { serviceAccountCredentials })
            .then(response => {
                dispatch({
                    type: "CVS:GET-GCP-PROJECTS-SUCCESS",
                    payload: { gcpProjects: response.data }
                })
            })
            .catch(error => {
                dispatch({
                    type: "CVS:GET-GCP-PROJECTS-FAILURE",
                    payload: { getGcpProjectsError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AZURE-GET-NETAPP-ACCOUNTS") {
        instance.get(`/cvs/azure/accounts/${accountId}/credentials/${credentialsId}/netAppAccounts`)
            .then(response => {
                dispatch({
                    type: "CVS:AZURE-GET-NETAPP-ACCOUNTS-SUCCESS",
                    payload: { netappAccounts: response.data }
                })
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AZURE-GET-NETAPP-ACCOUNTS-FAILURE",
                    payload: { getNetappAccountsError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AZURE-GET-SUBSCRIPTIONS") {
        instance.get(`/cvs/azure/accounts/${accountId}/credentials/${credentialsId}/subscriptions`)
            .then(response => {
                dispatch({
                    type: "CVS:AZURE-GET-SUBSCRIPTIONS-SUCCESS",
                    payload: { subscriptions: response.data }
                })
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AZURE-GET-SUBSCRIPTIONS-FAILURE",
                    payload: { getSubscriptionsError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AZURE-GET-RESOURCE-GROUPS") {
        const { subscriptionId } = payload;
        instance.get(`/cvs/azure/accounts/${accountId}/credentials/${credentialsId}/subscriptions/${subscriptionId}/resourceGroups`)
            .then(response => {
                dispatch({
                    type: "CVS:AZURE-GET-RESOURCE-GROUPS-SUCCESS",
                    payload: { resourceGroups: response.data }
                })
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AZURE-GET-RESOURCE-GROUPS-FAILURE",
                    payload: { getResourceGroupsError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AZURE-CREATE-RESOURCE-GROUP") {
        const { subscriptionId, location, resourceGroupName } = payload;
        instance.post(`/cvs/azure/accounts/${accountId}/credentials/${credentialsId}/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}`, {
            location: location
        })
            .then(response => {
                dispatch({
                    type: "CVS:AZURE-CREATE-RESOURCE-GROUP-SUCCESS",
                    payload: { anfResourceGroup: response.data }
                })
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AZURE-CREATE-RESOURCE-GROUP-FAILURE",
                    payload: { anfResourceGroupError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AZURE-GET-ACTIVE-DIRECTORY") {
        const { subscriptionId, resourceGroupName, netappAccountName, onSuccess } = payload;
        instance.get(`/cvs/azure/accounts/${accountId}/credentials/${credentialsId}/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/netAppAccounts/${netappAccountName}/activeDirectory`)
            .then(response => {
                dispatch({
                    type: "CVS:AZURE-GET-ACTIVE-DIRECTORY-SUCCESS",
                    payload: { activeDirectory: response.data, onSuccess }
                })
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AZURE-GET-ACTIVE-DIRECTORY-FAILURE",
                    payload: { getActiveDirectoryError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AZURE-EDIT-ACTIVE-DIRECTORY") {
        const { subscriptionId, resourceGroupName, netappAccountName, activeDirectory, onSuccess } = payload;
        const { DNS, netBIOS, domain, organizationalUnit, password, username, activeDirectoryId } = activeDirectory;
        instance.put(`/cvs/azure/accounts/${accountId}/credentials/${credentialsId}/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/netAppAccounts/${netappAccountName}/activeDirectory/${activeDirectoryId}`, {
                    DNS,
                    domain,
                    netBIOS,
                    password,
                    username,
                    organizationalUnit
                })
            .then(response => {
                dispatch({
                    type: "CVS:AZURE-EDIT-ACTIVE-DIRECTORY-SUCCESS",
                    payload: { activeDirectory: response.data, onSuccess }
                })
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AZURE-EDIT-ACTIVE-DIRECTORY-FAILURE",
                    payload: { editActiveDirectoryError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AZURE-CREATE-ACTIVE-DIRECTORY") {
        const { subscriptionId, resourceGroupName, netappAccountName, activeDirectory, onSuccess } = payload;
        const { DNS, netBIOS, domain, organizationalUnit, password, username } = activeDirectory;
        instance.post(`/cvs/azure/accounts/${accountId}/credentials/${credentialsId}/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/netAppAccounts/${netappAccountName}/activeDirectory`, {
            DNS,
            netBIOS,
            domain,
            username,
            password,
            organizationalUnit
        }).then(response => {
            dispatch({
                type: "CVS:AZURE-CREATE-ACTIVE-DIRECTORY-SUCCESS",
                payload: { activeDirectory: response.data, onSuccess }
            })
        }).catch(error => {
            dispatch({
                type: "CVS:AZURE-CREATE-ACTIVE-DIRECTORY-FAILURE",
                payload: { createActiveDirectoryError: getErrorMessage(error) }
            });
        });
    } else if (type === "CVS:AZURE-CREATE-NETAPP-ACCOUNT") {
        const { subscriptionId, location, accountName, resourceGroupName, onSuccess } = payload;
        instance.post(`/cvs/azure/accounts/${accountId}/credentials/${credentialsId}/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/netAppAccounts/${accountName}`, {
            location: location
        })
            .then(response => {
                dispatch({
                    type: "CVS:AZURE-CREATE-NETAPP-ACCOUNT-SUCCESS",
                    payload: { netappAccount: response.data, onSuccess }
                })
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AZURE-CREATE-NETAPP-ACCOUNT-FAILURE",
                    payload: { createNetappAccountError: getErrorMessage(error) }
                })
            });
    } else if (type === "CVS:AZURE-GET-CAPACITY-POOLS") {
        const { subscriptionId, resourceGroupName, netappAccountName, caller } = payload;
        instance.get(`/cvs/azure/accounts/${accountId}/credentials/${credentialsId}/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/netAppAccounts/${netappAccountName}/capacityPools`)
            .then(response => {
                dispatch({
                    type: "CVS:AZURE-GET-CAPACITY-POOLS-SUCCESS",
                    payload: { capacityPools: response.data, caller }
                })
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AZURE-GET-CAPACITY-POOLS-FAILURE",
                    payload: { getCapacityPoolsError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AZURE-CREATE-CAPACITY-POOL") {
        const { subscriptionId, location, resourceGroupName, netappAccountName, capacityPoolName, sizeInTiB, serviceLevel, onSuccess } = payload;
        instance.post(`/cvs/azure/accounts/${accountId}/credentials/${credentialsId}/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/netAppAccounts/${netappAccountName}/capacityPools/${capacityPoolName}`, {
            location: location,
            quotaInBytes: tibToByte(sizeInTiB),
            serviceLevel: serviceLevel
        })
            .then(response => {
                dispatch({
                    type: "CVS:AZURE-CREATE-CAPACITY-POOL-SUCCESS",
                    payload: { capacityPool: response.data, onSuccess }
                })
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AZURE-CREATE-CAPACITY-POOL-FAILURE",
                    payload: { createCapacityPoolError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AZURE-UPDATE-VOLUME-CAPACITY-POOL") {
        const { subscriptionId, resourceGroupName, netappAccountName, poolName, volumeName, poolId } = payload;
        instance.post(`/cvs/azure/accounts/${accountId}/credentials/${credentialsId}/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/netAppAccounts/${netappAccountName}/capacityPools/${poolName}/volumes/${volumeName}/poolChange`, {
            poolId
        })
            .then(() => {
                dispatch({
                    type: "CVS:AZURE-UPDATE-VOLUME-CAPACITY-POOL-SUCCESS"
                })
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AZURE-UPDATE-VOLUME-CAPACITY-POOL-FAILURE",
                    payload: { updateVolumePoolError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AZURE-GET-VIRTUAL-NETWORKS") {
        const { subscriptionId, location } = payload;
        instance.get(`/cvs/azure/accounts/${accountId}/credentials/${credentialsId}/subscriptions/${subscriptionId}/virtualNetworks?location=${location}`)
            .then(response => {
                dispatch({
                    type: "CVS:AZURE-GET-VIRTUAL-NETWORKS-SUCCESS",
                    payload: { virtualNetworks: response.data }
                })
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AZURE-GET-VIRTUAL-NETWORKS-FAILURE",
                    payload: { getVirtualNetworksError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AZURE-CREATE-VOLUME") {
        const { createVolumeForm } = payload;
        const { subscriptionId, location, netappAccountName, resourceGroupName, capacityPoolName } = getAzureProps(createVolumeForm);
        const data = createVolumeDataAzure(createVolumeForm, location);
        instance.post(`/cvs/azure/accounts/${accountId}/credentials/${credentialsId}/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/netAppAccounts/${netappAccountName}/capacityPools/${capacityPoolName}/volumes`, {
            ...data
        }).then(res => {
            dispatch({
                type: "CVS:AZURE-CREATE-VOLUME-SUCCESS",
                payload: { anfVolume: res.data }
            });
        })
            .catch(error => {
                dispatch({
                    type: "CVS:AZURE-CREATE-VOLUME-FAILURE",
                    payload: { createVolumeError: getErrorMessage(error)}
                });
            });
    } else if (type === "CVS:AZURE-EDIT-VOLUME") {
        const { newState, volume } = payload;
        let labels = {};
        if (!_.isEmpty(newState.tags[0].key) && !_.isEmpty(newState.tags[0].value)) {
            labels = _.reduce(newState.tags || [], (tagsObject, tag) => {
                tagsObject[tag.key] = tag.value;
                return tagsObject;
            }, {});
        }
        instance.put(`/cvs/azure/accounts/${accountId}/credentials/${credentialsId}/subscriptions/${volume.subscriptionId}/resourceGroups/${volume.resourceGroupName}/netAppAccounts/${volume.netAppAccountName}/capacityPools/${volume.poolName}/volumes/${volume.name}`, {
            quotaInBytes: gibToByte(newState.sizeInGiB),
            labels,
            location: volume.region
        })
            .then(res => {
                dispatch({
                    type: "CVS:AZURE-EDIT-VOLUME-SUCCESS",
                    payload: { anfVolume: res.data }
                })
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AZURE-EDIT-VOLUME-FAILURE",
                    payload: { editVolumeError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AZURE-GET-VOLUMES") {
        instance.get(`/cvs/azure/accounts/${accountId}/credentials/${credentialsId}/volumes`)
            .then(res => {
                dispatch({
                    type: "CVS:AZURE-GET-VOLUMES-SUCCESS",
                    payload: { volumes: res.data }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AZURE-GET-VOLUMES-FAILURE",
                    payload: { getVolumesError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AZURE-GET-VOLUME") {
        const { subscriptionId, resourceGroupName, netAppAccountName, poolName, name } = payload;
        return instance.get(`/cvs/azure/accounts/${accountId}/credentials/${credentialsId}/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/netAppAccounts/${netAppAccountName}/capacityPools/${poolName}/volumes/${name}`)
            .then(res => {
                dispatch({
                    type: "CVS:AZURE-GET-VOLUME-SUCCESS",
                    payload: { volume: res.data }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AZURE-GET-VOLUME-FAILURE",
                    payload: { getVolumeError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AZURE-DELETE-VOLUME") {
        const { subscriptionId, resourceGroupName, netAppAccountName, poolName, name } = payload.volumeToDelete;
        instance.delete(`/cvs/azure/accounts/${accountId}/credentials/${credentialsId}/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/netAppAccounts/${netAppAccountName}/capacityPools/${poolName}/volumes/${name}`)
            .then(res => {
                dispatch({
                    type: "CVS:AZURE-DELETE-VOLUME-SUCCESS",
                    payload: { anfDeletedVolume: res.data }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AZURE-DELETE-VOLUME-FAILURE",
                    payload: { deleteVolumeError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AZURE-CREATE-SNAPSHOT") {
        const { snapshotName, volume } = payload;
        instance.post(`/cvs/azure/accounts/${accountId}/credentials/${credentialsId}/subscriptions/${volume.subscriptionId}/resourceGroups/${volume.resourceGroupName}/netAppAccounts/${volume.netAppAccountName}/capacityPools/${volume.poolName}/volumes/${volume.name}/snapshots`, {
            location: volume.region,
            name: snapshotName
        })
            .then(() => {
                dispatch({
                    type: "CVS:AZURE-CREATE-SNAPSHOT-SUCCESS"
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AZURE-CREATE-SNAPSHOT-FAILURE",
                    payload: { createSnapshotError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AZURE-DELETE-SNAPSHOT") {
        const { snapshotToDelete, volume } = payload;
        instance.delete(`/cvs/azure/accounts/${accountId}/credentials/${credentialsId}/subscriptions/${volume.subscriptionId}/resourceGroups/${volume.resourceGroupName}/netAppAccounts/${volume.netAppAccountName}/capacityPools/${volume.poolName}/volumes/${volume.name}/snapshots/${snapshotToDelete.label}`)
            .then(() => {
                dispatch({
                    type: "CVS:AZURE-DELETE-SNAPSHOT-SUCCESS"
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AZURE-DELETE-SNAPSHOT-FAILURE",
                    payload: { deleteSnapshotError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AZURE-GET-VOLUME-SNAPSHOTS") {
        const { volume } = payload;
        instance.get(`/cvs/azure/accounts/${accountId}/credentials/${credentialsId}/subscriptions/${volume.subscriptionId}/resourceGroups/${volume.resourceGroupName}/netAppAccounts/${volume.netAppAccountName}/capacityPools/${volume.poolName}/volumes/${volume.name}/snapshots`)
            .then(res => {
                dispatch({
                    type: "CVS:AZURE-GET-VOLUME-SNAPSHOTS-SUCCESS",
                    payload: { volumeSnapshots: res.data }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AZURE-GET-VOLUME-SNAPSHOTS-FAILURE",
                    payload: { getVolumeSnapshotsError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:AZURE-REVERT-VOLUME") {
        const { snapshotId, volume } = payload;
        instance.post(`/cvs/azure/accounts/${accountId}/credentials/${credentialsId}/subscriptions/${volume.subscriptionId}/resourceGroups/${volume.resourceGroupName}/netAppAccounts/${volume.netAppAccountName}/capacityPools/${volume.poolName}/volumes/${volume.name}/restore-from-snapshot`, {
            snapshotId
        })
            .then(() => {
                dispatch({
                    type: "CVS:AZURE-REVERT-VOLUME-SUCCESS"
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:AZURE-REVERT-VOLUME-FAILURE",
                    payload: { revertVolumeError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:GCP-GET-VOLUMES") {
        instance.get(`/cvs/gcp/accounts/${accountId}/credentials/${credentialsId}/volumes`)
            .then(res => {
                dispatch({
                    type: "CVS:GCP-GET-VOLUMES-SUCCESS",
                    payload: { gcpVolumes: res.data }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:GCP-GET-VOLUMES-FAILURE",
                    payload: { getVolumesError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:GCP-GET-VOLUME") {
        const { volumeId, region } = payload;
        instance.get(`/cvs/gcp/accounts/${accountId}/credentials/${credentialsId}/locations/${region}/volumes/${volumeId}`)
            .then(res => {
                dispatch({
                    type: "CVS:GCP-GET-VOLUME-SUCCESS",
                    payload: { volume: res.data }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:GCP-GET-VOLUME-FAILURE",
                    payload: { getVolumeError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:GCP-CREATE-SNAPSHOT") {
        const { snapshotName, volume } = payload;
        instance.post(`/cvs/gcp/accounts/${accountId}/credentials/${credentialsId}/locations/${volume.region}/volumes/${volume.volumeId}/snapshots`, {
            name: snapshotName
        })
            .then(() => {
                dispatch({
                    type: "CVS:GCP-CREATE-SNAPSHOT-SUCCESS"
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:GCP-CREATE-SNAPSHOT-FAILURE",
                    payload: { createSnapshotError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:GCP-GET-VOLUME-SNAPSHOTS") {
        const { volume } = payload;
        instance.get(`/cvs/gcp/accounts/${accountId}/credentials/${credentialsId}/locations/${volume.region}/volumes/${volume.volumeId}/snapshots`)
            .then(res => {
                dispatch({
                    type: "CVS:GCP-GET-VOLUME-SNAPSHOTS-SUCCESS",
                    payload: { volumeSnapshots: res.data }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:GCP-GET-VOLUME-SNAPSHOTS-FAILURE",
                    payload: { getVolumeSnapshotsError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:GCP-DELETE-SNAPSHOT") {
        const { snapshotToDelete, volume } = payload;
        instance.delete(`/cvs/gcp/accounts/${accountId}/credentials/${credentialsId}/locations/${volume.region}/volumes/${volume.volumeId}/snapshots/${snapshotToDelete.value}`)
            .then(() => {
                dispatch({
                    type: "CVS:GCP-DELETE-SNAPSHOT-SUCCESS"
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:GCP-DELETE-SNAPSHOT-FAILURE",
                    payload: { deleteSnapshotError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:GCP-DELETE-VOLUME") {
        const { volumeId, region } = payload.volumeToDelete;
        instance.delete(`/cvs/gcp/accounts/${accountId}/credentials/${credentialsId}/locations/${region}/volumes/${volumeId}`)
            .then(res => {
                dispatch({
                    type: "CVS:GCP-DELETE-VOLUME-SUCCESS",
                    payload: { gcpDeletedVolume: res.data }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:GCP-DELETE-VOLUME-FAILURE",
                    payload: { deleteVolumeError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:GCP-GET-VIRTUAL-NETWORKS") {
        instance.get(`/cvs/gcp/accounts/${accountId}/credentials/${credentialsId}/virtualNetworks`)
            .then(res => {
                dispatch({
                    type: "CVS:GCP-GET-VIRTUAL-NETWORKS-SUCCESS",
                    payload: { virtualNetworks: res.data }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:GCP-GET-VIRTUAL-NETWORKS-FAILURE",
                    payload: { getVirtualNetworksError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:GCP-GET-ACTIVE-DIRECTORY") {
        const { region, onSuccess } = payload;
        instance.get(`/cvs/gcp/accounts/${accountId}/credentials/${credentialsId}/locations/${region}/activeDirectory`)
            .then(res => {
                dispatch({
                    type: "CVS:GCP-GET-ACTIVE-DIRECTORY-SUCCESS",
                    payload: { activeDirectory: res.data, onSuccess }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:GCP-GET-ACTIVE-DIRECTORY-FAILURE",
                    payload: { getActiveDirectoryError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:GCP-CREATE-ACTIVE-DIRECTORY") {
        const { activeDirectory, region, onSuccess } = payload;
        const { DNS, netBIOS, domain, organizationalUnit, password, username } = activeDirectory;
        instance.post(`/cvs/gcp/accounts/${accountId}/credentials/${credentialsId}/locations/${region}/activeDirectory`, {
            DNS,
            domain,
            netBIOS,
            password,
            username,
            organizationalUnit
        })
            .then(() => {
                dispatch({
                    type: "CVS:GCP-CREATE-ACTIVE-DIRECTORY-SUCCESS",
                    payload: { activeDirectory, onSuccess }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:GCP-CREATE-ACTIVE-DIRECTORY-FAILURE",
                    payload: { createActiveDirectoryError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:GCP-EDIT-ACTIVE-DIRECTORY") {
        const { activeDirectory, region, onSuccess } = payload;
        const { activeDirectoryId, DNS, netBIOS, domain, organizationalUnit, password, username } = activeDirectory;
        setTimeout(() => {
            dispatch({
                type: "CVS:GCP-EDIT-ACTIVE-DIRECTORY-FAILURE",
                payload: { editActiveDirectoryError: "getErrorMessage(error)" }
            });
        }, 1000);
        instance.put(`/cvs/gcp/accounts/${accountId}/credentials/${credentialsId}/locations/${region}/activeDirectory/${activeDirectoryId}`, {
            DNS,
            domain,
            netBIOS,
            password,
            username,
            organizationalUnit
        })
            .then(() => {
                dispatch({
                    type: "CVS:GCP-EDIT-ACTIVE-DIRECTORY-SUCCESS",
                    payload: { activeDirectory, onSuccess }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:GCP-EDIT-ACTIVE-DIRECTORY-FAILURE",
                    payload: { editActiveDirectoryError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:GCP-GET-SNAPSHOTS") {
        const { region } = payload;
        instance.get(`/cvs/gcp/accounts/${accountId}/credentials/${credentialsId}/locations/${region}/snapshots`)
            .then(res => {
                dispatch({
                    type: "CVS:GCP-GET-SNAPSHOTS-SUCCESS",
                    payload: { snapshots: res.data }
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:GCP-GET-SNAPSHOTS-FAILURE",
                    payload: { getSnapshotsError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:GCP-CREATE-VOLUME") {
        const { createVolumeForm } = payload;
        const {detailsAndTagsForm} = createVolumeForm;
        const data = createVolumeDataGcp(createVolumeForm);
        instance.post(`/cvs/gcp/accounts/${accountId}/credentials/${credentialsId}/locations/${detailsAndTagsForm.formState.region.value}/volumes`, data)
            .then(() => {
                dispatch({
                    type: "CVS:GCP-CREATE-VOLUME-SUCCESS"
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:GCP-CREATE-VOLUME-FAILURE",
                    payload: { createVolumeError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:GCP-REVERT-VOLUME") {
        const { snapshotId, volume } = payload;
        instance.post(`cvs/gcp/accounts/${accountId}/credentials/${credentialsId}/locations/${volume.region}/volumes/${volume.volumeId}/snapshots/${snapshotId}/restore-from-snapshot`)
            .then(() => {
                dispatch({
                    type: "CVS:GCP-REVERT-VOLUME-SUCCESS"
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:GCP-REVERT-VOLUME-FAILURE",
                    payload: { revertVolumeError: getErrorMessage(error) }
                });
            });
    } else if (type === "CVS:GCP-EDIT-VOLUME") {
        const { createVolumeForm, volumeId } = payload;
        const { detailsAndTagsForm, protocolForm , snapshotPolicyForm} = createVolumeForm;
        const data = {
            name: detailsAndTagsForm.formState.volumeName,
            quotaInBytes: gibToByte(detailsAndTagsForm.formState.sizeInGiB),
            serviceLevel: detailsAndTagsForm.formState.serviceLevel.value.toLowerCase(),
            snapshotPolicy: mapSnapshotPolicyForm(snapshotPolicyForm.formState)
        };
        if (protocolForm.formState.protocolOption === "nfs") {
            data.rules = mapRules(protocolForm.formState.exportPolicies);
        }
        instance.put(`/cvs/gcp/accounts/${accountId}/credentials/${credentialsId}/locations/${detailsAndTagsForm.formState.region.value}/volumes/${volumeId}`, data)
            .then(() => {
                dispatch({
                    type: "CVS:GCP-EDIT-VOLUME-SUCCESS"
                });
            })
            .catch(error => {
                dispatch({
                    type: "CVS:GCP-EDIT-VOLUME-FAILURE",
                    payload: { editVolumeError: getErrorMessage(error) }
                });
            });
    }
    return next(action);
};
