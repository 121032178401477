import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import classNames from "classnames";
import {useWizard, ActionButton, useForm, FormFieldInputNew, FormFieldSelect, Popover, Notifications} from "@netapp/bxp-design-system-react";
import {FooterLayout, ContentLayout} from "../../../create-volume/Page";
import {serviceLevelOptions} from "../../../../utils/gcp";
import {gcpDetailsAndTagsState} from "./utils";
import {schema as detailsAndTagsSchema} from "../../../create-volume/DetailsAndTagsGcp";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import commonStyles from "../../../../styles/common.module.scss";
import styles from "../../../create-volume/DetailsAndTags.module.scss";

export const Footer = () => {
    const dispatch = useDispatch();
    const getActiveDirectoryPending = useSelector(state => _.get(state, "cvs.getActiveDirectoryPending"));
    const {setStep, state} = useWizard();
    const detailsAndTagsForm = state.detailsAndTagsForm || {};
    const { formState, handleFormSubmit } = detailsAndTagsForm;

    const submitForm = () => {
        dispatch({
            type: "CVS:GCP-GET-ACTIVE-DIRECTORY",
            payload: { region: formState.region.value, onSuccess: () => setStep("protocol")}
        });
    };

    return (
        <FooterLayout><ActionButton isSubmitting={getActiveDirectoryPending} disabled={!handleFormSubmit} onClick={e => handleFormSubmit(submitForm)(e)}>Continue</ActionButton></FooterLayout>
    );
};

export const Content = ({volume}) => {
    const dispatch = useDispatch();
    const {setState, state} = useWizard();
    const currentState = state?.detailsAndTagsForm?.formState || {};
    const detailsAndTagsForm = useForm({...gcpDetailsAndTagsState(volume), ...currentState}, detailsAndTagsSchema);
    const getActiveDirectoryError = useSelector(state => _.get(state, "cvs.getActiveDirectoryError"));
    const getActiveDirectoryShowError = useSelector(state => _.get(state, "cvs.getActiveDirectoryShowError"));

    useEffect(() => {
        setState({ detailsAndTagsForm });
    }, [setState, detailsAndTagsForm]);

    const notifications = [{
        type: "error",
        description: getActiveDirectoryError,
        onClose: () => dispatch({ type: "CVS:CLEAR-STATE", payload: { getActiveDirectoryShowError: false }}),
        showError: getActiveDirectoryShowError
    }];

    return (
        <ContentLayout className={styles["container"]} title={"Details & Location"}>
            <div className={styles["content-grid"]}>
                <div className={styles["details"]}>
                    <div className={typography.body16}>Details</div>
                    <div>
                        <div className={styles["name-and-size"]}>
                            <FormFieldInputNew
                                form={detailsAndTagsForm}
                                name="volumeName"
                                label="Volume Name"
                                className={classNames(commonStyles["form-field"], styles["volume-name"])}
                                autoFocus={true}
                                maxChars={255}/>
                            <FormFieldInputNew
                                form={detailsAndTagsForm}
                                name="sizeInGiB"
                                label="Size (GiB)"
                                placeholder=">=1024"
                                maxChars={256}
                                className={classNames(commonStyles["form-field"], styles["volume-size"])}
                                labelChildren={<Popover>Volume size should be greater then or equal to 1024 GiB and smaller then or equal to 102400 GiB</Popover>}
                            />
                        </div>
                        <FormFieldSelect
                            form={detailsAndTagsForm}
                            name="serviceLevel"
                            label="Service level"
                            placeholder="- Select a VNet -"
                            options={serviceLevelOptions}
                            className={classNames(commonStyles["form-field"])}/>
                    </div>
                </div>
                <div className={styles["tags"]}>
                    <div className={typography.body16}>Location</div>
                    <FormFieldSelect
                        form={detailsAndTagsForm}
                        disabled={true}
                        name="region"
                        label="Region"
                    />
                    <FormFieldSelect
                        form={detailsAndTagsForm}
                        disabled={true}
                        name="vpc"
                        label="VPC Network"
                    />
                </div>
            </div>
            <Notifications className={commonStyles["error-area"]} notifications={_.filter(notifications, { showError: true })}/>
        </ContentLayout>
    );
};
