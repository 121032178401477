/*eslint default-case: 0*/

export default (state = {authenticationDialogState: "CLOSED"}, {type}) => {
    switch (type) {
        case "DEVELOPER-HUB:OPEN-DIALOG": {
            return { authenticationDialogState: "AUTHENTICATION" }
        }
        case "DEVELOPER-HUB:SWITCH-STATE-AUTHENTICATION": {
            return { authenticationDialogState: "AUTHENTICATION" }
        }
        case "DEVELOPER-HUB:SWITCH-STATE-REFRESH-TOKEN": {
            console.log("switching to refresh token");
            return { authenticationDialogState: "REFRESH-TOKEN" }
        }
        case "DEVELOPER-HUB:CLOSE-DIALOG": {
            return { authenticationDialogState: "CLOSED" }
        }
    }

    return state;
};
