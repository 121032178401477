import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {NotFoundBody} from "../../../NotFoundPage";
import {VolumeIcon} from "@netapp/bxp-design-system-react/icons/monochrome";
import Loader from "../../../../../../Loader";
import Content from "./Content";
import {PageLayoutNoFooter, SimpleHeader} from "../../../Page";
import {BackToGallery} from "../../common/BackToGalleryButton";
import {titleMapper} from "../../../../utils/common";

export default ({we}) => {
    const dispatch = useDispatch();
    const urlParamAreSet = useSelector(state => _.get(state, "cvs.urlParamsAreSet"));
    const getActiveDirectoryResolved = useSelector(state => _.get(state, "cvs.getActiveDirectoryResolved"));
    const getActiveDirectoryError = useSelector(state => _.get(state, "cvs.getActiveDirectoryError"));
    const getActiveDirectoryShowError = useSelector(state => _.get(state, "cvs.getActiveDirectoryShowError"));
    const activeDirectory = useSelector(state => _.get(state, "cvs.activeDirectory"));

    useEffect(() => {
        if (urlParamAreSet) {
            dispatch({
                type: "CVS:AWS-GET-ACTIVE-DIRECTORY"
            });
        }
        return () => dispatch({
            type: "CVS:CLEAR-STATE",
            payload: {
                activeDirectory: {},
                getActiveDirectoryResolved: false,
                getActiveDirectoryError: null,
                getActiveDirectoryShowError: false,
                createActiveDirectoryResolved: false,
                createActiveDirectoryShowError: false,
                createActiveDirectoryError: null,
                editActiveDirectoryResolved: false,
                editActiveDirectoryShowError: false,
                editActiveDirectoryError: null,
                deleteActiveDirectoryResolved: false,
                deleteActiveDirectoryShowError: false,
                deleteActiveDirectoryError: null,
            }
        });
    }, [dispatch, urlParamAreSet]);

    const hideNotification = () => dispatch({
        type: "CVS:CLEAR-STATE",
        payload: { getActiveDirectoryShowError: false }
    });

    const render = () => {
        if (getActiveDirectoryError) {
            return <NotFoundBody
                errorMessage={getActiveDirectoryError}
                icon={VolumeIcon}
                showError={getActiveDirectoryShowError}
                notFoundText={"Failed to load Active Directory details"}
                deleteNotification={hideNotification}/>
        }
        if (!getActiveDirectoryResolved) {
            return <Loader/>
        }
        return (<Content activeDirectory={activeDirectory}>content</Content>);
    };

    return (
        <PageLayoutNoFooter>
            <SimpleHeader flavour={"aws"} title={`${titleMapper.aws} | ${we.name}`}/>
            <BackToGallery/>
            {render()}
        </PageLayoutNoFooter>
    );
};
